/* eslint-disable no-useless-return */
import { useFetchAndLoad, useToastMui } from "hooks";
import { Roles } from "models";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import useQuery from "utilities/getLocation";
import { userGetOneAdapter } from "../adapters";
import { useUserContext } from "../context";
import { IUsersListDOM } from "../models";
import { getOneUserAPI } from "../pages/usersInformation/services";

export default function useUsersCreateSetValues() {
  const { callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const queryUrl = useQuery();

  const { setOrganization, setLocation } = useUserContext();

  //**Redux  */
  const userState = useSelector((store: AppStore) => store.user);
  const { roles: loggedUserRole, id: loggedUserId } = userState;

  const adminRoles = [`${Roles.superAdmin}`, `${Roles.multiOrgManager}`];

  const setOrgLocDefaultValue = async () => {
    try {
      if (adminRoles.includes(loggedUserRole)) return;

      const response = await callEndpoint(getOneUserAPI(loggedUserId), () => handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"));
      const adaptedData: IUsersListDOM = userGetOneAdapter(response?.data.result);

      const isMultiOrgRole = adaptedData.roleData[0].name === Roles.multiOrgManager;

      if (isMultiOrgRole) {
        setOrganization([]);
      } else {
        setOrganization({ label: adaptedData.organizationData[0]?.name, id: adaptedData.organizationData[0]?.id });
        setLocation({ label: adaptedData.locationData?.name || "", id: adaptedData.locationData?.id || "" });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setOrgLocDefaultValueBasedOnQuery = async () => {
    //** If the user comes from Org or loc landing The data should be autocompleted */
    try {
      //**Data comes from url */
      const autocompleteOrgWithQuery = { id: queryUrl.get("orgId") || "", label: queryUrl.get("orgName") || "" };
      const autocompleteLocWithQuery = { id: queryUrl.get("locId") || "", label: queryUrl.get("locName") || "" };

      setOrganization(autocompleteOrgWithQuery);
      setLocation(autocompleteLocWithQuery);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (queryUrl.get("locId") || queryUrl.get("orgId")) {
      setOrgLocDefaultValueBasedOnQuery();
    } else {
      setOrgLocDefaultValue();
    }
  }, []);
}

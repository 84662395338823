import { IUserAPI, UserDecodeTokenDOM } from "models";

export const userLoginAdapter = (item: IUserAPI): UserDecodeTokenDOM => {
  return {
    email: item.email,
    fullName: item.full_name,
    roles: item?.roles_data[0]?.name,
    uuidAkamai: item.akamai_uuid ?? "",
    id: item.sub,
    firstName: item.first_name,
    lastName: item.last_name,
  };
};

//** React imports */
import { useEffect } from "react";
//** MUI imports and external imports */
import { Grid, Skeleton, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import EmptyTableCards from "pages/locations/components/molecules/emptyTableCards/EmptyTableCards";
import useInfiniteScroll from "react-infinite-scroll-hook";
//** Models */
import { Roles } from "models";
//** Hooks */
import useEmptyCardsLocationData from "hooks/useEmptyCardsLocationData";
import useGetLocationsDashboard from "pages/locations/pages/locationsList/hooks/useLocationsDashboard";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useGlobalContext } from "context/globalContext";
//** Redux */
import { AppStore } from "redux/store";
//** Components imports */
import { EmptyListText } from "components";
import { LocationDashboardView } from "pages/locations/components";
import { AppListHeader } from "../../atoms";
import TableResponsiveRow from "../../responsive/TableResponsiveRow/TableResponsiveRow";
//** Context */
import { useLocationListContext } from "../../../context";
import useGetSearchData from "../../../hooks/useGetSearchData";

export default function LocationsTable({ organizationId }: { organizationId?: string }) {
  //** Redux */
  const userState = useSelector((store: AppStore) => store.user);
  const { organization, roles } = userState;
  const orgIdForMultiOrgs = roles === Roles.multiOrgManager ? "" : organization?.id;

  //**Hopks */
  const { id } = useParams();
  const { loading, hasNextPage, loadMoreLocations, onSearch, page } = useGetLocationsDashboard(
    `${id || orgIdForMultiOrgs || organizationId || ""}`,
    false,
  );
  const { locationHasFilter } = useListAndSearchContext();
  const { locationsMapListArray } = useLocationListContext();
  const { mapView, setLocEditMode } = useGlobalContext();
  const { dataCards } = useEmptyCardsLocationData();
  const { purposeOptions, getPurposes } = useGetSearchData();

  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: page === 0 ? () => {} : () => loadMoreLocations(),
    rootMargin: "0px 0px 400px 0px",
  });

  //**Functions */
  const hasData = locationHasFilter?.length === 0 ? locationsMapListArray.length !== 0 : true;

  useEffect(() => {
    setLocEditMode(false);
    getPurposes();
  }, []);

  return (
    <>
      {!mapView ? (
        <>
          <AppListHeader onSearch={onSearch} />
          <Box display="flex">
            <Box display="block" width="100%">
              <Box height={`calc(100vh - ${`180px - ${locationHasFilter ? "70px" : "0px"}`})`} overflow="scroll">
                {loading && locationsMapListArray.length === 0 ? (
                  <Stack spacing={4}>
                    {Array.from({ length: 12 }).map((_, i) => (
                      <Skeleton key={i} width="100%" height={60} variant="rounded" />
                    ))}
                  </Stack>
                ) : (
                  <Box mt={2}>
                    {locationsMapListArray?.map((item, i) => (
                      <TableResponsiveRow data={item} purposeOptions={purposeOptions} index={i} onSearch={onSearch} />
                    ))}
                    {(hasNextPage || loading) && <Skeleton width="100%" height={80} ref={infiniteRef} />}

                    <Box display="flex" justifyContent="center">
                      {!hasData && (
                        <Grid container spacing={4} justifyContent="center" my={8} ml={0} width="95vw">
                          {dataCards.map((item, index) => (
                            <EmptyTableCards
                              key={index}
                              data={dataCards[index]}
                              name="custom-radios-delivery"
                              gridProps={{ md: 4, sm: 12, xs: 12 }}
                            />
                          ))}
                        </Grid>
                      )}
                      {locationHasFilter?.length > 0 && locationsMapListArray.length === 0 && <EmptyListText />}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <LocationDashboardView
          loading={loading}
          hasNextPage={hasNextPage}
          loadMoreLocations={loadMoreLocations}
          onSearch={onSearch}
          page={page}
        />
      )}
    </>
  );
}

import { SyntheticEvent } from "react";
//** Imports componens */
import { SectionsTitles } from "components";
//**Mui components */
import { Autocomplete, Box, TextField } from "@mui/material";
//** Hooks */
import { useRoleGuard } from "hooks";
import { useTranslation } from "react-i18next";
//** Icon */
import { ArrowDropDown } from "@mui/icons-material";
//**Model
import { Roles } from "models";
import { useOrganizationContext } from "pages/organizations/context";
import { IOptions, useOrganizationInfoContext } from "../../../context/OrganizationInfoContext";
//** Context */

export default function EmailNotificationSection({ createView }: { createView?: boolean }) {
  //** Hooks */
  const { t } = useTranslation();
  const authUpdateOrganization = useRoleGuard([Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager]);
  //** Context */
  const { frecuency, setFrecuency } = useOrganizationInfoContext();
  const { setOrgHasChanges } = useOrganizationContext();
  const frecuencyOptions = [
    {
      label: t("FRECUENCY-1"),
      value: "7",
    },
    { label: t("FRECUENCY-2"), value: "30" },
  ];

  const changeControllerFn = () => setOrgHasChanges(true);

  const handleChange = (event: SyntheticEvent<Element, Event>, newValue: IOptions | null) => {
    changeControllerFn();
    setFrecuency(newValue);
  };
  return (
    <Box mt={12} mx={1}>
      <SectionsTitles
        title={`${t("ORGANIZATION-EMAIL-NOTIFICATION-TITLE")}`}
        subTitle={`${t("ORGANIZATION-EMAIL-NOTIFICATION-SUBTITLE")}`}
      />
      <Box width="100%" display="flex" gap={4}>
        <Box width="100%">
          <Autocomplete
            disabled={!authUpdateOrganization || createView}
            fullWidth
            value={frecuency}
            options={frecuencyOptions}
            onChange={handleChange}
            popupIcon={<ArrowDropDown sx={{ fontSize: "1.5em" }} />}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={`${t("INPUT-FRECUENCY")}`}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            blurOnSelect
          />
        </Box>
        <Box width="100%" />
      </Box>
    </Box>
  );
}

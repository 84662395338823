import { ReactNode, useRef, Ref } from "react";
import PerfectScrollbarComponent, { ScrollBarProps } from "react-perfect-scrollbar";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const PerfectScrollbar = styled(PerfectScrollbarComponent)<ScrollBarProps & { ref: Ref<unknown> }>(({ theme }) => ({
  padding: theme.spacing(5),
}));

export const ScrollWrapper = ({ children, hidden }: { children: ReactNode; hidden: boolean }) => {
  // ** Ref
  const chatArea = useRef(null);
  if (hidden) {
    return (
      <Box ref={chatArea} sx={{ p: 5, height: "100%", overflowY: "auto", overflowX: "hidden" }}>
        {children}
      </Box>
    );
  }
  return (
    <PerfectScrollbar ref={chatArea} options={{ wheelPropagation: false }}>
      {children}
    </PerfectScrollbar>
  );
};

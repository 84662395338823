//**Mui imports */
import { Box, InputAdornment, MenuItem, Select, SelectChangeEvent, Stack, Typography, useMediaQuery } from "@mui/material";
//**Hooks */
import { useTranslation } from "react-i18next";
import { useInputValueContext, useRoleGuard } from "hooks";
//**COMPONENTS IMPORTS */
import { Input } from "components";
import { useOrganizationContext } from "pages/organizations/context";
import { EMAIL_REGEX, Roles } from "models";
import isUrl from "is-url";
import { ChangeEvent } from "react";
import WarningTooltip from "components/molecules/WarningTooltip/WarningTooltip";
import ReturnDataIcon from "components/molecules/ReturnDataIcon/ReturnDataIcon";

export default function OrganizationContact() {
  //** Context */
  const { phoneOrganization, emailOrganization, url, initalUrl, isValidEmail, googleMapsOrgInputs, setOrgHasChanges } =
    useOrganizationContext();
  const { setPhoneOrganization, setEmailOrganization, setUrl, setInitalUrl, setIsValidEmail } = useOrganizationContext();
  //** Hooks */
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:600px)");
  const phoneInput = useInputValueContext("", setPhoneOrganization);

  const urlInput = useInputValueContext("", setUrl);
  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailOrganization(e.target.value);
    setIsValidEmail(EMAIL_REGEX.test(e.target.value));
  };

  const authUpdateOrganization = useRoleGuard([Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager]);

  const phoneGoogleCompare = !!phoneOrganization && !!googleMapsOrgInputs?.phone && googleMapsOrgInputs?.phone !== phoneOrganization;
  const webGoogleCompare = !!url && !!googleMapsOrgInputs?.website && googleMapsOrgInputs?.website !== `${initalUrl}${url}`;

  const websiteWithOutProtocol = googleMapsOrgInputs?.website?.replace("https://", "").replace("http://", "") || "";

  const rolesCanEditOrganization = [Roles.superAdmin, Roles.organizationManager];

  const changeControllerFn = () => setOrgHasChanges(true);

  return (
    <Box>
      <Typography variant="h3" sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}>
        {t("STEP-TITLE-CONTACT")}
      </Typography>
      <Stack direction={!matches ? "row" : "column"} spacing={4} my={6}>
        <Input
          name="org-phone"
          value={phoneOrganization}
          onChange={phoneInput.onChange}
          label={`${t("INPUT-PHONE")}`}
          fullWidth
          disabled={!authUpdateOrganization}
          startAdornment={phoneGoogleCompare ? <WarningTooltip /> : null}
          color={phoneGoogleCompare && "warning"}
          focused={phoneGoogleCompare}
          endAdornment={
            phoneGoogleCompare &&
            googleMapsOrgInputs?.phone && (
              <ReturnDataIcon
                authorizedRoles={rolesCanEditOrganization}
                onClick={() => {
                  setPhoneOrganization(googleMapsOrgInputs.phone);
                }}
              />
            )
          }
          changeControllerFn={changeControllerFn}
        />
        <Input
          name="org-email"
          value={emailOrganization}
          onChange={onChangeEmail}
          label={`${t("INPUT-EMAIL")}`}
          fullWidth
          disabled={!authUpdateOrganization}
          error={!isValidEmail}
          helperText={`${t("INVALID-EMAIL")}`}
          strink={emailOrganization === null ? false : emailOrganization?.length > 0}
          changeControllerFn={changeControllerFn}
        />
      </Stack>
      <Stack direction={!matches ? "row" : "column"} spacing={4} my={6}>
        <Input
          name="org-url"
          value={url}
          onChange={urlInput.onChange}
          label={`${t("INPUT-URL")} *`}
          fullWidth
          endAdornment={
            webGoogleCompare &&
            googleMapsOrgInputs?.website && (
              <ReturnDataIcon
                authorizedRoles={rolesCanEditOrganization}
                onClick={() => {
                  setUrl(websiteWithOutProtocol);
                  setInitalUrl(googleMapsOrgInputs.website.includes("https://") ? "https://" : "http://");
                }}
              />
            )
          }
          inputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Select
                  disabled={!authUpdateOrganization}
                  value={initalUrl}
                  onChange={(event: SelectChangeEvent) => {
                    setInitalUrl(event.target.value);
                  }}
                  variant="standard"
                  disableUnderline
                  sx={{
                    marginTop: "5px",
                  }}
                >
                  <MenuItem value="https://">https://</MenuItem>
                  <MenuItem value="http://">http://</MenuItem>
                </Select>
                {webGoogleCompare ? <WarningTooltip /> : null}
              </InputAdornment>
            ),
          }}
          disabled={!authUpdateOrganization}
          error={url?.length > 0 && !isUrl(`${initalUrl}${url}`)}
          helperText={`${t("INVALID-WEBSITE")}`}
          color={webGoogleCompare && "warning"}
          focused={webGoogleCompare}
          changeControllerFn={changeControllerFn}
        />
      </Stack>
    </Box>
  );
}

//** MUI Imports */
import { Box, Fade, Theme, useMediaQuery } from "@mui/material";
//** Error Management */
import { ErrorBoundary } from "utilities";
//** Context */
import { LocSpeedDial, MapSpeedDialLoc } from "components/responsiveComponents";
import { useGlobalContext } from "context/globalContext";
import { LocationListContextProvider } from "./context";

import LocationsTable from "./components/molecules/locationsTable/LocationsTable";
import LocTableResponsive from "./components/responsive/LocTableResponsive/LocTableResponsive";

export default function LocationsList({ organizationId }: { organizationId?: string }) {
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { locEditMode } = useGlobalContext();

  return (
    <LocationListContextProvider>
      <ErrorBoundary fallBackComponent={<>Something went wrong</>}>
        <Fade in timeout={500}>
          <Box m={!underMd ? 4 : 2}>{!underMd ? <LocationsTable organizationId={organizationId} /> : <LocTableResponsive />}</Box>
        </Fade>
        {underMd && locEditMode && <LocSpeedDial />}
        {underMd && !locEditMode && <MapSpeedDialLoc />}
      </ErrorBoundary>
    </LocationListContextProvider>
  );
}

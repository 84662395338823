// eslint-disable-next-line import/no-anonymous-default-export
export default {
  MuiBreadcrumbs: {
    styleOverrides: {
      li: {
        "& > .MuiLink-root": {
          textDecoration: "none",
        },
      },
    },
  },
};

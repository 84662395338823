//** Reat imports */
import { IOrganizationListDOM } from "pages/organizations/models";
import React, { useState, createContext, useContext, SetStateAction, Dispatch, useMemo } from "react";
//** Models */

/*
The idea of this context it is allow a mechanism to pass the information between the components of the page.
*/
export interface IOptions {
  label: string;
  value: string;
}
export interface ICopyOrganizationData extends Partial<IOrganizationListDOM> {
  initialUrl?: string;
  [index: string]: any;
}
type contextValues = {
  tabValue: string;
  setTabValue: Dispatch<SetStateAction<string>>;
  frecuency: IOptions | null;
  setFrecuency: Dispatch<SetStateAction<IOptions | null>>;
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
  dataCopy: ICopyOrganizationData;
  setDataCopy: Dispatch<SetStateAction<ICopyOrganizationData>>;
  toggleGetData: boolean;
  setToggleGetData: Dispatch<SetStateAction<boolean>>;
};

const defaultValues = {
  tabValue: "1",
  setTabValue: () => {},
  frecuency: { label: "", value: "" },
  setFrecuency: () => {},
  active: false,
  setActive: () => {},
  dataCopy: { id: "", name: "", url: "", logo: "", description: "", isSuspended: false, notificationTime: "" },
  setDataCopy: () => {},
  toggleGetData: false,
  setToggleGetData: () => {},
};

const OrganizationInfoContext = createContext<contextValues>(defaultValues);

const OrganizationInfoContextProvider = (props: any) => {
  const { children } = props;

  const [tabValue, setTabValue] = useState<string>("1");
  const [frecuency, setFrecuency] = useState<IOptions | null>({ value: "", label: "" });
  const [active, setActive] = useState<boolean>(false);
  const [dataCopy, setDataCopy] = useState<ICopyOrganizationData>({
    id: "",
    name: "",
    url: "",
    logo: "",
    description: "",
    isSuspended: false,
    notificationTime: "",
  });
  const [toggleGetData, setToggleGetData] = useState<boolean>(false);

  // eslint-disable-next-line no-shadow
  const contextValues = useMemo(
    () => ({
      tabValue,
      setTabValue,
      frecuency,
      setFrecuency,
      active,
      setActive,
      dataCopy,
      setDataCopy,
      toggleGetData,
      setToggleGetData,
    }),
    [tabValue, frecuency, active, dataCopy, toggleGetData],
  );

  return <OrganizationInfoContext.Provider value={contextValues}>{children}</OrganizationInfoContext.Provider>;
};

export const useOrganizationInfoContext = () => {
  const context = useContext(OrganizationInfoContext);
  if (context === undefined) {
    throw new Error("OrganizationInfoContext must be used within a OrganizationInfoContextProvider");
  }
  return context;
};

export { OrganizationInfoContext, OrganizationInfoContextProvider };

import React, { useState, createContext, useContext, SetStateAction, Dispatch, useMemo } from "react";

/*
The idea of this context it is allow a mechanism to pass the information between the components of the page.
*/

interface IOrganizationOption {
  label: string | null;
  id: string | null;
}

type contextValues = {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  email: string | null;
  setEmail: Dispatch<SetStateAction<string | null>>;
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  emailSBD: string;
  setEmailSBD: Dispatch<SetStateAction<string>>;
  selectedIconRadio: string;
  setSelectedIconRadio: Dispatch<SetStateAction<string>>;
  organization: IOrganizationOption;
  setOrganization: Dispatch<SetStateAction<IOrganizationOption>>;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  location: IOrganizationOption;
  setLocation: Dispatch<SetStateAction<IOrganizationOption>>;
  multiOrgOrganizations: IOrganizationOption[];
  setMultiOrgOrganizations: Dispatch<SetStateAction<IOrganizationOption[]>>;
};

const defaultValues = {
  activeStep: 0,
  setActiveStep: () => {},
  firstName: "",
  setFirstName: () => {},
  lastName: "",
  setLastName: () => {},
  email: null,
  setEmail: () => {},
  phone: "",
  setPhone: () => {},
  emailSBD: "",
  setEmailSBD: () => {},
  selectedIconRadio: "1",
  setSelectedIconRadio: () => {},
  organization: { label: "", id: "" },
  setOrganization: () => {},
  title: "",
  setTitle: () => {},
  location: { label: "", id: "" },
  setLocation: () => {},
  multiOrgOrganizations: [],
  setMultiOrgOrganizations: () => {},
};

const RequestContext = createContext<contextValues>(defaultValues);

const RequestContextProvider = (props: any) => {
  const { children } = props;

  //Array to render items
  const [activeStep, setActiveStep] = useState<number>(0);
  //**Step 1*/
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string | null>(null);
  const [phone, setPhone] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  //** Step 2 */
  const [selectedIconRadio, setSelectedIconRadio] = useState<string>("1");
  //**Step 3*/
  const [emailSBD, setEmailSBD] = useState<string>("");
  //**Step 3*/
  const [organization, setOrganization] = useState<IOrganizationOption>({ label: "", id: "" });
  const [multiOrgOrganizations, setMultiOrgOrganizations] = useState<IOrganizationOption[]>([]);
  const [location, setLocation] = useState<IOrganizationOption>({ label: "", id: "" });

  // eslint-disable-next-line no-shadow
  const contextValues = useMemo(
    () => ({
      activeStep,
      setActiveStep,
      firstName,
      setFirstName,
      lastName,
      setLastName,
      email,
      setEmail,
      phone,
      setPhone,
      emailSBD,
      setEmailSBD,
      selectedIconRadio,
      setSelectedIconRadio,
      organization,
      setOrganization,
      title,
      setTitle,
      location,
      setLocation,
      multiOrgOrganizations,
      setMultiOrgOrganizations,
    }),
    [activeStep, firstName, lastName, email, phone, emailSBD, selectedIconRadio, organization, title, location, multiOrgOrganizations],
  );

  return <RequestContext.Provider value={contextValues}>{children}</RequestContext.Provider>;
};

export const useRequestContext = () => {
  const context = useContext(RequestContext);
  if (context === undefined) {
    throw new Error("RequestContext must be used within a RequestContextProvider");
  }
  return context;
};

export { RequestContext, RequestContextProvider };

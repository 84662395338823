/* eslint-disable no-nested-ternary */
//** React imports */
import { useState } from "react";
//**Hooks */
import { useAsync, useFetchAndLoad, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
//**Model */
import { IUsersListDOM, IUsersListFromAPI, IUsersLoadHook, IUsersSearchHook } from "pages/users/models";
//**Utils */
import { createQuery } from "utilities";
//* Redux
import { AppStore } from "redux/store";
import { useSelector } from "react-redux";
//**Services */
import { getAllUsersAPI } from "pages/users/pages/usersList/services";
import { userGetallAdapter } from "pages/users/pages/usersList/adapters";
//** Context */
import { useUsersListContext } from "pages/users/pages/usersList/context";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import { PrivateRoutes, array20, array50 } from "models";
import { UserSearchFields } from "pages/users/pages/usersList/hooks/useUsersSearch";

export default function useGetUsersDashboard(organizationId: string, locationId: string, embebed?: boolean) {
  //** Context */
  const { setHasFilter, setUsersHasFilter, userGetToggle } = useListAndSearchContext();
  const { usersArray } = useUsersListContext();
  const { setUsersArray, setLoad } = useUsersListContext();
  //**Search */
  const { searchParamsLocs } = useListAndSearchContext();
  const firstNameSearch = searchParamsLocs.get(UserSearchFields.firstName);
  const lastNameSearch = searchParamsLocs.get(UserSearchFields.lastName);
  const emailSearch = searchParamsLocs.get(UserSearchFields.email);
  const statusUserSearch = searchParamsLocs.get(UserSearchFields.status);
  const roleSearch = searchParamsLocs.get(UserSearchFields.roleId); //** needs the ids */
  const organizationUserSearch = searchParamsLocs.get(UserSearchFields.organization); //** needs the ids */

  //** State */
  const [page, setPage] = useState<number>(0);
  const [searchToggle, setSearchToggle] = useState(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const limit = 50;
  const initialPagination = 20;

  //to sort list by api
  const [countCode, setCountCode] = useState(1);
  const [typeSort, setTypeSort] = useState("");

  //** Hooks */
  const { loading, callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const { startHistory } = useBreadcrumbs();

  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);

  const organizationIdOnView = embebed
    ? organizationId || organizationUserSearch
    : userState?.organization?.id
    ? `${userState?.organization.id}`
    : organizationId;

  const loadUsers: any = async ({ first_name, last_name, username, role_id, status, organization_id, location_id }: IUsersLoadHook) => {
    //? When non admin user in on organization landing they list must be filter by ther url org id
    //? Then in the others views is most important the user organization that was linked
    setLoad([...array20]);

    //Create an object with key that have some search value
    //If the table doesn't have a filter it will be sorted by creation date
    let query = createQuery<IUsersSearchHook>({
      first_name,
      last_name,
      username,
      role_id,
      status,
      organization_id: organizationIdOnView,
      location_id,
      sort: typeSort || "desc(name_short)",
    });
    setHasFilter(`${first_name}${last_name}${username}${role_id}${status}`);
    query = { offset: 0, limit: initialPagination, ...query };
    //Calling get all API
    setUsersHasFilter(`${first_name}${last_name}${username}${role_id}${status}${embebed ? "" : organization_id}`);
    const response = await callEndpoint(getAllUsersAPI({ query }), () => handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"));

    return response;
  };

  const adaptInfo = (data: IUsersListFromAPI) => {
    //Save the adapted data and the last page and length for the pagination
    const array: IUsersListDOM[] = userGetallAdapter(data?.result?.items);
    setUsersArray(array);

    //** Update the page to call the next data on api */
    setPage((prev: number) => prev + 1);
    //** If dosen`t appear more data sent that there are not more information*/
    if (!data?.result?.next) setHasNextPage(false);

    if (!organizationId) {
      startHistory({
        url: `/${PrivateRoutes.ORGANIZATIONS_DASHBOARD}`,
        label: "Dashboard",
        index: 0,
      });
    }
  };

  /**
   ** This function appears on dashboard for infinite scroll on users list
   * @return { response }
   */
  const loadMoreUsers = async () => {
    //? When non admin user in on organization landing they list must be filter by ther url org id
    //? Then in the others views is most important the user organization that was linked
    setLoad((prev) => [...prev, ...array50]);
    // eslint-disable-next-line no-nested-ternary

    let query = createQuery<IUsersSearchHook>({
      first_name: firstNameSearch,
      last_name: lastNameSearch,
      username: emailSearch,
      role_id: roleSearch,
      status: statusUserSearch,
      organization_id: organizationIdOnView,
      location_id: locationId,
      sort: typeSort || "desc(name_short)",
    });
    //?first 20 are loaded and then loaded 50 at a time
    if (page === 1) {
      query = { ...query, offset: initialPagination };
    } else {
      query = { ...query, offset: (page - 1) * limit + initialPagination };
    }

    query = { ...query, limit };

    //Calling get all API
    const response = await callEndpoint(getAllUsersAPI({ query }), () => handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"));
    const { data } = response;

    //Save the adapted data and the last page and length for the pagination
    const array: IUsersListDOM[] = userGetallAdapter(data?.result?.items);
    setUsersArray((prev) => [...prev, ...array]);
    //** Update the page to call the next data on api */
    setPage((prev: number) => prev + 1);

    //** If dosen`t appear more data sent that there are not more information*/
    if (!data?.result?.next) setHasNextPage(false);
    return response;
  };

  useAsync(
    () =>
      loadUsers({
        first_name: firstNameSearch || "",
        last_name: lastNameSearch || "",
        username: emailSearch || "",
        role_id: roleSearch || "",
        status: statusUserSearch || "",
        organization_id: organizationId || organizationUserSearch || "",
        location_id: locationId || "",
      }),
    adaptInfo,
    () => {},
    [searchToggle, userState?.organization?.id, userGetToggle],
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const onSearch = () => {
    setSearchToggle((value) => !value);
    setPage(0);
    setHasNextPage(true);
  };

  // * ------------------------SORT----------------------------------//

  // sort

  const incrementCount = (key: string) => {
    setCountCode((prev) => prev + 1);

    if (countCode === 1) {
      setTypeSort(`asc(${key})`);
    }
    if (countCode === 2) {
      setTypeSort(`desc(${key})`);
      setCountCode(1);
    }
  };

  return {
    usersArray,
    loading,
    limit,
    handleChange,
    onSearch,
    incrementCount,
    loadMoreUsers,
    hasNextPage,
    page,
  };
}

//**Imports */
import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { Chip } from "components/molecules";
import { IFilterChips } from "models";

//**Hooks */
import { useTranslation } from "react-i18next";

export default function FilterChips({
  filterChips,
  handleDelete,
  handleDeleteAll,
}: {
  filterChips: IFilterChips[];
  // eslint-disable-next-line no-unused-vars
  handleDelete: Function;
  handleDeleteAll: () => void;
}) {
  //**Hooks */
  const { t } = useTranslation();
  const dataExist = filterChips.filter((item: IFilterChips) => item.data);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useTheme();
  return (
    <Box display="flex" flexWrap="wrap" gap={4} alignItems="center">
      {filterChips.map((items) => (
        <>
          {items.data ? (
            <Chip
              key={items.key}
              skin={theme.palette.mode === "light" ? "light" : undefined}
              label={`${t(items.label)}: ${items.data}`}
              onDelete={() => handleDelete(items.key)}
              color="primary"
              sx={{ fontSize: "1.4rem", maxWidth: "50vw" }}
            />
          ) : null}
        </>
      ))}
      {dataExist.length > 0 && (
        <Typography variant="h6" sx={{ cursor: "pointer" }} onClick={handleDeleteAll}>
          {t("BUTTON-CLEAR-ALL")}
        </Typography>
      )}
    </Box>
  );
}

import { Box, Typography } from "@mui/material";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { actions } from "context/globalContext";
import { useFetchAndLoad, useGetReduxUser, useToastMui } from "hooks";
import { Roles } from "models";
import { notificationsAdapter } from "pages/locations";
import { ImportModalUsers } from "pages/users/components/molecules/ImportModalUsers";
import { importUsersAPI } from "pages/users/services";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addNotification } from "redux/slices/user.slice";
import { getCurrentDate } from "utilities/getCurrentDate";

export default function UserImportOnHeader({
  setCallStatus,
  callStatus,
}: {
  callStatus: "success" | "error" | null;
  setCallStatus: Dispatch<SetStateAction<"success" | "error" | null>>;
}) {
  const { callEndpoint, loading } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { userState } = useGetReduxUser();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { openImportModalUser, setOpenImportModalUser } = useListAndSearchContext();
  const { setUserGetToggle } = useListAndSearchContext();

  //**Modal */
  const handleClose = () => setOpenImportModalUser(false);
  const handleConfirm = async (file: File) => {
    setCallStatus(null);
    handleClose();
    handleCreateToast(
      <Box>
        <Typography variant="h5" mb={4} fontWeight={700}>
          {t("LOC-IMPORT-NOTIFICATION-TITLE")}
        </Typography>
        <Typography fontSize={6}>{t("LOC-IMPORT-NOTIFICATION-DESC-1")}</Typography>
        <Typography fontSize={6}>{t("LOC-IMPORT-NOTIFICATION-DESC-2")}</Typography>
      </Box>,
      "notification",
      true,
      { horizontal: "right", vertical: "top" },
    );
    try {
      const response = await callEndpoint(
        importUsersAPI({ file, organization: userState.roles === Roles.superAdmin ? null : `${userState.organization?.name}` }),
      );
      const { data } = response;
      const adaptedData = notificationsAdapter(data);
      setCallStatus("success");

      dispatch(
        addNotification({
          id: `${Math.random()}`,
          name: actions.userImportSuccess,
          emailAssosiated: userState.email,
          date: getCurrentDate(),
          fileName: file.name,
          ...adaptedData,
        }),
      );
      setUserGetToggle((prev) => !prev);
    } catch (error: any) {
      console.log(error);
      setCallStatus("error");
      dispatch(
        addNotification({
          name: actions.userImportError,
          emailAssosiated: userState.email,
          error: `${error.response.data.message || "Importation is taking too long"}`,
          date: getCurrentDate(),
        }),
      );
    }
  };
  return (
    <ImportModalUsers
      open={openImportModalUser}
      handleConfirm={handleConfirm}
      handleClose={handleClose}
      loading={loading}
      callStatus={callStatus}
    />
  );
}

/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { ModalConfirm } from "components";
import { RoleGuard } from "guards";
import { PrivateRoutes, Roles } from "models";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDeleteLocations } from "pages/locations/hooks";
import { useNavigate } from "react-router-dom";

export default function LocFastActions({
  onSearch,
  id,
  isHQ,
  handleMouseLeave,
  isDashboard,
}: {
  onSearch: Function;
  id: string;
  isHQ: boolean;
  handleMouseLeave?: (id: string) => void;
  isDashboard?: boolean;
}) {
  //** Context */
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  //** Context */
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  //** Hooks */
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { deleteLocations } = useDeleteLocations();
  const handleCloseDelete = () => setOpenModalDelete(false);

  const open = Boolean(anchorEl);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    if (handleMouseLeave) handleMouseLeave(id);
  };

  return (
    <Box onClick={(e) => e.stopPropagation()} display="inline-block" onTouchStart={(e) => e.stopPropagation()}>
      <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager, Roles.locationManager]}>
        <IconButton
          color="primary"
          aria-label="edit-organization"
          sx={{
            "&:focus": {
              outline: "0px auto -webkit-focus-ring-color",
            },
            p: 0,
            m: 0,
          }}
          onClick={(e) => {
            handleClickMenu(e);
          }}
        >
          <MoreVertIcon sx={{ fontSize: "2.5rem" }} color="secondary" />
        </IconButton>
      </RoleGuard>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager, Roles.locationManager]}>
          <MenuItem
            onClick={() => {
              navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.EDIT_NAV}/${id}`);
              handleCloseMenu();
            }}
          >
            <Typography variant="h3">{t("GLOBAL-BULK-ACTIONS-EDIT")}</Typography>
          </MenuItem>
        </RoleGuard>
        {!isDashboard && (
          <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager]}>
            <MenuItem
              onClick={() => {
                navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${id}`);
                handleCloseMenu();
              }}
            >
              <Typography variant="h3">{t("GLOBAL-BULK-ACTIONS-SHOW")}</Typography>
            </MenuItem>
          </RoleGuard>
        )}
        <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager]}>
          <MenuItem
            onClick={() => {
              setOpenModalDelete(true);
              handleCloseMenu();
            }}
          >
            {t("ICON-DELETE")}
          </MenuItem>
        </RoleGuard>
      </Menu>
      <Box display="flex" alignItems="center">
        <ModalConfirm
          open={openModalDelete}
          title="Delete 1 location?"
          description={isHQ ? t("LOCATIONS-DELETE-MODAL-ERROR-HQ") : t("LOCATIONS-DELETE-MODAL-CONFIRM-DESCRIPTION")}
          textButtonConfirm={t("BUTTON-ACCEPT")}
          handleClose={handleCloseDelete}
          handleConfirm={() => {
            deleteLocations([id]);
            setOpenModalDelete(false);
            onSearch();
          }}
          disableAccept={isHQ}
          sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
          sxTitle={{ fontSize: "2rem !important" }}
        />
      </Box>
    </Box>
  );
}

/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import { Box, Skeleton, Theme, Typography, useMediaQuery, useScrollTrigger, useTheme } from "@mui/material";
import { PrivateRoutes } from "models";
//**Icon */
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PlaceIcon from "@mui/icons-material/Place";
//**Hooks */
import { useNavigate, useParams } from "react-router-dom";

import { hexToRGBA } from "utilities";
import { useSettings } from "hooks";
import useQuery from "utilities/getLocation";
import { useTranslation } from "react-i18next";

export default function LocClaimHeader({ loading }: { loading?: boolean }) {
  const query = useQuery();
  //** Context */
  const { organizationId } = useParams();

  //** Hooks */
  const navigate = useNavigate();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();
  //** Change top color */
  const { settings } = useSettings();
  const scrollTrigger = useScrollTrigger({ threshold: 0, disableHysteresis: true });
  const theme = useTheme();
  const appBarFixedStyles = () => {
    return {
      backgroundColor: hexToRGBA(theme.palette.background.paper, 1),
    };
  };
  return (
    <Box
      position={underMd ? "sticky" : "static"}
      px={5}
      pt={8}
      pb={4}
      sx={{
        backgroundColor: underMd ? "background.default" : "background.paper",
        top: 60,
        zIndex: 10,
        ...(settings.appBar === "fixed" && scrollTrigger && { ...appBarFixedStyles() }),
      }}
    >
      <Box display="flex" alignItems="center" justifyContent={underMd ? "space-between" : ""}>
        <Box
          mr={4}
          display="flex"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${organizationId}`, { replace: false });
          }}
        >
          <ArrowBackIosNewIcon sx={{ fontSize: 16, stroke: (theme: Theme) => `${theme.palette.text.primary}`, strokeWidth: 2, mr: 2 }} />
          <PlaceIcon sx={{ fontSize: 24, mr: 4 }} />
          {loading ? (
            <Skeleton variant="rounded" width="calc(100vw - 140px)" height={30} />
          ) : (
            <Typography variant="h1" fontWeight="700">
              {query.get("orgName") || t("GLOBAL-TITLE-ORG-LANDING")}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

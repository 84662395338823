import { lazy } from "react";
import { PrivateRoutes } from "models";
import { Navigate, Route } from "react-router-dom";
import AdminGuard from "guards/admin.guard";
import ErrorView from "pages/ErrorView";
import { ErrorBoundary, RoutesWithNotFound } from "utilities";
import { UserContextProvider } from "./context/UserContext";
import UsersProfile from "./pages/userProfile/UserProfile";

const UsersList = lazy(() => import("./pages/usersList/UsersList"));
const UsersInformation = lazy(() => import("./pages/usersInformation/UsersInformation"));
const UsersCreate = lazy(() => import("./pages/usersCreate/UsersCreate"));

export default function Users() {
  return (
    <ErrorBoundary fallBackComponent={<ErrorView />}>
      <UserContextProvider>
        <RoutesWithNotFound>
          <Route path="/" element={<Navigate to={PrivateRoutes.LIST} />} />
          <Route element={<AdminGuard />}>
            <Route path={PrivateRoutes.LIST} element={<UsersList />} />
          </Route>
          <Route path={PrivateRoutes.INFORMATION} element={<UsersInformation />} />
          <Route path={PrivateRoutes.CREATE} element={<UsersCreate />} />
          <Route path={PrivateRoutes.USERS_PROFILE} element={<UsersProfile />} />
        </RoutesWithNotFound>
      </UserContextProvider>
    </ErrorBoundary>
  );
}

import { exportFromGetUrl, getAllFromCustomUrl, getAllFromUrl, getOneFromUrl, postFromUrl, putFromUrl } from "utilities";
import {
  IBulkLocation,
  IDeleteLocations,
  ILocationsFromAPI,
  ILocationsListFromAPI,
  IMapsAdditionalListFromAPI,
  IlocationListWithOutTokenFromAPI,
} from "pages/locations/models";
import {
  IClaimLocation,
  IClaimLocationBody,
  ICreateBodyLocation,
  ICreateBodyLocationAPI,
  IUpdateBodyLocation,
  IUpdateBodyLocationAPI,
} from "./api.interfaces";

export const getAllLocationsAPI = (props?: any) => {
  return getAllFromUrl<ILocationsListFromAPI>("locations", props);
};

export const getAllLocationWithOutTokenAPI = (props?: unknown) => {
  return getAllFromUrl<IlocationListWithOutTokenFromAPI>("locations/list-name", props);
};
export const getAllNextLocationsAPI = (next: string) => {
  return getAllFromCustomUrl<ILocationsListFromAPI>(next);
};

export const getOneLocationAPI = (id: string) => {
  return getOneFromUrl<ILocationsFromAPI>(`locations/${id}`);
};

export const updateLocationAPI = (id: string, { body }: Partial<IUpdateBodyLocationAPI>) => {
  return putFromUrl<unknown, IUpdateBodyLocation>(`locations/${id}`, body as any);
};

export const createLocationsAPI = ({ body }: ICreateBodyLocationAPI) => {
  return postFromUrl<unknown, ICreateBodyLocation>("locations", body);
};

export const getAllBrandsAPI = (props?: unknown) => {
  return getAllFromUrl("brands/get-all", props);
};

export const getAllRegionsAPI = (props?: unknown) => {
  return getAllFromUrl("regions/get-all", props);
};
export const getAllPurposesAPI = (props?: unknown) => {
  return getAllFromUrl("purposes/get-all", props);
};

export const claimLocationsAPI = ({ body }: IClaimLocation) => {
  return postFromUrl<unknown, IClaimLocationBody>("locations/claim-location", body);
};

export const googleMapsAdditionalDataAPI = ({ mapId }: { mapId: string }) => {
  return getOneFromUrl<IMapsAdditionalListFromAPI>(`locations/places/${mapId}`);
};

export const bulkLocationAPI = ({ body }: { body: IBulkLocation }) => {
  return putFromUrl<unknown, IBulkLocation>("locations/bulk-update", body);
};
export const getLocationImportTemplateAPI = (props?: any) => {
  return exportFromGetUrl("locations/download-template", props);
};

export const getLocationExportAPI = (props?: any) => {
  return exportFromGetUrl("locations/export-csv", props);
};

export const importLocationsAPI = ({ file, organization }: { file: File; organization: string | null }) => {
  const body = new FormData();
  body.append("file", file);
  const url = organization ? `locations/import?organization_name=${organization}` : "locations/import";
  return postFromUrl<unknown, FormData>(url, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteLocationsAPI = (usersIds: string[]) => {
  return putFromUrl<unknown, IDeleteLocations>("locations/delete-many", { items: usersIds });
};

import { Roles } from "models";
import { PrivateRoutes } from "models/routes";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { AppStore } from "redux/store";

export default function OrganizationGuard() {
  const userState = useSelector((store: AppStore) => store.user);
  const { organization } = userState;
  const role = userState.roles;
  return organization || role === Roles.superAdmin ? <Outlet /> : <Navigate to={PrivateRoutes.SELECT_ORGANIZARION} />;
}

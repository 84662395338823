/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

export enum ChipStatus {
  UNCLAIMED = "Unclaimed",
  CLAIMED = "Claimed",
  CREATED = "Created",
}

export enum Methods {
  MANUAL = "Manual",
  CLAIMED = "Claimed",
  UNCLAIMED = "Unclaimed",
}

export const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const HTTP_REGEX = /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/;
export const TIME_REGEX = /^([0-1][0-9]|2[0-3]):(00|30)$/;
export const COMMA_REGEX = /\s*,\s*/g;
export const PHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export const PHONE_REGEX_INTERNATIONAL = /^\+(?:[0-9] ?){6,14}[0-9]$/;
export const DATE_FORMAT = "DD-MM-YYYY";
export const LAT_LONG_REGEX = /^-?\d{1,3}\.\d{1,18}$/;
export const NUMBER_REGEX = /^-?\d+(\.\d+)?$/;
export const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
export const daysShort = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

export enum exportModalActions {
  EXPORT_ALL = "1",
  EXPORT_SELECTED_ITEMS = "2",
  EXPORT_CURRENT_SEARCH = "3",
  EXPORT_CURRENT_PAGE = "0",
}

//** Hooks */
import { useFetchAndLoad, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
//** Models */
import { Roles } from "models";
//** Utils */
import { capitalize } from "utilities";

//** Context */
import { useRequestContext } from "../context";
//** Services*/
import { IRequestAccessBodyUser, requestAccessAPI } from "../services";
import { RequestAccessRoleEnum } from "../models/requestAccessEnum";

export default function useRequestAccess() {
  //** States */
  const {
    firstName,
    lastName,
    email,
    phone,
    emailSBD,
    selectedIconRadio,
    organization,
    title,
    location,
    multiOrgOrganizations,
    setActiveStep,
  } = useRequestContext();
  //** Hooks */
  const { callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();

  const rolesArray = [
    Roles.multiOrgManager,
    Roles.multiOrgViewer,
    Roles.organizationManager,
    Roles.organizationViewer,
    Roles.locationManager,
    Roles.locationViewer,
  ];

  const requestAccess = async () => {
    const selectedRadioMultiOrg = [RequestAccessRoleEnum.multiOrgManager, RequestAccessRoleEnum.multiOrgViewer];
    try {
      const body: IRequestAccessBodyUser = {
        username: `${email}`,
        first_name: firstName,
        last_name: lastName,
        phone,
        description: "",
        sbd_contact: emailSBD,
        roles: [rolesArray[+selectedIconRadio - 1]],
        organization_id: selectedRadioMultiOrg.includes(selectedIconRadio as RequestAccessRoleEnum)
          ? multiOrgOrganizations.map((item) => `${item.id}`)
          : [`${organization.id}`],
        designation: title,
        location_id: location?.id || "",
      };

      await callEndpoint(requestAccessAPI({ body }), (error: any) =>
        handleCreateToast(`${t("ALERT-ERROR-REQUEST")} : ${capitalize(error?.response?.data?.message)} `, "error"),
      );

      setActiveStep(4);
    } catch (error) {
      console.error(error);
    }
  };
  return { requestAccess };
}

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import {
  IHQLocationAPI,
  IHQLocationDOM,
  IOrganizationListAPI,
  IOrganizationListDOM,
  IUsersDatAPI,
  IUsersDataDOM,
} from "pages/organizations/models";
import { orgPrimaryContactAdapter } from "../../organizationsInformation/adapters";

export const organizationGetallAdapter = (items: IOrganizationListAPI[]): IOrganizationListDOM[] => {
  const array: IOrganizationListDOM[] = [];
  items?.map((item: IOrganizationListAPI) => {
    return array.push({
      id: item.id,
      name: item.name,
      isSuspended: item.is_suspended,
      logo: item.logo,
      notificationTime: item.notification_time,
      users: item.users,
      users_data: userDataAdapter(item.users_data),
      description: item.description,
      hqInformation: hqLocDataAdapter(item.hq_information),
      primaryContact: item.primary_contact,
      primaryContactData: orgPrimaryContactAdapter(item.primary_contact_data),
      locationCounter: item.location_count,
      userCounter: item.user_count,
    });
  });
  return array;
};
const userDataAdapter = (items: IUsersDatAPI[] | undefined): IUsersDataDOM[] => {
  const array: IUsersDataDOM[] = [];
  items?.map((item: IUsersDatAPI) => {
    return array.push({
      id: item._id,
      akamaiUuid: item.akamai_uuid,
      createdAt: item.created_at,
      deletedAt: item.deleted_at,
      roles: item.roles,
      updatedAt: item.updated_at,
      username: item.username,
    });
  });
  return array;
};

const hqLocDataAdapter = (item: IHQLocationAPI): IHQLocationDOM => ({
  address: item?.address ? item?.address : "",
  email: item?.email ? item?.email : "",
  phone: item?.phone ? item?.phone : "",
  website: item?.website ? item?.website : "",
  id: item?.loc_id ? item?.loc_id : "",
  region: item?.region ? item?.region : "",
});

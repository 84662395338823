/* eslint-disable no-nested-ternary */
import { EMAIL_REGEX } from "models";
import { useOrganizationContext } from "../context";

export default function useChangeHq() {
  const {
    setUrl,
    setInitalUrl,
    setEmailOrganization,
    setIsValidEmail,
    setPhoneOrganization,
    setCityOrganization,
    setLocHqId,
    setMapId,
    setLatOrganization,
    setLongOrganization,
  } = useOrganizationContext();

  const { setAddress2Organization, setCountryOrganization, setPostalCodeOrganization, setRegionOrganization, setStateOrganization } =
    useOrganizationContext();

  const updateFieldsWithHQSelected = (data: any) => {
    setUrl(data.url?.includes("https://") ? data.url.slice(8) : data.url?.includes("http://") ? data.url.slice(7) : data.url);
    setInitalUrl(data.url?.includes("https://") ? "https://" : data.url?.includes("http://") ? "http://" : "https://");
    setEmailOrganization(data.email);
    setPhoneOrganization(data.phone);
    setIsValidEmail(EMAIL_REGEX.test(data.email));
    setLocHqId(data.id);

    //** Address */
    setStateOrganization(data.state);
    setMapId(data.mapId);
    setAddress2Organization(data.address2);
    setCountryOrganization({ label: data.country, id: data.country });
    setCityOrganization(data.city);
    setPostalCodeOrganization(data.postalCode);
    setRegionOrganization({ label: data.region, id: data.region });
    setLatOrganization(`${data.coordinates[0]}`);
    setLongOrganization(`${data.coordinates[1]}`);
  };

  return { updateFieldsWithHQSelected };
}

import { Box, Typography } from "@mui/material";

export default function SectionsTitles({
  title,
  subTitle,
  mb,
  h2,
  fontWeight = 600,
}: {
  title: string;
  subTitle?: string;
  mb?: number;
  h2?: boolean;
  fontWeight?: number;
}) {
  return (
    <Box sx={{ mb: mb || 12 }}>
      <Typography variant={!h2 ? "h1" : "h2"} sx={{ mb: 1.5, fontWeight, letterSpacing: "0.18px" }}>
        {title}
      </Typography>
      {subTitle && <Typography variant="h6">{subTitle}</Typography>}
    </Box>
  );
}

/* eslint-disable no-shadow */
import React, { useState, createContext, useContext, SetStateAction, Dispatch, useMemo } from "react";

/*
The idea of this context it is allow a mechanism to pass the information between the components of the page.
*/

type contextValues = {
  email: string | null;
  setEmail: Dispatch<SetStateAction<string | null>>;
  password: string | null;
  setPassword: Dispatch<SetStateAction<string | null>>;
  isValid: boolean;
  setIsValid: Dispatch<SetStateAction<boolean>>;
};

const defaultValues = {
  email: null,
  setEmail: () => {},
  password: null,
  setPassword: () => {},
  isValid: false,
  setIsValid: () => {},
};

const LoginContext = createContext<contextValues>(defaultValues);

const LoginContextProvider = (props: any) => {
  const { children } = props;

  //Array to render items
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [isValid, setIsValid] = useState<boolean>(true);

  const contextValues = useMemo(
    () => ({
      email,
      setEmail,
      password,
      setPassword,
      isValid,
      setIsValid,
    }),
    [email, password, isValid],
  );

  return <LoginContext.Provider value={contextValues}>{children}</LoginContext.Provider>;
};

export const useLoginContext = () => {
  const context = useContext(LoginContext);
  if (context === undefined) {
    throw new Error("LoginContext must be used within a LoginContextProvider");
  }
  return context;
};

export { LoginContext, LoginContextProvider };

//** MUI Imports */
import { UserContextProvider } from "pages/users";
import { UsersListContextProvider } from "pages/users/pages/usersList/context";
import { LocationListContextProvider } from "../locationsList";
import { LocationDashboard } from "./components/organism/LocationDashboard";

export default function LocationsInformation() {
  return (
    <UserContextProvider>
      <UsersListContextProvider>
        <LocationListContextProvider>
          <LocationDashboard />
        </LocationListContextProvider>
      </UsersListContextProvider>
    </UserContextProvider>
  );
}

import { Dispatch, SetStateAction, useEffect, useState } from "react";
//** MUI Imports */
import { Box, Checkbox, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
//** Model */
import { IDropdownOption } from "models";
//** Icon */
import { ArrowDropDown } from "@mui/icons-material";

interface IInputApiCalls {
  // eslint-disable-next-line no-unused-vars
  apiCallFunction: (value: string) => any;
  label: string;
  loading: boolean;
  setPropValue: Dispatch<SetStateAction<IDropdownOption[]>>;
  changeWithInputText?: boolean; //when need a query to call the api with autocomplete input value
  initialValue: any;
  disabled?: boolean;
  requiredFields?: boolean;
  alertLabel?: string;
  size?: "small" | "medium";
  changeControllerFn?: Function;
}
const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
const iconAll = <IndeterminateCheckBoxIcon fontSize="large" />;
const checkedIcon = <CheckBoxIcon fontSize="large" />;

export default function InputApiCallCheckbox({
  apiCallFunction,
  label,
  loading,
  initialValue,
  setPropValue,
  disabled,
  requiredFields,
  alertLabel,
  size,
  changeControllerFn,
}: IInputApiCalls) {
  const [value, setValue] = useState<any>(initialValue ?? []);
  const [options, setOptions] = useState<any[]>([]);
  const isAllSelected = options?.length > 0 && value?.length === options?.length;

  const callApis = async (inputValue: string) => {
    // if (options.length && !changeWithInputText) return;
    const arrayResponse: any = await apiCallFunction(inputValue);
    setOptions(arrayResponse);
  };

  useEffect(() => {
    if (initialValue.length > 0 && value.length === 0) {
      setValue(initialValue);
    }
    if (window.location.pathname.includes("create") && initialValue.length === 0) {
      setValue([]);
    }
  }, [initialValue]);
  useEffect(() => {
    callApis(value);
  }, []);

  const handleChange = (event: any, newValue: any | null) => {
    if (changeControllerFn) changeControllerFn();
    if (newValue.findIndex((item: any) => item.label === "Select all") !== -1) {
      setValue(options);
      setPropValue(options);
    } else if (isAllSelected) {
      setValue([]);
      setPropValue([]);
    } else {
      setValue(newValue);
      setPropValue(newValue);
    }
  };

  return (
    <Box width="100%">
      <Autocomplete
        size={size}
        disabled={disabled}
        fullWidth
        multiple
        loading={loading}
        popupIcon={<ArrowDropDown sx={{ fontSize: "1.5em" }} />}
        value={value}
        disableCloseOnSelect
        options={options}
        onChange={handleChange}
        isOptionEqualToValue={(option, valueOption) => option?.label === valueOption?.label}
        renderOption={(props: any, option: any, { selected }) => (
          <li {...props}>
            {option.name === "Select all" ? (
              <>
                <Checkbox
                  icon={icon}
                  checkedIcon={iconAll}
                  checked={selected}
                  style={{ marginRight: 8 }}
                  indeterminate={value.length > 0 && value.length <= options.length}
                />
                Select All
              </>
            ) : (
              <>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.label}
              </>
            )}
          </li>
        )}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label={label}
            sx={{ "& .MuiChip-label": { fontSize: 16 } }}
            color={requiredFields && "error"}
            focused={requiredFields}
          />
        )}
        onClick={() => callApis(value)}
      />
      {requiredFields && alertLabel && (
        <Typography component="span" color="error" ml={6} mt={2}>
          {alertLabel}
        </Typography>
      )}
    </Box>
  );
}

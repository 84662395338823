/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
// ** React Imports
import { useState, SyntheticEvent } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import MuiMenu, { MenuProps } from "@mui/material/Menu";
import MuiMenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import Typography, { TypographyProps } from "@mui/material/Typography";

// ** Icon Imports
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";

import { Settings } from "context/settingsContext";
// ** Custom Components Imports
import { Divider } from "@mui/material";
import { actions } from "context/globalContext";
import { INotificationDOM } from "models";
import { ImportDetailsModal } from "components/molecules/ImportDetailsModal";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { LocalStorageKeys, getInLocalStorage, getInLocalStorageArray, setInLocalStorage, setInLocalStorageArray } from "utilities";
import { TooltipApp } from "components";
import { useTranslation } from "react-i18next";

export type NotificationsType = {
  meta: string;
  title: string;
  subtitle: string;
};
interface Props {
  settings: Settings;
}

// ** Styled Menu component
const Menu = styled(MuiMenu)<MenuProps>(({ theme }) => ({
  "& .MuiMenu-paper": {
    width: 380,
    overflow: "hidden",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .MuiMenu-list": {
    padding: 0,
  },
}));

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)<MenuItemProps>(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  "&:not(:last-of-type)": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

// ** Styled component for the title in MenuItems
const MenuItemTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  flex: "1 1 100%",
  overflow: "hidden",
  fontSize: "0.875rem",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginBottom: theme.spacing(0.75),
}));

// ** Styled component for the subtitle in MenuItems
const MenuItemSubtitle = styled(Typography)<TypographyProps>({
  flex: "1 1 100%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

const NotificationDropdown = (props: Props) => {
  // ** Props
  const { settings } = props;

  const { t } = useTranslation();
  // ** States
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState<number>(0);

  const notificationState = useSelector((store: AppStore) => store.user);
  const { notifications } = notificationState;

  // ** Vars
  const { direction } = settings;

  const [currentNotifications, setCurrentNotifications] = useState<string>(
    getInLocalStorage(LocalStorageKeys.NOTIFICATIONS_COUNTER) || "0",
  );
  const [notificationsIds, setNotificationsIds] = useState<string[]>(getInLocalStorageArray(LocalStorageKeys.NOTIFICATIONS_IDS) || []);

  // ** Functions to handle open/close of Details modal
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  //** Functions to hancle the dropdown */
  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
    setCurrentNotifications(`${notificationState.counter}`);
    setInLocalStorage(LocalStorageKeys.NOTIFICATIONS_COUNTER, `${notificationState.counter}`);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (index: number) => {
    setAnchorEl(null);
    handleOpen();
    setSelectedDetail(index);
    setNotificationsIds((prev) => [...prev, notifications[index].id]);
    const prevStoredNotifications = getInLocalStorageArray(LocalStorageKeys.NOTIFICATIONS_IDS);
    setInLocalStorageArray(LocalStorageKeys.NOTIFICATIONS_IDS, [...(prevStoredNotifications || []), notifications[index].id]);
  };

  return (
    <>
      <IconButton
        sx={{ "&:focus": { outline: "0px auto -webkit-focus-ring-color" } }}
        color="inherit"
        aria-haspopup="true"
        onClick={handleDropdownOpen}
        aria-controls="customized-menu"
      >
        <TooltipApp
          title={t("ICON-NOTIFICATION-TOOLTIP")}
          sxText={{
            fontWeight: 600,
          }}
        >
          <Badge
            color="primary"
            variant="dot"
            invisible={+currentNotifications === notificationState.counter}
            sx={{
              "& .MuiBadge-badge": { top: 4, right: 4, boxShadow: (theme) => `0 0 0 2px ${theme.palette.background.paper}` },
            }}
          >
            <NotificationsOutlinedIcon sx={{ fontSize: 25 }} />
          </Badge>
        </TooltipApp>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{ vertical: "bottom", horizontal: direction === "ltr" ? "right" : "left" }}
        transformOrigin={{ vertical: "top", horizontal: direction === "ltr" ? "right" : "left" }}
        sx={{ "& .MuiMenu-paper": { borderRadius: "10px", p: 2 } }}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{ cursor: "default", userSelect: "auto", backgroundColor: "transparent !important" }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <Typography sx={{ cursor: "text", fontWeight: 600 }}>Notifications</Typography>
          </Box>
        </MenuItem>
        <Divider />
        <Box sx={{ maxHeight: 349, overflowY: "auto", overflowX: "hidden" }}>
          {notifications?.map((notification: INotificationDOM, index: number) => (
            // eslint-disable-next-line react/no-array-index-key

            <MenuItem key={index} onClick={() => handleClickItem(index)}>
              <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                <Box sx={{ mx: 4, flex: "1 1", display: "flex", overflow: "hidden", flexDirection: "column" }}>
                  <MenuItemTitle>
                    {notification.name === actions.locationImportSuccess
                      ? "Location import finished"
                      : notification.name === actions.locationImportError
                      ? "Location import has an error"
                      : notification.name === actions.userImportSuccess
                      ? "User import finished"
                      : "User import has an error"}
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={notificationsIds.includes(notification.id)}
                      sx={{
                        "& .MuiBadge-badge": { top: 4, left: 4, boxShadow: (theme) => `0 0 0 2px ${theme.palette.background.paper}` },
                      }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Box height="10px" />
                    </Badge>
                  </MenuItemTitle>{" "}
                  <MenuItemSubtitle variant="body2">
                    {notification.name === actions.locationImportSuccess
                      ? "Click to check the import overview."
                      : "Click to check the import overview."}
                  </MenuItemSubtitle>
                </Box>
                <Typography variant="caption" sx={{ color: "text.disabled" }}>
                  {`${notification.date}`}
                </Typography>
              </Box>
            </MenuItem>
          ))}
          {notifications?.length === 0 && (
            <Box display="flex" justifyContent="center" my={8}>
              <Typography variant="h5">You have no notifications yet</Typography>{" "}
            </Box>
          )}
        </Box>
      </Menu>
      <ImportDetailsModal open={open} handleClose={handleClose} id={selectedDetail} />
    </>
  );
};

export default NotificationDropdown;

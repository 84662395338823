import { IconButton, Tooltip } from "@mui/material";
import { Image } from "components/atoms";
//** Assets */
import errorAlert from "assets/ErrorAlert.png";
import { useTranslation } from "react-i18next";

export default function ToolipError({ tooltipLabel, size }: { tooltipLabel?: string; size?: string }) {
  const { t } = useTranslation();
  return (
    <Tooltip title={tooltipLabel || t("WARNING-ALERT-ERROR")} placement="top">
      <IconButton sx={{ padding: 0 }}>
        <Image src={errorAlert} style={{ maxWidth: size || "22px" }} alt="errorWarning" name="error-warning" />
      </IconButton>
    </Tooltip>
  );
}

import { useState } from "react";
import { useTheme } from "@mui/material";
import { Button, ModalConfirm } from "components";
//**Redux */
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppStore } from "redux/store";
import { useTranslation } from "react-i18next";
import { useFetchAndLoad, useToastMui } from "hooks";
import { claimLocationsAPI } from "pages/locations/services";
import { IClaimLocationBody } from "pages/locations/services/api.interfaces";
import { useLocationListContext } from "pages/locations/pages/locationsList";

export default function ClaimLocationButton({ isItemSelected, itemsId }: { isItemSelected: boolean; itemsId: string[] }) {
  const theme = useTheme();
  const isLightTheme = theme.palette.mode === "light";
  const darkColors = isItemSelected ? "inherit" : "primary";
  const lightColors = isItemSelected ? "white" : "primary";
  const { setToggleClaim } = useLocationListContext();

  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { handleCreateToast } = useToastMui();
  // Handle  dialog
  const handleClickOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  //** GET ORGANIZATION */
  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);
  const { organization } = userState;

  const { organizationId } = useParams();
  const { callEndpoint, loading } = useFetchAndLoad();
  const handleClaim = async () => {
    try {
      const body: IClaimLocationBody = {
        maps_ids: itemsId,
        organization_id: organizationId || `${organization?.id}`,
      };
      await callEndpoint(claimLocationsAPI({ body }), () => handleCreateToast(t("ALERT-ERROR-CLAIM"), "error"));
      setToggleClaim((prev: boolean | null) => (prev === null ? true : !prev));
      handleCreateToast(t("ALERT-SUCCESS-CLAIM"), "success");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Button
        label="Claim"
        size="small"
        onClick={handleClickOpen}
        name="claim-button"
        variant="outlined"
        color={isLightTheme ? lightColors : darkColors}
        disabled={loading}
      />
      <ModalConfirm
        open={openModal}
        title={t("CALIM-LOCATION-MODAL-TITLE")}
        description={t("CALIM-LOCATION-MODAL-DESCRIPTION")}
        handleClose={handleClose}
        handleConfirm={() => {
          handleClaim();
          handleClose();
        }}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
    </>
  );
}

//**MUI Imports */
import Box from "@mui/material/Box";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled, Typography, useTheme } from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { visuallyHidden } from "@mui/utils";

//** Models*/
import { EnhancedTableProps } from "models";
import { Data } from "pages/organizations";
import React from "react";

export default function CustomHeadTable(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, checkbox } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const theme = useTheme();
  const StyledTableCell = styled(TableCell)(() => ({
    backgroundColor: theme.palette.customColors.tableHeaderBgBlue,
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      "&:last-child": {
        borderRadius: " 0 4px 4px 0",
      },
    },
  }));
  const StyledTableFirstCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      borderRadius: " 4px 0 0 4px",
    },
  }));

  return (
    <TableHead sx={{ width: "100%", position: "sticky", top: 0, zIndex: 1 }}>
      <TableRow sx={{ height: 74 }}>
        {checkbox && (
          <StyledTableFirstCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
            />
          </StyledTableFirstCell>
        )}
        {headCells.map((headCell) => (
          <React.Fragment key={headCell.id}>
            {!headCell.hidden ? (
              <StyledTableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  <Typography variant="h5" fontWeight="700">
                    {headCell.label}
                  </Typography>
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc" ? "sorted descending" : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
            ) : null}
          </React.Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
}

import { Roles } from "models";
import { ICounterAPI } from "models/countersAPI";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { getCountersAPI } from "services";
import useToastMui from "./Toasts/useToastMui";
import useAsync from "./useAsync";
import useFetchAndLoad from "./useFetchAndLoad";

export default function useCounters(entity: "locations" | "users" | "organizations") {
  const [counter, setCounter] = useState(0);
  const { loading, callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();

  //** Redux */
  const userState = useSelector((store: AppStore) => store.user);
  const { roles } = userState;
  const authorizedRoles = [Roles.superAdmin, Roles.multiOrgManager, Roles.multiOrgViewer];

  const getCounters = async () => {
    if (!authorizedRoles.includes(roles as Roles)) return { result: { count: 0 } };
    const response = await callEndpoint(getCountersAPI(entity), () => handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"));
    return response;
  };

  const adaptInfo = (data: ICounterAPI) => {
    if (!authorizedRoles.includes(roles as Roles)) return { result: { count: 0 } };
    setCounter(data.result.count);
  };

  useAsync(
    () => getCounters(),
    adaptInfo,
    () => {},
    [entity],
  );

  return { loading, getCounters, counter };
}

import { postFromUrl } from "utilities";

export interface ICreateBodyUser {
  username: string | null;
  first_name: string;
  last_name: string;
  phone: string;
  description: string;
  sbd_contact: string;
  roles: string[];
  organization_id: string[];
  location_id: string;
  designation: string;
}
export interface ICreateBodyUserAPI {
  body: ICreateBodyUser;
}

export const createUsersAPI = ({ body }: ICreateBodyUserAPI) => {
  return postFromUrl<any, ICreateBodyUser>("users/create-one", body);
};

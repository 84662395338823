import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { IButton } from "./interface";
import "./style.css";

export default function ButtonComponent(props: IButton) {
  const {
    onClick,
    disabled,
    type = "button",
    variant = "contained",
    size = "medium",
    style = {},
    label = "Button",
    name,
    id,
    color,
    startIcon,
    endIcon,
    capitalize,
  } = props;
  //   variant text contained outlined

  return (
    <Button
      disabled={disabled}
      type={type}
      onClick={onClick}
      style={style}
      name={`button-${name}`}
      id={id}
      variant={variant}
      color={color}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      <Typography variant="button" display="block" sx={capitalize ? { textTransform: "capitalize" } : {}}>
        {label}
      </Typography>
    </Button>
  );
}

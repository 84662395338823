import { INotificationDOM } from "./global.interface";

interface IRoles {
  name: string;
  scopes: string[];
}

interface IOrganizationUser {
  id: string;
  name: string;
  url: string;
  logo: string;
}
interface IApplicationUser {
  id: string;
  name: string;
}

export interface IInfoUser {
  email: string;
  full_name: string;
  roles: IRoles[];
  uuid_akamai: string;
}

interface IRolesDataAPI {
  id: string;
  name: string;
  views_enabled: string;
}
export interface IUserAPI {
  akamai_uuid: string;
  email: string;
  exp: number;
  full_name: string;
  first_name: string;
  last_name: string;
  iat: number;
  iss: string;
  jti: string;
  sub: string;
  tokenAkamai: string;
  username: string;
  roles_data: IRolesDataAPI[];
}

export interface UserDecodeTokenDOM {
  remember?: boolean;
  email: string;
  fullName: string;
  roles: string;
  uuidAkamai: string;
  organization?: IOrganizationUser;
  locationId?: string;
  locationName?: string;
  organizations?: IOrganizationUser[];
  application?: IApplicationUser;
  applications?: IApplicationUser[];
  firstName?: string;
  lastName?: string;
  id: string;
}

export interface UserDOM extends UserDecodeTokenDOM {
  token: string;
  pingIDToken?: string;
  notifications: INotificationDOM[];
  counter: number;
  emailAssosiated: string;
}

interface IUserAssociatedUrl {
  name: string;
  url: string;
}

export interface ApiUser {
  id: number;
  name: string;
  status: string;
  species: string;
  type: string;
  gender: string;
  origin: IUserAssociatedUrl;
  location: IUserAssociatedUrl;
  image: string;
  episode: string[];
  url: string;
  created: string;
}

export const UserEmptyState: UserDOM = {
  id: "",
  email: "",
  fullName: "",
  roles: "",
  uuidAkamai: "",
  organization: {
    id: "",
    name: "",
    logo: "",
    url: "",
  },
  locationId: "",
  locationName: "",
  organizations: [],
  application: {
    id: "",
    name: "",
  },
  applications: [],
  token: "",
  remember: false,
  pingIDToken: "",
  notifications: [],
  counter: 0,
  emailAssosiated: "",
};

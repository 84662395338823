// ** Third Party Import
import { useTranslation } from "react-i18next";

interface Props {
  text: string;
  toUpperCase?: boolean;
}

const Translations = ({ text, toUpperCase }: Props) => {
  // ** Hook
  const { t } = useTranslation();

  if (toUpperCase) return <>{`${t(text)}`.toUpperCase()}</>;
  return <>{`${t(text)}`}</>;
};

export default Translations;

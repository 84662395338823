/* eslint-disable import/no-extraneous-dependencies */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "models/translations";

i18n
  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
  });

export default i18n;

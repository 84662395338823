import { Roles } from "models";
import { PrivateRoutes } from "models/routes";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { AppStore } from "redux/store";

export default function AdminGuard() {
  const userState = useSelector((store: AppStore) => store.user);
  const role = userState.roles;

  return role === Roles.multiOrgViewer || role === Roles.superAdmin || role === Roles.multiOrgManager ? (
    <Outlet />
  ) : (
    <Navigate to={`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}`} />
  );
}

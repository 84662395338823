//** MUI Imports */
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
//** Components imports */
import { CardHeaderLogin } from "components";
//** Hooks */
import { useTranslation } from "react-i18next";
/**Icons */
import { Link } from "react-router-dom";
import { PublicRoutes } from "models";

export default function RequestSuccess() {
  //** Hooks */
  const { t } = useTranslation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center" py={40}>
      <Box width={underMd ? "100%" : "70%"}>
        <CardHeaderLogin
          title={`${t("SUCCESS-REQUEST-ACCESS-TITLE")}`}
          subTitle={`${t("SUCCESS-REQUEST-ACCESS-SUBTITLE")}`}
          subTitle2={`${t("SUCCESS-REQUEST-ACCESS-SUBTITLE-2")}`}
          paddingSecondTitle={1.5}
          variant="subtitle1"
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "center" }} mt={8}>
        <Link to={`/${PublicRoutes.LOGIN}`}>
          <Typography sx={{ color: "primary.main", textDecoration: "none" }}>{t("REQUEST-FOOOTER-BACK-LOGIN")}</Typography>
        </Link>
      </Box>
    </Box>
  );
}

import { ICoordinates } from "components/atoms/Map/Maps";
import React, { useState, createContext, useContext, SetStateAction, Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IGoBackModal } from "pages/organizations";
import { IDropdownOption } from "models";
import { IPrimaryContactDOM } from "../models";
/*
The idea of this context it is allow a mechanism to pass the information between the components of the page.
*/
interface IOrganizationOption {
  label: string | null;
  id: string | null;
}
interface IOpenHourItem {
  opening: string;
  closing: string;
  is_enabled?: boolean;
}

interface IObjectFields {
  name?: string;
  email?: string | null;
  phone?: string;
  title?: string;
}

export interface IObjectFieldsContact {
  name?: string;
  email?: string | null;
  isValidEmail: boolean;
  phone?: string;
  title?: string;
}
interface IGoogleMapInput {
  address1: string;
  market: string;
  city: string;
  state: string;
  postalCode: string;
  coordinates_latitude: string;
  coordinates_longitude: string;
  phone: string;
  website: string;
  openingHours: {
    monday: IOpenHourItem;
    tuesday: IOpenHourItem;
    wednesday: IOpenHourItem;
    thursday: IOpenHourItem;
    friday: IOpenHourItem;
    saturday: IOpenHourItem;
    sunday: IOpenHourItem;
    [index: string]: any;
  };
  [index: string]: any;
}

interface SBDUSEmails {
  mngDirectorEmail: boolean;
  kyContact1Email: boolean;
  kyContact2Email: boolean;
  kyContact3Email: boolean;
  kyContact4Email: boolean;
}

export const defaultKeyContact: IObjectFieldsContact = { name: "", email: "", phone: "", title: "", isValidEmail: true };

type contextValues = {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;

  name: string;
  setName: Dispatch<SetStateAction<string>>;
  organization: IOrganizationOption;
  setOrganization: Dispatch<SetStateAction<IOrganizationOption>>;
  logo: File[];
  setLogo: Dispatch<SetStateAction<File[]>>;
  clearBasicInformation: Function;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;

  //**Contact */
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  isValidEmail: boolean;
  setIsValidEmail: Dispatch<SetStateAction<boolean>>;
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  isValidPhone: boolean;
  setIsValidPhone: Dispatch<SetStateAction<boolean>>;
  website: string;
  setWebsite: Dispatch<SetStateAction<string>>;
  fax: string;
  setFax: Dispatch<SetStateAction<string>>;
  openingHours: any[];
  setOpeningHours: Dispatch<SetStateAction<any[]>>;
  initalUrl: string;
  setInitalUrl: Dispatch<SetStateAction<string>>;

  //**Address */
  address1: string;
  setAddress1: Dispatch<SetStateAction<string>>;
  address2: string;
  setAddress2: Dispatch<SetStateAction<string>>;
  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  postalCode: string;
  setPostalCode: Dispatch<SetStateAction<string>>;
  latitude: string;
  setLatitude: Dispatch<SetStateAction<string>>;
  longitude: string;
  setLongitude: Dispatch<SetStateAction<string>>;
  positions: ICoordinates[];
  setPositions: Dispatch<SetStateAction<ICoordinates[]>>;
  state: string;
  setState: Dispatch<SetStateAction<string>>;

  //** Business */
  applicableBrand: IDropdownOption[];
  setApplicableBrand: Dispatch<SetStateAction<IDropdownOption[]>>;
  region: IDropdownOption;
  setRegion: Dispatch<SetStateAction<IDropdownOption>>;
  marketCountry: IDropdownOption | null;
  setMarketCountry: Dispatch<SetStateAction<IDropdownOption>>;
  category: string[];
  setCategory: Dispatch<SetStateAction<string[]>>;

  //** Additional */
  purpose: IDropdownOption[];
  setPurpose: Dispatch<SetStateAction<IDropdownOption[]>>;

  //** Additional Location Services*/

  isPremium: boolean;
  setIsPremium: Dispatch<SetStateAction<boolean>>;
  isFactory: boolean;
  setIsFactory: Dispatch<SetStateAction<boolean>>;
  displayOrder: string;
  setDisplayOrder: Dispatch<SetStateAction<string>>;

  //** Additional SBD US*/
  sustainable: boolean;
  setSustainable: Dispatch<SetStateAction<boolean>>;
  facilitySquareFootage: string;
  setFacilitySquareFootage: Dispatch<SetStateAction<string>>;
  descriptionSBDUS: string;
  setDescriptionSBDUS: Dispatch<SetStateAction<string>>;
  amountOfEmployees: string;
  setAmountOfEmployees: Dispatch<SetStateAction<string>>;
  business: string;
  setBusiness: Dispatch<SetStateAction<string>>;
  mainLocationImage: File[];
  setMainLocationImage: Dispatch<SetStateAction<File[]>>;
  locationImagesAdditional: File[];
  setLocationImagesAdditional: Dispatch<SetStateAction<File[]>>;
  brandsPictures: File[];
  setBrandsPictures: Dispatch<SetStateAction<File[]>>;
  purposeOptions: IDropdownOption[];
  setPurposeOptions: Dispatch<SetStateAction<IDropdownOption[]>>;
  yearFounded: number;
  setYearFounded: Dispatch<SetStateAction<number>>;
  mngDirector: IObjectFields | null;
  setMngDirector: Dispatch<SetStateAction<IObjectFields | null>>;
  keyContacts: IObjectFieldsContact[];
  setKeyContacts: Dispatch<SetStateAction<IObjectFieldsContact[]>>;
  isValidSBDUSEmails: SBDUSEmails;
  setIsValidSBDUSEmails: Dispatch<SetStateAction<SBDUSEmails>>;
  purposesData: { label: string; id: string; code: string }[];
  setPurposesData: Dispatch<SetStateAction<{ label: string; id: string; code: string }[]>>;

  tabValue: string;
  setTabValue: Dispatch<SetStateAction<string>>;

  dataCopy: any;
  setDataCopy: Dispatch<SetStateAction<any>>;

  //** Logos */
  logoText: string;
  setLogoText: Dispatch<SetStateAction<string>>;
  mainPictureText: string;
  setMainPictureText: Dispatch<SetStateAction<string>>;
  additionalPicturesText: string[];
  setAdditionalPicturesText: Dispatch<SetStateAction<string[]>>;
  brandsPicturesText: string[];
  setBrandsPicturesText: Dispatch<SetStateAction<string[]>>;

  view: string;
  setView: Dispatch<SetStateAction<string>>;
  updateToggle: boolean;
  setUpdateToggle: Dispatch<SetStateAction<boolean>>;
  excelErrors: boolean;
  setExcelErrors: Dispatch<SetStateAction<boolean>>;
  excelDataLenght: number;
  setExcelDataLenght: Dispatch<SetStateAction<number>>;
  googleMapsInputs: IGoogleMapInput | null;
  setGoogleMapsInputs: Dispatch<SetStateAction<IGoogleMapInput | null>>;
  mapId: string;
  setMapId: Dispatch<SetStateAction<string>>;

  alertRequiredFields: boolean[];
  setAlertRequiredFields: Dispatch<SetStateAction<boolean[]>>;
  arrayApps: any[];
  setArrayApps: Dispatch<SetStateAction<any[]>>;
  primaryContact: IPrimaryContactDOM | null;
  setPrimaryContact: Dispatch<SetStateAction<IPrimaryContactDOM | null>>;
  isHQLocation: boolean;
  setIsHQLocation: Dispatch<SetStateAction<boolean>>;
  hqPrimaryContact: IDropdownOption;
  setHqPrimaryContact: Dispatch<SetStateAction<IDropdownOption>>;
  isOnlineOnly: boolean;
  setIsOnlineOnly: Dispatch<SetStateAction<boolean>>;
  userCount: number;
  setUserCount: Dispatch<SetStateAction<number>>;

  locLatitudeMap: number | undefined;
  setLocLatitudeMap: Dispatch<SetStateAction<number | undefined>>;
  locLongitudeMap: number | undefined;
  setLocLongitudeMap: Dispatch<SetStateAction<number | undefined>>;
  activeLocationItemListCoordinates: ICoordinates[];
  setActiveLocationItemListCoordinates: Dispatch<SetStateAction<ICoordinates[]>>;
  locSelectedMark: string;
  setLocSelectedMark: Dispatch<SetStateAction<string>>;
  activeLocationItemList: string[];
  setActiveLocationItemList: Dispatch<SetStateAction<string[]>>;

  locHasChanges: boolean;
  setLocHasChanges: Dispatch<SetStateAction<boolean>>;
  goBackModalLoc: IGoBackModal | null;
  setGoBackModalLoc: Dispatch<SetStateAction<IGoBackModal | null>>;
};

const defaultValues = {
  activeStep: 0,
  setActiveStep: () => {},
  name: "",
  setName: () => {},
  organization: { label: "", id: "" },
  setOrganization: () => {},
  logo: [],
  setLogo: () => {},
  clearBasicInformation: () => {},
  isVisible: true,
  setIsVisible: () => {},

  //**Contact */
  email: "",
  setEmail: () => {},
  isValidEmail: true,
  setIsValidEmail: () => {},
  phone: "",
  isValidPhone: true,
  setIsValidPhone: () => {},

  setPhone: () => {},
  website: "",
  setWebsite: () => {},
  fax: "",
  setFax: () => {},
  openingHours: [],
  setOpeningHours: () => {},
  initalUrl: "",
  setInitalUrl: () => {},

  //**Address
  address1: "",
  setAddress1: () => {},
  address2: "",
  setAddress2: () => {},
  city: "",
  setCity: () => {},
  postalCode: "",
  setPostalCode: () => {},
  latitude: "",
  setLatitude: () => {},
  longitude: "",
  setLongitude: () => {},
  positions: [],
  setPositions: () => {},
  state: "",
  setState: () => {},

  //** Business */
  applicableBrand: [],
  setApplicableBrand: () => {},
  region: { id: "", label: "" },
  setRegion: () => {},
  marketCountry: {
    id: "",
    label: "",
  },
  setMarketCountry: () => {},
  category: [],
  setCategory: () => {},

  //** Additional */
  purpose: [],
  setPurpose: () => {},

  //** Additional Location Services*/
  isPremium: false,
  setIsPremium: () => {},
  isFactory: false,
  setIsFactory: () => {},
  displayOrder: "",
  setDisplayOrder: () => {},

  //** Additional SBD US*/
  sustainable: false,
  setSustainable: () => {},
  descriptionSBDUS: "",
  setDescriptionSBDUS: () => {},
  facilitySquareFootage: "",
  setFacilitySquareFootage: () => {},
  amountOfEmployees: "",
  setAmountOfEmployees: () => {},
  keyProducts: [],
  business: "",
  setBusiness: () => {},
  mainLocationImage: [],
  setMainLocationImage: () => {},
  locationImagesAdditional: [],
  setLocationImagesAdditional: () => {},
  brandsPictures: [],
  setBrandsPictures: () => {},
  purposeOptions: [],
  setPurposeOptions: () => {},
  purposesData: [],
  setPurposesData: () => {},
  yearFounded: 0,
  setYearFounded: () => {},
  mngDirector: null,
  setMngDirector: () => {},
  keyContacts: [],
  setKeyContacts: () => {},
  isValidSBDUSEmails: {
    mngDirectorEmail: true,
    kyContact1Email: true,
    kyContact2Email: true,
    kyContact3Email: true,
    kyContact4Email: true,
  },
  setIsValidSBDUSEmails: () => {},

  tabValue: "1",
  setTabValue: () => {},
  dataCopy: {},
  setDataCopy: () => {},

  //** Logos */
  logoText: "",
  setLogoText: () => {},
  mainPictureText: "",
  setMainPictureText: () => {},
  additionalPicturesText: [],
  setAdditionalPicturesText: () => {},
  brandsPicturesText: [],
  setBrandsPicturesText: () => {},

  view: "",
  setView: () => {},
  updateToggle: false,
  setUpdateToggle: () => {},
  excelErrors: false,
  setExcelErrors: () => {},
  excelDataLenght: 0,
  setExcelDataLenght: () => {},
  googleMapsInputs: null,
  setGoogleMapsInputs: () => {},
  mapId: "",
  setMapId: () => {},

  alertRequiredFields: [false, false, false, false],
  setAlertRequiredFields: () => {},

  arrayApps: [],
  setArrayApps: () => {},
  primaryContact: null,
  setPrimaryContact: () => {},
  isHQLocation: false,
  setIsHQLocation: () => {},
  hqPrimaryContact: { label: "", id: "" },
  setHqPrimaryContact: () => {},
  isOnlineOnly: false,
  setIsOnlineOnly: () => {},
  userCount: 0,
  setUserCount: () => {},
  locLatitudeMap: undefined,
  setLocLatitudeMap: () => {},
  locLongitudeMap: undefined,
  setLocLongitudeMap: () => {},
  activeLocationItemListCoordinates: [],
  setActiveLocationItemListCoordinates: () => {},
  locSelectedMark: "",
  setLocSelectedMark: () => {},
  activeLocationItemList: [],
  setActiveLocationItemList: () => {},

  locHasChanges: false,
  setLocHasChanges: () => {},
  goBackModalLoc: null,
  setGoBackModalLoc: () => {},
};

const LocationContext = createContext<contextValues>(defaultValues);

const LocationContextProvider = (props: any) => {
  const { children } = props;

  //** Hooks */
  const { t } = useTranslation();

  //Array to render items
  const [activeStep, setActiveStep] = useState<number>(0);

  //** Basic information*/
  const [name, setName] = useState<string>("");
  const [organization, setOrganization] = useState<IOrganizationOption>({ label: "", id: "" });
  const [logo, setLogo] = useState<File[]>([]);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isHQLocation, setIsHQLocation] = useState<boolean>(true);

  //** Contact */
  const days: string[] = [
    t("SCHEDULE-DAY-1"),
    t("SCHEDULE-DAY-2"),
    t("SCHEDULE-DAY-3"),
    t("SCHEDULE-DAY-4"),
    t("SCHEDULE-DAY-5"),
    t("SCHEDULE-DAY-6"),
    t("SCHEDULE-DAY-7"),
  ];
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [fax, setFax] = useState<string>("");
  const [openingHours, setOpeningHours] = useState<any[]>(
    days.map((day, index) => ({ label: day, id: index, opening: "", closing: "", is_enabled: false })),
  );
  const [initalUrl, setInitalUrl] = useState<string>("https://");
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);

  //** Address */
  const [address1, setAddress1] = useState<string>("");
  const [address2, setAddress2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");
  const [positions, setPositions] = useState<ICoordinates[]>([]);
  const [googleMapsInputs, setGoogleMapsInputs] = useState<IGoogleMapInput | null>(null);
  const [mapId, setMapId] = useState<string>("");

  //** Business */
  const [applicableBrand, setApplicableBrand] = useState<IDropdownOption[]>([]);
  const [region, setRegion] = useState<IDropdownOption>({ id: "", label: "" });
  const [marketCountry, setMarketCountry] = useState<IDropdownOption>({ id: "", label: "" });
  const [category, setCategory] = useState<string[]>([]);
  const [primaryContact, setPrimaryContact] = useState<IPrimaryContactDOM | null>(null);

  //** Additional */
  const [purpose, setPurpose] = useState<IDropdownOption[]>([]);
  const [arrayApps, setArrayApps] = useState<any[]>([]);
  const [purposesData, setPurposesData] = useState<{ label: string; id: string; code: string }[]>([]);

  //** Additional Location Services*/
  const [isPremium, setIsPremium] = useState<boolean>(false);
  const [isFactory, setIsFactory] = useState<boolean>(false);
  const [isOnlineOnly, setIsOnlineOnly] = useState<boolean>(false);
  const [displayOrder, setDisplayOrder] = useState<string>("");

  //** Additional SBD US*/
  const [sustainable, setSustainable] = useState<boolean>(false);
  const [descriptionSBDUS, setDescriptionSBDUS] = useState<string>("");
  const [amountOfEmployees, setAmountOfEmployees] = useState<string>("");
  const [facilitySquareFootage, setFacilitySquareFootage] = useState<string>("");
  const [business, setBusiness] = useState<string>("");
  const [mainLocationImage, setMainLocationImage] = useState<File[]>([]);
  const [locationImagesAdditional, setLocationImagesAdditional] = useState<File[]>([]);
  const [brandsPictures, setBrandsPictures] = useState<File[]>([]);
  const [purposeOptions, setPurposeOptions] = useState<IDropdownOption[]>([]);
  const [isValidSBDUSEmails, setIsValidSBDUSEmails] = useState<SBDUSEmails>({
    mngDirectorEmail: true,
    kyContact1Email: true,
    kyContact2Email: true,
    kyContact3Email: true,
    kyContact4Email: true,
  });

  const [yearFounded, setYearFounded] = useState<number>(0);

  const [mngDirector, setMngDirector] = useState<IObjectFields | null>(null);
  const [keyContacts, setKeyContacts] = useState<IObjectFieldsContact[]>([{ ...defaultKeyContact }]);

  //**Others */
  const [tabValue, setTabValue] = useState<string>("1");
  const [dataCopy, setDataCopy] = useState<any>({});
  const [view, setView] = useState<string>("");
  const [updateToggle, setUpdateToggle] = useState<boolean>(false);
  //** Funtions to clear the states */

  //**Logos */
  const [logoText, setLogoText] = useState<string>("");
  const [mainPictureText, setMainPictureText] = useState<string>("");
  const [additionalPicturesText, setAdditionalPicturesText] = useState<string[]>([]);
  const [brandsPicturesText, setBrandsPicturesText] = useState<string[]>([]);

  const [excelErrors, setExcelErrors] = useState<boolean>(false);
  const [excelDataLenght, setExcelDataLenght] = useState<number>(0);

  const [alertRequiredFields, setAlertRequiredFields] = useState<boolean[]>([false, false, false, false]);
  const [hqPrimaryContact, setHqPrimaryContact] = useState<IDropdownOption>({ label: "", id: "" });

  const [userCount, setUserCount] = useState<number>(0);

  const [locLatitudeMap, setLocLatitudeMap] = useState<number | undefined>(undefined);
  const [locLongitudeMap, setLocLongitudeMap] = useState<number | undefined>(undefined);
  const [activeLocationItemList, setActiveLocationItemList] = useState<string[]>([]);
  const [activeLocationItemListCoordinates, setActiveLocationItemListCoordinates] = useState<ICoordinates[]>([]);
  const [locSelectedMark, setLocSelectedMark] = useState<string>("");

  const [locHasChanges, setLocHasChanges] = useState<boolean>(false);
  const [goBackModalLoc, setGoBackModalLoc] = useState<IGoBackModal | null>(null);

  const clearBasicInformation = () => {
    setName("");
    setOrganization({ label: "", id: "" });
    setLogo([]);
    setIsVisible(true);
    setEmail("");
    setPhone("");

    setWebsite("");
    setFax("");
    setOpeningHours(days.map((day, index) => ({ label: day, id: index, opening: "", closing: "", is_enabled: false })));
    setAddress1("");
    setAddress2("");
    setCity("");
    setPostalCode("");
    setLatitude("");
    setLongitude("");
    setState("");

    setApplicableBrand([]);
    setRegion({ label: "", id: "" });
    setMarketCountry({
      id: "",
      label: "",
    });
    setCategory([]);
    setGoogleMapsInputs(null);
    //** Additional */
    setPurpose([]);
    setMapId("");

    //** Additional Location Services*/
    setIsPremium(false);
    setIsFactory(false);
    setDisplayOrder("");

    //** Additional SBD US*/
    setSustainable(false);
    setDescriptionSBDUS("");
    setFacilitySquareFootage("");
    setAmountOfEmployees("");
    setBusiness("");
    setMainLocationImage([]);
    setLocationImagesAdditional([]);
    setBrandsPictures([]);
    setPurposeOptions([]);
    setYearFounded(0);
    setMngDirector(null);
    setKeyContacts([{ ...defaultKeyContact }]);

    setLogoText("");
    setMainPictureText("");
    setAdditionalPicturesText([]);
    setBrandsPicturesText([]);
    setAlertRequiredFields([false, false, false, false]);
    setPrimaryContact(null);
    setIsHQLocation(false);
    setHqPrimaryContact({ label: "", id: "" });
    setIsOnlineOnly(false);
    setActiveLocationItemListCoordinates([]);
    setActiveLocationItemList([]);
    setLocLatitudeMap(undefined);
    setLocLongitudeMap(undefined);
    setLocHasChanges(false);
    setGoBackModalLoc(null);
  };

  // eslint-disable-next-line no-shadow

  const states = {
    activeStep,
    name,
    organization,
    logo,
    isVisible,
    email,
    phone,
    website,
    fax,
    openingHours,
    initalUrl,
    isValidEmail,
    isValidPhone,
    address1,
    address2,
    city,
    postalCode,
    latitude,
    longitude,
    positions,
    state,
    applicableBrand,
    region,
    marketCountry,
    category,
    purpose,
    isPremium,
    isFactory,
    displayOrder,
    sustainable,
    descriptionSBDUS,
    facilitySquareFootage,
    amountOfEmployees,
    business,
    mainLocationImage,
    locationImagesAdditional,
    brandsPictures,
    tabValue,
    dataCopy,
    logoText,
    mainPictureText,
    additionalPicturesText,
    brandsPicturesText,
    view,
    updateToggle,
    purposeOptions,
    excelErrors,
    excelDataLenght,
    googleMapsInputs,
    mapId,
    yearFounded,
    mngDirector,
    keyContacts,
    isValidSBDUSEmails,
    alertRequiredFields,
    arrayApps,
    primaryContact,
    isHQLocation,
    hqPrimaryContact,
    isOnlineOnly,
    purposesData,
    userCount,
    locLatitudeMap,
    locLongitudeMap,
    activeLocationItemListCoordinates,
    locSelectedMark,
    activeLocationItemList,
    locHasChanges,
    goBackModalLoc,
  };

  // eslint-disable-next-line no-shadow
  const contextValues = useMemo(
    () => ({
      setActiveStep,
      setName,
      setOrganization,
      setLogo,
      clearBasicInformation,
      setIsVisible,

      //**Contact */
      setEmail,
      setPhone,
      setWebsite,
      setFax,
      setOpeningHours,
      setInitalUrl,
      setIsValidEmail,
      setIsValidPhone,

      //** Address */
      setAddress1,
      setAddress2,
      setCity,
      setPostalCode,
      setLatitude,
      setLongitude,
      setPositions,
      setState,

      //** Business */
      setApplicableBrand,
      setRegion,
      setMarketCountry,
      setCategory,

      //** Additional */
      setPurpose,
      setPurposesData,

      //** Additional Location Services*/
      setIsPremium,
      setIsFactory,
      setDisplayOrder,

      //** Additional SBD US*/
      setSustainable,
      setDescriptionSBDUS,
      setFacilitySquareFootage,
      setAmountOfEmployees,
      setBusiness,
      setMainLocationImage,
      setLocationImagesAdditional,
      setBrandsPictures,
      setPurposeOptions,
      setTabValue,
      setDataCopy,
      setYearFounded,
      setMngDirector,
      setKeyContacts,
      setIsValidSBDUSEmails,
      //** Logo */
      setLogoText,
      setMainPictureText,
      setAdditionalPicturesText,
      setBrandsPicturesText,

      setView,
      setUpdateToggle,
      setExcelErrors,
      setExcelDataLenght,
      setGoogleMapsInputs,
      setMapId,
      setAlertRequiredFields,
      setArrayApps,
      setPrimaryContact,
      setIsHQLocation,
      setHqPrimaryContact,
      setIsOnlineOnly,
      setUserCount,
      setLocLatitudeMap,
      setLocLongitudeMap,
      setActiveLocationItemListCoordinates,
      setLocSelectedMark,
      setActiveLocationItemList,
      setLocHasChanges,
      setGoBackModalLoc,
      ...states,
    }),
    [{ ...states }],
  );

  return <LocationContext.Provider value={contextValues}>{children}</LocationContext.Provider>;
};

export const useLocationContext = () => {
  const context = useContext(LocationContext);
  if (context === undefined) {
    throw new Error("LocationContext must be used within a LocationContextProvider");
  }
  return context;
};

export { LocationContext, LocationContextProvider };

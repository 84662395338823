import { useNavigate } from "react-router-dom";
import { Box, Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";
//**Icons */
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import useBreadcrumbs from "hooks/useBreadcrumbs";

export interface IBreadcrumbs {
  label: string;
  url: string;
  index: number;
}
export default function Breadcrumbs({ breadcrumbs }: { breadcrumbs: IBreadcrumbs[] }) {
  //** Hooks */
  const navigate = useNavigate();
  const { deleteFromHistory } = useBreadcrumbs();

  function handleClick(url: string) {
    navigate(url, { replace: false });
  }

  return (
    <MUIBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" sx={{ mb: 5 }}>
      {breadcrumbs?.map((item, index) => {
        return (
          <div key={index}>
            {index < breadcrumbs.length - 1 ? (
              <Box
                onClick={() => {
                  handleClick(`${item.url}`);
                  deleteFromHistory(index);
                }}
                sx={{
                  textDecoration: "none",
                  color: "text.secondary",
                  cursor: "pointer",
                  "&:hover": {
                    opacity: "0.8",
                  },
                }}
              >
                {item.label}
              </Box>
            ) : (
              <Typography color="text.primary">{item.label}</Typography>
            )}
          </div>
        );
      })}
    </MUIBreadcrumbs>
  );
}

/* eslint-disable no-shadow */
// ** React Imports
import { Dispatch, Fragment, SetStateAction } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// ** Icon Imports
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
// ** Third Party Components

import { useDropzone } from "react-dropzone";
//** Hooks */
import { useToastMui } from "hooks";
import { Skeleton, Theme, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

//** Style */
import "./style.css";
import { useTranslation } from "react-i18next";

interface FileProp {
  name: string;
  type: string;
  size: number;
}
export default function FileUploaderLocations({
  files,
  setFiles,
  label,
  disabled,
  fileText,
  setFileText,
  changeControllerFn,
}: {
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  label: string;
  disabled?: boolean;
  fileText: string[];
  setFileText: Dispatch<SetStateAction<string[]>>;
  changeControllerFn?: Function;
}) {
  // ** Hooks
  const { handleCreateToast } = useToastMui();
  const theme: any = useTheme();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 10,
    maxSize: 3000000,
    disabled,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    onDrop: (acceptedfiles: any) => {
      setFiles((prev: File[]) => [...prev, ...acceptedfiles.map((file: File) => Object.assign(file))]);
      if (changeControllerFn) changeControllerFn();
    },
    onDropRejected: () => {
      handleCreateToast("You can only upload 10 files & maximum size of 3 MB.", "error");
    },
  });

  const renderFilePreview = (file: FileProp) => {
    if (file.type.startsWith("image")) {
      return (
        <img
          style={{ borderRadius: "8px", maxHeight: "200px", width: "100%", height: "100%" }}
          alt={file.name}
          src={URL.createObjectURL(file as any)}
        />
      );
    }
    return <CloseIcon />;
  };

  const handleRemoveFile = (file: FileProp) => {
    const uploadedFiles = files;
    const filtered = uploadedFiles.filter((i: FileProp) => i.name !== file.name);
    setFiles([...filtered]);
    setFileText([]);
    if (changeControllerFn) changeControllerFn();
  };

  const fileList = files?.map((file: FileProp, index: number) => {
    return (
      <>
        {files.length < fileText.length && fileText[index] ? (
          <Skeleton width="20%" height={250} animation="wave" />
        ) : (
          <Box className="dropzone-main" key={`location-file-${file.name}`} sx={{ mt: underMd ? 10 : 0 }}>
            <div className="full-width">
              <div style={{ height: "15%", textAlign: "right" }}>
                <IconButton onClick={() => handleRemoveFile(file)} disabled={disabled} sx={{ top: underMd ? "10px" : "" }}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div style={{ height: "85%", textAlign: "right", maxHeight: "85%" }}>
                <Box sx={{ display: "flex", flexDirection: ["column", "column", "row"], alignItems: "center", height: "200px" }}>
                  <div className="file-preview" style={{ margin: "0 auto" }}>
                    {renderFilePreview(file)}
                  </div>
                </Box>
              </div>
            </div>
          </Box>
        )}
      </>
    );
  });

  return (
    <>
      <div
        {...getRootProps({ className: `dropzone-main ${theme?.palette?.mode} ${disabled ? "disabled" : ""}` })}
        style={underMd ? { cursor: "pointer", margin: "0 auto" } : { cursor: "pointer" }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", flexDirection: ["column", "column", "row"], alignItems: "center" }}>
          <Box sx={{ display: "flex", flexDirection: ["column", "column", "row"], alignItems: "center" }}>
            <Box sx={{ display: "flex", flexDirection: "column", textAlign: ["center", "center", "inherit"], alignItems: "center" }}>
              <AddIcon fontSize="large" />
              <Typography
                variant="h5"
                fontWeight={700}
                color={disabled ? "rgba(76, 78, 100, 0.5)" : ""}
                textAlign="center"
                className={`${disabled ? "text-disabled" : ""}`}
              >
                {label}
              </Typography>

              <Typography color={disabled ? "rgba(76, 78, 100, 0.5)" : "textSecondary"} textAlign="center">
                {t("FILE-ALLOWED")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </div>

      {files.length ? <>{fileList}</> : null}
    </>
  );
}

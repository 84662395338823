import { putFromUrl } from "utilities";

interface IAccessConfirmationBodyUser {
  status: boolean;
}

export const accessConfirmationAPI = (id: string, accepted: boolean) => {
  const body: IAccessConfirmationBodyUser = {
    status: accepted,
  };
  return putFromUrl<any, IAccessConfirmationBodyUser>(`users/${id}/status`, body);
};

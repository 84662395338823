/* eslint-disable no-shadow */
//** Reat imports */
import React, { useState, createContext, useContext, SetStateAction, Dispatch, useMemo } from "react";
//** Models */
import { IUsersListDOM } from "pages/users/models";

/*
The idea of this context it is allow a mechanism to pass the information between the components of the page.
*/

type contextValues = {
  order: string;
  setOrder: Dispatch<SetStateAction<string>>;
  orderBy: any;
  setOrderBy: Dispatch<SetStateAction<any>>;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;

  usersRequestArray: IUsersListDOM[];
  setUsersRequestArray: Dispatch<SetStateAction<IUsersListDOM[]>>;
};

const defaultValues = {
  order: "asc",
  setOrder: () => {},
  orderBy: "first_name",
  setOrderBy: () => {},
  selected: [],
  setSelected: () => {},
  page: 0,
  setPage: () => {},

  usersRequestArray: [],
  setUsersRequestArray: () => {},
};

const UsersRequestListContext = createContext<contextValues>(defaultValues);

const UsersRequestListContextProvider = (props: any) => {
  const { children } = props;

  const [order, setOrder] = useState<string>("asc");
  const [orderBy, setOrderBy] = useState<any>("first_name");
  const [selected, setSelected] = useState<string[]>([]);

  const [usersRequestArray, setUsersRequestArray] = useState<IUsersListDOM[]>([]);

  const contextValues = useMemo(
    () => ({
      order,
      setOrder,
      orderBy,
      setOrderBy,
      selected,
      setSelected,

      usersRequestArray,
      setUsersRequestArray,
    }),
    [order, orderBy, selected, usersRequestArray],
  );

  return <UsersRequestListContext.Provider value={contextValues}>{children}</UsersRequestListContext.Provider>;
};

export const useUsersRequestListContext = () => {
  const context = useContext(UsersRequestListContext);
  if (context === undefined) {
    throw new Error("UsersRequestListContext must be used within a UsersRequestListContextProvider");
  }
  return context;
};

export { UsersRequestListContext, UsersRequestListContextProvider };

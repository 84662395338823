import { Box, Card, Typography } from "@mui/material";
import { GoBackBreadcrumb, TableSkeleton } from "components";
import { useGetReduxUser } from "hooks";
import { PrivateRoutes } from "models";
import { UpdateUserButtons } from "pages/users/components";
import useUsers from "pages/users/hooks/useUsers";
import { UserViewsName } from "pages/users/models";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserProfileView } from "./components";

export default function UserProfile() {
  const { userState } = useGetReduxUser();
  const { loading } = useUsers(`${userState.id}`, UserViewsName.PROFILE);
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (loading) {
    return (
      <Box my={28}>
        <TableSkeleton />
      </Box>
    );
  }

  return (
    <Box>
      <GoBackBreadcrumb
        label={t("GO-BACK-ORGANIZATION-DASHBOARD")}
        onClick={() => {
          navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`);
        }}
      />
      <Card sx={{ py: 8, px: 6, mx: 6 }}>
        <Typography variant="h1" sx={{ mb: 8, fontWeight: 600, letterSpacing: "0.18px" }}>
          {t("USERS-PROFILE-TITLE")}
        </Typography>
        <UserProfileView />
        <UpdateUserButtons profile />
      </Card>
    </Box>
  );
}

import { Dispatch, SetStateAction } from "react";
import { Box, Typography } from "@mui/material";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useFetchAndLoad, useToastMui } from "hooks";
import { importLocationsAPI } from "pages/locations/services";

import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { Roles } from "models";
import { addNotification } from "redux/slices/user.slice";
import { useTranslation } from "react-i18next";
import { actions } from "context/globalContext";
import { getCurrentDate } from "utilities/getCurrentDate";
import { ImportModalLocations } from "pages/locations/components";
import { notificationsAdapter } from "../../../adapters";

export default function LocationImportOnHeader({
  setCallStatus,
  callStatus,
}: {
  callStatus: "success" | "error" | null;
  setCallStatus: Dispatch<SetStateAction<"success" | "error" | null>>;
}) {
  //**Context */
  const { openImportModal, setOpenImportModal, setSearchToggleDash } = useListAndSearchContext();

  const dispatch = useDispatch();
  const { callEndpoint, loading } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);

  //**Modal */
  const handleClose = () => setOpenImportModal(false);

  const handleConfirm = async (file: File) => {
    setCallStatus(null);
    handleClose();
    handleCreateToast(
      <Box>
        <Typography variant="h5" mb={4} fontWeight={700}>
          {t("LOC-IMPORT-NOTIFICATION-TITLE")}
        </Typography>
        <Typography fontSize={6}>{t("LOC-IMPORT-NOTIFICATION-DESC-1")}</Typography>
        <Typography fontSize={6}>{t("LOC-IMPORT-NOTIFICATION-DESC-2")}</Typography>
      </Box>,
      "notification",
      true,
      { horizontal: "right", vertical: "top" },
    );
    try {
      const response = await callEndpoint(
        importLocationsAPI({ file, organization: userState.roles === Roles.superAdmin ? null : `${userState.organization?.name}` }),
      );
      const { data } = response;
      const adaptedData = notificationsAdapter(data);
      setCallStatus("success");

      dispatch(
        addNotification({
          id: `${Math.random()}`,
          name: actions.locationImportSuccess,
          emailAssosiated: userState.email,
          date: getCurrentDate(),
          fileName: file.name,
          ...adaptedData,
        }),
      );
      setSearchToggleDash((prev: boolean) => !prev);
    } catch (error: any) {
      console.log(error);
      setCallStatus("error");
      dispatch(
        addNotification({
          name: actions.locationImportError,
          emailAssosiated: userState.email,
          error: `${error.response.data.message || "Importation is taking too long"}`,
          date: getCurrentDate(),
        }),
      );
    }
  };

  return (
    <ImportModalLocations
      open={openImportModal}
      handleConfirm={handleConfirm}
      handleClose={handleClose}
      loading={loading}
      callStatus={callStatus}
    />
  );
}

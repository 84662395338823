//** React imports */
import { ChangeEvent, useState } from "react";
//** COMPONTENS*/
import { Input } from "components";
//** MUI IMPORTS */
import { Box, Button } from "@mui/material";
//** Hooks */
import { useTranslation } from "react-i18next";
import { useResetPassword } from "pages/resetPassword/hooks";
import { EMAIL_REGEX } from "models";

export default function EmailToSendPasswordInputs() {
  //** Hooks */
  const { t } = useTranslation();
  const { resendResetPasswordEmail } = useResetPassword();
  //** States */
  const [email, setEmail] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsValid(EMAIL_REGEX.test(e.target.value));
  };
  const handleSendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resendResetPasswordEmail(email);
  };
  return (
    <Box>
      <form action="/" onSubmit={handleSendEmail}>
        <Input
          sx={{ mb: 10 }}
          value={email}
          onChange={onChange}
          label={`${t("INPUT-EMAIL")}`}
          fullWidth
          error={email.length > 0 && !isValid}
          name="email-send-password-email"
          id="outlined-basic"
          helperText={`${t("INVALID-EMAIL")}`}
        />
        <Button
          name="button-send-email-password"
          fullWidth
          disabled={!isValid}
          size="large"
          type="submit"
          variant="contained"
          sx={{ mb: 7 }}
        >
          {t("BUTTON-SEND")}
        </Button>
      </form>
    </Box>
  );
}

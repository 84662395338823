//** Componets */
import { Box, IconButton, Typography } from "@mui/material";
//** Icon */
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export default function GoBackBreadcrumb({ label, onClick }: { label: string; onClick: () => void }) {
  return (
    <Box display="flex" alignItems="center" my={8} sx={{ cursor: "pointer" }} onClick={onClick}>
      <IconButton sx={{ mr: 2 }}>
        <ArrowBackIosNewIcon sx={{ fontSize: 16 }} />
      </IconButton>
      <Typography variant="h3">{label}</Typography>
    </Box>
  );
}

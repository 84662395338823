import { Dispatch, SetStateAction, useEffect, useState } from "react";
//** MUI Imports */
import { Checkbox, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//** Model */
import { IDropdownOption } from "models";
//** Icon */
import { ArrowDropDown } from "@mui/icons-material";

interface IInputApiCalls {
  // eslint-disable-next-line no-unused-vars
  apiCallFunction: (value: string) => any;
  label: string;
  loading: boolean;
  setPropValue: Dispatch<SetStateAction<IDropdownOption[]>>;
  changeWithInputText?: boolean; //when need a query to call the api with autocomplete input value
  initialValue?: any;
  disabled?: boolean;
}
const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
const checkedIcon = <CheckBoxIcon fontSize="large" />;

export default function InputApiCallCheckboxWithOutSelectAll({
  apiCallFunction,
  label,
  loading,
  setPropValue,
  initialValue,
  disabled,
}: IInputApiCalls) {
  const [value, setValue] = useState<any>(initialValue ?? []);
  const [options, setOptions] = useState<any[]>([]);

  const callApis = async (inputValue: string) => {
    // if (options.length && !changeWithInputText) return;
    const arrayResponse: any = await apiCallFunction(inputValue);
    setOptions(arrayResponse);
  };

  useEffect(() => {
    callApis(value);
  }, []);

  useEffect(() => {
    if (initialValue.length > 0 && value.length === 0) {
      setValue(initialValue);
    }
    if (initialValue.length === 0) {
      setValue([]);
    }
  }, [initialValue]);

  const handleChange = (event: any, newValue: any | null) => {
    setValue(newValue);
    setPropValue(newValue);
  };

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      multiple
      loading={loading}
      popupIcon={<ArrowDropDown sx={{ fontSize: "1.5em" }} />}
      value={value}
      disableCloseOnSelect
      options={options}
      isOptionEqualToValue={(option, valueOption) => option?.label === valueOption?.label}
      onChange={handleChange}
      renderOption={(props: any, option: any, { selected }) => {
        return (
          <li {...props} key={`autocomplete-${option.label}`}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.label}
          </li>
        );
      }}
      renderInput={(params: any) => <TextField {...params} label={label} sx={{ "& .MuiChip-label": { fontSize: 16 } }} />}
      onClick={() => callApis(value)}
    />
  );
}

import { postFromUrl } from "utilities";

export interface IRequestAccessBodyUser {
  username: string | null;
  first_name: string;
  last_name: string;
  phone: string;
  description: string;
  sbd_contact: string;
  roles: string[];
  organization_id: string[];
  location_id: string;
  designation: string;
}
export interface IRequestAccessBodyUserAPI {
  body: IRequestAccessBodyUser;
}

export const requestAccessAPI = ({ body }: IRequestAccessBodyUserAPI) => {
  return postFromUrl<unknown, IRequestAccessBodyUser>("users/request-access", body);
};

/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
//** React imports */
import { useEffect, useState } from "react";
//**Hooks */
import { useAsync, useFetchAndLoad } from "hooks";
//**Utils */
import { createQuery } from "utilities";
//**Model */
import { IOrgMapsListDOM, IPotentialPlacesAPI, IPotentialPlacesLoadHook, IPotentialPlacesSearchHook } from "../models";
//** Context */
import { useOrganizationContext } from "../context";
import { locationOrgMapAdapter } from "./adapters/location.orgmaps.adapter";
//**Services */
import { getPotentialPlaces } from "../services";

export default function useMapOrgData(name: string) {
  //** Context */
  const {
    locationsMapListArray,
    setLocationsMapListArray,
    setLocationsMapMarksArray,
    claimCitySearchOrg,
    nameLocSearchOrg,
    claimStateSearchOrg,
    claimCountrySearchOrg,
    setActiveLocationItemListCoordinates,
    toggleChangeCoordinates,
  } = useOrganizationContext();
  const { orgLatitudeMap, orgLongitudeMap, setOrgLatitudeMap, setOrgLongitudeMap, latOrganization, longOrganization } =
    useOrganizationContext();
  //** State */

  const [searchToggle, setSearchToggle] = useState(false);

  const limit = 20;

  //** Hooks */
  const { loading, callEndpoint } = useFetchAndLoad();

  //**Redux */
  const locationFilters = `${claimCitySearchOrg}${claimStateSearchOrg}${claimCountrySearchOrg?.label}`;
  const [componentMounted, setComponentMounted] = useState(false);

  const loadLocationsOrgMap: any = async ({ name, latitude, longitude, city, state, market_or_country }: IPotentialPlacesLoadHook) => {
    if (!name && !nameLocSearchOrg) return [];
    //Create an object with key that have some search value
    //If the table doesn't have a filter it will be sorted by creation
    const query = createQuery<Partial<IPotentialPlacesSearchHook>>({
      name,
      coordinates: latitude && longitude ? `${latitude},${longitude}` : undefined,
      city,
      state,
      market_or_country,
    });

    //Calling get all API
    const response = await callEndpoint(getPotentialPlaces({ query }));
    return response;
  };
  const adaptInfo = (data: IPotentialPlacesAPI) => {
    //Save the adapted data and the last page and length for the pagination
    const array: IOrgMapsListDOM[] = locationOrgMapAdapter(data?.result?.items);
    setLocationsMapListArray([...array]);
    setLocationsMapMarksArray(
      array.map((item) => ({
        lat: +item.coordinates[0],
        lng: +item.coordinates[1],
        id: item.mapId,
        name: item.name,
        address: item.address,
        mapId: item.mapId,
      })),
    );
    setComponentMounted(true);
  };

  useAsync(
    () =>
      loadLocationsOrgMap({
        name: nameLocSearchOrg || name,
        city: orgLatitudeMap || orgLongitudeMap ? "" : claimCitySearchOrg,
        state: orgLatitudeMap || orgLongitudeMap ? "" : claimStateSearchOrg,
        market_or_country: orgLatitudeMap || orgLongitudeMap ? "" : claimCountrySearchOrg?.label,
        //** If i send coordinates the other location filter are not necesary */
        //** latOrganization and longOrganization come from the location saved coodinates */
        //** If the user made any filter this coordinates will not be the reference */
        latitude: orgLatitudeMap || (locationFilters ? "" : latOrganization),
        longitude: orgLongitudeMap || (locationFilters ? "" : longOrganization),
      }),
    adaptInfo,
    () => {},
    [searchToggle],
  );

  const onSearch = () => {
    setSearchToggle(!searchToggle);
    setLocationsMapListArray([]);

    //** When search by filters the filters must be the main search */
    //** Then when i move the map and ge coordinates this lat,long must be the main search */
    setOrgLatitudeMap(undefined);
    setOrgLongitudeMap(undefined);
    setActiveLocationItemListCoordinates([]);
  };

  const onSearchWithCoordinates = () => {
    setSearchToggle((value) => !value);
    setLocationsMapListArray([]);
  };

  //**Search by org name */
  useEffect(() => {
    if (!name) return;
    if (!componentMounted) return;

    const delayDebounceFn = setTimeout(() => {
      onSearchWithCoordinates();
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(delayDebounceFn);
  }, [name]);

  //**Search by coordinates */
  useEffect(() => {
    if (!orgLatitudeMap) return;
    if (!orgLongitudeMap) return;
    if (!componentMounted) return;

    onSearchWithCoordinates();
  }, [orgLatitudeMap, orgLongitudeMap, toggleChangeCoordinates]);

  // * ------------------------SORT----------------------------------//

  return {
    locationsMapListArray,
    loadList: loading,
    limit,
    onSearch,
  };
}

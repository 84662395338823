/* eslint-disable indent */
// ** MUI Imports
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import MuiListSubheader, { ListSubheaderProps } from "@mui/material/ListSubheader";

// ** Types
import { NavSectionTitle } from "models/layoutsTypes";
import { Settings } from "context/settingsContext";

// ** Custom Components Imports
import Translations from "layouts/components/Translations";

//** Hooks */
import { useGetReduxUser } from "hooks";

interface Props {
  navHover: boolean;
  settings: Settings;
  item: NavSectionTitle;
  collapsedNavWidth: number;
  navigationBorderWidth: number;
}

// ** Styled Components
const ListSubheader = styled((props: ListSubheaderProps) => <MuiListSubheader component="li" {...props} />)(({ theme }) => ({
  lineHeight: 1,
  display: "flex",
  position: "static",
  padding: theme.spacing(3),
  marginTop: theme.spacing(6.25),
  backgroundColor: "transparent",
  color: theme.palette.text.disabled,
  transition: "padding-left .25s ease-in-out",
}));

const VerticalNavSectionTitle = (props: Props) => {
  // ** Props
  const { item, navHover, settings, collapsedNavWidth, navigationBorderWidth } = props;
  // ** Hook
  const theme = useTheme();
  const { userState } = useGetReduxUser();
  // ** Vars
  const { mode, navCollapsed } = settings;

  const conditionalBorderColor = () => {
    if (mode === "semi-dark") {
      return {
        "&, &:before": {
          borderColor: `rgba(${theme.palette.customColors.dark}, 0.12)`,
        },
      };
    }
    return {};
  };

  const conditionalColor = () => {
    if (mode === "semi-dark") {
      return {
        color: `rgba(${theme.palette.customColors.dark}, 0.38) !important`,
      };
    }
    return {
      color: "text.disabled",
    };
  };

  // eslint-disable-next-line no-shadow
  const isValidRole = !!item?.roles.find((item) => {
    if (item === "all") {
      return true;
    }
    return item === userState.roles;
  });

  if (isValidRole) {
    return (
      <ListSubheader
        className="nav-section-title"
        sx={{
          ...(navCollapsed && !navHover ? { py: 4.75, px: (collapsedNavWidth - navigationBorderWidth - 22) / 8 } : { pl: 0 }),
        }}
      >
        <Divider
          textAlign="left"
          sx={{
            m: "0 !important",
            lineHeight: "normal",
            ...conditionalBorderColor(),
            "&:after": { display: "none" },
            ...(navCollapsed && !navHover
              ? { width: 22 }
              : {
                  width: "100%",
                  "&:before": { top: 7, transform: "none", width: theme.spacing(4) },
                  "& .MuiDivider-wrapper": { px: 4, fontSize: "0.75rem", letterSpacing: "0.21px" },
                }),
          }}
        >
          {navCollapsed && !navHover ? null : (
            <Typography noWrap variant="caption" sx={{ ...conditionalColor() }}>
              <Translations text={item.sectionTitle} />
            </Typography>
          )}
        </Divider>
      </ListSubheader>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default VerticalNavSectionTitle;

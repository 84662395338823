//** Imports componens */
import { ModalConfirm, SectionsTitles } from "components";
//**Mui components */
import { Box, FormControlLabel, Switch } from "@mui/material";
//** Hooks */
import { useTranslation } from "react-i18next";
import { useRoleGuard } from "hooks";
import { Roles } from "models";
import { useState } from "react";
import { useLocationContext } from "../../../context";

export default function VisibilitySection() {
  //**Context */
  const { isVisible, setIsVisible, setLocHasChanges } = useLocationContext();

  //** Hooks */
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const authUpdateOrganization = useRoleGuard([Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager, Roles.locationManager]);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const changeControllerFn = () => setLocHasChanges(true);

  const handleChangeActive = () => {
    setIsVisible(!isVisible);
    changeControllerFn();
    handleCloseModal();
  };

  return (
    <Box my={6} mx={1}>
      <ModalConfirm
        open={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleChangeActive}
        title={isVisible ? t("LOCATION-MODAL-VISIBILITY-TITLE") : t("LOCATION-MODAL-ACTIVE-TITLE")}
        description={isVisible ? `${t("LOCATION-MODAL-VISIBILITY-DESCRIPTION")}` : `${t("LOCATION-MODAL-ACTIVE-DESCRIPTION")}`}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
      <SectionsTitles title={`${t("LOCATION-STATUS-TITLE")}`} subTitle={`${t("LOCATION-STATUS-DESCRIPTION")}`} mb={4} h2 />

      <FormControlLabel
        control={<Switch checked={isVisible} onChange={handleOpenModal} disabled={!authUpdateOrganization} color="success" />}
        label={!isVisible ? t("CHIP-HIDDEN") : t("CHIP-VISIBLE")}
      />
    </Box>
  );
}

import { SyntheticEvent } from "react";
import { Autocomplete, Box, CircularProgress, IconButton, TextField, Theme, Tooltip, useMediaQuery } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
//** Assets */
import { useTranslation } from "react-i18next";
import warningImg from "assets/Warning.png";
import Image from "../Image/Image";
import { IAutoComplete } from "./interface";

function AutoComplete(props: IAutoComplete) {
  const {
    value,
    options,
    setValue,
    disablePortal,
    disableCloseOnSelect,
    clearOnEscape,
    disableClearable,
    includeInputInList,
    disableListWrap,
    openOnFocus,
    autoSelect,
    disabled,
    clearOnBlur,
    freeSolo,
    multiple,
    getOptionLabel,
    defaultValue,
    variant,
    sx,
    onChangeInput,
    inputLabel,
    onClick,
    loading,
    fullWidth,
    name,
    renderOption,
    clearIcon,
    warning,
    requiredFields,
    alertLabel,
    inputSize,
    endAdornment,
    readOnly,
  } = props;

  const { t } = useTranslation();

  const handleChange = (event: SyntheticEvent<Element, Event>, newValue: any | null) => {
    setValue(newValue);
  };

  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <Box width="100%">
      <Autocomplete
        fullWidth={fullWidth}
        sx={sx}
        value={value}
        disablePortal={disablePortal}
        id={name}
        loading={loading}
        options={options}
        onChange={handleChange}
        popupIcon={<ArrowDropDown sx={{ fontSize: "1.5em" }} />}
        readOnly={readOnly}
        renderInput={(params: any) => (
          <TextField
            {...params}
            onClick={onClick}
            label={inputLabel}
            variant={variant}
            error={requiredFields}
            helperText={requiredFields ? alertLabel : null}
            onChange={onChangeInput}
            // eslint-disable-next-line no-nested-ternary
            color={warning ? "warning" : ""}
            focused={warning || requiredFields}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                  {endAdornment && (
                    <Box position="absolute" right={underMd ? 30 : 50}>
                      {endAdornment}
                    </Box>
                  )}
                </>
              ),
              startAdornment:
                warning && !underMd ? (
                  <Tooltip title={t("WARNING-ALERT")} placement="top">
                    <IconButton sx={{ marginRight: "12px" }}>
                      <Image src={warningImg} alt="warning" name="warning-img" style={{ width: "22px" }} />
                    </IconButton>
                  </Tooltip>
                ) : null,
            }}
            size={inputSize}
          />
        )}
        isOptionEqualToValue={(option, valueOpt) => option?.label === valueOpt?.label}
        defaultValue={defaultValue}
        getOptionLabel={getOptionLabel}
        disableCloseOnSelect={disableCloseOnSelect}
        clearOnEscape={clearOnEscape}
        disableClearable={disableClearable}
        includeInputInList={includeInputInList}
        disableListWrap={disableListWrap}
        openOnFocus={openOnFocus}
        autoSelect={autoSelect}
        disabled={disabled}
        blurOnSelect
        clearOnBlur={clearOnBlur}
        freeSolo={freeSolo}
        multiple={multiple}
        renderOption={renderOption}
        clearIcon={clearIcon}
      />
    </Box>
  );
}

export default AutoComplete;

// ** React Imports
import { useEffect, useCallback, useRef, useState, ReactNode } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import MuiDialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import MuiAutocomplete, { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
//** Hooks */
import { useTranslation } from "react-i18next";
// ** Icon Imports
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Stack, Divider } from "@mui/material";
import Button from "../Button/Button";

// ** Styled Autocomplete component
const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  "& fieldset": {
    border: 0,
  },
  "& + .MuiAutocomplete-popper": {
    "& .MuiAutocomplete-listbox": {
      paddingTop: 0,
      height: "100%",
      maxHeight: "inherit",
      "& .MuiListSubheader-root": {
        top: 0,
        fontWeight: 400,
        lineHeight: "15px",
        fontSize: "0.75rem",
        letterSpacing: "1px",
        color: theme.palette.text.disabled,
      },
    },
    "& .MuiAutocomplete-paper": {
      border: 0,
      height: "100%",
      borderRadius: 0,
      boxShadow: "none",
    },
    "& .MuiListItem-root.suggestion": {
      padding: 0,
      "& .MuiListItemSecondaryAction-root": {
        display: "flex",
      },
      "&.Mui-focused.Mui-focusVisible, &:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "& .MuiListItemButton-root: hover": {
        backgroundColor: "transparent",
      },
      "&:not(:hover)": {
        "& .MuiListItemSecondaryAction-root": {
          display: "none",
        },
        "&.Mui-focused, &.Mui-focused.Mui-focusVisible:not(:hover)": {
          "& .MuiListItemSecondaryAction-root": {
            display: "flex",
          },
        },
        [theme.breakpoints.down("sm")]: {
          "&.Mui-focused:not(.Mui-focusVisible) .MuiListItemSecondaryAction-root": {
            display: "none",
          },
        },
      },
    },
    "& .MuiAutocomplete-noOptions": {
      display: "grid",
      minHeight: "100%",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      padding: theme.spacing(10),
    },
  },
}));

// ** Styled Dialog component
const Dialog = styled(MuiDialog)({
  "& .MuiBackdrop-root": {
    backdropFilter: "blur(4px)",
  },
  "& .MuiDialog-paper": {
    overflow: "hidden",
    "&:not(.MuiDialog-paperFullScreen)": {
      height: "auto",
      maxHeight: 550,
    },
  },
});
export default function ClaimMoreOrgSearch({
  noOptionsComponent,
  onReset,
  onSubmit,
}: {
  noOptionsComponent: ReactNode;
  onReset: Function;
  onSubmit: any;
  isDashboard?: boolean;
}) {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const [options, setOptions] = useState<any[]>([]);

  // ** Hooks & Vars
  const theme = useTheme();
  const { t } = useTranslation();

  const wrapper = useRef<HTMLDivElement>(null);
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  // Handle shortcut keys keyup events
  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      // ** ESC key to close searchbox
      if (openDialog && (event.key === "esc" || event.key === "Enter")) {
        setOpenDialog(false);
      }
    },
    [openDialog],
  );

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp]);

  if (!isMounted) {
    return null;
  }
  return (
    <Box
      ref={wrapper}
      onClick={() => !openDialog && setOpenDialog(true)}
      sx={{
        position: "absolute !important",
        top: "40px",
        left: "15px",
        width: "40px",
        transition: "width .12s linear .12s",
        height: "40px",
        borderRadius: "5px",
        background: "#FFF",
        boxShadow:
          "0px 1px 3px 0px rgba(76, 78, 100, 0.12), 0px 1px 1px 0px rgba(76, 78, 100, 0.14), 0px 2px 1px -1px rgba(76, 78, 100, 0.20)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "1000",
        cursor: "pointer",
      }}
    >
      <SearchIcon
        sx={{
          p: 0,
          color: "black",
          fontSize: "20px",
          backgroundColor: "transparent !important",
          "&:focus": { outline: "0px auto -webkit-focus-ring-color" },
        }}
      />
      {openDialog && (
        <Dialog fullWidth open={openDialog} fullScreen={false} onClose={() => setOpenDialog(false)}>
          <Box sx={{ top: 0, width: "100%", position: "sticky" }}>
            <Autocomplete
              autoHighlight
              disablePortal
              disabled
              options={options}
              id="appBar-search"
              isOptionEqualToValue={() => true}
              renderInput={(params: AutocompleteRenderInputParams) => {
                return (
                  <TextField
                    {...params}
                    value=""
                    InputProps={{
                      ...params.InputProps,
                      sx: { p: `${theme.spacing(3.75, 6)} !important` },
                      startAdornment: (
                        <InputAdornment position="start" sx={{ color: "text.primary" }}>
                          <SearchOutlinedIcon sx={{ fontSize: 25 }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={() => setOpenDialog(false)}
                          sx={{ display: "flex", cursor: "pointer", alignItems: "center" }}
                        >
                          <Typography sx={{ mr: 2.5, color: "text.disabled" }}>[esc]</Typography>
                          <IconButton size="small" sx={{ p: 1, "&:focus": { outline: "0px auto -webkit-focus-ring-color" } }}>
                            <CloseOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                );
              }}
            />
          </Box>
          <form
            onSubmit={(e) => {
              onSubmit(e);
              setOpenDialog(false);
            }}
          >
            <Box
              sx={{
                p: 10,
                overflow: "auto",
                borderTop: `1px solid ${theme.palette.divider}`,
                height: fullScreenDialog ? "auto" : "100%",
              }}
            >
              {noOptionsComponent}
            </Box>
            <Divider />
            <Stack direction="row" spacing={4} mx={10} my={6}>
              <Button label={t("BUTTON-SEARCH")} type="submit" name="search" />
              <Button
                onClick={() => {
                  onReset();
                  setOpenDialog(false);
                }}
                variant="outlined"
                label={t("BUTTON-RESET")}
                color="secondary"
                name="resetSearch"
              />
            </Stack>
          </form>
        </Dialog>
      )}
    </Box>
  );
}

/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable quotes */
import { useEffect } from "react";
// //**Hooks */
import { useNavigate } from "react-router-dom";
import useQuery from "utilities/getLocation";
import { useFetchAndLoad, useSettings, useToastMui } from "hooks";
import { userLoginAdapter } from "pages/login";
// //**Redux */
import { useDispatch } from "react-redux";
import { createUser, modifyUser } from "redux/slices/user.slice";
import { useGlobalContext } from "context/globalContext";
import { Spinner } from "components";
import config from "config/settings.json";
import { createQuery } from "utilities";
// //**Service */
import { IUserAPI, PrivateRoutes, PublicRoutes, Roles } from "models";
import { Settings } from "context/settingsContext";
import { decodeJwtToken } from "frameworks_and_drivers/external_interfaces/jwt";
import { SSOverficationCodeAPI } from "./services";

export default function PingIDAuth() {
  const queryUrl = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleCreateToast } = useToastMui();
  const { callEndpoint } = useFetchAndLoad();

  //** Context */
  const { urlHistory } = useGlobalContext();

  //** Hook-> Funtion to hide the left bar */
  const { saveSettings } = useSettings();
  const handleChange = (field: keyof Settings, value: Settings[keyof Settings]): void => {
    const settings = window.localStorage.getItem("settings") || "";
    saveSettings({ ...JSON.parse(settings), [field]: value });
  };

  const redirectToExpectedPage = () => {
    if (document.referrer.indexOf(`${config.HOST_URL}`) !== -1 || urlHistory.length > 0) {
      navigate(-1);
    } else {
      navigate(`/${PrivateRoutes.SELECT_ORGANIZARION}`, { replace: false });
    }
  };

  const getToken = async () => {
    try {
      const accessToken = queryUrl.get("code");
      if (!accessToken) return navigate(`/${PublicRoutes.LOGIN}`);

      const query = createQuery({ access_token: accessToken });
      const response = await callEndpoint(SSOverficationCodeAPI({ query }));
      const { data } = response;
      //** Get payload token information */
      const decodeUser: IUserAPI = decodeJwtToken(data.result?.token);
      // eslint-disable-next-line consistent-return
      if (!decodeUser) return;

      //** Save user */
      dispatch(createUser({ token: data.result?.token }));
      dispatch(modifyUser(userLoginAdapter(decodeUser)));
      redirectToExpectedPage();

      //* Show the bar depending on the user role */
      // eslint-disable-next-line max-len
      if (decodeUser.roles_data[0].name === Roles.superAdmin) {
        handleChange("navHidden", false);
      } else {
        handleChange("navHidden", true);
      }
    } catch (error: any) {
      handleCreateToast(`${error.response.data.message}`, "error");
      navigate(`/${PublicRoutes.LOGIN}`);
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  return <Spinner />;
}

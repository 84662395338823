import { useEffect } from "react";
//** Context */
import { useOrganizationContext } from "pages/organizations/context";
import OrganizationCreateView from "./components/template/OrganizationCreateView";
//** Components imports */

export default function OrganizationsCreate() {
  const { cleatBasicInformation } = useOrganizationContext();
  useEffect(() => {
    //Clear context variables
    cleatBasicInformation();
  }, []);
  return <OrganizationCreateView />;
}

import { LocationContextProvider, LocationListContextProvider } from "pages/locations";
import { OrganizationContextProvider } from "pages/organizations/context";
import { OrganizationInfoContextProvider } from "pages/organizations/pages/organizationsInformation/context/OrganizationInfoContext";
import { UsersListContextProvider } from "pages/users/pages/usersList/context";
import { ReactNode } from "react";

export default function GlobalProvider({ children }: { children: ReactNode }) {
  return (
    <LocationContextProvider>
      <OrganizationContextProvider>
        <OrganizationInfoContextProvider>
          <LocationListContextProvider>
            <UsersListContextProvider>{children}</UsersListContextProvider>
          </LocationListContextProvider>
        </OrganizationInfoContextProvider>
      </OrganizationContextProvider>
    </LocationContextProvider>
  );
}

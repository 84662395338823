import { useParams } from "react-router-dom";
import useGetLocationsDashboard from "pages/locations/pages/locationsList/hooks/useLocationsDashboard";
import LocationDashboardView from "./LocationDashboardView";

export default function LocationDashboardViewWithCall({
  isDashboard,
  hiddeEdit,
  locationOrgCounter,
  orgName,
}: {
  isDashboard?: boolean;
  hiddeEdit?: boolean;
  locationOrgCounter?: number;
  orgName?: string;
}) {
  const { id } = useParams();

  const { loading, hasNextPage, loadMoreLocations, onSearch, page } = useGetLocationsDashboard(`${id || ""}`, isDashboard);
  return (
    <LocationDashboardView
      loading={loading}
      hasNextPage={hasNextPage}
      loadMoreLocations={loadMoreLocations}
      onSearch={onSearch}
      page={page}
      isDashboard={isDashboard}
      hiddeEdit={hiddeEdit}
      locationOrgCounter={locationOrgCounter}
      orgName={orgName}
    />
  );
}

import { Box, Skeleton, Stack } from "@mui/material";

export default function TableSkeleton() {
  return (
    <Box>
      <Skeleton variant="rectangular" height={54} animation="wave" sx={{ mb: 6, borderRadius: "8px" }} />
      <Stack direction="row" spacing={6} sx={{ mb: 6 }}>
        <Skeleton variant="rectangular" height={54} animation="wave" width="100%" sx={{ borderRadius: "8px" }} />
        <Skeleton variant="rectangular" height={54} animation="wave" width="100%" sx={{ borderRadius: "8px" }} />
        <Skeleton variant="rectangular" height={54} animation="wave" width="100%" sx={{ borderRadius: "8px" }} />
      </Stack>
      <Stack direction="row" spacing={6} sx={{ mb: 6 }}>
        <Skeleton variant="rectangular" height={54} animation="wave" width="100%" sx={{ borderRadius: "8px" }} />
        <Skeleton variant="rectangular" height={54} animation="wave" width="100%" sx={{ borderRadius: "8px" }} />
        <Skeleton variant="rectangular" height={54} animation="wave" width="100%" sx={{ borderRadius: "8px" }} />
      </Stack>
      <Stack direction="row" spacing={6} sx={{ mb: 6 }}>
        <Skeleton variant="rectangular" height={54} animation="wave" width="100%" sx={{ borderRadius: "8px" }} />
        <Skeleton variant="rectangular" height={54} animation="wave" width="100%" sx={{ borderRadius: "8px" }} />
        <Skeleton variant="rectangular" height={54} animation="wave" width="100%" sx={{ borderRadius: "8px" }} />
      </Stack>
      <Stack direction="row" spacing={6} sx={{ mb: 6 }}>
        <Skeleton variant="rectangular" height={54} animation="wave" width="100%" sx={{ borderRadius: "8px" }} />
        <Skeleton variant="rectangular" height={54} animation="wave" width="100%" sx={{ borderRadius: "8px" }} />
        <Skeleton variant="rectangular" height={54} animation="wave" width="100%" sx={{ borderRadius: "8px" }} />
      </Stack>
    </Box>
  );
}

//** MUI Imports */
import { Box, Stack } from "@mui/material";
//** Components */
import { Button } from "components";
import { PrivateRoutes } from "models";
import { useOrganizationContext } from "pages/organizations/context";
//**Hooks */
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useGetPictures from "hooks/useGetPictures";
//**Services */
import { useFetchAndLoad, useToastMui } from "hooks";
import { capitalize } from "utilities";
import { purposeCodeforAplicableBrand } from "pages/locations/constants/purposesToConditions";
import isUrl from "is-url";
import { ICreateBodyOrganization, createOrganizationsAPI } from "../../../services";

export default function ButtonCreateOrganization() {
  //** Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { callEndpoint, loading } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { handleGetCompressedBase64 } = useGetPictures();

  //** Context */
  const { name, url, description, logo, initalUrl, mapId, isValidEmail, regionOrganization, locationHQName } = useOrganizationContext();
  const {
    Address1Organization,
    Address2Organization,
    cityOrganization,
    postalCodeOrganization,
    latOrganization,
    longOrganization,
    phoneOrganization,
    countryOrganization,
    stateOrganization,
    emailOrganization,
    setAlertRequiredFields,
    applicableBrand,
    purpose,
    displayOrder,
    isPremium,
    isFactory,
    category,
    business,
    isOnlineOnly,
  } = useOrganizationContext();

  // eslint-disable-next-line no-shadow
  const hadleCreate = async (name: string, url: string, description: string, logo: File[]) => {
    const fileCompressed: string = await handleGetCompressedBase64(logo, 0);
    const body: ICreateBodyOrganization = {
      name,
      location_name: locationHQName,
      description,
      website_url: `${initalUrl}${url}`,
      logo: fileCompressed,
      address: {
        address1: Address1Organization,
        address2: Address2Organization,
        city: cityOrganization,
        postal_code: postalCodeOrganization,
        coordinates: [latOrganization, longOrganization],
        country: countryOrganization?.label ?? "",
        maps_id: mapId,
        state: stateOrganization,
        region: regionOrganization?.label ?? "",
      },
      phone: phoneOrganization || "",
      email: emailOrganization || "",
      purpose_codes: purpose.map((item) => `${item.code}`),
      premium: isPremium,
      factory: isFactory,
      categories_products: category,
      business,
      brands: applicableBrand.map((item) => `${item.code}`),
      is_online_only: isOnlineOnly,
      display_order: displayOrder,
    };
    const response = await callEndpoint(await createOrganizationsAPI({ body }), (error: any) =>
      handleCreateToast(`${t("ALERT-ERROR-CREATE")} : ${capitalize(error?.response?.data?.message)}`, "error"),
    );
    const { data } = response;

    handleCreateToast(t("ALERT-SUCCESS-CREATE"), "success");
    //When create organization needs to be redirected to the create one
    navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${data?.result?.id}`, { replace: false });
  };

  const basicReqFields = !name || logo.length === 0;
  const contactReqFields = !locationHQName || !isValidEmail || (url ? !isUrl(`${initalUrl}${url}`) : false);
  const requiredInputBusiness =
    (purpose.findIndex((item) => item.code === "OR") !== -1 ? !displayOrder : false) ||
    purpose.length === 0 ||
    (purpose.some((item) => purposeCodeforAplicableBrand.includes(`${item.code}`)) && applicableBrand.length === 0);
  //**Address step */
  const requiredInputAddress =
    !Address1Organization ||
    !cityOrganization ||
    !postalCodeOrganization ||
    !stateOrganization ||
    !regionOrganization?.id ||
    !countryOrganization?.id;

  const areRequiredFieldsFilled = basicReqFields || contactReqFields || requiredInputBusiness || requiredInputAddress;

  // eslint-disable-next-line consistent-return
  const handleRequiredFields = () => {
    if (areRequiredFieldsFilled) return setAlertRequiredFields(true);
    hadleCreate(name, url, description, logo);
  };

  return (
    <Stack direction="row" gap={4} mt={8} mx={2} display="flex" justifyContent="space-between">
      <Button
        name="org-cancel-create"
        onClick={() => navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`, { replace: false })}
        label={t("BUTTON-CANCEL")}
        size="large"
        color="secondary"
        variant="outlined"
      />
      <Box onClick={() => setAlertRequiredFields(true)} sx={{ cursor: "pointer" }}>
        <Button
          name="org-create"
          onClick={handleRequiredFields}
          label={t("BUTTON-CREATE")}
          size="large"
          disabled={loading || areRequiredFieldsFilled}
        />
      </Box>
    </Stack>
  );
}

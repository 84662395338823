import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import { SnackbarContent, CustomContentProps, useSnackbar } from "notistack";
import { forwardRef, useCallback, useRef } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const SnackbarDiv = styled(SnackbarContent)(
  ({ theme }) => `
  display: flex;
  gap: 8px;
  overflow: hidden;
  background-color: ${theme.palette.background.default};
  border-radius: 8px;
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  padding: 0.75rem;
  color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 500;
  text-align: start;
  position: relative;
  margin-top: 50px;

  & .snackbar-message {
    flex: 1 1 0%;
    max-width: 100%;
  }

  & .snackbar-title {
    margin: 0;
    line-height: 1.5rem;
    margin-right: 0.5rem;
  }

  & .snackbar-description {
    margin: 0;
    line-height: 1.5rem;
    font-weight: 400;
    color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
  }

  & .snackbar-close-icon {
    cursor: pointer;
    flex-shrink: 0;
    padding: 2px;
    border-radius: 4px;

    &:hover {
      background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    }
  }
  `,
);

interface ReportCompleteProps extends CustomContentProps {
  allowDownload?: boolean;
}

const SnackbarNotification = forwardRef<HTMLDivElement, ReportCompleteProps>(({ id, ...props }, ref) => {
  const nodeRef = useRef(null);
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarDiv ref={ref}>
      <SnackbarContent ref={nodeRef}>
        <Box m={2} display="flex">
          <InfoOutlinedIcon sx={{ fontSize: 18 }} />
          <Box mx={4}>
            {typeof props.message === "string" ? (
              <Typography variant="h5" mb={4}>
                {props.message}
              </Typography>
            ) : (
              <> {props.message}</>
            )}
          </Box>

          <IconButton aria-label="close" onClick={handleDismiss} sx={{ width: "25px", height: "25px" }}>
            <CloseIcon sx={{ fontSize: 20 }} />
          </IconButton>
        </Box>
      </SnackbarContent>
    </SnackbarDiv>
  );
});

SnackbarNotification.displayName = "notification";

export default SnackbarNotification;

import { Skeleton, Stack } from "@mui/material";
import { useListAndSearchContext } from "context/ListsAndSearchContext";

export default function OrgLoadingListType() {
  const { orgListViewType } = useListAndSearchContext();

  return (
    <>
      {orgListViewType === "cards" ? (
        <Stack flexWrap="wrap" direction="row" gap={8} justifyContent="center" mt={8}>
          {Array.from({ length: 12 }).map((_, i) => (
            <Skeleton key={i} width="372px" height="300px" variant="rounded" />
          ))}
        </Stack>
      ) : (
        <Stack spacing={4}>
          {Array.from({ length: 12 }).map((_, i) => (
            <Skeleton key={i} width="100%" height={60} variant="rounded" />
          ))}
        </Stack>
      )}
    </>
  );
}

//** React imports */
import { useState } from "react";
//**Hooks */
import { useAsync, useFetchAndLoad, useGetDateRange, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import useBreadcrumbs from "hooks/useBreadcrumbs";
//**Model */
import { IUsersListDOM, IUsersListFromAPI, IUsersLoadHook, IUsersSearchHook } from "pages/users/models";
//**Utils */
import { capitalize, createQuery } from "utilities";
//**Services */
import dayjs from "dayjs";
import { DATE_FORMAT, PrivateRoutes } from "models";
import { getAllUsersAPI } from "../../usersList/services";
import { userGetallAdapter } from "../../usersList/adapters";
//** Context */
import { useUsersRequestListContext } from "../context";
import { UserRequestSearchFields } from "../../usersList/hooks/useUsersRequestSearch";

// eslint-disable-next-line no-shadow, no-unused-vars
export enum statusRequest {
  // eslint-disable-next-line no-unused-vars
  USERS_WITH_CREATION_REQUEST = "Pending",
}
export default function useGetUsersRequest() {
  //** Context */
  const { usersRequestPage, setUsersRequestPage, setUsersRequestHasFilter } = useListAndSearchContext();

  //**Search */
  const { searchParamsUsersRequest } = useListAndSearchContext();
  const firstNameRequestSearch = searchParamsUsersRequest.get(UserRequestSearchFields.firstName);
  const lastNameRequestSearch = searchParamsUsersRequest.get(UserRequestSearchFields.lastName);
  const emailRequestSearch = searchParamsUsersRequest.get(UserRequestSearchFields.email);
  const roleRequestSearch = searchParamsUsersRequest.get(UserRequestSearchFields.roleId); //** needs the ids */
  const dateSearchRequest = searchParamsUsersRequest.get(UserRequestSearchFields.date);
  const afterDateSearchRequest = dayjs(searchParamsUsersRequest.get(UserRequestSearchFields.afterDate));
  const beforeDateSearchRequest = dayjs(searchParamsUsersRequest.get(UserRequestSearchFields.beforeDate));

  const { usersRequestArray } = useUsersRequestListContext();
  const { setUsersRequestArray } = useUsersRequestListContext();

  //** State */
  const [searchToggle, setSearchToggle] = useState(false);
  const limit = 50;
  const [page, setPage] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const initialPagination = 20;

  //to sort list by api
  const [countCode, setCountCode] = useState(1);
  const [typeSort, setTypeSort] = useState("");

  //** Hooks */
  const { loading, callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const { startHistory } = useBreadcrumbs();

  const { getDateRange } = useGetDateRange();

  const searchObject = {
    first_name: firstNameRequestSearch,
    last_name: lastNameRequestSearch,
    username: emailRequestSearch,
    role_id: roleRequestSearch,
    nowPage: usersRequestPage,
    after_date: afterDateSearchRequest ? afterDateSearchRequest?.format(DATE_FORMAT) : "",
    before_date: beforeDateSearchRequest ? beforeDateSearchRequest?.add(1, "day").format(DATE_FORMAT) : "",
  };
  const loadUsersRequest: any = async ({ first_name, last_name, username, role_id, before_date, after_date }: IUsersLoadHook) => {
    //Create an object with key that have some search value
    //If the table doesn't have a filter it will be sorted by creation date

    const { beforeDate, afterDate } = getDateRange(dateSearchRequest, `${before_date}`, `${after_date}`);

    let query = createQuery<IUsersSearchHook>({
      first_name,
      last_name,
      username,
      role_id,
      status: statusRequest.USERS_WITH_CREATION_REQUEST,
      sort: typeSort || "desc(created_at)",
      before_date: beforeDate === "Invalid Date" ? "" : beforeDate,
      after_date: afterDate === "Invalid Date" ? "" : afterDate,
    });
    query = { offset: 0, limit: initialPagination, ...query };
    setUsersRequestHasFilter(`${first_name}${last_name}${username}${role_id}${beforeDate}${afterDate}`);
    //Calling get all API
    const response = await callEndpoint(getAllUsersAPI({ query }), () => handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"));

    return response;
  };

  const adaptInfo = (data: IUsersListFromAPI) => {
    //Save the adapted data and the last page and length for the pagination
    const array: IUsersListDOM[] = userGetallAdapter(data?.result?.items);
    setUsersRequestArray(array);
    //** Update the page to call the next data on api */
    setPage((prev: number) => prev + 1);
    //** If dosen`t appear more data sent that there are not more information*/
    if (!data?.result?.next) setHasNextPage(false);

    startHistory({
      url: `/${PrivateRoutes.USERS_REQUEST}/${PrivateRoutes.LIST}`,
      label: capitalize(`${PrivateRoutes.USERS_REQUEST}`),
      index: 0,
    });
  };

  /**
   ** This function appears on dashboard for infinite scroll on users list
   * @return { response }
   */
  const loadMoreUsersRequest = async () => {
    let query = createQuery<IUsersSearchHook>({
      first_name: searchObject.first_name,
      last_name: searchObject.last_name,
      username: searchObject.username,
      role_id: searchObject.role_id,
      status: statusRequest.USERS_WITH_CREATION_REQUEST,
      sort: typeSort || "desc(created_at)",
      before_date: searchObject.before_date === "Invalid Date" ? "" : searchObject.before_date,
      after_date: searchObject.after_date === "Invalid Date" ? "" : searchObject.after_date,
    });
    //?first 20 are loaded and then loaded 50 at a time
    if (page === 1) {
      query = { ...query, offset: initialPagination };
    } else {
      query = { ...query, offset: (page - 1) * limit + initialPagination };
    }

    query = { ...query, limit };

    //Calling get all API
    const response = await callEndpoint(getAllUsersAPI({ query }), () => handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"));
    const { data } = response;

    //Save the adapted data and the last page and length for the pagination
    const array: IUsersListDOM[] = userGetallAdapter(data?.result?.items);
    setUsersRequestArray((prev) => [...prev, ...array]);
    //** Update the page to call the next data on api */
    setPage((prev: number) => prev + 1);

    //** If dosen`t appear more data sent that there are not more information*/
    if (!data?.result?.next) setHasNextPage(false);
    return response;
  };

  useAsync(
    () => loadUsersRequest(searchObject),
    adaptInfo,
    () => {},
    [usersRequestPage, searchToggle, typeSort],
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setUsersRequestPage(value);
  };
  const onSearch = () => {
    setSearchToggle((value) => !value);
    setUsersRequestPage(0);
    setHasNextPage(true);
  };

  // * ------------------------SORT----------------------------------//

  // sort

  const incrementCount = (key: string) => {
    setCountCode((prev) => prev + 1);

    if (countCode === 1) {
      setTypeSort(`asc(${key})`);
    }
    if (countCode === 2) {
      setTypeSort(`desc(${key})`);
      setCountCode(1);
    }
  };

  return {
    usersRequestArray,
    loading,
    limit,
    handleChange,
    onSearch,
    incrementCount,
    hasNextPage,
    page,
    loadMoreUsersRequest,
  };
}

import { useEffect } from "react";
import { Box } from "@mui/material";
//** Context */
import { useUserContext } from "pages/users/context";
import { UserViewsName } from "pages/users/models";
import { UserInformationView } from "./components";

export default function UsersInformation() {
  //** Context*/
  const { setView } = useUserContext();
  useEffect(() => {
    setView(UserViewsName.INFORMATION);
  }, []);
  return (
    <Box>
      <UserInformationView />
    </Box>
  );
}

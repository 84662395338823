import { postFromUrl } from "utilities";

export const loginFunction = async (email: string | null, password: string | null) => {
  const body = {
    username: email,
    password,
  };
  return postFromUrl("users/login", body);
};

export default loginFunction;

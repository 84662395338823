//**Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";

//**Hooks */
import { useFetchAndLoad, useToastMui } from "hooks";
import { useNavigate } from "react-router-dom";
import useGetPictures from "hooks/useGetPictures";

//** Services */
import { useTranslation } from "react-i18next";
//** Utils */
import { capitalize, hasHttp } from "utilities";
import { PrivateRoutes } from "models";
import { useGlobalContext } from "context/globalContext";
import config from "config/settings.json";
import { createLocationsAPI } from "../services";
import { ICreateBodyLocation } from "../services/api.interfaces";
//**Context */
import { useLocationContext } from "../context";
import { useUpdateLocation } from "../pages/locationsInformation/hooks";

export default function useCreateLocation() {
  //**Context */
  //** Step */
  const { purpose } = useLocationContext();
  //** Others Step */
  const { name, organization, logo, applicableBrand, category, isVisible, initalUrl } = useLocationContext();
  const { email, phone, website, fax, openingHours, marketCountry } = useLocationContext();
  const { address1, address2, city, postalCode, latitude, longitude, state, mapId, region } = useLocationContext();
  //* location Services */
  const { displayOrder, isPremium, isFactory, isOnlineOnly } = useLocationContext();
  //* SBDUSE*/
  const {
    descriptionSBDUS,
    amountOfEmployees,
    facilitySquareFootage,
    business,
    mainLocationImage,
    locationImagesAdditional,
    brandsPictures,
    isHQLocation,
  } = useLocationContext();

  const { sustainable, yearFounded, mngDirector, keyContacts } = useLocationContext();

  //**Hooks */
  const { callEndpoint, loading } = useFetchAndLoad();
  const navigate = useNavigate();
  const { handleCreateToast } = useToastMui();
  const { handleGetCompressedBase64 } = useGetPictures();
  const { urlHistory } = useGlobalContext();
  const { t } = useTranslation();
  const { updateHqLocation } = useUpdateLocation();

  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);

  const handleCreateLocation = async () => {
    //** Get the base 64 of compressed images */
    const logoCompressed: string = await handleGetCompressedBase64(logo, 0);

    let additionalImagesCompressed: string[] = [];
    let brandPicturesCompressed: string[] = [];
    const mainImageCompressed = await handleGetCompressedBase64(mainLocationImage, 0);

    //** Get all compressed images data */
    for (let i = 0; i < locationImagesAdditional.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const imageCompressed = await handleGetCompressedBase64(locationImagesAdditional, i);
      additionalImagesCompressed = [...additionalImagesCompressed, imageCompressed];
    }

    for (let i = 0; i < brandsPictures.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const imageCompressed = await handleGetCompressedBase64(brandsPictures, i);
      brandPicturesCompressed = [...brandPicturesCompressed, imageCompressed];
    }

    //** Create a location with the location data */

    let body: ICreateBodyLocation = {
      address: {
        address1,
        address2,
        city,
        coordinates: [latitude, longitude],
        postal_code: postalCode,
        state,
        maps_id: mapId,
        region: region.id || "",
      },
      brands: applicableBrand.map((item) => `${item.code}`),
      categories_products: category,
      contact: {
        email,
        phone,
        fax,
        // eslint-disable-next-line no-nested-ternary
        website: website ? `${initalUrl}${hasHttp(website)}` : "",
        key_contacts: keyContacts.map((item) => ({
          name: item.name || "",
          title: item.title || "",
          email: item.email || "",
          phone: item.phone || "",
        })),
        managing_director_or_leader: {
          name: mngDirector?.name || "",
          title: mngDirector?.title || "",
          email: mngDirector?.email || "",
          phone: mngDirector?.phone || "",
        },
      },
      is_visible: isVisible,
      logo: logoCompressed,
      market_or_country: `${marketCountry ? marketCountry?.label : ""}`,
      name,
      open_hours: {
        1: {
          opening: openingHours[0].opening,
          closing: openingHours[0].closing,
          is_enabled: openingHours[0].is_enabled,
        },
        2: {
          opening: openingHours[1].opening,
          closing: openingHours[1].closing,
          is_enabled: openingHours[1].is_enabled,
        },
        3: {
          opening: openingHours[2].opening,
          closing: openingHours[2].closing,
          is_enabled: openingHours[2].is_enabled,
        },
        4: {
          opening: openingHours[3].opening,
          closing: openingHours[3].closing,
          is_enabled: openingHours[3].is_enabled,
        },
        5: {
          opening: openingHours[4].opening,
          closing: openingHours[4].closing,
          is_enabled: openingHours[4].is_enabled,
        },
        6: {
          opening: openingHours[5].opening,
          closing: openingHours[5].closing,
          is_enabled: openingHours[5].is_enabled,
        },
        7: {
          opening: openingHours[6].opening,
          closing: openingHours[6].closing,
          is_enabled: openingHours[6].is_enabled,
        },
      },
      //** If the user have and organization the location will be assigned to that organization */
      organization_id: `${userState.organization?.id ? userState.organization?.id : organization.id}`,
      purpose_codes: purpose.map((item) => `${item.code}`),
      region: "",
      amount_of_employees: +amountOfEmployees,
      description: descriptionSBDUS,
      facility_square_footage: +facilitySquareFootage,
      business,
      sustainability: sustainable,
      images: additionalImagesCompressed,
      brand_manufactured: brandPicturesCompressed,
      main_image: mainImageCompressed,
      display_order: displayOrder,
      factory: isFactory,
      premium: isPremium,
      is_online_only: isOnlineOnly,
    };
    if (yearFounded) {
      body = { ...body, year_founded: +yearFounded };
    }

    const response = await callEndpoint(createLocationsAPI({ body }), (error: any) =>
      handleCreateToast(`${t("ALERT-ERROR-CREATE")} : ${capitalize(error?.response?.data?.message)}`, "error"),
    );

    //** ADD HQ to created location
    if (isHQLocation) await updateHqLocation(organization.id || "", response.data.result.id || "");

    handleCreateToast(t("ALERT-SUCCESS-CREATE"), "success");

    // ? If the user come from org landing or loc list then go back to the respective page
    if (document.referrer.indexOf(`${config.HOST_URL}`) !== -1 || urlHistory.length > 0) {
      navigate(-1);
    } else {
      navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.LIST}`, { replace: false });
    }
  };
  return { handleCreateLocation, loading };
}

import React from "react";
import { useLocationListContext } from "../context";

export default function useSelectLocationsCheckbox() {
  const { locationsMapListArray, selectedClaim, setSelectedClaim } = useLocationListContext();

  const numSelected = selectedClaim.length;
  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = locationsMapListArray.map((n) => ({ id: n.id, isHQ: n.isHeadQuarter, status: n.isVisible }));
      setSelectedClaim(newSelected);
      return;
    }
    setSelectedClaim([]);
  };

  const rowCount = locationsMapListArray.length;

  return { numSelected, onSelectAllClick, rowCount };
}

import { FormControl, FormControlLabel, RadioGroup, Typography } from "@mui/material";
import ExportModal from "components/molecules/ExportModal/ExportModal";
import Radio from "@mui/material/Radio";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "context/globalContext";
import { useLocationListContext } from "pages/locations/pages";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { exportModalActions } from "constants/globalConstants";

export default function ModalLocationExports({
  exportLocations,
  open,
  handleClose,
}: {
  exportLocations: () => Promise<void>;
  open: boolean;
  handleClose: () => void;
}) {
  //** States */
  const { selectedClaim } = useLocationListContext();
  const { locationHasFilter } = useListAndSearchContext();
  const { exportLocationFilter, setExportLocationFilter } = useGlobalContext();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExportLocationFilter((event.target as HTMLInputElement).value);
  };

  return (
    <ExportModal open={open} handleClose={handleClose} handleConfirm={exportLocations}>
      <Typography variant="h5">{t("LOC-EXPORT-TITLE")} </Typography>
      <FormControl sx={{ ml: 4, mt: 4 }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={exportModalActions.EXPORT_ALL}
          onChange={handleChange}
          value={exportLocationFilter}
        >
          <FormControlLabel value={exportModalActions.EXPORT_ALL} control={<Radio />} label={t("LOC-EXPORT-ITEM-2")} />
          <FormControlLabel
            value={exportModalActions.EXPORT_SELECTED_ITEMS}
            control={<Radio />}
            label={t("LOC-EXPORT-ITEM-3")}
            disabled={selectedClaim.length === 0}
          />
          <FormControlLabel
            value={exportModalActions.EXPORT_CURRENT_SEARCH}
            control={<Radio />}
            label={t("LOC-EXPORT-ITEM-4")}
            disabled={!locationHasFilter}
          />
        </RadioGroup>
      </FormControl>
    </ExportModal>
  );
}

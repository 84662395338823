//** Components Imports */
import { Box } from "@mui/material";
import { CenterCard } from "components";
import FooterContent from "core/layouts/components/shared-components/footer/FooterContent";
import { LoginButtons, LoginHeader, LoginInputs } from "./components";

//** Hooks */
import { LoginContextProvider, useLoginContext } from "./context";
import { useLogin } from "./hooks";

//** Style */
import "./style/style.css";

export default function Login() {
  //**Context */
  const { email, password } = useLoginContext();
  // ** Hooks
  const { login } = useLogin();
  const handleSubmit = (event: any) => {
    event.preventDefault();
    login(email, password);
  };

  return (
    <LoginContextProvider>
      <CenterCard>
        <LoginHeader />
        <form noValidate onSubmit={handleSubmit} autoComplete="chrome-off">
          <LoginInputs />
          <LoginButtons />
        </form>
      </CenterCard>
      <Box position="absolute" bottom={8} left={8}>
        <FooterContent />
      </Box>
    </LoginContextProvider>
  );
}

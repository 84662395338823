//**Libraries */
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

//** Hooks */
import { useLoginContext } from "pages/login/context";
import { useLogin } from "pages/login/hooks";
import { useTranslation } from "react-i18next";
//** Models */
import { PublicRoutes, SBD_EMAIL_REGEX } from "models";

export default function LoginButtons() {
  //**Context */
  const { email, password, isValid } = useLoginContext();
  /**Hooks */
  const { t } = useTranslation();
  const { login, loginWithPingID } = useLogin();
  return (
    <>
      <Button
        data-testid="btnLogin"
        name="login"
        fullWidth
        onClick={() => login(email, password)}
        disabled={!email || !password || !isValid || SBD_EMAIL_REGEX.test(email || "")}
        size="large"
        type="submit"
        variant="contained"
        sx={{ mb: 7 }}
      >
        {t("LOGIN-BUTTON")}
      </Button>
      <Button fullWidth size="large" type="submit" variant="outlined" sx={{ mb: 7 }} name="login-sso" onClick={loginWithPingID}>
        {t("LOGIN-BUTTON-SSO")}
      </Button>

      <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "center" }}>
        <Typography sx={{ mr: 2, color: "text.secondary" }}>{t("LOGIN-REQUEST-1")}</Typography>
        <Link to={`/${PublicRoutes.ACCOUNT_REQUEST}`}>
          <Typography sx={{ color: "primary.main", textDecoration: "none" }}>{t("LOGIN-REQUEST-2")}</Typography>
        </Link>
      </Box>
    </>
  );
}

/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { getLocationsGoogleAPIInformation } from "services";
import { ArrowDropDown } from "@mui/icons-material";
import { useJsApiLoader } from "@react-google-maps/api";
import settings from "../../../config/settings.json";

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
}
interface IGoogleAutocomplete {
  onPlaceChanged: (data: any) => void;
  getGMDataForInfoView?: (data: any) => void;
  onLoad?: (autocomplete: google.maps.places.Autocomplete) => void;
  initialValue: string;
  label: string;
  disabled?: boolean;
  requiredFields?: boolean;
  alertLabel?: string;
  mapId?: string;
  size?: "small" | "medium";
  multiline?: boolean;
}

export default function GoogleMaps({
  onLoad,
  onPlaceChanged,
  initialValue,
  label,
  disabled,
  getGMDataForInfoView,
  requiredFields,
  alertLabel,
  mapId,
  size,
  multiline,
}: IGoogleAutocomplete) {
  // const  libraries :Libraries[] = React.useState(["geometry", "drawing", "places"]);

  const [libraries] = React.useState<any>(["geometry", "drawing", "places"]);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${settings.GOOGLE_API_KEY}`,
    libraries,
  });

  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const [firstTime, setFirstTime] = React.useState(true);

  const getData = async (placeId: string) => {
    const data: any = await getLocationsGoogleAPIInformation(placeId);
    onPlaceChanged(data);
  };

  const getByMapsId = async (placeId: string) => {
    const response: any = await getLocationsGoogleAPIInformation(placeId);
    // const newOptions = response.data.results;
    const newOptions = [
      {
        description: response.data.results[0].formatted_address,
        place_id: response.data.results[0].place_id,
        structured_formatting: response.data.results[0].address_components,
      },
    ];
    setOptions(newOptions);
    setValue(newOptions[0]);
    setInputValue(newOptions[0]?.description);
    getGMDataForInfoView && getGMDataForInfoView(response);
  };

  const fetch = React.useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 400),
    [],
  );
  React.useEffect(() => {
    //Active status to clear the effect in case unmont the component
    let active = true;

    if (isLoaded && firstTime && initialValue) {
      if (active) {
        getByMapsId(initialValue);
      }
      setFirstTime(false);
    }

    return () => {
      active = false;
    };
  }, [isLoaded]);
  React.useEffect(() => {
    if (!mapId) return;
    //Active status to clear the effect in case unmont the component
    let active = true;

    if (isLoaded) {
      if (active) {
        getByMapsId(mapId);
      }
      setFirstTime(false);
    }

    return () => {
      active = false;
    };
  }, [mapId]);

  React.useEffect(() => {
    //Active status to clear the effect in case unmont the component
    let active = true;

    //If google api service are loaded get autocompleteService
    if (!autocompleteService.current && (window as any).google?.maps?.places) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }

    //See if the autocomplete service already exists
    if (!autocompleteService.current) {
      return undefined;
    }

    //see if the autocompletion has any input value to be able to do the place search
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    //Call the google maps service to get the search options
    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    //Clear the state if unmount component
    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <>
      {isLoaded && (
        <Autocomplete
          popupIcon={<ArrowDropDown sx={{ fontSize: "1.5em" }} />}
          disabled={disabled}
          fullWidth
          id="google-map-demo"
          getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          noOptionsText="No locations"
          onChange={(event: any, newValue: PlaceType | null) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            getData(`${newValue?.place_id}`);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              multiline={multiline}
              {...params}
              label={label}
              fullWidth
              color={requiredFields ? "error" : "primary"}
              focused={requiredFields}
              size={size}
            />
          )}
          renderOption={(props, option) => {
            const matches = option.structured_formatting.main_text_matched_substrings || [];

            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match: any) => [match.offset, match.offset + match.length]),
            );

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: "flex", width: 44 }}>
                    <LocationOnIcon sx={{ color: "text.secondary" }} />
                  </Grid>
                  <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                    {parts.map((part) => (
                      <Box key={part.text} component="span" sx={{ fontWeight: part.highlight ? "bold" : "regular" }}>
                        {part.text}
                      </Box>
                    ))}
                    <Typography variant="body2" color="text.secondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      )}
      {requiredFields && alertLabel && (
        <Typography component="span" color="error" ml={6} mt={2}>
          {alertLabel}
        </Typography>
      )}
    </>
  );
}

//** React imports */
import { ChangeEvent, useState } from "react";
//** Components import */
import { CardHeaderLogin, Input, Spinner } from "components";
import { BackToLoginButton } from "pages/resetPassword/components";
//** MeterialUI Imports */
import { Box, Button, Container, Stack } from "@mui/material";
//** Hooks */
import { useTranslation } from "react-i18next";
import { EMAIL_REGEX } from "models";
import { useValidateAccount } from "./hooks";

export default function ValidateAccount() {
  //** Hooks */
  const { loading, resendValidationEmail } = useValidateAccount();
  const { t } = useTranslation();

  //** States */
  const [email, setEmail] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsValid(EMAIL_REGEX.test(e.target.value));
  };
  const handleSendEmail = () => {
    resendValidationEmail(email);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Container maxWidth="md">
          <Box display="flex" flexDirection="column" textAlign="center" marginTop="20%">
            <CardHeaderLogin title={`${t("VERIFY-ACCOUNT-TITLE-ERROR")}`} subTitle={`${t("VERIFY-ACCOUNT-SUBTITLE-ERROR")}`} />
            <Stack spacing={8} direction="row" mb={10}>
              <Input
                value={email}
                onChange={onChange}
                label={`${t("INPUT-EMAIL")}`}
                fullWidth
                error={email.length > 0 && !isValid}
                name="email-validate-account"
                id="outlined-basic"
                helperText={`${t("INVALID-EMAIL")}`}
                size="small"
              />
              <Button
                name="button-validate-account"
                onClick={handleSendEmail}
                disabled={!isValid}
                size="large"
                type="submit"
                variant="contained"
                sx={{ marginBottom: email.length > 0 && !isValid ? "22px !important" : "" }}
              >
                {t("BUTTON-SEND")}
              </Button>
            </Stack>
            <BackToLoginButton />
          </Box>
        </Container>
      )}
    </>
  );
}

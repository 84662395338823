/* eslint-disable no-nested-ternary */
//** Components imports */
import { Input } from "components";
//** MUI imports */
import { Box, InputAdornment, MenuItem, Select, SelectChangeEvent, Stack, Typography, useMediaQuery } from "@mui/material";
//**Hooks */
import { useInputValueContext, useRoleGuard } from "hooks";
import { useTranslation } from "react-i18next";
//**Context */
import { useLocationContext } from "pages/locations/context";
import { EMAIL_REGEX, Roles } from "models";
import { ChangeEvent, useEffect } from "react";
import WarningTooltip from "components/molecules/WarningTooltip/WarningTooltip";
import isUrl from "is-url";
import { LocationValidations } from "pages/locations/models";
import { validate } from "class-validator";
import ReturnDataIcon from "components/molecules/ReturnDataIcon/ReturnDataIcon";

export default function ContactInputs() {
  //**Context */
  const { email, phone, website, initalUrl, googleMapsInputs } = useLocationContext();
  const { setEmail, setPhone, setWebsite, setInitalUrl, setLocHasChanges } = useLocationContext();
  const { setIsValidEmail, isValidEmail, setIsValidPhone, isValidPhone } = useLocationContext();
  //** Hooks */
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:600px)");
  const authUpdateOrganization = useRoleGuard([Roles.superAdmin, Roles.organizationManager, Roles.locationManager, Roles.multiOrgManager]);

  //** States */

  const websiteInput = useInputValueContext("", setWebsite);
  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsValidEmail(EMAIL_REGEX.test(e.target.value));
  };
  const validateIfPhoneIsInternational = (phoneNumber: string) => {
    const validationObject = new LocationValidations();
    validationObject.phone = phoneNumber;
    validate(validationObject).then((errors) => {
      if (errors.length > 0) {
        setIsValidPhone(false);
      } else {
        setIsValidPhone(true);
      }
    });
  };
  useEffect(() => {
    validateIfPhoneIsInternational(phone);
  }, [phone]);

  const onChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
    validateIfPhoneIsInternational(e.target.value);
  };

  const changeControllerFn = () => setLocHasChanges(true);

  const phoneGoogleCompare = !!phone && !!googleMapsInputs?.phone && googleMapsInputs?.phone !== phone;
  const webGoogleCompare = !!website && !!googleMapsInputs?.website && googleMapsInputs?.website !== `${initalUrl}${website}`;

  const websiteWithOutProtocol = googleMapsInputs?.website?.replace("https://", "").replace("http://", "") || "";

  return (
    <Box mt={12}>
      <Typography variant="h2" sx={{ mb: 8.5, fontWeight: 600, letterSpacing: "0.18px" }}>
        {t("STEP-TITLE-LOCATION-CONTACT")}
      </Typography>
      <Box>
        <Stack spacing={4} my={6} direction={!matches ? "row" : "column"}>
          <Input
            name="loc-phone"
            type="text"
            value={phone}
            onChange={onChangePhone}
            label={`${t("INPUT-PHONE-NUMBER")}`}
            fullWidth
            disabled={!authUpdateOrganization}
            startAdornment={phoneGoogleCompare ? <WarningTooltip /> : null}
            color={!isValidPhone ? "error" : phoneGoogleCompare && "warning"}
            focused={phoneGoogleCompare}
            error={phone?.length > 0 && !isValidPhone}
            helperText={phone?.length > 0 && !isValidPhone ? `${t("INVALID-PHONE")}` : ""}
            endAdornment={
              phoneGoogleCompare &&
              googleMapsInputs?.phone && (
                <ReturnDataIcon
                  onClick={() => {
                    setPhone(googleMapsInputs.phone);
                    validateIfPhoneIsInternational(googleMapsInputs.phone);
                  }}
                />
              )
            }
            changeControllerFn={changeControllerFn}
          />
          <Input
            name="loc-email"
            value={email}
            onChange={onChangeEmail}
            label={`${t("INPUT-EMAIL")}`}
            fullWidth
            disabled={!authUpdateOrganization}
            error={email?.length > 0 && !isValidEmail}
            helperText={!isValidEmail && `${email || ""}`.length > 0 ? `${t("INVALID-EMAIL")}` : ""}
            strink={email === null ? false : email.length > 0}
            changeControllerFn={changeControllerFn}
          />
        </Stack>
        <Stack spacing={4} mb={6} direction={!matches ? "row" : "column"}>
          <Input
            name="loc-website"
            value={website}
            onChange={websiteInput.onChange}
            label={`${t("INPUT-WEBSITE")}`}
            fullWidth
            disabled={!authUpdateOrganization}
            color={webGoogleCompare && "warning"}
            focused={webGoogleCompare}
            endAdornment={
              webGoogleCompare &&
              googleMapsInputs?.website && (
                <ReturnDataIcon
                  onClick={() => {
                    setWebsite(websiteWithOutProtocol);
                    setInitalUrl(googleMapsInputs.website.includes("https://") ? "https://" : "http://");
                  }}
                />
              )
            }
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Select
                    value={initalUrl}
                    onChange={(event: SelectChangeEvent) => {
                      setInitalUrl(event.target.value);
                    }}
                    disabled={!authUpdateOrganization}
                    variant="standard"
                    disableUnderline
                    sx={{
                      marginTop: "5px",
                    }}
                  >
                    <MenuItem value="https://">https://</MenuItem>
                    <MenuItem value="http://">http://</MenuItem>
                  </Select>
                  {webGoogleCompare ? <WarningTooltip /> : null}
                </InputAdornment>
              ),
            }}
            error={!isUrl(`${initalUrl}${website}`) && website.length > 0}
            helperText={`${t("INVALID-WEBSITE")}`}
            changeControllerFn={changeControllerFn}
          />
        </Stack>
      </Box>
    </Box>
  );
}

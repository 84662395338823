import { IBreadcrumbs } from "components/atoms/Breadcrumbs/Breadcrumbs";

import { useGlobalContext } from "context/globalContext";

export default function useBreadcrumbs() {
  const { breadcrumbsHistory, setBreadcrumbsHistory } = useGlobalContext();

  const startHistory = (newBreadcrumbObj: IBreadcrumbs): void => {
    setBreadcrumbsHistory([{ ...newBreadcrumbObj, index: 0 }]);
  };

  const createCustomHistory = (newBreadcrumbObj: IBreadcrumbs[]): void => {
    setBreadcrumbsHistory([...newBreadcrumbObj]);
  };

  const addToTheEndOfHistory = (newBreadcrumbObj: IBreadcrumbs): void => {
    if (!breadcrumbsHistory) {
      setBreadcrumbsHistory([{ ...newBreadcrumbObj, index: 0 }]);
    } else {
      const lastBreadcrumb = breadcrumbsHistory[breadcrumbsHistory.length - 1];
      if (newBreadcrumbObj?.label === lastBreadcrumb?.label || newBreadcrumbObj?.url === lastBreadcrumb?.url) {
        return;
      }
      setBreadcrumbsHistory([...breadcrumbsHistory, { ...newBreadcrumbObj, index: breadcrumbsHistory.length + 1 }]);
    }
  };

  const deleteFromHistory = (index: number): void => {
    setBreadcrumbsHistory((prev: IBreadcrumbs[]) => (prev.length > 0 ? [...prev.slice(0, index)] : []));
  };

  const deleteallHistory = (): void => {
    setBreadcrumbsHistory([]);
  };

  return { addToTheEndOfHistory, deleteFromHistory, deleteallHistory, startHistory, createCustomHistory };
}

import { actions } from "context/globalContext";
import { Dayjs } from "dayjs";
import { AxiosResponse } from "frameworks_and_drivers/external_interfaces/Axios";

export const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const DATE_FORMAT = "DD-MM-YYYY";
export const SBD_EMAIL_REGEX = /@sbdinc.com/i;

export interface AxiosCall<T> {
  call: Promise<AxiosResponse<T>>;
  controller?: AbortController;
}

export interface IFilterChips {
  key: string;
  label: string;
  data: string | number | boolean | Date | null | Dayjs;
}

export interface IMarket {
  code: string;
  name: string;
}
export interface IRegion {
  id: string;
  name: string;
  code: string;
  markets: IMarket[];
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
}

export interface IDropdownOption {
  id: string | null;
  label: string | null;
  code?: string;
  parentCode?: string;
}

export interface IRowError {
  row_number: number;
  errors: string[];
}
export interface IRowErrorDOM {
  rowNumber: number | string;
  errors: string[];
}
export interface INotification {
  summary: {
    execution_time: string;
    success: number;
    warning: number;
    failed: number;
    total: number;
  };
  template_errors: string[];
  rows_errors: IRowError[];
}
export interface INotificationDOM {
  id: string;
  name: actions;
  summary: {
    executionTime: string;
    success: number;
    warning: number;
    failed: number;
    total: number;
  };
  templateErrors: IRowErrorDOM[];
  rowsErrors: IRowErrorDOM[];
  error: string;
  date: Date;
  fileName: string;
}

export interface NotificationItem {
  notifications: INotificationDOM[];
  counter: number;
  emailAssosiated: string;
}
export const NotificationEmptyState = {
  notifications: [],
  counter: 0,
  emailAssosiated: "",
};

import { Fab, styled } from "@mui/material";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

import { useGlobalContext } from "context/globalContext";
import { LocalStorageKeys, setInLocalStorage } from "utilities";

export default function MapSpeedDialLoc({ absolute }: { absolute?: boolean }) {
  const { setMapView, mapView } = useGlobalContext();
  const OrgSpeedDialStyled = styled("div")(({ theme }) => ({
    zIndex: 11,
    position: absolute ? "absolute" : "fixed",
    right: theme.spacing(6), //6
    bottom: absolute ? theme.spacing(15) : theme.spacing(30), //10
  }));

  return (
    <OrgSpeedDialStyled
      onClick={() => {
        setMapView((prev) => {
          setInLocalStorage(LocalStorageKeys.LIST_VIEW, `${prev ? "LIST" : "MAP"}`);
          return !prev;
        });
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <Fab size="medium" color="primary" aria-label="add">
        {mapView ? <ViewListOutlinedIcon sx={{ fontSize: 26 }} /> : <MapOutlinedIcon sx={{ fontSize: 26 }} />}
      </Fab>
    </OrgSpeedDialStyled>
  );
}

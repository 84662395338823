/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useDateSearch, useDropdownSearch, useDropdownStringSearch, useInputSearch } from "hooks";

export enum UserRequestSearchFields {
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  role = "role",
  roleId = "roleId",
  date = "date",
  afterDate = "afterDate",
  beforeDate = "beforeDate",
}

export default function useUsersRequestSearch() {
  const { searchParamsUsersRequest, setSearchParamsUsersRequest } = useListAndSearchContext();
  const userReqFirstNameSearch = useInputSearch(searchParamsUsersRequest, setSearchParamsUsersRequest, UserRequestSearchFields.firstName);
  const userReqLastNameSearch = useInputSearch(searchParamsUsersRequest, setSearchParamsUsersRequest, UserRequestSearchFields.lastName);
  const userReqEmailSearch = useInputSearch(searchParamsUsersRequest, setSearchParamsUsersRequest, UserRequestSearchFields.email);
  const userReqDateSearch = useDropdownStringSearch(searchParamsUsersRequest, setSearchParamsUsersRequest, UserRequestSearchFields.date);
  const userReqRoleSearch = useDropdownSearch(
    searchParamsUsersRequest,
    setSearchParamsUsersRequest,
    UserRequestSearchFields.role,
    UserRequestSearchFields.roleId,
  );
  const userReqAfterDateSearch = useDateSearch(searchParamsUsersRequest, setSearchParamsUsersRequest, UserRequestSearchFields.afterDate);
  const userReqBeforeDateSearch = useDateSearch(searchParamsUsersRequest, setSearchParamsUsersRequest, UserRequestSearchFields.beforeDate);

  return {
    userReqFirstNameSearch,
    userReqLastNameSearch,
    userReqEmailSearch,
    userReqDateSearch,
    userReqRoleSearch,
    userReqAfterDateSearch,
    userReqBeforeDateSearch,
  };
}

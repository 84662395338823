import { useEffect, useState } from "react";
//**External libraries Imports */
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Color from "color-thief-react";
//**Components Imports*/
import { Chip, TooltipError } from "components";
import WarningTooltip from "components/molecules/WarningTooltip/WarningTooltip";
import { LocFastActions } from "pages/locations/components";
//**Guard */
import { RoleGuard } from "guards";
//**Hooks and models*/
import useLocTables from "hooks/useLocTables";
import useLocationTableItems from "pages/locations/hooks/useLocationTableItems";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IDropdownOption, PrivateRoutes, Roles } from "models";
import { ILocationsListDOM } from "pages/locations/models";
//**Icons */
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
//** Context */
import { useLocationListContext } from "../../../context";

interface ILocListProps {
  data: ILocationsListDOM;
  index: number;
  purposeOptions: IDropdownOption[];
  onSearch?: () => void;
}
export default function TableResponsiveRow({ data, index, purposeOptions, onSearch }: ILocListProps) {
  //**Vars */
  const [expandPurposes, setExpandPurposes] = useState(false);
  const { locationsMapListArray, selectedClaim, statusFocus, clickedMarker, setClickedMarker } = useLocationListContext();
  //** Hooks */
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setSelectedClaim, load, setLoad } = useLocationListContext();
  const { isSelected, handleClickTableCheckbox, handleClickTableCheckboxMobile } = useLocTables(
    setSelectedClaim,
    selectedClaim,
    locationsMapListArray,
  );
  const { handleTouchStart, handleTouchMove, handleTouchEnd, hoverItem, setHoverItem } = useLocationTableItems({
    handleClickTableCheckboxMobile,
  });
  //** Media querys */
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const smAbove = useMediaQuery("(min-width:900px)");
  const over1000px = useMediaQuery("(min-width:1000px)");

  //** Logic and functions */
  const locPurposes = purposeOptions.filter((item) => data.purposeCodes?.includes(`${item.code}`));
  const isItemSelected = isSelected(data.id);
  const isItemHover = data.id === hoverItem;

  //Table logic to navigate
  const handleNavigate = (id: string) => {
    navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${id}`, { replace: false });
  };

  useEffect(() => {
    if (clickedMarker) {
      statusFocus?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
    setClickedMarker(null);
  }, [clickedMarker]);

  return (
    <ListItem key={`loc-${data.id}`} sx={{ px: 4, py: 0 }} ref={`${clickedMarker}` === data.id ? statusFocus : null}>
      <ListItemButton
        onClick={() => handleNavigate(data.id)}
        sx={{
          pl: 6,
          pr: 0,
          backgroundColor: "background.paper",
          py: underMd ? 4 : 0,
          borderBottom: (theme: Theme) => `${theme.palette.divider} 1px solid`,
          display: "flex",
          alignItems: "center",
          height: !underMd ? "100px" : "auto",
        }}
        onMouseEnter={smAbove ? () => setHoverItem(data.id) : undefined}
        onMouseLeave={smAbove ? () => setHoverItem("") : undefined}
        onTouchStart={smAbove ? undefined : () => handleTouchStart(data.id, data.isHeadQuarter, !data.isVisible)}
        onTouchEnd={smAbove ? undefined : () => handleTouchEnd()}
        onTouchMove={smAbove ? undefined : () => handleTouchMove()}
      >
        <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.organizationManager, Roles.locationManager]}>
          <ListItemIcon onClick={(e) => e.stopPropagation()} sx={{ mr: 0 }}>
            <Checkbox
              onClick={() => handleClickTableCheckbox(data?.id, data?.isHeadQuarter, !data?.isVisible)}
              sx={
                isItemHover || isItemSelected || selectedClaim.length > 0
                  ? { "& .MuiSvgIcon-root": { fontSize: 24 }, visibility: "visible", display: "block", p: 0, pl: 4, pr: 4 }
                  : { visibility: "hidden", display: "none" }
              }
              checked={isItemSelected}
            />
          </ListItemIcon>
        </RoleGuard>
        <ListItemAvatar sx={{ mr: 8 }}>
          <Box display="flex">
            <Box mt={0.4}>
              <Color src={data.organizationData.logo as string} crossOrigin="*" format="hex">
                {({ data: dataImg, loading }) => {
                  return (
                    <>
                      <Skeleton
                        sx={{ display: load[index] || loading ? "block" : "none", ml: 4 }}
                        animation="wave"
                        variant="circular"
                        width={60}
                        height={60}
                      />

                      <Avatar
                        sx={{
                          display: load[index] ? "none" : "block",
                          ml: 4,
                          width: 60,
                          height: 60,
                          backgroundColor: dataImg || "background.default",
                          "& .MuiAvatar-img": { objectFit: "contain !important" },
                        }}
                        src={typeof data.organizationData.logo === "string" ? data.organizationData.logo : ""}
                        alt={data.name}
                        onLoad={(e: any) => {
                          if (e.type === "load") {
                            setLoad((prev: any) => {
                              const copy = prev;
                              copy[index] = false;
                              return [...copy];
                            });
                          }
                        }}
                      />
                    </>
                  );
                }}
              </Color>
            </Box>
          </Box>
        </ListItemAvatar>
        <Box width="100%" pr={7.2} display="flex" justifyContent="space-between">
          <Box width="100%" display="flex" alignItems="center">
            <Box display="flex" alignItems="flex-start" flexDirection="column" width={over1000px ? "40%" : "100%"}>
              <Box display="flex" alignItems="flex-start">
                <Typography variant="h2" fontWeight="500" mb={2} sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box mr={underMd ? 0 : 2}>
                    {data.warnings && data.warnings.length > 0 && <WarningTooltip table />}
                    {!data.hasRequiredFields && <TooltipError />}
                  </Box>
                  {data.name}
                </Typography>
              </Box>
              <Box width="30vw" display="flex" alignItems="center">
                <LocationOnOutlinedIcon sx={{ fontSize: "2rem", mr: 2 }} color="secondary" />
                <Typography variant="h4" color="text.secondary">
                  {data.address.address1}
                </Typography>
              </Box>
            </Box>

            {over1000px && (
              <Box width="30%" display="flex" alignItems="center">
                <IconButton sx={{ color: (theme: Theme) => theme.palette.text.primary, py: 2, px: 2 }}>
                  <DomainOutlinedIcon sx={{ fontSize: 22, pb: 0 }} />
                </IconButton>
                <Typography variant="h4" color="text.secondary">
                  {data.organizationData.name}
                </Typography>
              </Box>
            )}
            {over1000px && (
              <Box width="30%" display="flex" alignItems="center">
                <IconButton sx={{ color: (theme: Theme) => theme.palette.text.primary, py: 2, px: 2 }}>
                  <StoreMallDirectoryOutlinedIcon sx={{ fontSize: 22 }} />
                </IconButton>
                <Typography variant="h4" color="text.secondary">
                  {locPurposes.map((item, i) => (
                    <>{i >= 2 && !expandPurposes ? null : `${item.label}${i < locPurposes.length - 1 ? " - " : ""}`}</>
                  ))}
                </Typography>
                {locPurposes.length > 2 && !expandPurposes && (
                  <Button
                    variant="text"
                    color="secondary"
                    sx={{ px: 0, minWidth: 40 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpandPurposes(true);
                    }}
                  >
                    +{locPurposes.length - 2}
                  </Button>
                )}
              </Box>
            )}
          </Box>

          <Box display="flex" alignItems="center" width={underMd ? 50 : 140} justifyContent="flex-end">
            <Box
              sx={{
                display: "flex",
                alignItems: underMd ? "center" : "flex-end",
                justifyContent: "flex-start",
              }}
              gap={2}
            >
              {underMd ? (
                <>
                  {data.isVisible ? (
                    <Box
                      bgcolor={(theme: Theme) => {
                        return theme.palette.mode === "dark" ? theme.palette.secondary.dark : theme.palette.secondary.light;
                      }}
                      borderRadius="50%"
                      width="12px"
                      height="12px"
                    />
                  ) : (
                    <Box
                      bgcolor={(theme: Theme) => (theme.palette.mode === "dark" ? theme.palette.success.dark : theme.palette.success.light)}
                      borderRadius="50%"
                      width="12px"
                      height="12px"
                    />
                  )}
                </>
              ) : (
                <>
                  {data.isVisible ? (
                    <Chip size="small" label={t("OPTION-SELECT-1")} color="secondary" sx={{ fontSize: "1.4rem" }} />
                  ) : (
                    <Chip size="small" label={t("OPTION-SELECT-2")} color="success" sx={{ fontSize: "1.4rem" }} />
                  )}
                </>
              )}
              <LocFastActions
                id={data.id}
                isHQ={data.isHeadQuarter}
                handleMouseLeave={() => setHoverItem("")}
                onSearch={() => {
                  if (onSearch) onSearch();
                }}
              />
            </Box>
          </Box>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}

//** MUI Imports */
import { CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiCard, { CardProps } from "@mui/material/Card";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Box } from "@mui/system";

//** Components Imports */
import BlankLayout from "core/layouts/BlankLayout";
import { ReactNode } from "react";

// ** Styled Components
const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: 450 },
}));
export default function CenterCard({ children }: { children: ReactNode }) {
  return (
    <BlankLayout>
      <Box className="content-center">
        <Card sx={{ zIndex: 1, backgroundColor: "background.paper" }}>
          <CardContent sx={{ p: (theme) => `${theme.spacing(13, 15, 13)} !important` }}>{children}</CardContent>
        </Card>
      </Box>
    </BlankLayout>
  );
}

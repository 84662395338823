/* eslint-disable no-restricted-syntax */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getLocationsGoogleAPIInformationByCords } from "services";

export default function useSelectOnMap({
  mapref,
  setMapId,
}: {
  mapref: google.maps.Map | undefined;
  setMapId: Dispatch<SetStateAction<string>>;
}) {
  const [isMoving, setIsMoving] = useState<boolean>(false);
  const [address, setAddress] = useState<string>("");
  const [currentMapId, setCurrentMapId] = useState<string>("");
  const [loadingMapInfo, setLoadingMapInfo] = useState<boolean>(false);
  const [coordinatesToSend, setCoordinatesToSend] = useState<[number | undefined, number | undefined] | []>([]);
  const [internalCoordinates, setInternalCoordinates] = useState<[number | undefined, number | undefined] | []>([]);

  const selectBestResult = (results: any) => {
    const precisionPriority = ["ROOFTOP", "RANGE_INTERPOLATED", "GEOMETRIC_CENTER", "APPROXIMATE"];

    for (const precision of precisionPriority) {
      const result = results.find((r: any) => r.geometry.location_type === precision);
      if (result) return result;
    }
    return results[0];
  };

  const getGoogleMapsData = async () => {
    try {
      setLoadingMapInfo(true);
      if (!coordinatesToSend[0] || !coordinatesToSend[1]) {
        setLoadingMapInfo(false);
        return;
      }

      const response = await getLocationsGoogleAPIInformationByCords(coordinatesToSend);
      const { data } = response;
      const bestResult = selectBestResult(data.results);
      let routeGoogle = "";
      let streetNumberGoogle = "";

      bestResult?.address_components.forEach((addressComponent: any) => {
        if (addressComponent.types.includes("route")) {
          routeGoogle = addressComponent?.long_name;
        }
        if (addressComponent.types.includes("street_number")) {
          streetNumberGoogle = addressComponent?.long_name;
        }
      });

      setAddress(routeGoogle ? `${routeGoogle}, ${streetNumberGoogle}` : bestResult.formatted_address);
      setCurrentMapId(bestResult.place_id);
      // setInternalCoordinates([bestResult.geometry.location.lat, bestResult.geometry.location.lng]);

      setLoadingMapInfo(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getInternalMapDataZoom = () => {
    if (!mapref) return;
    setIsMoving(false);
    const newCenter = mapref.getCenter();
    const latitude = newCenter?.lat() !== 0 ? newCenter?.lat() : undefined;
    const longitude = newCenter?.lng() !== 0 ? newCenter?.lng() : undefined;

    setInternalCoordinates([latitude, longitude]);
  };

  const getInternalMapData = () => {
    if (!mapref) return;
    setIsMoving(false);
    const newCenter = mapref.getCenter();
    // const newZoom = mapref.getZoom();
    const latitude = newCenter?.lat() !== 0 ? newCenter?.lat() : undefined;
    const longitude = newCenter?.lng() !== 0 ? newCenter?.lng() : undefined;

    setInternalCoordinates([latitude, longitude]);
    setCoordinatesToSend([latitude, longitude]);
  };

  const handleMouseDown = () => {
    setIsMoving(true);
    setLoadingMapInfo(true);
  };

  const onSearchPlaceByMapId = () => {
    setMapId(currentMapId);
  };

  useEffect(() => {
    getGoogleMapsData();
  }, [coordinatesToSend]);

  return {
    isMoving,
    address,
    setAddress,
    loadingMapInfo,
    coordinatesToSend,
    internalCoordinates,
    getInternalMapData,
    getInternalMapDataZoom,
    handleMouseDown,
    onSearchPlaceByMapId,
  };
}

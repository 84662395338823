/* eslint-disable no-shadow */
//** Reat imports */
import { useState, createContext, useContext, SetStateAction, Dispatch, useMemo } from "react";
//** Models */
import { IOrganizationListDOM } from "pages/organizations/models";
import { array20 } from "models";

/*
The idea of this context it is allow a mechanism to pass the information between the components of the page.
*/

type contextValues = {
  order: string;
  setOrder: Dispatch<SetStateAction<string>>;
  orderBy: any;
  setOrderBy: Dispatch<SetStateAction<any>>;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;

  organizationsListLength: number;
  setOrganizationsListLength: Dispatch<SetStateAction<number>>;
  organizationsArray: IOrganizationListDOM[];
  setOrganizationsArray: Dispatch<SetStateAction<IOrganizationListDOM[]>>;
  load: boolean[];
  setLoad: Dispatch<SetStateAction<boolean[]>>;
};

const defaultValues = {
  order: "asc",
  setOrder: () => {},
  orderBy: "name",
  setOrderBy: () => {},
  selected: [],
  setSelected: () => {},
  page: 0,
  setPage: () => {},

  organizationsListLength: 0,
  setOrganizationsListLength: () => {},
  organizationsArray: [],
  setOrganizationsArray: () => {},
  load: array20,
  setLoad: () => {},
};

const OrganizationListContext = createContext<contextValues>(defaultValues);

const OrganizationListContextProvider = (props: any) => {
  const { children } = props;

  const [order, setOrder] = useState<string>("asc");
  const [orderBy, setOrderBy] = useState<any>("name");
  const [selected, setSelected] = useState<string[]>([]);

  const [organizationsListLength, setOrganizationsListLength] = useState(0);

  const [organizationsArray, setOrganizationsArray] = useState<IOrganizationListDOM[]>([]);
  const [load, setLoad] = useState<boolean[]>(array20);

  const contextValues = useMemo(
    () => ({
      order,
      setOrder,
      orderBy,
      setOrderBy,
      selected,
      setSelected,
      organizationsListLength,
      setOrganizationsListLength,
      organizationsArray,
      setOrganizationsArray,
      load,
      setLoad,
    }),
    [order, orderBy, selected, organizationsListLength, organizationsArray, load, setLoad],
  );

  return <OrganizationListContext.Provider value={contextValues}>{children}</OrganizationListContext.Provider>;
};

export const useOrganizationListContext = () => {
  const context = useContext(OrganizationListContext);
  if (context === undefined) {
    throw new Error("OrganizationListContext must be used within a OrganizationListContextProvider");
  }
  return context;
};

export { OrganizationListContext, OrganizationListContextProvider };

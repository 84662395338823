/* eslint-disable no-restricted-syntax */
/**
 * Generates an object with only the variables that are being used as a filter
 * @param {any} queryKeys - Object with key and values of API filters
 *
 * @return {any} Object with only filters with information
 */

export const createQuery = <T>(queryKeys: T) => {
  let query = {};
  for (const key in queryKeys) {
    if (queryKeys[key]) {
      query = { ...query, [key]: queryKeys[key] };
    }
  }
  return query;
};

//** Components imports */
import { LocationListContextProvider } from "pages/locations";

//** UTILS */

import OrganizationDashboard from "./components/organism/OrganizationDashboard";
import { OrganizationInfoContextProvider } from "./context/OrganizationInfoContext";
import { OrganizationListContextProvider } from "../organizationList";

export default function OrganizationsInformation() {
  return (
    <OrganizationInfoContextProvider>
      <OrganizationListContextProvider>
        <LocationListContextProvider>
          <OrganizationDashboard />
        </LocationListContextProvider>
      </OrganizationListContextProvider>
    </OrganizationInfoContextProvider>
  );
}

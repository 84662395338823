//** React imports */
import { useEffect, useState } from "react";
//** Hooks */
import useQuery from "utilities/getLocation";
import { useFetchAndLoad, useToastMui } from "hooks";
import { useNavigate } from "react-router-dom";
//*Utils* */
import { LocalStorageKeys, setInLocalStorage } from "utilities";
import { PublicRoutes } from "models";
//** Services */
import { resendVerificationEmailAPI, verifyEmailAPI } from "../services";

export default function useValidateAccount() {
  //** Hooks */
  const query = useQuery();
  const navigate = useNavigate();
  const { callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();

  //** State */
  const [loading, setLoading] = useState(false);

  /**
   ** Funtion to validate the user email on akamai
   * @return Redirection to reset password view
   */
  const confirmEmail = async () => {
    setLoading(true);
    //The account of the user is created by an admin.
    //The user s to validate the account and generate a new password
    try {
      //Email verification with email code provided
      const response = await callEndpoint(await verifyEmailAPI(query.get("verification_code")));
      const { data } = response;

      //Set the user email in localstorage to change the password before on reset password view
      setInLocalStorage(LocalStorageKeys.EMAIL, data.result?.email_user);

      //Redirect to the reset password view
      navigate(`/${PublicRoutes.CHANGE_PASSWORD}`, { replace: true });
    } catch (error: any) {
      console.error(error);
      handleCreateToast(`${error.response.data.message}`, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    confirmEmail();
  }, []);

  /**
   ** Funtion to validate the user email on akamai
   * @param email - Email to resent the validation email if the confirmation fails
   *
   * @return  {Promise<void>} Send a new email
   */
  const resendValidationEmail = async (email: string) => {
    try {
      await callEndpoint(await resendVerificationEmailAPI(email));
    } catch (error: any) {
      console.log("error sending email");
      console.log(error);
      handleCreateToast(`${error.response.data.message}`, "error");
    }
  };
  return { loading, resendValidationEmail };
}

//** MUI Imports */
import { IconButton, Stack, Theme, useMediaQuery } from "@mui/material";
//** Components */
import { Button } from "components";
import { PrivateRoutes, Roles } from "models";
//**Hooks */
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
// import { useRoleGuard } from "hooks";
//**Models */
import isUrl from "is-url";
//**Redux */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { RoleGuard } from "guards";
import { useLocationContext } from "pages/locations/context";
import { purposeCodeforAplicableBrand } from "../../../../constants/purposesToConditions";
import { useUpdateLocation } from "../../hooks";

export default function ButtonUpdateLocation() {
  //**Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { updateLocation, completeLoading } = useUpdateLocation();
  const { id } = useParams();
  //**Context */
  //** Step */
  const { purpose, isValidEmail, isValidPhone, keyContacts, locHasChanges, setGoBackModalLoc } = useLocationContext();
  //** Others Step */
  const { name, organization, applicableBrand, initalUrl } = useLocationContext();
  const { email, phone, website, marketCountry } = useLocationContext();
  const { city, postalCode, latitude, longitude, state, region } = useLocationContext();
  //* location Services */
  const { displayOrder } = useLocationContext();
  //* SBDUSE*/
  const { isValidSBDUSEmails } = useLocationContext();
  //** See which apps are selected */
  const isAplicableBrandRequired =
    purpose.some((item) => purposeCodeforAplicableBrand.includes(`${item.code}`)) && applicableBrand.length === 0;

  const disableButton =
    !name ||
    !organization?.label ||
    !marketCountry?.label ||
    !region?.label ||
    (!isUrl(`${initalUrl}${website}`) && website.length > 0) ||
    !city ||
    !postalCode ||
    !latitude ||
    !longitude ||
    !state ||
    (email?.length > 0 && !isValidEmail) ||
    (phone?.length > 0 && !isValidPhone) ||
    purpose.length === 0 ||
    // This fields are mandaroty only if the app is selected
    (purpose.findIndex((item) => item.code === "OR") !== -1 ? !displayOrder : false) ||
    isAplicableBrandRequired ||
    purpose.length === 0 ||
    !isValidSBDUSEmails.mngDirectorEmail ||
    keyContacts?.some((item) => !item.isValidEmail);

  return (
    <>
      {underMd ? (
        <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.organizationManager, Roles.locationManager, Roles.multiOrgManager]}>
          <IconButton onClick={updateLocation} disabled={disableButton || completeLoading}>
            <CheckCircleIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </RoleGuard>
      ) : (
        <Stack direction="row" gap={4} mt={8} width="100%" justifyContent="space-between">
          <Button
            name="loc-cancel-update"
            onClick={() => {
              const urlToGo = `/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${id}`;
              if (locHasChanges) return setGoBackModalLoc({ open: true, urlToGo: `${urlToGo}` });
              return navigate(`${urlToGo}`);
            }}
            label={t("BUTTON-CANCEL")}
            size="large"
            color="secondary"
            variant="outlined"
          />
          <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.organizationManager, Roles.locationManager, Roles.multiOrgManager]}>
            <Button
              name="loc-update"
              onClick={updateLocation}
              label={t("BUTTON-UPDATE")}
              size="large"
              disabled={disableButton || completeLoading}
            />
          </RoleGuard>
        </Stack>
      )}
    </>
  );
}

import { ReactNode } from "react";
// ** MUI Imports
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import { Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IModalProps {
  open: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
  children: ReactNode;
}
export default function ExportModal({ open, handleConfirm, handleClose, children }: IModalProps) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="loc-export"
      sx={{ "& .MuiPaper-root": { width: "100%", maxWidth: 570 } }}
      aria-describedby="loc-export-description"
    >
      <DialogTitle id="loc-export" sx={{ fontSize: "2rem !important", px: 8, pt: 8 }}>
        {t("GLOBAL-EXPORT")}
      </DialogTitle>
      <Divider />
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon sx={{ fontSize: 24 }} />
      </IconButton>

      <DialogContent sx={{ px: 8 }}>{children}</DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: "flex-start", mb: 4, px: 8 }}>
        <Button
          variant="contained"
          sx={{ mr: 1 }}
          size="large"
          onClick={() => {
            handleConfirm();
            handleClose();
          }}
        >
          {t("BUTTON-EXPORT")}
        </Button>
        <Button variant="outlined" color="secondary" size="large" sx={{ mr: 1 }} onClick={handleClose}>
          {t("BUTTON-CANCEL")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import { useEffect, useRef, useState } from "react";
import { MarkerF, MarkerProps, InfoWindow } from "@react-google-maps/api";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Chip } from "components/molecules";
import { useLocationListContext } from "pages";
import { useNavigate } from "react-router-dom";
import { PrivateRoutes } from "models";
import "./style.css";

interface IMarkerProps extends MarkerProps {
  name: string;
  address1: string;
  isVisible: boolean;
  id: string;
  embebed?: boolean;
}
export default function Marker(props: IMarkerProps) {
  const markerRef: any = useRef(null);

  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setActiveLocationItemList, activeLocationItemList, setClickedMarker } = useLocationListContext();

  const { id, name, address1, isVisible, position, embebed } = props;

  const markerIconFactory = (scale: number) => ({
    path: faLocationDot.icon[4] as string,
    fillColor: "#0288D1",
    fillOpacity: 1,
    strokeWeight: 2,
    strokeColor: "#ffffff",
    rotation: 0,
    scale: scale || 0.065,
    anchor: new google.maps.Point(
      faLocationDot.icon[0] / 2, // width
      faLocationDot.icon[1], // height
    ),
  });

  useEffect(() => {
    setActiveLocationItemList(null);
    if (markerRef.current) {
      markerRef?.current.setIcon(markerIconFactory(mouseOver ? 0.08 : 0.065));
    }
  }, [mouseOver]);

  const handleOnLoad = (markerInstance: any) => {
    markerRef.current = markerInstance;
  };

  const handleMouseOver = () => {
    setMouseOver(true);
  };

  const handleMouseOut = () => {
    setMouseOver(false);
  };

  // eslint-disable-next-line no-shadow
  const handleChatClick = (type: "location", id: string, coordinates: [number, number]) => {
    setActiveLocationItemList({ type, id, coordinates });
    setClickedMarker(id);
  };

  const activeCondition = activeLocationItemList !== null && activeLocationItemList.id === id && activeLocationItemList.type === "location";

  return (
    <MarkerF
      onClick={() => {
        handleChatClick("location", id, [+position.lat, +position.lng]);
      }}
      icon={markerIconFactory(0.065)}
      onLoad={handleOnLoad}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      {...props}
    >
      {activeCondition && position.lat && position.lng && (
        <InfoWindow onCloseClick={() => setActiveLocationItemList(null)} position={{ lat: +position.lat, lng: +position.lng }}>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${id}`);
            }}
          >
            {!embebed && (
              <Box
                sx={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box mr={6}>
                  <Typography fontWeight={600} noWrap sx={{ color: "rgba(76, 78, 100, 0.7)" }}>
                    {name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  {isVisible ? (
                    <Chip size="small" skin="light" label={t("CHIP-VISIBLE")} color="success" sx={{ fontSize: "1.4rem" }} />
                  ) : (
                    <Chip size="small" skin="light" label={t("CHIP-HIDDEN")} color="secondary" sx={{ fontSize: "1.4rem" }} />
                  )}
                </Box>
              </Box>
            )}

            <Box sx={{ maxWidth: "250px" }}>
              <Typography noWrap variant="body2" sx={{ color: "rgba(76, 78, 100, 0.5)", whiteSpace: "normal" }}>
                {address1}
              </Typography>
            </Box>
          </Box>
        </InfoWindow>
      )}
    </MarkerF>
  );
}

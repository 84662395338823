/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useDropdownStringSearch, useInputSearch } from "hooks";

export enum OrgsSearchFields {
  name = "name",
  url = "url",
  status = "status",
}

export default function useOrganizationsSearch(variableName: OrgsSearchFields) {
  const { searchParamsOrgs, setSearchParamsOrgs } = useListAndSearchContext();
  const orgNameSearch = useInputSearch(searchParamsOrgs, setSearchParamsOrgs, variableName);
  const orgStatusSearch = useDropdownStringSearch(searchParamsOrgs, setSearchParamsOrgs, variableName);
  const orgUrlSearch = useInputSearch(searchParamsOrgs, setSearchParamsOrgs, variableName);

  return { orgNameSearch, orgStatusSearch, orgUrlSearch };
}

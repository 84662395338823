import { useFetchAndLoad, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useGlobalContext } from "context/globalContext";
import { deleteLocationsAPI } from "../services";
import { useLocationListContext } from "../pages";

export default function useDeleteLocations() {
  //** Context */
  const { setSelectedClaim } = useLocationListContext();
  const { setSearchToggleDash } = useListAndSearchContext();
  //**Hooks */
  const { callEndpoint, loading } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { setLocEditMode } = useGlobalContext();
  const { t } = useTranslation();
  const deleteLocations = async (locationsIds: string[]) => {
    try {
      await callEndpoint(deleteLocationsAPI(locationsIds), (err: any) => handleCreateToast(err.response.data.message, "error"));
      handleCreateToast(t("ALERT-SUCCESS-DELETE-ACTION"), "success");
      setSearchToggleDash((prev: boolean) => !prev);
      setSelectedClaim([]);
      setLocEditMode(false);
    } catch (error) {
      console.log(error);
    }
  };
  return { deleteLocations, loading };
}

// ** Type Imports
import { PaletteMode } from "@mui/material";
import { Skin } from "models/layoutsTypes";

const DefaultPalette = (mode: PaletteMode, skin: Skin) => {
  // ** Vars
  const whiteColor = "#FFF";
  const lightColor = "76, 78, 100";
  const darkColor = "234, 234, 255";
  const mainColor = mode === "light" ? lightColor : darkColor;

  const defaultBgColor = () => {
    if (skin === "bordered" && mode === "light") {
      return whiteColor;
    }
    if (skin === "bordered" && mode === "dark") {
      return "#30334E";
    }
    if (mode === "light") {
      return "#F7F7F9";
    }
    return "#282A42";
  };

  return {
    customColors: {
      dark: darkColor,
      main: mainColor,
      light: lightColor,
      darkBg: "#282A42",
      lightBg: "#F7F7F9",
      bodyBg: mode === "light" ? "#F7F7F9" : "#282A42", // Same as palette.background.default but doesn't consider bordered skin
      trackBg: mode === "light" ? "#F2F2F4" : "#41435C",
      tooltipBg: mode === "light" ? "#262732" : "#464A65",
      tableHeaderBg: mode === "light" ? "#F5F5F7" : "#3A3E5B",
      primaryBackground: "#E5EBF7",
      errorBackground: "#FDE9ED",
      warningBackground: "#F8ECD4",
      successBackground: "#E2E9DE",
      secondaryBackground: "#BFBFBF",
      tableHeaderBgBlue: mode === "light" ? "#F4F8FF" : "#454970",
    },
    mode,
    common: {
      black: "#100E0E",
      white: whiteColor,
    },
    primary: {
      light: "#2F62BE",
      main: "#2F62BE",
      dark: "#2F62BE",
      contrastText: whiteColor,
    },
    secondary: {
      light: "#BFBFBF",
      main: "#BFBFBF",
      dark: "#BFBFBF",
      contrastText: whiteColor,
    },
    error: {
      light: "#B82D2B",
      main: "#B82D2B",
      dark: "#B82D2B",
      contrastText: whiteColor,
    },
    warning: {
      light: "#D19805",
      main: "#D19805",
      dark: "#D19805",
      contrastText: whiteColor,
    },
    info: {
      light: "#2961CA",
      main: "#2961CA",
      dark: "#2961CA",
      contrastText: whiteColor,
    },
    success: {
      light: "#367B09",
      main: "#367B09",
      dark: "#367B09",
      contrastText: whiteColor,
    },
    white: {
      light: "rgba(255,255,255,0.8)",
      main: "rgba(255,255,255,1)",
      dark: "rgba(255,255,255,0.6)",
      contrastText: whiteColor,
    },

    grey: {
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      400: "#BDBDBD",
      500: "#9E9E9E",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#F5F5F5",
      A200: "#EEEEEE",
      A400: "#BDBDBD",
      A700: "#616161",
    },
    text: {
      primary: `rgba(${mainColor})`,
      secondary: `rgba(${mainColor})`,
      disabled: `rgba(${mainColor}, 0.50)`,
    },
    divider: `rgba(${mainColor}, 0.12)`,
    background: {
      paper: mode === "light" ? whiteColor : "#30334E",
      default: defaultBgColor(),
    },
    action: {
      active: `rgba(${mainColor}, 0.54)`,
      hover: `rgba(${mainColor}, 0.05)`,
      hoverOpacity: 0.05,
      selected: `rgba(${mainColor}, 0.08)`,
      disabled: `rgba(${mainColor}, 0.26)`,
      disabledBackground: `rgba(${mainColor}, 0.12)`,
      focus: `rgba(${mainColor}, 0.12)`,
    },
  };
};

export default DefaultPalette;

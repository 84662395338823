import { useEffect } from "react";
//** MUI Imports */
import { Box, Card, Divider, Theme, useMediaQuery } from "@mui/material";
//** Components */
import { GoBackBreadcrumb, SectionsTitles } from "components";
import { BasicInformationUsers } from "pages/users/components";
import CreateUsersButtons from "pages/users/components/molecules/CreateUsersButtons/CreateUsersButtons";
//** Hooks */
import { useTranslation } from "react-i18next";

//** Context */
import { useUserContext } from "pages/users/context";
import { UserViewsName } from "pages/users/models";
import { useNavigate } from "react-router-dom";
import { PrivateRoutes, Roles } from "models";
import useUsersCreateSetValues from "pages/users/hooks/useUsersCreateSetValues";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { useRoleGuard } from "hooks";

export default function UsersCreate() {
  //** Context*/
  const { clearBasicInformation, setView } = useUserContext();
  useUsersCreateSetValues();

  //** Hooks */
  const { t } = useTranslation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();

  //**Redux  */
  const userState = useSelector((store: AppStore) => store.user);
  const { roles: loggedUserRole } = userState;

  const isAdminRole = useRoleGuard([Roles.superAdmin, Roles.multiOrgManager, Roles.multiOrgViewer]);
  useEffect(() => {
    clearBasicInformation();
    setView(UserViewsName.CREATE);
  }, []);

  return (
    <Box mx={4} mb={underMd ? 12 : 0}>
      <GoBackBreadcrumb
        label={isAdminRole ? t("GO-BACK-USER-LIST") : t("GO-BACK-ORGANIZATION-DASHBOARD")}
        onClick={() => {
          navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.LIST}`);
        }}
      />
      <Card sx={{ py: 8, px: 6, mx: 6 }}>
        <SectionsTitles title={`${t("GLOBAL-TITLE-USERS-INVITATION")}`} subTitle={`${t("INVITATION-VIEW-SUBTITLE")}`} />
        {loggedUserRole !== Roles.locationManager && loggedUserRole !== Roles.locationViewer && (
          <SectionsTitles
            title={`${t("GLOBAL-TITLE-ROLE_RELATION")}`}
            subTitle={`${t("ROLE-RELATION-SUBTITLE")}`}
            h2
            fontWeight={500}
            mb={4}
          />
        )}
        <BasicInformationUsers />
        <Divider />
        <CreateUsersButtons />
      </Card>
    </Box>
  );
}

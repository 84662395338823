import { Dispatch, SetStateAction, useState } from "react";
//** MUI Imports */
import { Checkbox, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
//** Model */
import { IDropdownOption } from "models";
//** Icon */
import { ArrowDropDown } from "@mui/icons-material";

interface IInputApiCalls {
  label: string;
  loading: boolean;
  setPropValue: Dispatch<SetStateAction<IDropdownOption[]>>;
  options: IDropdownOption[];
  onClick: Function;
  initialValue: any;
  disabled?: boolean;
}
const icon = <CheckBoxOutlineBlankIcon fontSize="large" />;
const checkedIcon = <CheckBoxIcon fontSize="large" />;

export default function InputCheckbox({ label, loading, setPropValue, options, onClick, initialValue, disabled }: IInputApiCalls) {
  const [value, setValue] = useState<any>(initialValue ?? []);

  const handleChange = (event: any, newValue: any | null) => {
    setValue(newValue);
    setPropValue(newValue);
  };
  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      multiple
      loading={loading}
      popupIcon={<ArrowDropDown sx={{ fontSize: "1.5em" }} />}
      value={value}
      disableCloseOnSelect
      options={options}
      onChange={handleChange}
      isOptionEqualToValue={(option, valueOption) => option?.label === valueOption?.label}
      renderOption={(props: any, option: any, { selected }) => (
        <li {...props} key={`autocomplete-${option.label}`}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.label}
        </li>
      )}
      renderInput={(params: any) => (
        <TextField onClick={() => onClick()} {...params} label={label} sx={{ "& .MuiChip-label": { fontSize: 16 } }} />
      )}
      onClick={() => onClick()}
    />
  );
}

import dayjs, { Dayjs } from "dayjs";
import { IDropdownOption } from "models";
import { useState, ChangeEvent, useEffect } from "react";
import { SetURLSearchParams } from "react-router-dom";

// Permite hacer un cambio en el input solo creando una variable y pasandola como {...variable}
export const useInputValue = <T>(initialValue: T) => {
  const [value, setValue] = useState<T | any>(initialValue);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);
  return { value, onChange };
};

// Permite hacer uso del context para traspaso de informacion entre componentes
// Se requiere importar en contexto donde se llame e importarlo en la funcion
export const useInputValueContext = <T>(initialValue: T, functionContext: any) => {
  const [value, setValue] = useState<T | any>(initialValue);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    // Import the set state of the conxtext
    functionContext(e.target.value);
  };
  const onChangeState = (status: string | number | boolean) => {
    setValue(status);
    // Import the set state of the conxtext
    functionContext(status);
  };
  return { value, onChange, onChangeState };
};

//** Searchs by query functions */

/**
 ** Function that allows you to send what is written in a variable as a query for the url
 * @param searchParams - URLSearchParams , object with the search parameters
 * @param functionContext - SetURLSearchParams, context used for manage the global state of search
 * @param variableName - string, name of the variable to be searched or change in the search object
 * @return { onChange , value , clearSearch }
 */
export const useInputSearch = (searchParams: URLSearchParams, functionContext: SetURLSearchParams, variableName: string) => {
  const value = searchParams.get(variableName);

  useEffect(() => {
    if (!value) {
      functionContext(
        (prev: any) => {
          prev.delete(variableName);
          return prev;
        },
        { replace: true },
      );
    }
  }, [searchParams]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    functionContext(
      (prev: any) => {
        const newValue = e.target.value;
        prev.set([variableName], newValue);
        return prev;
      },
      { replace: true },
    );
  };

  const clearByKey = () => {
    functionContext(
      (prev: any) => {
        prev.delete(variableName);
        return prev;
      },
      { replace: true },
    );
  };

  const clearSearch = () => {
    functionContext(
      () => {
        return {};
      },
      { replace: true },
    );
  };
  return { onChange, value, clearSearch, clearByKey };
};

/**
 ** Used for strings dropdowns on filters
 * @param searchParams - URLSearchParams , object with the search parameters
 * @param functionContext - SetURLSearchParams, context used for manage the global state of search
 * @param variableName - string, name of the variable to be searched or change in the search object
 * @return { onChange , value , clearSearch }
 */
export const useDropdownStringSearch = (searchParams: URLSearchParams, functionContext: SetURLSearchParams, variableName: string) => {
  const value = searchParams.get(variableName);

  useEffect(() => {
    if (!value) {
      functionContext(
        (prev: any) => {
          prev.delete(variableName);
          return prev;
        },
        { replace: true },
      );
    }
  }, [searchParams]);

  const onChange = (valueDropdown: string) => {
    functionContext(
      (prev: any) => {
        prev.set([variableName], valueDropdown);
        return prev;
      },
      { replace: true },
    );
  };

  const clearByKey = () => {
    functionContext(
      (prev: any) => {
        prev.delete(variableName);
        return prev;
      },
      { replace: true },
    );
  };

  const clearSearch = () => {
    functionContext(
      () => {
        return {};
      },
      { replace: true },
    );
  };
  return { onChange, value, clearSearch, clearByKey };
};

/**
 ** Used for dropdowns on filters with {id:"", label:""} Interface, here we need to separate the labels and ids
 * *Then if we need to use this function we need to pass one variable for the labels and one for the ids
 * @param searchParams - URLSearchParams , object with the search parameters
 * @param functionContext - SetURLSearchParams, context used for manage the global state of search
 * @param variableName - string, name of the variable to be searched or change in the search object
 * @param labelVariableName - string, name of the variable realed to the string who will be shown in the dropdown
 * @param idVariableName - string, name of the variable realed to the id who will be save as the main value on dropdown
 * this is the value who we will send to the backend search
 * @return { onChange , value , clearSearch }
 */
export const useDropdownSearch = (
  searchParams: URLSearchParams,
  functionContext: SetURLSearchParams,
  labelVariableName: string,
  idVariableName: string,
) => {
  const valueLabel = searchParams.get(labelVariableName);
  const valueId = searchParams.get(idVariableName);

  useEffect(() => {
    //** If the url doesn't have the complete object {id, label} then clear the complete filter*/

    if (!valueLabel || !valueId) {
      functionContext(
        (prev: any) => {
          prev.delete(labelVariableName);
          prev.delete(idVariableName);
          return prev;
        },
        { replace: true },
      );
    }
  }, [searchParams]);

  const onChange = (valueDropdown: IDropdownOption) => {
    functionContext(
      (prev: any) => {
        prev.set([labelVariableName], valueDropdown.label);
        prev.set([idVariableName], valueDropdown.id);

        return prev;
      },
      { replace: true },
    );
  };

  const clearByKey = () => {
    functionContext(
      (prev: any) => {
        prev.delete(labelVariableName);
        prev.delete(idVariableName);
        return prev;
      },
      { replace: true },
    );
  };

  const clearSearch = () => {
    functionContext(
      () => {
        return {};
      },
      { replace: true },
    );
  };
  return { onChange, valueLabel, valueId, clearSearch, clearByKey };
};

/**
 ** Used for dropdowns on filters with {id:"", label:""}[] Interface, here we need to separate the labels and ids
 * *Then if we need to use this function we need to pass one variable for the labels and one for the ids,
 * * and here we are going to separate the values with a comma, then you need to split the values on search
 * @param searchParams - URLSearchParams , object with the search parameters
 * @param functionContext - SetURLSearchParams, context used for manage the global state of search
 * @param variableName - string, name of the variable to be searched or change in the search object
 * @param labelVariableName - string, name of the variable realed to the string who will be shown in the dropdown
 * @param idVariableName - string, name of the variable realed to the id who will be save as the main value on dropdown
 * this is the value who we will send to the backend search
 * @return { onChange , value , clearSearch }
 */
export const useDropdownArraySearch = (
  searchParams: URLSearchParams,
  functionContext: SetURLSearchParams,
  labelVariableName: string,
  idVariableName: string,
) => {
  const valueLabel = searchParams.get(labelVariableName);
  const valueId = searchParams.get(idVariableName);
  useEffect(() => {
    //** If the url doesn't have the complete object {id, label} then clear the complete filter*/
    if (!valueLabel || !valueId) {
      functionContext(
        (prev: any) => {
          prev.delete(labelVariableName);
          prev.delete(idVariableName);
          return prev;
        },
        { replace: true },
      );
    }
  }, [searchParams]);

  const onChange = (valueDropdown: IDropdownOption[]) => {
    functionContext(
      (prev: any) => {
        prev.set([labelVariableName], valueDropdown.map((item) => item.label).join(","));
        prev.set([idVariableName], valueDropdown.map((item) => item.id).join(","));
        return prev;
      },
      { replace: true },
    );
  };

  const clearByKey = () => {
    functionContext(
      (prev: any) => {
        prev.delete(labelVariableName);
        prev.delete(idVariableName);
        return prev;
      },
      { replace: true },
    );
  };

  const clearSearch = () => {
    functionContext(
      () => {
        return {};
      },
      { replace: true },
    );
  };
  return { onChange, valueLabel, valueId, clearSearch, clearByKey };
};

export const useDateSearch = (searchParams: URLSearchParams, functionContext: SetURLSearchParams, variableName: string) => {
  const stringDate = searchParams.get(variableName);
  const value = stringDate ? dayjs(searchParams.get(variableName)) : null;

  useEffect(() => {
    if (!stringDate) {
      functionContext(
        (prev: any) => {
          prev.delete(variableName);
          return prev;
        },
        { replace: true },
      );
    }
  }, [searchParams]);

  const onChange = (valueDate: Dayjs | null) => {
    functionContext(
      (prev: any) => {
        prev.set([variableName], valueDate);
        return prev;
      },
      { replace: true },
    );
  };

  const clearByKey = () => {
    functionContext(
      (prev: any) => {
        prev.delete(variableName);
        return prev;
      },
      { replace: true },
    );
  };

  const clearSearch = () => {
    functionContext(
      () => {
        return {};
      },
      { replace: true },
    );
  };
  return { onChange, value, clearSearch, clearByKey };
};

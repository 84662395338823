/* eslint-disable no-shadow */
//** Reat imports */
import React, { useState, createContext, useContext, SetStateAction, Dispatch, useMemo, useRef } from "react";
//** Models */
import { ILocationsListDOM } from "pages/locations/models";
import { ICoordinatesDashboard } from "components/atoms/Map/MapDashboard";
import { array20 } from "models";

/*
The idea of this context it is allow a mechanism to pass the information between the components of the page.
*/

type contextValues = {
  order: string;
  setOrder: Dispatch<SetStateAction<string>>;
  orderBy: any;
  setOrderBy: Dispatch<SetStateAction<any>>;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  locationsListLength: number;
  setLocationsListLength: Dispatch<SetStateAction<number>>;
  locationsArray: ILocationsListDOM[];
  setLocationsArray: Dispatch<SetStateAction<ILocationsListDOM[]>>;
  locationsMapListArray: ILocationsListDOM[];
  setLocationsMapListArray: Dispatch<SetStateAction<ILocationsListDOM[]>>;
  locationsMapMarksArray: ICoordinatesDashboard[];
  setLocationsMapMarksArray: Dispatch<SetStateAction<ICoordinatesDashboard[]>>;
  activeLocationItemList: null | { type: string; id: string | number; coordinates: [number, number] };
  setActiveLocationItemList: Dispatch<SetStateAction<null | { type: string; id: string | number; coordinates: [number, number] }>>;
  statusFocus: any;
  clickedMarker: string | null;
  setClickedMarker: Dispatch<SetStateAction<string | null>>;
  selectedClaim: { id: string; isHQ: boolean; status: boolean }[];
  setSelectedClaim: Dispatch<SetStateAction<{ id: string; isHQ: boolean; status: boolean }[]>>;
  toggleClaim: boolean | null;
  setToggleClaim: Dispatch<SetStateAction<boolean | null>>;
  latitudeClaimMap: number | undefined;
  setLatitudeClaimMap: Dispatch<SetStateAction<number | undefined>>;
  longitudeClaimMap: number | undefined;
  setLongitudeClaimMap: Dispatch<SetStateAction<number | undefined>>;
  toggleChangeCoordinates: boolean;
  setToggleChangeCoordinates: Dispatch<SetStateAction<boolean>>;
  load: boolean[];
  setLoad: Dispatch<SetStateAction<boolean[]>>;

  nameLocSearch: string;
  setNameLocSearch: Dispatch<SetStateAction<string>>;
};

const defaultValues = {
  order: "asc",
  setOrder: () => {},
  orderBy: "name",
  setOrderBy: () => {},
  selected: [],
  setSelected: () => {},

  locationsListLength: 0,
  setLocationsListLength: () => {},
  locationsArray: [],
  setLocationsArray: () => {},
  locationsMapListArray: [],
  setLocationsMapListArray: () => {},
  locationsMapMarksArray: [],
  setLocationsMapMarksArray: () => {},
  activeLocationItemList: null,
  setActiveLocationItemList: () => {},
  statusFocus: null,
  clickedMarker: null,
  setClickedMarker: () => {},
  selectedClaim: [],
  setSelectedClaim: () => {},
  toggleClaim: null,
  setToggleClaim: () => {},

  latitudeClaimMap: undefined,
  setLatitudeClaimMap: () => {},
  longitudeClaimMap: undefined,
  setLongitudeClaimMap: () => {},
  toggleChangeCoordinates: false,
  setToggleChangeCoordinates: () => {},

  load: array20,
  setLoad: () => {},

  nameLocSearch: "",
  setNameLocSearch: () => {},
};

const LocationListContext = createContext<contextValues>(defaultValues);

const LocationListContextProvider = (props: any) => {
  const { children } = props;

  const [order, setOrder] = useState<string>("asc");
  const [orderBy, setOrderBy] = useState<any>("name");
  const [selected, setSelected] = useState<string[]>([]);

  const [locationsListLength, setLocationsListLength] = useState(0);
  const [load, setLoad] = useState<boolean[]>(array20);

  const [locationsArray, setLocationsArray] = useState<ILocationsListDOM[]>([]);
  const [locationsMapListArray, setLocationsMapListArray] = useState<ILocationsListDOM[]>([]);
  const [locationsMapMarksArray, setLocationsMapMarksArray] = useState<ICoordinatesDashboard[]>([]);
  const [activeLocationItemList, setActiveLocationItemList] = useState<null | {
    type: string;
    id: string | number;
    coordinates: [number, number];
  }>(null);
  const [clickedMarker, setClickedMarker] = useState<string | null>(null);
  const [selectedClaim, setSelectedClaim] = useState<{ id: string; isHQ: boolean; status: boolean }[]>([]);

  const [toggleClaim, setToggleClaim] = useState<boolean | null>(null);
  const statusFocus: any = useRef(null);

  //**MAP */
  const [latitudeClaimMap, setLatitudeClaimMap] = useState<number | undefined>(undefined);
  const [longitudeClaimMap, setLongitudeClaimMap] = useState<number | undefined>(undefined);
  const [toggleChangeCoordinates, setToggleChangeCoordinates] = useState<boolean>(false);

  //**Search */
  const [nameLocSearch, setNameLocSearch] = useState<string>("");
  const contextValues = useMemo(
    () => ({
      order,
      setOrder,
      orderBy,
      setOrderBy,
      selected,
      setSelected,
      locationsListLength,
      setLocationsListLength,
      locationsArray,
      setLocationsArray,
      locationsMapListArray,
      setLocationsMapListArray,
      locationsMapMarksArray,
      setLocationsMapMarksArray,
      activeLocationItemList,
      setActiveLocationItemList,
      statusFocus,
      clickedMarker,
      setClickedMarker,
      selectedClaim,
      setSelectedClaim,
      toggleClaim,
      setToggleClaim,
      latitudeClaimMap,
      setLatitudeClaimMap,
      longitudeClaimMap,
      setLongitudeClaimMap,
      toggleChangeCoordinates,
      setToggleChangeCoordinates,
      load,
      setLoad,
      nameLocSearch,
      setNameLocSearch,
    }),
    [
      order,
      orderBy,
      selected,
      locationsListLength,
      locationsArray,
      locationsMapListArray,
      locationsMapMarksArray,
      activeLocationItemList,
      statusFocus,
      clickedMarker,
      selectedClaim,
      toggleClaim,
      latitudeClaimMap,
      longitudeClaimMap,
      toggleChangeCoordinates,
      load,
      setLoad,
      nameLocSearch,
    ],
  );

  return <LocationListContext.Provider value={contextValues}>{children}</LocationListContext.Provider>;
};

export const useLocationListContext = () => {
  const context = useContext(LocationListContext);
  if (context === undefined) {
    throw new Error("LocationListContext must be used within a LocationListContextProvider");
  }
  return context;
};

export { LocationListContext, LocationListContextProvider };

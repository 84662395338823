/* eslint-disable max-len */
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import PlaceIcon from "@mui/icons-material/Place";
import GroupIcon from "@mui/icons-material/Group";
import { useLocation, useNavigate } from "react-router-dom";
import { PrivateRoutes, Roles } from "models";
import { useGetReduxUser, useRoleGuard } from "hooks";

export default function MenuNavigationResponsive() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const { userState } = useGetReduxUser();
  const adminUsers = [Roles.superAdmin, Roles.multiOrgManager, Roles.multiOrgViewer];
  const isAdmin = useRoleGuard(adminUsers);

  const navRoutes = [PrivateRoutes.ORGANIZATIONS, PrivateRoutes.LOCATIONS, PrivateRoutes.USERS];
  const navRoutesNonAdmin = [
    `${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}/${userState.organization?.id}`,
    PrivateRoutes.LOCATIONS,
    PrivateRoutes.USERS,
  ];

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes(`/${PrivateRoutes.ORGANIZATIONS}`)) setValue(0);
    if (location.pathname.includes(`/${PrivateRoutes.LOCATIONS}`)) setValue(1);
    if (location.pathname.includes(`/${PrivateRoutes.USERS}`)) setValue(2);
  }, [location]);

  return (
    <Box sx={{ width: "100vw", position: "fixed", bottom: 0, zIndex: "100000" }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          navigate(isAdmin ? `/${navRoutes[newValue]}` : `/${navRoutesNonAdmin[newValue]}`);
        }}
        sx={{ height: 60 }}
      >
        <BottomNavigationAction
          sx={{ "& .MuiBottomNavigationAction-label": { fontSize: 14 } }}
          icon={<CorporateFareIcon sx={{ fontSize: 28 }} />}
        />
        <BottomNavigationAction
          sx={{ "& .MuiBottomNavigationAction-label": { fontSize: 14 } }}
          icon={<PlaceIcon sx={{ fontSize: 28 }} />}
        />
        <BottomNavigationAction
          sx={{ "& .MuiBottomNavigationAction-label": { fontSize: 14 } }}
          icon={<GroupIcon sx={{ fontSize: 28 }} />}
        />
      </BottomNavigation>
    </Box>
  );
}

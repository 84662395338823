// ** Type Import
import { Settings } from "context/settingsContext";

//** Redux */
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { modifyUser } from "redux/slices/user.slice";

//** Icon */
import AppsIcon from "@mui/icons-material/Apps";

//** Context */
import { useGlobalContext } from "context/globalContext";
import { useTranslation } from "react-i18next";

// ** Custom Components Imports
import { TooltipApp } from "components";
import { OptionType } from "../option-menu-organization/types";
import OptionsMenuApplication from "../option-menu-apps";

interface Props {
  settings: Settings;
  // eslint-disable-next-line react/no-unused-prop-types, no-unused-vars
  saveSettings: (values: Settings) => void;
}

export default function ApplicationsDropdown({ settings }: Props) {
  const { t } = useTranslation();
  // ** Vars
  const { layout } = settings;

  //** Context */
  const { setToggleChangeApplication } = useGlobalContext();

  //**Get user information */
  const userState = useSelector((store: AppStore) => store.user);
  const { application, applications } = userState;
  const dispatch = useDispatch();

  const applicationList: OptionType[] | undefined = applications?.map((item) => ({
    text: item.name,
    menuItemProps: {
      sx: { py: 2 },
      selected: item.id === application?.id,
      onClick: () => {
        dispatch(modifyUser({ application: item }));
        setToggleChangeApplication((prev: boolean) => !prev);
      },
    },
  }));

  return (
    <OptionsMenuApplication
      icon={
        <TooltipApp
          title={t("ICON-APPS-TOOLTIP")}
          sxText={{
            fontWeight: 600,
          }}
        >
          <AppsIcon sx={{ fontSize: 25 }} />
        </TooltipApp>
      }
      menuProps={{ sx: { "& .MuiMenu-paper": { mt: 4, minWidth: 130 } } }}
      iconButtonProps={{ color: "inherit", sx: { ...(layout === "vertical" ? { mr: 0.75 } : { mx: 0.75 }) } }}
      options={applicationList || []}
    />
  );
}

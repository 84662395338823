import { Fab, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { PrivateRoutes, Roles } from "models";
import { useNavigate } from "react-router-dom";
import { RoleGuard } from "guards";

export default function OrgSpeedDial({ absolute }: { absolute?: boolean }) {
  const OrgSpeedDialStyled = styled("div")(({ theme }) => ({
    zIndex: 11,
    position: absolute ? "absolute" : "fixed",
    right: theme.spacing(6), //6
    bottom: absolute ? theme.spacing(15) : theme.spacing(30), //10
  }));

  const navigate = useNavigate();
  return (
    <RoleGuard authorizedRoles={[Roles.superAdmin]}>
      <OrgSpeedDialStyled
        onClick={() => {
          navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.CREATE}`, { replace: false });
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Fab size="medium" color="primary" aria-label="add">
          <AddIcon sx={{ fontSize: 28, fontWeight: 700 }} />
        </Fab>
      </OrgSpeedDialStyled>
    </RoleGuard>
  );
}

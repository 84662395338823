export const PublicRoutes = {
  LOGIN: "login",
  HEALTHY: "healthy",
  CHANGE_PASSWORD: "forgot-password/validate",
  EMAIL_VERIFICATION: "confirm-email",
  CHANGE_PASSWORD_GET_EMAIL: "forgot-password/email",
  ACCOUNT_REQUEST: "create-account-request",
  ERROR_VALIDATION_LINK: "resend-email",
  SURE_ROUTE: "akamai/sureroute-test-object.html",
  PINGID_AUTORIZED: "authorization",
};

export const PrivateRoutes = {
  LIST: "list",
  CREATE: "create",
  CLAIM_LOCATION: "claim-location/:organizationId",
  CLAIM_LOCATION_NAV: "claim-location",
  INFORMATION: "information/:id",
  INFORMATION_NAV: "information",
  EDIT: "edit/:id",
  EDIT_NAV: "edit",
  ITEMS: "items",
  USERS: "users",
  USERS_PROFILE: "profile",
  LOCATIONS: "locations",
  ORGANIZATIONS: "organizations",
  ORGANIZATIONS_DASHBOARD: "organizations-dashboard",
  MY_ORGANIZATION: "organization-view",
  CHANGELOG: "audit-log",
  API_TOKEN: "api-token",
  USERS_REQUEST: "requests",
  SELECT_ORGANIZARION: "organization-select",
};

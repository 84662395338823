// ** React Imports
import { useState, SyntheticEvent, Fragment } from "react";

// ** Router Import
import { useNavigate } from "react-router-dom";

// ** MUI Imports
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

// ** Icon Imports

// import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
// import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";

// ** Type Imports
import { Settings } from "context/settingsContext";
import { useDispatch, useSelector } from "react-redux";
import { PrivateRoutes, PublicRoutes, SBD_EMAIL_REGEX } from "models";
import { resetUser } from "redux/slices/user.slice";
import { useTranslation } from "react-i18next";

//**Redux */
import { AppStore } from "redux/store";
import { logOutFromSSO } from "services";

interface Props {
  settings: Settings;
}

// ** Styled Components
const BadgeContentSpan = styled("span")(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const UserDropdown = (props: Props) => {
  //**Context */
  const userState = useSelector((store: AppStore) => store.user);

  // ** Redux */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    dispatch(resetUser());
    navigate(`/${PublicRoutes.LOGIN}`, { replace: false });
    if (SBD_EMAIL_REGEX.test(userState.email)) logOutFromSSO();
  };

  // ** Props
  const { settings } = props;

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  //** Hooks
  const history = useNavigate();
  const { t } = useTranslation();

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url?: string) => {
    setAnchorEl(null);
    if (url) {
      history(url);
    }
  };

  const styles = {
    py: 2,
    px: 6,
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "text.primary",
    textDecoration: "none",
    "& svg": {
      mr: 4,
      fontSize: 20,
      color: "text.primary",
    },
  };

  const handleLogout = () => {
    logOut();
    handleDropdownClose();
  };

  function stringAvatar(firstName: string, lastName: string) {
    return {
      sx: {
        fontWeight: 700,
      },
      children: `${firstName?.split(" ")[0][0]?.toUpperCase() || ""}${lastName?.split(" ")[0][0]?.toUpperCase() || ""}`,
    };
  }

  return (
    <>
      <Badge
        overlap="circular"
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: "pointer" }}
        badgeContent={<BadgeContentSpan sx={{ ml: 1, "&:focus": { outline: "0px auto -webkit-focus-ring-color" } }} />}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Avatar
          {...stringAvatar(`${userState.firstName || ""}`, `${userState.lastName || ""}`)}
          onClick={handleDropdownOpen}
          sx={{ width: 40, height: 40 }}
          src="/images/avatars/1.png"
        />
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ "& .MuiMenu-paper": { minWidth: 230, mt: 4 } }}
        anchorOrigin={{ vertical: "bottom", horizontal: direction === "ltr" ? "right" : "left" }}
        transformOrigin={{ vertical: "top", horizontal: direction === "ltr" ? "right" : "left" }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Badge
              overlap="circular"
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Avatar
                {...stringAvatar(`${userState.firstName || ""}`, `${userState.lastName || ""}`)}
                src=""
                sx={{ width: "4.5rem", height: "4.5rem", mr: 2, ml: 4 }}
              />
            </Badge>
            <Box sx={{ display: "flex", ml: 1, alignItems: "flex-start", flexDirection: "column" }}>
              <Typography sx={{ fontWeight: 600, fontSize: "1.2rem" }}>{`${userState.fullName}`}</Typography>
              <Typography variant="body2" sx={{ fontSize: "0.8rem", color: "text.disabled" }}>
                {userState.roles}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: "0 !important" }} />
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose(`/${PrivateRoutes.USERS}/${PrivateRoutes.USERS_PROFILE}`)}>
          <Box sx={styles}>
            <PermIdentityOutlinedIcon />
            {t("USER-DROPDOWN-OPTION-1")}
          </Box>
        </MenuItem>

        {/* <Divider /> */}
        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose("/pages/account-settings/account")}>
          <Box sx={styles}>
            <SettingsOutlinedIcon />
            {t("USER-DROPDOWN-OPTION-2")}
          </Box>
        </MenuItem> */}

        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose("/pages/faq")}>
          <Box sx={styles}>
            <HelpOutlineOutlinedIcon />
            {t("USER-DROPDOWN-OPTION-3")}
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ p: 0 }} onClick={handleLogout}>
          <Box sx={styles}>
            <ExitToAppOutlinedIcon />
            {t("USER-DROPDOWN-OPTION-4")}
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserDropdown;

//** HOOKS*/
import { useTranslation } from "react-i18next";
//** COMPONTENS*/
import { CardHeaderLogin, CenterCard } from "components";
import { LocalStorageKeys, getInLocalStorage } from "utilities";
import useQuery from "utilities/getLocation";
import { useRef } from "react";
import { ResetPasswordInputs } from "./components";

export default function ResetPassword() {
  //** HOOKS */
  const { t } = useTranslation();

  const query = useQuery();

  const titleRef = useRef(query.get("code") ? t("FORGOT-PASSWORD-TITLE") : `${t("CREATE-PASSWORD-TITLE")} 🔒`).current;

  const email = `${getInLocalStorage(LocalStorageKeys.EMAIL)}`;

  return (
    <CenterCard>
      <CardHeaderLogin
        title={titleRef}
        subTitle={`${t("RESET-PASSWORD-SUBTITLE")}`}
        subTitle2={email}
        paddingSecondTitle={1}
        subTitle2Bold
      />
      <ResetPasswordInputs />
    </CenterCard>
  );
}

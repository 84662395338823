/* eslint-disable no-shadow */
import { Methods } from "constants/globalConstants";
import { IDropdownOption } from "models";
import { useState, createContext, useContext, SetStateAction, Dispatch, useMemo } from "react";
import { SetURLSearchParams, useSearchParams } from "react-router-dom";
import { getInLocalStorage, LocalStorageKeys } from "utilities";

/*
The idea of this context it is allow a mechanism to pass the information between the components of the page.
*/
export interface IOrganizationOption {
  label: string | null;
  id: string | null;
}

type contextValues = {
  rememberMe: boolean;
  setRememberMe: Dispatch<SetStateAction<boolean>>;
  toggleChangeOrganization: boolean;
  setToggleChangeOrganization: Dispatch<SetStateAction<boolean>>;
  toggleChangeApplication: boolean;
  setToggleChangeApplication: Dispatch<SetStateAction<boolean>>;
  organizationsPage: number;
  setOrganizationsPage: Dispatch<SetStateAction<number>>;
  rowsPerPageOrg: number;
  setRowsPerPageOrg: Dispatch<SetStateAction<number>>;
  next: string;
  setNext: Dispatch<SetStateAction<string>>;
  locationsPage: number;
  setLocationsPage: Dispatch<SetStateAction<number>>;
  rowsPerPageLoc: number;
  setRowsPerPageLoc: Dispatch<SetStateAction<number>>;
  nextLocations: string;
  setNextLocations: Dispatch<SetStateAction<string>>;

  usersPage: number;
  setUsersPage: Dispatch<SetStateAction<number>>;
  rowsPerPageUsers: number;
  setRowsPerPageUsers: Dispatch<SetStateAction<number>>;
  nextUsers: string;
  setNextUsers: Dispatch<SetStateAction<string>>;

  usersRequestPage: number;
  setUsersRequestPage: Dispatch<SetStateAction<number>>;
  rowsPerPageUsersRequest: number;
  setRowsPerPageUsersRequest: Dispatch<SetStateAction<number>>;
  nextUsersRequest: string;
  setNextUsersRequest: Dispatch<SetStateAction<string>>;
  changeLogPage: number;
  setChangeLogPage: Dispatch<SetStateAction<number>>;
  rowsPerPageChangelog: number;
  setRowsPerPageChangelog: Dispatch<SetStateAction<number>>;
  nextChangelog: string;
  setNextChangelog: Dispatch<SetStateAction<string>>;

  hasFilter: string;
  setHasFilter: Dispatch<SetStateAction<string>>;
  locationHasFilter: string;
  setLocationHasFilter: Dispatch<SetStateAction<string>>;

  locationStatus: Methods | null;
  setLocationStatus: Dispatch<SetStateAction<Methods | null>>;
  organizationHasFilter: string;
  setOrganizationHasFilter: Dispatch<SetStateAction<string>>;
  usersHasFilter: string;
  setUsersHasFilter: Dispatch<SetStateAction<string>>;
  apiTokenHasFilter: string;
  setApiTokenHasFilter: Dispatch<SetStateAction<string>>;
  auditLogHasFilter: string;
  setAuditLogHasFilter: Dispatch<SetStateAction<string>>;
  usersRequestHasFilter: string;
  setUsersRequestHasFilter: Dispatch<SetStateAction<string>>;

  searchToggleDash: boolean;
  setSearchToggleDash: Dispatch<SetStateAction<boolean>>;
  openImportModal: boolean;
  setOpenImportModal: Dispatch<SetStateAction<boolean>>;
  userGetToggle: boolean;
  setUserGetToggle: Dispatch<SetStateAction<boolean>>;
  organizationGetToggle: boolean;
  setOrganizationGetToggle: Dispatch<SetStateAction<boolean>>;
  claimStateSearch: string;
  setClaimStateSearch: Dispatch<SetStateAction<string>>;
  claimCitySearch: string;
  setClaimCitySearch: Dispatch<SetStateAction<string>>;
  claimCountrySearch: IDropdownOption;
  setClaimCountrySearch: Dispatch<SetStateAction<IDropdownOption>>;
  claimSearchToggle: boolean;
  setClaimSearchToggle: Dispatch<SetStateAction<boolean>>;
  openImportModalUser: boolean;
  setOpenImportModalUser: Dispatch<SetStateAction<boolean>>;

  locationIsLoading: boolean;
  setLocationIsLoading: Dispatch<SetStateAction<boolean>>;
  openAddModal: boolean;
  setOpenAddModal: Dispatch<SetStateAction<boolean>>;
  openExport: boolean;
  setOpenexport: Dispatch<SetStateAction<boolean>>;

  searchParamsApiToken: any;
  setSearchParamsApiToken: SetURLSearchParams;
  searchParamsOrgs: any;
  setSearchParamsOrgs: SetURLSearchParams;
  searchParamsLocs: any;
  setSearchParamsLocs: SetURLSearchParams;
  searchParamsUsers: any;
  setSearchParamsUsers: SetURLSearchParams;
  searchParamsUsersRequest: any;
  setSearchParamsUsersRequest: SetURLSearchParams;
  searchParamsAuditLog: any;
  setSearchParamsAuditLog: SetURLSearchParams;

  orgListViewType: "list" | "cards" | null;
  setOrgListViewType: Dispatch<SetStateAction<"list" | "cards" | null>>;
};

const defaultValues = {
  rememberMe: true,
  setRememberMe: () => {},
  toggleChangeOrganization: false,
  setToggleChangeOrganization: () => {},
  toggleChangeApplication: false,
  setToggleChangeApplication: () => {},

  organizationsPage: 0,
  setOrganizationsPage: () => {},
  rowsPerPageOrg: 10,
  setRowsPerPageOrg: () => {},
  next: "",
  setNext: () => {},

  locationsPage: 0,
  setLocationsPage: () => {},
  rowsPerPageLoc: 10,
  setRowsPerPageLoc: () => {},
  nextLocations: "",
  setNextLocations: () => {},

  usersPage: 0,
  setUsersPage: () => {},
  rowsPerPageUsers: 10,
  setRowsPerPageUsers: () => {},
  nextUsers: "",
  setNextUsers: () => {},

  usersRequestPage: 0,
  setUsersRequestPage: () => {},
  rowsPerPageUsersRequest: 10,
  setRowsPerPageUsersRequest: () => {},
  nextUsersRequest: "",
  setNextUsersRequest: () => {},
  changeLogPage: 0,
  setChangeLogPage: () => {},
  rowsPerPageChangelog: 10,
  setRowsPerPageChangelog: () => {},
  nextChangelog: "",
  setNextChangelog: () => {},

  hasFilter: "",
  setHasFilter: () => {},
  locationHasFilter: "",
  setLocationHasFilter: () => {},

  locationStatus: null,
  setLocationStatus: () => {},
  organizationHasFilter: "",
  setOrganizationHasFilter: () => {},
  usersHasFilter: "",
  setUsersHasFilter: () => {},
  apiTokenHasFilter: "",
  setApiTokenHasFilter: () => {},
  auditLogHasFilter: "",
  setAuditLogHasFilter: () => {},
  usersRequestHasFilter: "",
  setUsersRequestHasFilter: () => {},

  searchToggleDash: false,
  setSearchToggleDash: () => {},
  openImportModal: false,
  setOpenImportModal: () => {},
  userGetToggle: false,
  setUserGetToggle: () => {},
  organizationGetToggle: false,
  setOrganizationGetToggle: () => {},

  claimStateSearch: "",
  setClaimStateSearch: () => {},
  claimCitySearch: "",
  setClaimCitySearch: () => {},
  claimCountrySearch: { label: "", id: "" },
  setClaimCountrySearch: () => {},

  claimSearchToggle: false,
  setClaimSearchToggle: () => {},
  openImportModalUser: false,
  setOpenImportModalUser: () => {},

  locationIsLoading: true,
  setLocationIsLoading: () => {},
  openAddModal: false,
  setOpenAddModal: () => {},
  openExport: false,
  setOpenexport: () => {},
  searchParamsApiToken: { name: "" },
  setSearchParamsApiToken: () => {},
  searchParamsOrgs: { name: "", status: "", url: "" },
  setSearchParamsOrgs: () => {},
  searchParamsLocs: {
    name: "",
    visibility: "",
    purpose: "",
    purposeCodes: "",
    market: "",
    marketId: "",
    organization: "",
    organizationName: "",
  },
  setSearchParamsLocs: () => {},
  searchParamsUsers: {
    firstName: "",
    lastName: "",
    email: "",
    status: "",
    role: "",
    roleId: "",
    organization: "",
    organizationName: "",
  },
  setSearchParamsUsers: () => {},
  searchParamsUsersRequest: {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    roleId: "",
    date: "",
    afterDate: "",
    beforeDate: "",
  },
  setSearchParamsUsersRequest: () => {},
  searchParamsAuditLog: {
    actor: "",
    activityType: "",
    date: "",
    afterDate: "",
    beforeDate: "",
  },
  setSearchParamsAuditLog: () => {},
  orgListViewType: (getInLocalStorage(LocalStorageKeys.LIST_VIEW) as "list" | "cards" | null) || null,
  setOrgListViewType: () => {},
};

const ListAndSearchContext = createContext<contextValues>(defaultValues);

const ListAndSearchContextProvider = (props: any) => {
  const { children } = props;

  //Array to render items
  const [rememberMe, setRememberMe] = useState<boolean>(true);
  const [toggleChangeOrganization, setToggleChangeOrganization] = useState<boolean>(true);
  const [toggleChangeApplication, setToggleChangeApplication] = useState<boolean>(true);

  //**Organizations */
  //** Search */
  const [searchParamsOrgs, setSearchParamsOrgs] = useSearchParams({ name: "", status: "", url: "" });
  const [organizationGetToggle, setOrganizationGetToggle] = useState<boolean>(false);
  const [orgListViewType, setOrgListViewType] = useState<"list" | "cards" | null>(
    (getInLocalStorage(LocalStorageKeys.LIST_VIEW) as "list" | "cards" | null) || null,
  );

  //**List */
  const [organizationsPage, setOrganizationsPage] = useState<number>(0);
  const [rowsPerPageOrg, setRowsPerPageOrg] = useState<number>(10);
  const [next, setNext] = useState<string>("");

  //**Locations */
  //** Search */
  const [searchParamsLocs, setSearchParamsLocs] = useSearchParams({
    name: "",
    visibility: "",
    purpose: "",
    purposeCodes: "",
    market: "",
    marketId: "",
    organization: "",
    organizationName: "",
  });
  const [claimStateSearch, setClaimStateSearch] = useState<string>("");
  const [claimCitySearch, setClaimCitySearch] = useState<string>("");
  const [claimCountrySearch, setClaimCountrySearch] = useState<IDropdownOption>({ label: "", id: "" });
  const [locationStatus, setLocationStatus] = useState<Methods | null>(Methods.UNCLAIMED);
  const [locationHasFilter, setLocationHasFilter] = useState<string>("");
  const [locationIsLoading, setLocationIsLoading] = useState<boolean>(true);
  const [openExport, setOpenexport] = useState<boolean>(false);
  //**List */
  const [locationsPage, setLocationsPage] = useState(0);
  const [rowsPerPageLoc, setRowsPerPageLoc] = useState(10);
  const [nextLocations, setNextLocations] = useState<string>("");

  //**Users */
  //** Search */
  const [searchParamsUsers, setSearchParamsUsers] = useSearchParams({
    firstName: "",
    lastName: "",
    email: "",
    status: "",
    role: "",
    roleId: "",
    organization: "",
    organizationName: "",
  });
  const [hasFilter, setHasFilter] = useState<string>("");
  const [userGetToggle, setUserGetToggle] = useState<boolean>(false);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  //**List */
  const [usersPage, setUsersPage] = useState<number>(0);
  const [rowsPerPageUsers, setRowsPerPageUsers] = useState<number>(10);
  const [nextUsers, setNextUsers] = useState<string>("");

  //** Requests */
  //** Search */
  const [searchParamsUsersRequest, setSearchParamsUsersRequest] = useSearchParams({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    roleId: "",
    date: "",
    afterDate: "",
    beforeDate: "",
  });

  //**List */
  const [usersRequestPage, setUsersRequestPage] = useState(0);
  const [rowsPerPageUsersRequest, setRowsPerPageUsersRequest] = useState(10);
  const [nextUsersRequest, setNextUsersRequest] = useState<string>("");

  //**Change logs */
  //** Search */
  const [searchParamsAuditLog, setSearchParamsAuditLog] = useSearchParams({
    actor: "",
    activityType: "",
    date: "",
    afterDate: "",
    beforeDate: "",
  });

  //**List */
  const [changeLogPage, setChangeLogPage] = useState<number>(0);
  const [rowsPerPageChangelog, setRowsPerPageChangelog] = useState<number>(10);
  const [nextChangelog, setNextChangelog] = useState<string>("");

  //** API TOKENS */
  const [searchParamsApiToken, setSearchParamsApiToken] = useSearchParams({ name: "" });

  //** Filters strings */
  const [organizationHasFilter, setOrganizationHasFilter] = useState<string>("");
  const [usersHasFilter, setUsersHasFilter] = useState<string>("");
  const [usersRequestHasFilter, setUsersRequestHasFilter] = useState<string>("");
  const [apiTokenHasFilter, setApiTokenHasFilter] = useState<string>("");
  const [auditLogHasFilter, setAuditLogHasFilter] = useState<string>("");

  const [searchToggleDash, setSearchToggleDash] = useState<boolean>(false);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [claimSearchToggle, setClaimSearchToggle] = useState<boolean>(false);
  const [openImportModalUser, setOpenImportModalUser] = useState<boolean>(false);
  const states = {
    rememberMe,
    toggleChangeOrganization,
    toggleChangeApplication,

    organizationsPage,
    locationsPage,
    rowsPerPageOrg,
    rowsPerPageLoc,
    next,
    nextLocations,

    usersPage,
    rowsPerPageUsers,
    nextUsers,
    hasFilter,
    usersRequestPage,
    rowsPerPageUsersRequest,
    nextUsersRequest,
    changeLogPage,
    rowsPerPageChangelog,
    nextChangelog,
    locationHasFilter,
    locationStatus,
    organizationHasFilter,
    usersHasFilter,
    apiTokenHasFilter,
    auditLogHasFilter,
    usersRequestHasFilter,
    openImportModal,
    searchToggleDash,
    userGetToggle,
    organizationGetToggle,
    claimStateSearch,
    claimCitySearch,
    claimCountrySearch,
    claimSearchToggle,
    openImportModalUser,
    locationIsLoading,
    openAddModal,
    openExport,
    searchParamsApiToken,
    searchParamsOrgs,
    searchParamsLocs,
    searchParamsUsers,
    searchParamsUsersRequest,
    searchParamsAuditLog,
    orgListViewType,
  };

  const contextValues = useMemo(
    () => ({
      setRememberMe,
      setToggleChangeApplication,
      setToggleChangeOrganization,
      setOrganizationsPage,
      setLocationsPage,
      setRowsPerPageOrg,
      setRowsPerPageLoc,
      setNext,
      setNextLocations,
      setUsersPage,
      setRowsPerPageUsers,
      setNextUsers,
      setHasFilter,
      setUsersRequestPage,
      setRowsPerPageUsersRequest,
      setNextUsersRequest,
      setChangeLogPage,
      setRowsPerPageChangelog,
      setNextChangelog,
      setLocationHasFilter,
      setLocationStatus,
      setOrganizationHasFilter,
      setUsersHasFilter,
      setApiTokenHasFilter,
      setAuditLogHasFilter,
      setUsersRequestHasFilter,
      setSearchToggleDash,
      setOpenImportModal,
      setUserGetToggle,
      setOrganizationGetToggle,
      setClaimStateSearch,
      setClaimCitySearch,
      setClaimCountrySearch,
      setClaimSearchToggle,
      setOpenImportModalUser,
      setLocationIsLoading,
      setOpenAddModal,
      setOpenexport,
      setSearchParamsApiToken,
      setSearchParamsOrgs,
      setSearchParamsLocs,
      setSearchParamsUsers,
      setSearchParamsUsersRequest,
      setSearchParamsAuditLog,
      setOrgListViewType,
      ...states,
    }),
    [{ ...states }],
  );

  return <ListAndSearchContext.Provider value={contextValues}>{children}</ListAndSearchContext.Provider>;
};

export const useListAndSearchContext = () => {
  const context = useContext(ListAndSearchContext);
  if (context === undefined) {
    throw new Error("ListAndSearchContext must be used within a ListAndSearchContextProvider");
  }
  return context;
};

export { ListAndSearchContext, ListAndSearchContextProvider };

import themeConfig from "config/themeConfig";
import { ThemeContextProvider } from "context/AppContext";
import { SettingsConsumer, SettingsProvider } from "context/settingsContext";
import { setInterceptors } from "frameworks_and_drivers/external_interfaces/Axios";
import { SnackbarProvider } from "notistack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackbarNotification } from "components";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Navigation from "./Navigation";

setInterceptors();

declare module "notistack" {
  interface VariantOverrides {
    notification: true;
  }
}

function App() {
  return (
    <SettingsProvider pageSettings={themeConfig}>
      <SettingsConsumer>
        {({ settings }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeContextProvider settings={settings}>
                <SnackbarProvider maxSnack={3} Components={{ notification: SnackbarNotification }}>
                  <Navigation />
                </SnackbarProvider>
              </ThemeContextProvider>
            </LocalizationProvider>
          );
        }}
      </SettingsConsumer>
    </SettingsProvider>
  );
}

export default App;

/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
// ** MUI Imports
import Box from "@mui/material/Box";

// ** Custom Icon Import
import IconButton from "@mui/material/IconButton";
import { Typography, useMediaQuery } from "@mui/material";
import { useSettings } from "hooks";
import { LayoutProps } from "models";

import MapIcon from "@mui/icons-material/Map";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

type IProps = {
  permanentLeftBar: boolean;
  handleChangeLeftBar: () => void;
  menuLockedIcon?: LayoutProps["verticalLayoutProps"]["navMenu"]["lockedIcon"];
  menuUnlockedIcon?: LayoutProps["verticalLayoutProps"]["navMenu"]["unlockedIcon"];
  smAbove: boolean;
};

export default function ButtonToggleMap(props: IProps) {
  // ** Props
  const {
    permanentLeftBar,
    handleChangeLeftBar,
    menuLockedIcon: userMenuLockedIcon,
    menuUnlockedIcon: userMenuUnlockedIcon,
    smAbove,
  } = props;
  const { settings } = useSettings();
  const { direction } = settings;
  const horizontalMobile = useMediaQuery("(min-width:500px)");

  const svgRotationDeg = () => {
    if (permanentLeftBar) {
      if (direction === "rtl") {
        if (permanentLeftBar) {
          return 180;
        }
        return 10;
      }
      if (permanentLeftBar) {
        return 0;
      }
      return 180;
    }
    if (direction === "rtl") {
      return 0;
    }
    return 180;
  };

  return (
    <Box>
      {smAbove ? (
        <Box
          sx={{
            position: "relative !important",
            top: "90px",
            left: "15px",
            width: permanentLeftBar ? "40px" : "130px",
            transition: "width .12s linear .12s",
            height: "40px",
            borderRadius: "5px",
            background: "#FFF",
            boxShadow:
              "0px 1px 3px 0px rgba(76, 78, 100, 0.12), 0px 1px 1px 0px rgba(76, 78, 100, 0.14), 0px 2px 1px -1px rgba(76, 78, 100, 0.20)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1001",
          }}
          onClick={handleChangeLeftBar}
        >
          {userMenuLockedIcon === null && userMenuUnlockedIcon === null ? null : (
            <IconButton
              disableRipple
              disableFocusRipple
              sx={{
                p: 0,
                color: "text.primary",
                backgroundColor: "transparent !important",
                "&:focus": { outline: "0px auto -webkit-focus-ring-color" },
              }}
            >
              {userMenuLockedIcon && userMenuUnlockedIcon ? (
                permanentLeftBar ? (
                  userMenuUnlockedIcon
                ) : (
                  userMenuLockedIcon
                )
              ) : (
                <Box
                  width={22}
                  fill="none"
                  height={22}
                  component="svg"
                  viewBox="0 0 22 22"
                  xmlns="http://www.w3.org/2000/svg"
                  sx={{
                    transform: `rotate(${svgRotationDeg()}deg)`,
                    transition: "transform .11s ease-in-out .11s",
                  }}
                >
                  <path
                    fill="rgba(76, 78, 100, 0.90)"
                    d="M11.4854 4.88844C11.0082 4.41121 10.2344 4.41121 9.75716 4.88844L4.51029 10.1353C4.03299 10.6126 4.03299 11.3865 4.51029 11.8638L9.75716 17.1107C10.2344 17.5879 11.0082 17.5879 11.4854 17.1107C11.9626 16.6334 11.9626 15.8597 11.4854 15.3824L7.96674 11.8638C7.48943 11.3865 7.48943 10.6126 7.96674 10.1353L11.4854 6.61667C11.9626 6.13943 11.9626 5.36568 11.4854 4.88844Z"
                  />
                  <path
                    fill="rgba(76, 78, 100, 0.7)"
                    d="M15.8683 4.88844L10.6214 10.1353C10.1441 10.6126 10.1441 11.3865 10.6214 11.8638L15.8683 17.1107C16.3455 17.5879 17.1193 17.5879 17.5965 17.1107C18.0737 16.6334 18.0737 15.8597 17.5965 15.3824L14.0779 11.8638C13.6005 11.3865 13.6005 10.6126 14.0779 10.1353L17.5965 6.61667C18.0737 6.13943 18.0737 5.36568 17.5965 4.88844C17.1193 4.41121 16.3455 4.41121 15.8683 4.88844Z"
                  />
                </Box>
              )}
            </IconButton>
          )}
          <Typography
            variant="h5"
            color="rgba(76, 78, 100, 0.90)"
            fontWeight={600}
            ml={2}
            sx={{
              opacity: !permanentLeftBar ? "100%" : "0",
              display: !permanentLeftBar ? "block" : "none",
              transition: "all 1s linear 1s",
            }}
          >
            Show List
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            position: "fixed",
            bottom: "80px",
            left: !horizontalMobile ? "30%" : "60%",
            width: "160px",
            transition: "width .12s linear .12s",
            height: "50px",
            borderRadius: "20px",
            background: "rgb(34,34,34)",
            boxShadow:
              "0px 1px 3px 0px rgba(76, 78, 100, 0.12), 0px 1px 1px 0px rgba(76, 78, 100, 0.14), 0px 2px 1px -1px rgba(76, 78, 100, 0.20)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1001",
          }}
          onClick={handleChangeLeftBar}
        >
          {permanentLeftBar ? (
            <>
              <Typography variant="h2" color="common.white">
                Show List
              </Typography>
              <FormatListBulletedIcon sx={{ fontSize: "2rem", ml: 2, color: "common.white" }} />
            </>
          ) : (
            <>
              <Typography variant="h2" color="common.white">
                Hide List
              </Typography>
              <MapIcon sx={{ fontSize: "2rem", ml: 2, color: "common.white" }} />
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useMediaQuery } from "@mui/material";
import pJson from "../../../../../../package.json";

const FooterContent = () => {
  const underMd = useMediaQuery("(max-width: 900px)");
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", my: underMd ? 4 : 0 }}>
      <Typography sx={{ mr: 2 }}>{!underMd && `© ${new Date().getFullYear()}, Version: ${pJson.version}`}</Typography>
    </Box>
  );
};

export default FooterContent;

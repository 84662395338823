import { Theme } from "@emotion/react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, SxProps, Typography } from "@mui/material";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

interface IModalConfirmProps {
  open: boolean;
  title: string;
  description: string;
  handleConfirm: MouseEventHandler<HTMLButtonElement> | any;
  handleClose: MouseEventHandler<HTMLButtonElement>;
  textButtonConfirm?: string | null;
  sxDialog?: SxProps<Theme>;
  sxTitle?: SxProps<Theme>;
  sxDescription?: SxProps<Theme>;
  disableAccept?: boolean;
}

export default function ModalConfirm({
  open,
  title,
  description,
  handleConfirm,
  handleClose,
  textButtonConfirm,
  sxDialog,
  sxTitle,
  sxDescription,
  disableAccept,
}: IModalConfirmProps) {
  const { t } = useTranslation();

  return (
    <Modal open={open}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="user-view-edit"
        sx={sxDialog}
        aria-describedby="user-view-edit-description"
      >
        <DialogTitle id="user-view-edit" sx={sxTitle}>
          {title}
        </DialogTitle>
        <DialogContent>
          <Box mt={4}>
            <Typography sx={sxDescription} variant="h2">
              {description}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", mt: 4, mx: -2 }}>
          <Button variant="text" onClick={handleClose}>
            {t("BUTTON-CANCEL")}
          </Button>
          <Button variant="text" onClick={handleConfirm} disabled={disableAccept}>
            {textButtonConfirm || t("BUTTON-ACCEPT")}
          </Button>
        </DialogActions>
      </Dialog>
    </Modal>
  );
}

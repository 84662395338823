/* eslint-disable no-lonely-if */
import { useEffect, useRef, useState } from "react";
//**Hooks */
import { useLocationContext } from "pages";
import { useTranslation } from "react-i18next";
//* Config */
import settings from "config/settings.json";
//** Icons */
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import SearchIcon from "@mui/icons-material/Search";
//** Components */
import { Box, CircularProgress, Theme, Typography, useMediaQuery } from "@mui/material";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { useSelectOnMap } from "hooks";

import "./style.css";
import SelectMarker from "./SelectMarker";
import MarkerLocation from "./MarkerLocation";
import { ICoordinatesDashboard } from "../MapOrg/MapOrg";

interface IMaps {
  positions: ICoordinatesDashboard[];
  loading?: boolean;
  mapHeight?: string;
  options?: google.maps.MapOptions | undefined;
  loadingGetMaker: boolean;
}
const markerIconFactory = (scale: number) => ({
  path: faLocationDot.icon[4] as string,
  fillColor: "red",
  fillOpacity: 1,
  strokeWeight: 2,
  strokeColor: "#ffffff",
  rotation: 0,
  scale: scale || 0.065,
  anchor: new google.maps.Point(
    faLocationDot.icon[0] / 2, // width
    faLocationDot.icon[1], // height
  ),
});
export default function MapLocations({ positions, mapHeight, options, loading, loadingGetMaker }: IMaps) {
  //** Maps init */
  const [libraries] = useState<any>(["geometry", "drawing", "places"]);

  //**Hooks */
  const matches = useMediaQuery("(max-width:700px)");
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const {
    locLatitudeMap,
    setLocLatitudeMap,
    locLongitudeMap,
    setLocLongitudeMap,
    latitude,
    longitude,
    activeLocationItemListCoordinates,
    setLocSelectedMark,
  } = useLocationContext();

  const mapRef = useRef<google.maps.Map>();
  const [mapref, setMapRef] = useState<google.maps.Map>();

  const {
    isMoving,
    address,
    loadingMapInfo,
    internalCoordinates,
    getInternalMapData,
    getInternalMapDataZoom,
    handleMouseDown,
    onSearchPlaceByMapId,
  } = useSelectOnMap({ mapref: mapRef.current, setMapId: setLocSelectedMark });

  const [hasCoordinates, setHasCoordinates] = useState(false);

  const handleLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    setMapRef(map);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${settings.GOOGLE_API_KEY}`,
    libraries,
  });

  const mapStyles = {
    minHeight: mapHeight || "60vh",
    height: "100%",
    width: "100%",
  };

  //when the center change it will be to the new center with smooth transition
  const mackerOnChange = {
    lat: activeLocationItemListCoordinates[0]?.lat,
    lng: activeLocationItemListCoordinates[0]?.lng,
  };

  const orghaveCoordinates = { lat: +latitude, lng: +longitude };

  useEffect(() => {
    if (mapRef.current) {
      if (internalCoordinates[0] && internalCoordinates[1]) {
        //** Coordinates when moving the map are of the highest priority*/

        mapRef.current.panTo({ lat: internalCoordinates[0], lng: internalCoordinates[1] });
      } else {
        //** Coordinates when I'm in the details of an org to take the location there.*/
        //** But If the user made a search the center is on the first item of the list*/
        if (latitude && longitude) {
          mapRef.current.panTo(orghaveCoordinates);
          setHasCoordinates(false);
        } else if (positions.length > 0) {
          //** Coordinates when I load a list to redirect to the fist items coordinates*/
          mapRef.current.panTo({ lat: positions[0]?.lat, lng: positions[0]?.lng });
        }
      }
    }
  }, [mackerOnChange, locLatitudeMap, locLongitudeMap, internalCoordinates]);

  const center = {
    lat: 0,
    lng: 0,
  };

  const hasRenderMorInfoButton = hasCoordinates;

  const responsiveOptions: google.maps.MapOptions | undefined = underMd
    ? {
        zoomControlOptions: {
          position: 4,
        },
        streetViewControlOptions: {
          position: 4,
        },
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: 10.0,
        },
      }
    : {};

  const getCurrentMapData = () => {
    if (!mapref) return;
    const newCenter = mapref.getCenter();
    // const newZoom = mapref.getZoom();
    setLocLatitudeMap(newCenter?.lat() !== 0 ? newCenter?.lat() : undefined);
    setLocLongitudeMap(newCenter?.lng() !== 0 ? newCenter?.lng() : undefined);
  };

  return (
    <Box position="relative">
      {loadingGetMaker && (
        <Box
          position="absolute"
          top="2%"
          left="46%"
          zIndex={1000}
          width={100}
          height={40}
          sx={{ backgroundColor: "#FFFFFF", boxShadow: "0 6px 16px rgba(0, 0, 0, 0.12)" }}
          borderRadius={8}
        >
          <CircularProgress size={20} sx={{ position: "relative", top: "25%", left: "40%" }} />
        </Box>
      )}
      {hasRenderMorInfoButton && (
        <Box
          position="absolute"
          top="2%"
          left={matches ? "30%" : "40%"}
          zIndex={1000}
          height={40}
          sx={{ backgroundColor: "#FFFFFF", boxShadow: "0 6px 16px rgba(0, 0, 0, 0.12)", cursor: "pointer" }}
          borderRadius={8}
          display="flex"
          onClick={getCurrentMapData}
          alignItems="center"
        >
          <Box width={20} height={20} ml={6} mr={4}>
            <SearchIcon sx={{ fontSize: 20 }} color="primary" />
          </Box>

          <Typography variant="h6" color="#202124" sx={{ opacity: 1 }} fontWeight={500} mr={6}>
            {t("BUTTON-SEARCH-MAP")}
          </Typography>
        </Box>
      )}

      <SelectMarker
        internalCoordinates={internalCoordinates}
        isMoving={isMoving}
        address={address}
        loadingMapInfo={loadingMapInfo}
        onSearchPlaceByMapId={onSearchPlaceByMapId}
      />
      {isLoaded && (
        <GoogleMap
          center={center}
          mapContainerStyle={mapStyles}
          zoom={positions.length > 0 ? 12 : 4}
          options={{
            ...options,
            mapTypeControl: !matches,
            restriction: {
              latLngBounds: {
                north: 90,
                south: -90,
                east: 180,
                west: -180,
              },
              ...responsiveOptions,
              strictBounds: true,
            },
          }}
          onLoad={handleLoad}
          onMouseDown={handleMouseDown}
          onMouseUp={getInternalMapData}
          onZoomChanged={getInternalMapDataZoom}
        >
          {positions?.map((item) => (
            <MarkerLocation
              position={{ lat: +item.lat, lng: +item.lng }}
              name={item.name}
              address1={item.address}
              id={item.id}
              mapId={item.mapId}
              loading={loading}
            />
          ))}
          {latitude && longitude && <MarkerF icon={markerIconFactory(0.065)} position={orghaveCoordinates} />}
        </GoogleMap>
      )}
    </Box>
  );
}

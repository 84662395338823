import { Alert, Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomAvatar from "core/components/mui/avatar";
import { ThemeColor } from "models";

//**Hooks */
import { useTranslation } from "react-i18next";
//**Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
//** Images */
import listimg from "assets/list.png";
import checkImg from "assets/check.png";
import warningImg from "assets/Warning.png";
import errorImg from "assets/error.png";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import ImportDetailTable from "./ImportDetailTable";

interface DataType {
  imgAlt: string;
  imgSrc: string;
  imgWidth: number;
  imgHeight: number;
  avatarColor: ThemeColor;
}

const data: DataType[] = [
  {
    imgHeight: 12,
    imgWidth: 20,
    imgAlt: "list",
    avatarColor: "secondary",
    imgSrc: listimg,
  },
  {
    imgHeight: 16,
    imgWidth: 20,
    imgAlt: "success",
    avatarColor: "success",
    imgSrc: checkImg,
  },
  {
    imgHeight: 18,
    imgWidth: 20,
    imgAlt: "inconsistences",
    avatarColor: "warning",
    imgSrc: warningImg,
  },
  {
    imgHeight: 20,
    imgWidth: 20,
    imgAlt: "failed",
    avatarColor: "error",
    imgSrc: errorImg,
  },
];
export default function ImportDetailsModal({ open, handleClose, id }: { open: boolean; handleClose: () => void; id: number }) {
  const { t } = useTranslation();

  const notificationState = useSelector((store: AppStore) => store.user);
  const { notifications } = notificationState;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="user-view-edit"
      sx={{ "& .MuiPaper-root": { width: "100%", maxWidth: 570 } }}
      aria-describedby="user-view-edit-description"
    >
      <DialogTitle id="user-view-edit" sx={{ fontSize: "2rem !important", px: 8, pt: 8 }}>
        {t("GLOBAL-IMPORT-DETAIL")}
      </DialogTitle>
      <Divider />
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon sx={{ fontSize: 24 }} />
      </IconButton>
      {notifications?.length > 0 ? (
        <DialogContent sx={{ px: 16 }}>
          {notifications[+id]?.error ? (
            <Box mb={12}>
              <Alert severity="error" sx={{ "& .MuiPaper-root": { padding: "0" }, mt: 8 }} className="alert-no-padding">
                {notifications[+id]?.error}
              </Alert>
            </Box>
          ) : (
            <>
              <Box mt={4}>
                <Typography variant="h2" mb={2}>
                  {`${notifications[+id]?.fileName}`}
                </Typography>
              </Box>
              <Box mb={12} display="flex">
                <Box width="100%">
                  <Typography sx={{ fontSize: "12px !important" }}>{`${notifications[+id]?.date}`}</Typography>
                </Box>
                <Box width="100%" display="flex" justifyContent="flex-end">
                  <TimerOutlinedIcon />
                  <Typography sx={{ fontSize: "12px !important", ml: 2 }}>{`${notifications[+id]?.summary?.executionTime}`}</Typography>
                </Box>
              </Box>

              <Box display="flex" width="100%" mb={12}>
                <Box justifyContent="flex-start" width="100%">
                  <Box display="flex">
                    <Box>
                      <CustomAvatar skin="light" color={data[0].avatarColor} variant="rounded" sx={{ mr: 3 }}>
                        <img alt={data[0].imgAlt} src={data[0].imgSrc} width={data[0].imgWidth} height={data[0].imgHeight} />
                      </CustomAvatar>
                    </Box>
                    <Box ml={4}>
                      <Typography variant="h3" fontWeight="700">
                        {notifications[+id]?.summary?.total?.toLocaleString("es-MX")}
                      </Typography>
                      <Typography variant="body1">{t("IMPORT-DETAIL-TOTAL")}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box justifyContent="flex-end" width="100%">
                  <Box display="flex">
                    <Box>
                      <CustomAvatar skin="light" color={data[1].avatarColor} variant="rounded" sx={{ mr: 3 }}>
                        <img alt={data[1].imgAlt} src={data[1].imgSrc} width={data[1].imgWidth} height={data[1].imgHeight} />
                      </CustomAvatar>
                    </Box>
                    <Box ml={4}>
                      <Typography variant="h3" fontWeight="700">
                        {notifications[+id]?.summary?.success?.toLocaleString("es-MX")}
                      </Typography>
                      <Typography variant="body1">{t("IMPORT-DETAIL-SUCCESS")}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" width="100%">
                {notifications[+id]?.summary?.warning ? (
                  <Box justifyContent="flex-start" width="100%">
                    <Box display="flex">
                      <Box>
                        <CustomAvatar skin="light" color={data[2].avatarColor} variant="rounded" sx={{ mr: 3 }}>
                          <img alt={data[2].imgAlt} src={data[2].imgSrc} width={data[2].imgWidth} height={data[2].imgHeight} />
                        </CustomAvatar>
                      </Box>
                      <Box ml={4}>
                        <Typography variant="h3" fontWeight="700">
                          {notifications[+id]?.summary?.warning?.toLocaleString("es-MX")}
                        </Typography>
                        <Typography variant="body1">{t("IMPORT-DETAIL-WARNING")}</Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
                <Box justifyContent="flex-end" width="100%">
                  <Box display="flex">
                    <Box>
                      <CustomAvatar skin="light" color={data[3].avatarColor} variant="rounded" sx={{ mr: 3 }}>
                        <img alt={data[3].imgAlt} src={data[3].imgSrc} width={data[3].imgWidth} height={data[3].imgHeight} />
                      </CustomAvatar>
                    </Box>
                    <Box ml={4}>
                      <Typography variant="h3" fontWeight="700">
                        {notifications[+id]?.summary?.failed?.toLocaleString("es-MX")}
                      </Typography>
                      <Typography variant="body1">{t("IMPORT-DETAIL-ERROR")}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box my={8}>
                <Typography variant="h5" fontWeight="700" mb={8}>
                  Error List
                </Typography>
                <ImportDetailTable
                  rows={notifications[+id]?.rowsErrors?.length > 0 ? notifications[+id]?.rowsErrors : notifications[+id]?.templateErrors}
                />
              </Box>
            </>
          )}
        </DialogContent>
      ) : null}
    </Dialog>
  );
}

// ** MUI Imports
import { Theme } from "@mui/material/styles";

// ** Util Import
import { hexToRGBA } from "utilities";

const Backdrop = (theme: Theme) => {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.mode === "light" ? `rgba(${theme.palette.customColors.main}, 0.5)` : hexToRGBA("#101121", 0.87),
        },
        invisible: {
          backgroundColor: "transparent",
        },
      },
    },
  };
};

export default Backdrop;

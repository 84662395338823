// ** MUI Imports
import { styled, useTheme } from "@mui/material/styles";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import MuiToolbar, { ToolbarProps } from "@mui/material/Toolbar";

// ** Type Import
import { LayoutProps } from "models/layoutsTypes";

// ** Util Import
import { hexToRGBA } from "utilities";

interface Props {
  hidden: LayoutProps["hidden"];
  toggleNavVisibility: () => void;
  settings: LayoutProps["settings"];
  saveSettings: LayoutProps["saveSettings"];
  appBarContent: NonNullable<LayoutProps["verticalLayoutProps"]["appBar"]>["content"];
  appBarProps: NonNullable<LayoutProps["verticalLayoutProps"]["appBar"]>["componentProps"];
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  transition: "none",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
  minHeight: theme.mixins.toolbar.minHeight,
  py: 0,
}));

const Toolbar = styled(MuiToolbar)<ToolbarProps>(({ theme }) => ({
  width: "100%",
  padding: "0 !important",
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  minHeight: `${theme.mixins.toolbar.minHeight}px !important`,
  transition: "padding .25s ease-in-out, box-shadow .25s ease-in-out, backdrop-filter .25s ease-in-out",
}));

const LayoutAppBar = (props: Props) => {
  // ** Props
  const { settings, appBarProps, appBarContent: userAppBarContent } = props;

  // ** Hooks
  const theme = useTheme();
  const scrollTrigger = useScrollTrigger({ threshold: 0, disableHysteresis: true });

  // ** Vars
  const { appBar, contentWidth } = settings;

  //** DEFAULT */
  // const appBarFixedStyles = () => {
  //   return {
  //     px: `${theme.spacing(6)} !important`,
  //     boxShadow: skin === "bordered" ? 0 : 3,
  //     ...(appBarBlur && { backdropFilter: "blur(8px)" }),
  //     backgroundColor: hexToRGBA(theme.palette.background.paper, appBarBlur ? 0.9 : 1),
  //     ...(skin === "bordered" && { border: `1px solid ${theme.palette.divider}`, borderTopWidth: 0 }),
  //   };
  // };

  const appBarFixedStyles = () => {
    return {
      backgroundColor: hexToRGBA(theme.palette.background.paper, 1),
    };
  };

  if (appBar === "hidden") {
    return null;
  }

  let userAppBarStyle = {};
  if (appBarProps && appBarProps.sx) {
    userAppBarStyle = appBarProps.sx;
  }
  // eslint-disable-next-line prefer-object-spread
  const userAppBarProps = Object.assign({}, appBarProps);
  delete userAppBarProps.sx;

  return (
    <AppBar
      elevation={0}
      color="default"
      className="layout-navbar"
      sx={{ ...userAppBarStyle, backgroundColor: theme.palette.background.paper }}
      position={appBar === "fixed" ? "sticky" : "static"}
      {...userAppBarProps}
    >
      <Toolbar
        className="navbar-content-container"
        sx={{
          ...(appBar === "fixed" && scrollTrigger && { ...appBarFixedStyles() }),
          ...(contentWidth === "boxed" && {
            "@media (min-width:1440px)": { maxWidth: `calc(1440px - ${theme.spacing(6)} * 2)` },
          }),
        }}
      >
        {(userAppBarContent && userAppBarContent(props)) || null}
      </Toolbar>
    </AppBar>
  );
};

export default LayoutAppBar;

/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum RequestAccessRoleEnum {
    multiOrgManager = "1",
    multiOrgViewer = "2",
    organizationManager = "3",
    organizationViewer = "4",
    locationManager = "5",
    locationViewer = "6",
}

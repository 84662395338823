import { Alert, Stack } from "@mui/material";
import { Button, ModalConfirm } from "components";
import { useState } from "react";
//** models */
import { PrivateRoutes, Roles } from "models";
//**Hooks */
import config from "config/settings.json";
import { useGlobalContext } from "context/globalContext";
import { useGetReduxUser } from "hooks";
import { useUserContext } from "pages/users/context";
import { RolesRadioButtons } from "pages/users/models";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdateUsers } from "./hooks";

export default function UpdateUserButtons({ profile }: { profile?: boolean }) {
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  //**Hooks */
  const { id } = useParams();
  const { updateUser } = useUpdateUsers();
  const { organization, radioRole, role, location, isPrimaryContact } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userState } = useGetReduxUser();
  const { urlHistory } = useGlobalContext();
  const { roles, locationId } = userState;

  //**Methods */
  const hadleUpdate = () => {
    updateUser(`${profile ? userState.id : id}`);
  };
  const handleOpenConfirm = () => setOpenModalConfirm(true);
  const handleCloseConfirm = () => setOpenModalConfirm(false);

  const orgNotFilled = Array.isArray(organization) ? organization?.length === 0 : organization?.label === "";
  const needsOrganization = userState.roles === Roles.superAdmin && orgNotFilled;
  const userLocReqFields = radioRole === RolesRadioButtons.locRelated && (needsOrganization || !location?.label);

  const isOrganizationNeeded = radioRole === RolesRadioButtons.orgRelated ? orgNotFilled || !role?.label : false;
  const haveRoleIfNonAdmin = isOrganizationNeeded && !role?.label;

  const disabledButtonRoles = [Roles.multiOrgViewer, Roles.organizationViewer, Roles.locationViewer];
  const diabledButtonByRole = disabledButtonRoles.includes(userState?.roles as Roles);

  const needsRole = radioRole === RolesRadioButtons.locRelated || radioRole === RolesRadioButtons.orgRelated ? !role?.label : false;
  const isAdminUser =
    roles === Roles.superAdmin || roles === Roles.multiOrgManager || roles === Roles.organizationManager || roles === Roles.locationManager;
  const isMyProfile = userState?.id === id;

  //** locations users only can edit users related to their own location*/
  const locManagerCanEdit = roles === Roles.locationManager && radioRole === RolesRadioButtons.locRelated && locationId === location?.id;
  //** org users only can edit users related to their own org except the org managers that can edit all */
  const orgManagerCanEdit =
    roles === Roles.multiOrgManager || roles === Roles.superAdmin
      ? true
      : roles === Roles.organizationManager &&
        (radioRole === RolesRadioButtons.locRelated ||
          (radioRole === RolesRadioButtons.orgRelated && role.label !== Roles.multiOrgManager));

  //**Users can edit their on profile */
  const canEditInformation = (isAdminUser && (locManagerCanEdit || orgManagerCanEdit)) || isMyProfile;

  return (
    <>
      {isPrimaryContact && (
        <Alert severity="warning" sx={{ "& .MuiPaper-root": { padding: "0" }, mt: 8 }}>
          {`${t("ALERT-ERROR-UPDATE-PRIMARY-CONTACT-USER")}`}
        </Alert>
      )}

      {locationId !== location?.id &&
        roles === Roles.locationManager &&
        (role.label === Roles.locationManager || role.label === Roles.locationViewer) && (
          <Alert severity="warning" sx={{ "& .MuiPaper-root": { padding: "0" }, mt: 8 }}>
            {`${t("ALERT-ERROR-UPDATE-OTHER-LOC-USER")}`}
          </Alert>
        )}

      <Stack direction="row" gap={4} mt={8}>
        <Button
          name="user-update"
          onClick={profile ? handleOpenConfirm : hadleUpdate}
          label={t("BUTTON-UPDATE")}
          size="large"
          disabled={
            profile || canEditInformation
              ? isPrimaryContact
              : isOrganizationNeeded ||
                haveRoleIfNonAdmin ||
                diabledButtonByRole ||
                userLocReqFields ||
                needsRole ||
                isPrimaryContact ||
                !canEditInformation ||
                !locManagerCanEdit ||
                !orgManagerCanEdit
          }
        />
        <Button
          name="user-cancel-update"
          onClick={() => {
            if (document.referrer.indexOf(`${config.HOST_URL}`) !== -1 || urlHistory.length > 0) {
              navigate(-1);
            } else {
              navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.LIST}`, { replace: false });
            }
          }}
          label={t("BUTTON-CANCEL")}
          size="large"
          color="secondary"
          variant="outlined"
        />

        <ModalConfirm
          open={openModalConfirm}
          title={t("USERS-MODAL-CONFIRM-TITLE")}
          description={t("USERS-MODAL-CONFIRM-DESCRIPTION")}
          textButtonConfirm={t("BUTTON-ACCEPT")}
          handleClose={handleCloseConfirm}
          handleConfirm={() => {
            hadleUpdate();
            setOpenModalConfirm(false);
          }}
          sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
          sxTitle={{ fontSize: "2rem !important" }}
        />
      </Stack>
    </>
  );
}

/* eslint-disable max-len */
import { Button, Menu, MenuItem, MenuProps, alpha } from "@mui/material";
import { MouseEvent, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

export interface IDataButtonExpand {
  label: string;
  onClick: () => void;
  hide?: boolean;
  disabled?: boolean;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }: { theme?: any }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 110,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function ButtonExpand({ data, buttonLabel }: { data: IDataButtonExpand[]; buttonLabel?: string }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // ** Var
  const open = Boolean(anchorEl);

  // ** Hooks
  const { t } = useTranslation();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        size="large"
        variant="contained"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-controls={open ? "user-view-overview-export" : undefined}
        onClick={handleClick}
        endIcon={<ExpandMoreIcon sx={{ fontSize: 24 }} />}
      >
        {buttonLabel || t("BUTTON-ADD")}
      </Button>
      <StyledMenu
        id="user-view-overview-export"
        MenuListProps={{
          "aria-labelledby": "user-view-overview-export",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {data.map((item) => (
          <>
            {!item.hide && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  item.onClick();
                }}
                disabled={item.disabled}
              >
                {item.label}
              </MenuItem>
            )}
          </>
        ))}
      </StyledMenu>
    </>
  );
}

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
import { IOrganizationListAPI } from "pages/organizations";
import { IRoleDataAPI, IRoleDataDOM, IUsersListAPI, IUsersListDOM } from "pages/users/models";

export const userGetallAdapter = (items: IUsersListAPI[]): IUsersListDOM[] => {
  const array: IUsersListDOM[] = [];
  items?.map((item: IUsersListAPI) => {
    return array.push({
      id: item.id,
      akamaiUuid: item.akamai_uuid,
      username: item.username,
      firstName: item.first_name,
      lastName: item.last_name,
      phone: item.phone,
      status: item.status,
      isSuspended: item.is_suspended,
      description: item.description,
      roles: item.roles,
      createdAt: item.created_at,
      updatedAt: item.updated_at,
      deletedAt: item.deleted_at,
      roleData: userDataAdapter(item.role_data),
      SBDEmail: item.sbd_contact,
      title: item.designation,
      locationData: {
        name: item?.location_data?.name,
        id: item?.location_data?.id,
      },
      organizationData: orgDataAdapter(item.organization_data),
      primaryContact: {
        relatedId: item?.primary_contact?.related_id,
        type: item?.primary_contact?.type,
      },
    });
  });
  return array;
};
const userDataAdapter = (items: IRoleDataAPI[] | undefined): IRoleDataDOM[] => {
  const array: IRoleDataDOM[] = [];
  items?.map((item: IRoleDataAPI) => {
    return array.push({
      id: item.id,
      name: item.name,
      description: item.description,
      enabled: item.enabled,
      viewsEnabled: item.views_enabled,
      actions: item.actions,
      createdAt: item.created_at,
      updatedAt: item.updated_at,
      deletedAt: item.deleted_at,
    });
  });
  return array;
};

export const orgDataAdapter = (items: IOrganizationListAPI[]): { logo: string; name: string; id: string }[] => {
  const array: { logo: string; name: string; id: string }[] = [];
  items?.map((item: IOrganizationListAPI) => {
    return array.push({
      name: item.name,
      logo: item.logo,
      id: item.id,
    });
  });
  return array;
};

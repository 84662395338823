//**External imports */
import { Box } from "@mui/material";
//**Components */
import { Maps } from "components";
//**Context*/
import { useLocationContext } from "pages/locations/context";
import { LocationOpenHoursMap } from "../LocationOpenHoursMap";

export default function LocationMap({ loading }: { loading: boolean }) {
  //**Context*/
  const { latitude, longitude } = useLocationContext();
  const MapOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
  };
  return (
    <Box borderRadius="8px" overflow="hidden" position="relative" width="100%">
      <Box position="absolute" top={20} zIndex={100} px={10} width="100%">
        <LocationOpenHoursMap loading={loading} />
      </Box>
      <Maps positions={[{ lat: +latitude, lng: +longitude }]} mapHeight="calc(100vh - 160px)" options={MapOptions} />
    </Box>
  );
}

/* eslint-disable no-nested-ternary */
import { Dispatch, SetStateAction, useRef, useState } from "react";
//**Mui imports */
import { Box, TextField } from "@mui/material";
//**Components imports */
import { Chip } from "components";

interface ITags {
  valueTag: string[];
  setValueTag: Dispatch<SetStateAction<string[]>>;
  label: string;
  name: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  size?: "small" | "medium";
  changeControllerFn?: Function;
}
export default function TagInput({ label, setValueTag, valueTag, name, disabled, error, helperText, size, changeControllerFn }: ITags) {
  //**States */
  const tagRef = useRef<any>("");
  const [focus, setFocus] = useState<boolean>(false);

  const handleDelete = (value: string) => {
    const newtags = valueTag.filter((val) => val !== value);
    setValueTag(newtags);
  };
  const handleChange = (e: any) => {
    if (e.target.value.includes(",")) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      setValueTag([...valueTag, tagRef?.current?.value.slice(0, tagRef?.current?.value.length - 1)]);
      tagRef.current.value = "";
      changeControllerFn && changeControllerFn();
    }
  };
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    setValueTag([...valueTag, tagRef?.current?.value]);
    tagRef.current.value = "";
  };

  return (
    <Box sx={{ width: "100%" }}>
      <form onSubmit={handleOnSubmit}>
        <TextField
          size={size}
          disabled={disabled}
          name={`taginput-${name}`}
          inputRef={tagRef}
          onChange={handleChange}
          fullWidth
          InputLabelProps={{ shrink: valueTag?.length > 0 || focus || tagRef?.current?.value?.length > 0 }}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
          label={label}
          InputProps={{
            startAdornment: (
              <Box sx={{ margin: "0 0.2rem 0 0", display: "flex" }} flexWrap="wrap" py={4} gap={4}>
                {valueTag?.map((data, index) => {
                  // eslint-disable-next-line react/no-array-index-key
                  return <Chip label={data} onDelete={() => handleDelete(data)} key={index} disabled={disabled} size={size} />;
                })}
              </Box>
            ),
          }}
          helperText={valueTag?.length > 0 || focus ? "Categories should be separated by commas" : error ? helperText : ""}
          error={error}
        />
      </form>
    </Box>
  );
}

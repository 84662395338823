import { CardContent } from "@mui/material";
import StepperHeader from "./StepperHeader";
import StepperRequest from "./StepperRequest";
import StepperContent from "./StepperContent";

export default function RequestSteps() {
  return (
    <>
      <CardContent>
        <StepperHeader />
        <StepperRequest />
      </CardContent>
      <CardContent>
        <StepperContent />
      </CardContent>
    </>
  );
}

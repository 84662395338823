import dayjs from "dayjs";
import { DATE_FORMAT } from "models";
import { useTranslation } from "react-i18next";

export default function useGetDateRange() {
  const { t } = useTranslation();

  const getDateRange = (dateSearch: string, before_date: string, after_date: string) => {
    let beforeDate = ""; //Antes de
    let afterDate = ""; //Despues de
    if (dateSearch === t("DATE-OPTION-2")) {
      //Today
      afterDate = dayjs().format(DATE_FORMAT);
    }
    if (dateSearch === t("DATE-OPTION-3")) {
      //Yesterday
      beforeDate = dayjs().format(DATE_FORMAT);
      afterDate = dayjs().subtract(1, "day").format(DATE_FORMAT);
    }
    if (dateSearch === t("DATE-OPTION-4")) {
      //Last 7 days
      afterDate = dayjs().subtract(1, "week").format(DATE_FORMAT);
    }
    if (dateSearch === t("DATE-OPTION-5")) {
      //Last 30 days
      afterDate = dayjs().subtract(1, "month").format(DATE_FORMAT);
    }
    if (dateSearch === t("DATE-OPTION-6")) {
      // Last 90 days
      afterDate = dayjs().subtract(3, "month").format(DATE_FORMAT);
    }
    if (dateSearch === t("DATE-OPTION-7")) {
      // Custom
      beforeDate = before_date;
      afterDate = after_date;
    }

    return { beforeDate, afterDate };
  };
  return { getDateRange };
}

// ** MUI Imports
import IconButton from "@mui/material/IconButton";

// ** Icon Imports
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import ModeNightIcon from "@mui/icons-material/ModeNight";
// ** Types Import
import { Mode } from "models/layoutsTypes";
import { Settings } from "context/settingsContext";
import { useSettings } from "hooks/useSettings";
import { useTranslation } from "react-i18next";
import { TooltipApp } from "components";

interface Props {
  settings: Settings;
  // eslint-disable-next-line react/no-unused-prop-types, no-unused-vars
  saveSettings: (values: Settings) => void;
}

const ModeToggler = (props: Props) => {
  const { t } = useTranslation();

  const { saveSettings } = useSettings();
  // ** Props
  const { settings } = props;

  const handleModeChange = (mode: Mode) => {
    saveSettings({ ...settings, mode });
  };

  const handleModeToggle = () => {
    if (settings.mode === "light") {
      handleModeChange("dark" as Mode);
    } else {
      handleModeChange("light" as Mode);
    }
  };

  return (
    <IconButton
      color="inherit"
      aria-haspopup="true"
      onClick={handleModeToggle}
      sx={{ "&:focus": { outline: "0px auto -webkit-focus-ring-color" } }}
    >
      <TooltipApp
        title={settings.mode === "dark" ? t("ICON-SUN-TOOLTIP") : t("ICON-MOON-TOOLTIP")}
        sxText={{
          fontWeight: 600,
        }}
      >
        {settings.mode === "dark" ? <WbSunnyIcon sx={{ fontSize: 25 }} /> : <ModeNightIcon sx={{ fontSize: 25 }} />}
      </TooltipApp>
    </IconButton>
  );
};

export default ModeToggler;

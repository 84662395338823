/* eslint-disable no-shadow */
// ** MUI Imports
import { deepmerge } from "@mui/utils";
import { Box, createTheme, CssBaseline, GlobalStyles, responsiveFontSizes, Theme, ThemeProvider } from "@mui/material";

import { createContext, useContext, ReactNode } from "react";
// ** Type Imports
import { Settings } from "context/settingsContext";

// ** Theme Override Imports
import overrides from "theme/overrides";
import typography from "theme/typography";

// ** Direction component for LTR or RTL
import Direction from "layouts/components/Direction";

// ** Theme Config
import themeConfig from "config/themeConfig";

// ** Theme
import themeOptions from "theme/ThemeOptions";
import UserThemeOptions from "layouts/UserThemeOptions";

// ** Global Styles
import GlobalStyling from "theme/globalStyles";

const ThemeContext = createContext({ toggleColorMode: () => {} });

interface Props {
  settings: Settings;
  children: ReactNode;
}

function ThemeContextProvider(props: Props) {
  // ** Props
  const { settings, children } = props;

  // ** Merged ThemeOptions of Core and User
  const coreThemeConfig = themeOptions(settings);

  // ** Pass ThemeOptions to CreateTheme Function to create partial theme without component overrides
  let theme = createTheme(coreThemeConfig);

  // ** Deep Merge Component overrides of core and user
  const mergeComponentOverrides = (theme: Theme, settings: Settings) =>
    deepmerge({ ...overrides(theme, settings) }, UserThemeOptions()?.components);

  // ** Deep Merge Typography of core and user
  const mergeTypography = (theme: Theme) => deepmerge(typography(theme), UserThemeOptions()?.typography);

  // ** Continue theme creation and pass merged component overrides to CreateTheme function
  theme = createTheme(theme, {
    components: { ...mergeComponentOverrides(theme, settings) },
    typography: { ...mergeTypography(theme) },
  });

  // ** Set responsive font sizes to true
  if (themeConfig.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return (
    <ThemeProvider theme={theme}>
      <Direction direction={settings.direction}>
        <CssBaseline />
        <GlobalStyles styles={() => GlobalStyling(theme) as any} />
        <Box
          sx={{
            "& ::-webkit-scrollbar ": {
              width: "0px",
              height: "0px",
            },
            "& ::-webkit-scrollbar-thumb": {
              backgroundColor: "background.paper",
              borderRadius: "8px",
            },
            "& ::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "primary.light",
            },
            "& ::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          {children}
        </Box>
      </Direction>
    </ThemeProvider>
  );
}

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  return context;
};

export { ThemeContext, ThemeContextProvider };

import { Box } from "@mui/material";
import { ReactNode } from "react";

// ** Third Party Components
import PerfectScrollbar from "react-perfect-scrollbar";

export default function ScrollWraperList({ children, hidden }: { children: ReactNode; hidden: boolean }) {
  if (hidden) {
    return <Box sx={{ height: "100%", overflow: "auto" }}>{children}</Box>;
  }
  return <PerfectScrollbar options={{ wheelPropagation: false }}>{children}</PerfectScrollbar>;
}

//** React imports */
import { useState } from "react";

// ** MUI Components
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { InputLabel, Button, FormHelperText } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

//** Hooks */
import { useInputValue } from "hooks";
import { useTranslation } from "react-i18next";
import useResetPassword from "pages/resetPassword/hooks/useResetPassword";

//** Icons */
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function ResetPasswordInputs() {
  //** Hooks */
  const { t } = useTranslation();
  const { validateResetPassword } = useResetPassword();
  //** States */
  const newPassword = useInputValue("");
  const confirmPassword = useInputValue("");

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  //** Inputs Functions */
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleResetPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    validateResetPassword(newPassword.value);
  };
  return (
    <Box>
      <form action="/" onSubmit={handleResetPassword}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel error={newPassword.value && newPassword.value.length < 8} htmlFor="outlined-adornment-password">
            {t("INPUT-NEW-PASSWORD")}
          </InputLabel>
          <OutlinedInput
            error={newPassword.value && newPassword.value.length < 8}
            name="rest-password-new-password"
            {...newPassword}
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={`${t("INPUT-NEW-PASSWORD")}`}
          />
          {newPassword.value && newPassword.value.length < 8 && (
            <FormHelperText error={newPassword.value.length < 8}>{t("NEW-PASSWORD-VALIDATION")}</FormHelperText>
          )}
        </FormControl>
        <FormControl variant="outlined" fullWidth sx={{ mt: 10, mb: 10 }}>
          <InputLabel error={newPassword.value !== confirmPassword.value} htmlFor="outlined-adornment-password">
            {t("INPUT-CONFIRM-PASSWORD")}
          </InputLabel>
          <OutlinedInput
            inputProps={{
              helperText: "hola",
            }}
            error={newPassword.value !== confirmPassword.value}
            name="input-reset-password-password"
            {...confirmPassword}
            id="outlined-adornment-password"
            type={showPasswordConfirm ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPasswordConfirm}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={`${t("INPUT-CONFIRM-PASSWORD")}`}
          />
          {newPassword.value && newPassword.value !== confirmPassword.value && (
            <FormHelperText error={newPassword.value !== confirmPassword.value}>{t("CONFRIM-PASSWORD-VALIDATION")}</FormHelperText>
          )}
        </FormControl>
        <Button
          name="reset-password"
          fullWidth
          disabled={newPassword.value.length < 8 || newPassword.value !== confirmPassword.value}
          size="large"
          type="submit"
          variant="contained"
          sx={{ mb: 7 }}
        >
          {t("BUTTON-SAVE")}
        </Button>
      </form>
    </Box>
  );
}

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { ISelect } from "./interface";

export default function SelectComponent(props: ISelect) {
  const {
    labelId,
    id,
    value,
    label,
    setValue,
    variant,
    autoWidth,
    size,
    options,
    disabled,
    error,
    fullWidth = true,
    required,
    name,
  } = props;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 350,
      },
    },
  };

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };
  const iconComponent = (iconProps: any) => {
    return <ArrowDropDown sx={{ fontSize: "1.8em" }} className={iconProps.className} />;
  };

  return (
    <FormControl variant={variant} size={size} fullWidth={fullWidth}>
      <InputLabel sx={{ fontSize: "14px" }}>{label}</InputLabel>
      <Select
        name={name}
        MenuProps={MenuProps}
        autoWidth={autoWidth}
        labelId={labelId}
        id={id}
        value={value}
        label={label}
        onChange={handleChange}
        disabled={disabled}
        error={error}
        required={required}
        IconComponent={iconComponent}
        sx={{
          "& .MuiInputBase-input": {
            height: "26px",
            padding: "14px 14px",
          },
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options.map((option: string) => (
          <MenuItem value={option} key={`${option}`}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import { useEffect, useRef, useState } from "react";
import { MarkerF, MarkerProps, InfoWindow } from "@react-google-maps/api";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PrivateRoutes } from "models";

interface IMarkerProps extends MarkerProps {
  name: string;
  address1: string;
  isVisible: boolean;
  id: string;
}
export default function MarkerInfo(props: IMarkerProps) {
  const markerRef: any = useRef(null);

  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const navigate = useNavigate();

  const { id, address1, position } = props;
  const markerIconFactory = (scale: number) => ({
    path: faLocationDot.icon[4] as string,
    fillColor: "#0288D1",
    fillOpacity: 1,
    strokeWeight: 2,
    strokeColor: "#ffffff",
    rotation: 0,
    scale: scale || 0.065,
    anchor: new google.maps.Point(
      faLocationDot.icon[0] / 2, // width
      faLocationDot.icon[1], // height
    ),
  });

  useEffect(() => {
    if (markerRef.current) {
      markerRef?.current.setIcon(markerIconFactory(mouseOver ? 0.08 : 0.065));
    }
  }, [mouseOver]);

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      if (position.lat && position.lng) {
        setActive(true);
      }
    }, 200);

    return () => clearTimeout(delayTimer);
  }, []);

  const handleOnLoad = (markerInstance: any) => {
    markerRef.current = markerInstance;
  };

  const handleMouseOver = () => {
    setMouseOver(true);
  };

  const handleMouseOut = () => {
    setMouseOver(false);
  };

  const activeCondition = active;
  return (
    <MarkerF icon={markerIconFactory(0.065)} onLoad={handleOnLoad} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} {...props}>
      {position.lat && position.lng && activeCondition && (
        <InfoWindow position={{ lat: +position.lat, lng: +position.lng }}>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.INFORMATION_NAV}/${id}`);
            }}
          >
            <Box sx={{ maxWidth: "250px" }}>
              <Typography noWrap variant="body2" sx={{ color: "rgba(76, 78, 100, 0.5)", whiteSpace: "normal" }}>
                {address1}
              </Typography>
            </Box>
          </Box>
        </InfoWindow>
      )}
    </MarkerF>
  );
}

import { NotFound } from "pages";
import { Route, Routes } from "react-router-dom";

interface IProps {
  children: JSX.Element[] | JSX.Element;
}
export default function RoutesWithNotFound({ children }: IProps) {
  return (
    <Routes>
      {children}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

// ** MUI Imports
import Box from "@mui/material/Box";
import { MapDashboard } from "components";

// ** Custom Icon Import
import { ButtonToggleMap, useLocationListContext } from "pages/locations";

type IProps = {
  permanentLeftBar: boolean;
  handleChangeLeftBar: () => void;
  handleLeftSidebarToggle: () => void;
  smAbove: boolean;
  loading?: boolean;
  isListShow?: boolean;
  isLocationList?: boolean;
  mapHeight?: string;
};

export default function LocationDashboardContent(props: IProps) {
  // ** Props
  const { permanentLeftBar, handleChangeLeftBar, smAbove, handleLeftSidebarToggle, loading, isListShow, isLocationList, mapHeight } = props;
  const { locationsMapMarksArray } = useLocationListContext();

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        height: "100%",
        backgroundColor: "action.hover",
      }}
    >
      <Box
        sx={{
          height: "100%",
          marginTop: "-80px",
        }}
      >
        <ButtonToggleMap
          smAbove={smAbove}
          permanentLeftBar={permanentLeftBar}
          handleChangeLeftBar={
            !smAbove
              ? () => {
                  handleChangeLeftBar();
                  handleLeftSidebarToggle();
                }
              : () => {
                  handleChangeLeftBar();
                }
          }
        />

        <MapDashboard
          positions={locationsMapMarksArray}
          mapHeight={mapHeight}
          options={{ mapTypeControlOptions: { position: 3 } }}
          loading={loading}
          isListShow={isListShow}
          isLocationList={isLocationList}
        />
      </Box>
    </Box>
  );
}

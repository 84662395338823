/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
interface IUsersRowsDOM {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  status: string;
  date: string;
  sbdEmail: string;
  fullName: string;
}

export type Data = IUsersRowsDOM;

export interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  actions?: any;
}

//**ROLES */
export enum RolesRadioButtons {
  superAdmin = "Super Admin",
  multiOrgRelated = "Multi Organization related",
  orgRelated = "Organization related",
  locRelated = "Location related",
}

export enum UserViewsName {
  CREATE = "create",
  INFORMATION = "update",
  REQUEST = "request",
  PROFILE = "profile",
}

export interface IBulkUser {
  is_suspended?: boolean;
  item_id: string[];
}

export interface IDeleteUser {
  items?: string[];
}

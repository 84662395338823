//** MUI IMPORTS */
import { Box, Typography } from "@mui/material";
//** Hooks */
import { useTranslation } from "react-i18next";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PublicRoutes } from "models";
//** Navigation */

export default function BackToLoginButton() {
  //** Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Typography
        sx={{
          display: "flex",
          "& svg": { mr: 1.5 },
          alignItems: "center",
          color: "primary.main",
          textDecoration: "none",
          justifyContent: "center",
        }}
        onClick={() => navigate(`/${PublicRoutes.LOGIN}`, { replace: true })}
        variant="h5"
      >
        <ChevronLeft sx={{ fontSize: "24px" }} />

        {t("BUTTON-BACK-LOGIN")}
      </Typography>
    </Box>
  );
}

/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
function hasCloseNumbers(array: number[], threshold: number) {
  for (let i = 0; i < array?.length; i++) {
    for (let j = i + 1; j < array?.length; j++) {
      if (Math.abs(array[i] - array[j]) <= threshold) {
        array[j] += 0.001;
      }
    }
  }
  return array;
}
export { hasCloseNumbers };

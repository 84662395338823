import { getAllFromUrl, putFromUrl } from "utilities";
import { IDeleteOrganizations, IPotentialPlacesAPI } from "../models";

export const deleteOrganizationAPI = (orgsIds: string[]) => {
  return putFromUrl<unknown, IDeleteOrganizations>("organizations/delete-many", { items: orgsIds });
};

export const getPotentialPlaces = (props?: any) => {
  return getAllFromUrl<IPotentialPlacesAPI>("organizations/potential-places", props);
};

export const changeHQLocationAPI = (organizationId: string, loc_id: string) => {
  return putFromUrl<unknown, unknown>(`organizations/${organizationId}/hq`, { loc_id });
};

import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useFetchAndLoad, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
import { useUsersListContext } from "../pages/usersList/context";
import { deleteUserAPI } from "../services";

export default function useDeleteUsers() {
  //** Context */
  const { setSelected } = useUsersListContext();
  const { setUserGetToggle } = useListAndSearchContext();
  //**Hooks */
  const { callEndpoint, loading } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const deleteUsers = async (usersIds: string[]) => {
    try {
      await callEndpoint(deleteUserAPI(usersIds), (err: any) => handleCreateToast(err.response.data.message, "error"));
      setUserGetToggle((prev: boolean) => !prev);
      handleCreateToast(t("ALERT-SUCCESS-DELETE-ACTION"), "success");
      setSelected([]);
    } catch (error) {
      console.log(error);
    }
  };
  return { deleteUsers, loading };
}

import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { Input, InputApiCall, InputApiCallInfiniteScroll } from "components";
import InputApiCallCheckboxInfiniteScroll from "components/atoms/InputApiCallCheckbox/InputApiCallCheckboxInfiniteScroll";
import { useGetOrganizationsInfiniteScroll, useGetRoles, useInputValueContext, useLocationInfiniteScroll } from "hooks";
import { IDropdownOption, PrivateRoutes, Roles } from "models";
import { useUserContext } from "pages/users/context";
import { RolesRadioButtons } from "pages/users/models";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

export default function UserProfileView() {
  //** Context */
  const { firstName, lastName, email, phone, role, title, organization, location, radioRole } = useUserContext();
  const { setFirstName, setLastName, setEmail, setPhone, setRole, setTitle, setOrganization, setLocation } = useUserContext();

  //**States */
  const firstNameInput = useInputValueContext("", setFirstName);
  const lastNameInput = useInputValueContext("", setLastName);
  const phoneInput = useInputValueContext("", setPhone);
  const titleInput = useInputValueContext("", setTitle);

  const { getOrganizationsInfiniteScroll, hasNextPage, orgData, orgPage, setOrgPage, setOrgData } = useGetOrganizationsInfiniteScroll();
  const { getlocationsInfiniteScroll, locHasNextPage, locData, locPage, setLocPage, setLocData } = useLocationInfiniteScroll();

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  //** Hooks */
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:600px)");
  const { getRoles, loading } = useGetRoles();

  const requestViewLocRole =
    (role?.label === Roles.locationViewer || role?.label === Roles.locationManager) && !Array.isArray(organization) && !!organization?.id;

  const locRelatedHasOrgSelected = radioRole === RolesRadioButtons.locRelated && !Array.isArray(organization) && !!organization?.id;

  return (
    <Box>
      <Typography variant="h2" sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}>
        {t("ORGANIZATION-BASIC-INFORMATION")}
      </Typography>
      <Stack direction={!matches ? "row" : "column"} spacing={4} my={6}>
        <Input
          name="users-first-name"
          value={firstName}
          onChange={firstNameInput.onChange}
          label={`${t("INPUT-FIRST-NAME")}*`}
          fullWidth
          error={firstName?.length === 0}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
        />
        <Input
          name="users-last-name"
          value={lastName}
          onChange={lastNameInput.onChange}
          label={`${t("INPUT-LAST-NAME")}*`}
          fullWidth
          error={lastName?.length === 0}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
        />
      </Stack>
      <Stack direction={!matches ? "row" : "column"} spacing={4} my={6}>
        <Input name="users-email" value={email} onChange={onChangeEmail} label={`${t("INPUT-EMAIL")}*`} fullWidth disabled />
        <Input
          name="users-phone"
          value={phone}
          onChange={phoneInput.onChange}
          label={`${t("INPUT-PHONE")}*`}
          fullWidth
          error={phone?.length === 0}
          helperText={`${t("ALL-REQUIRED.INPUT")}`}
        />
      </Stack>
      <Stack direction={!matches ? "row" : "column"} spacing={4} mb={8}>
        <Input
          name="users-title"
          value={title}
          onChange={titleInput.onChange}
          label={`${t("INPUT-TITLE")}`}
          fullWidth
          sx={{ display: "flex" }}
        />
        <InputApiCall
          name="usr-role"
          externalValue={role}
          setPropValue={setRole}
          apiCallFunction={getRoles}
          loading={loading}
          label={`${t("INPUT-ROLE")}*`}
          disabled
          requiredFields={role?.label?.length === 0}
          alertLabel={`${t("ALL-REQUIRED.INPUT")}`}
        />
      </Stack>

      <Typography variant="h2" sx={{ mb: 10, fontWeight: 600, letterSpacing: "0.18px" }}>
        {t("GLOBAL-TITLE-ORGNAIZATION")}
      </Typography>

      {role?.label === Roles.multiOrgManager || role.label === Roles.multiOrgViewer ? (
        <InputApiCallCheckboxInfiniteScroll
          initialValue={organization}
          name="user-organization"
          setPropValue={setOrganization as Dispatch<SetStateAction<IDropdownOption[]>>}
          changeWithInputText
          apiCallFunction={getOrganizationsInfiniteScroll}
          loading={false}
          label={`${t("GLOBAL-TITLE-ORGNAIZATION")} *`}
          hasNextPage={hasNextPage}
          page={orgPage}
          options={orgData}
          setPage={setOrgPage}
          setOptions={setOrgData}
          disabled
          redirect
          redirectUrl={`${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}`}
        />
      ) : (
        <InputApiCallInfiniteScroll
          initialValue={organization}
          name="loc-organization"
          setPropValue={setOrganization}
          changeWithInputText
          apiCallFunction={getOrganizationsInfiniteScroll}
          loading={false}
          label={`${t("GLOBAL-TITLE-ORGNAIZATION")} *`}
          hasNextPage={hasNextPage}
          page={orgPage}
          options={orgData}
          setPage={setOrgPage}
          disabled
          setOptions={setOrgData}
        />
      )}
      <>
        {(locRelatedHasOrgSelected || requestViewLocRole) && (
          <Box mt={8}>
            <InputApiCallInfiniteScroll
              initialValue={location}
              name="loc.user"
              setPropValue={setLocation}
              changeWithInputText
              apiCallFunction={(name: string) => {
                getlocationsInfiniteScroll(name, !Array.isArray(organization) ? `${organization?.id}` : "");
              }}
              loading={false}
              label={`${t("GLOBAL-TITLE-LOCATION")} *`}
              hasNextPage={locHasNextPage}
              page={locPage}
              options={locData}
              setPage={setLocPage}
              disabled
              setOptions={setLocData}
              alwaysCallApi
            />
          </Box>
        )}
      </>
    </Box>
  );
}

/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
//** MUI Imports */
import { Box, Typography } from "@mui/material";
//** HOOKS */
import { useFetchAndLoad, useGetReduxUser, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
//**Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { createQuery } from "utilities";

//** Services */
import { downloadFileFromResponse } from "utilities/HelpService";
import { useGlobalContext } from "context/globalContext";
import { exportModalActions } from "constants/globalConstants";

import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { getUserExportAPI } from "../services";
import { useUsersListContext } from "../pages/usersList/context";
import { IUsersSearchHook } from "../models";
import { UserSearchFields } from "../pages/usersList/hooks/useUsersSearch";

export default function useExportUsers({
  organizationId,
  locationId,
  embebed,
}: {
  organizationId: string;
  locationId: string;
  embebed?: boolean;
}) {
  const { callEndpoint, loading } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();

  //** Redux */
  const userData = useSelector((store: AppStore) => store.user);
  const { userState } = useGetReduxUser();
  const { exportUserFilter } = useGlobalContext();
  const { selected } = useUsersListContext();

  const { searchParamsLocs } = useListAndSearchContext();
  const firstNameSearch = searchParamsLocs.get(UserSearchFields.firstName);
  const lastNameSearch = searchParamsLocs.get(UserSearchFields.lastName);
  const emailSearch = searchParamsLocs.get(UserSearchFields.email);
  const statusUserSearch = searchParamsLocs.get(UserSearchFields.status);
  const roleSearch = searchParamsLocs.get(UserSearchFields.roleId); //** needs the ids */
  const organizationUserSearch = searchParamsLocs.get(UserSearchFields.organization); //** needs the ids */

  const exportUsers = async () => {
    handleCreateToast(
      <Box>
        <Typography variant="h5" mb={4} fontWeight={700}>
          {t("LOC-IMPORT-NOTIFICATION-EXPORT-TITLE")}
        </Typography>
        <Typography fontSize={6}>{t("LOC-IMPORT-NOTIFICATION-DESC-2")}</Typography>
      </Box>,
      "notification",
      true,
      { horizontal: "right", vertical: "top" },
    );
    try {
      const organizationIdForFilter = organizationId || organizationUserSearch;
      const organizationIdOnView = embebed
        ? organizationIdForFilter
        : userState?.organization?.id
        ? `${userState?.organization.id}`
        : organizationIdForFilter;

      let query = createQuery<Partial<IUsersSearchHook>>({
        first_name: firstNameSearch,
        last_name: lastNameSearch,
        username: emailSearch,
        role_id: roleSearch,
        status: statusUserSearch,
      });
      //** Get all without search */
      const userRoleQuery = createQuery<Partial<IUsersSearchHook>>({
        organization_id: userData?.organization?.id ? `${userData?.organization.id}` : organizationId,
        location_id: locationId,
      });
      //** Get all with search */
      const userRoleQueryFilter = createQuery<Partial<IUsersSearchHook>>({
        organization_id: organizationIdOnView || "",
      });

      if (exportUserFilter === exportModalActions.EXPORT_ALL) query = { ...userRoleQuery };
      if (exportUserFilter === exportModalActions.EXPORT_SELECTED_ITEMS) query = { search: selected.toString() || "empty" };
      if (exportUserFilter === exportModalActions.EXPORT_CURRENT_SEARCH) query = { ...query, ...userRoleQueryFilter };

      const response = await callEndpoint(getUserExportAPI({ query }), async (error: any) => {
        const responseObj = await error?.response?.data?.text();
        handleCreateToast(JSON.parse(responseObj)?.message || t("ALERT-ERROR-ON-EXPORT"), "error");
      });
      downloadFileFromResponse(response, ".csv", "users");
    } catch (error) {
      console.log(error);
    }
  };

  return { loading, exportUsers };
}

// ** MUI Imports
import { Theme } from "@mui/material/styles";

// ** Util Import
import { hexToRGBA } from "utilities";

const Tooltip = (theme: Theme) => {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 6,
          lineHeight: 1.455,
          backgroundColor: hexToRGBA(theme.palette.customColors.tooltipBg, 0.9),
          fontSize: 14,
          textAlign: "center",
        },
        arrow: {
          color: hexToRGBA(theme.palette.customColors.tooltipBg, 0.9),
        },
      },
    },
  };
};

export default Tooltip;

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IRowErrorDOM } from "models";
import { Typography } from "@mui/material";

export default function ImportDetailTable({ rows }: { rows: IRowErrorDOM[] }) {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 240, overflow: "scroll" }}>
      <Table sx={{ minWidth: 350 }} aria-labelledby="tableTitle" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography sx={{ fontSize: "12px !important" }} fontWeight="700">
                ROW
              </Typography>
            </TableCell>
            <TableCell>
              <Typography sx={{ fontSize: "12px !important" }} fontWeight="700">
                ERRORS
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow key={row.rowNumber} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                <Typography variant="h5">{row.rowNumber}</Typography>
              </TableCell>
              <TableCell>
                {row?.errors?.map((error) => (
                  <Typography variant="h5">- {error}</Typography>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

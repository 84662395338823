/* eslint-disable nonblock-statement-body-position */
/* eslint-disable curly */
//** Hooks */
import { useFetchAndLoad, useGetReduxUser, useToastMui } from "hooks";
import { useGetRoles } from "hooks/apiCalls";
import { useTranslation } from "react-i18next";
//** Models */
import { PrivateRoutes, Roles } from "models";
import { RolesRadioButtons } from "pages/users/models";
//** Context */
import { useUserContext } from "pages/users/context";
import config from "config/settings.json";
//** Services*/
import { IUpdateBodyUser, suspendUsersAPI, updateUsersAPI } from "pages/users/pages/usersInformation/services";
//** Utils */
import { capitalize } from "utilities";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "context/globalContext";

export default function useUpdateUsers(fromRequest?: boolean) {
  //** CONTEXT */
  const { setUpdatedToggle, email, firstName, lastName, phone, SBDEmail, role, radioRole, organization, active, title, location } =
    useUserContext();
  //** Hooks */
  const { urlHistory } = useGlobalContext();
  const updateFetch = useFetchAndLoad();
  const statusFetch = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getRoles } = useGetRoles();

  const { userState } = useGetReduxUser();
  const { roles, id } = userState;
  const params = useParams();

  const updateUser = async (userId: string, isRequest?: boolean) => {
    let organizationIds: string[];
    const isMyProfile = params?.id === id;
    if (Array.isArray(organization)) {
      organizationIds = organization?.map((item) => (item.id ? item.id : ""));
    } else {
      organizationIds = organization.id ? [organization.id] : [];
    }
    const isLocRole = role.label === Roles.locationManager || role.label === Roles.locationViewer;

    const isAdminRole = RolesRadioButtons.superAdmin;
    try {
      let body: Partial<IUpdateBodyUser> = {
        username: email,
        first_name: firstName,
        last_name: lastName,
        phone,
        description: "",
        sbd_contact: SBDEmail,
        designation: title,
      };

      //? Admins can change the org and location
      if (roles === Roles.superAdmin || roles === Roles.multiOrgManager) {
        body = {
          ...body,
          organization_id: radioRole === isAdminRole ? [] : organizationIds,
          location_id: isLocRole ? `${location?.id}` : "",
        };
      }
      //? Orgs users can change the location but no the org
      if (roles === Roles.organizationManager) {
        body = {
          ...body,
          location_id: isLocRole ? `${location?.id}` : "",
        };
      }
      //? Locs users can't change the org or location
      //? A user cannot change their own role
      if (!isMyProfile) {
        body = {
          ...body,
          roles: [`${role.label}`],
        };
      }

      if (radioRole === RolesRadioButtons.superAdmin) {
        //** When selecting an admin type it is necessary to obtain the ids of the roles separately  */
        const rolesArray = await getRoles();
        body = { ...body, roles: [rolesArray.filter((item) => item.label === Roles.superAdmin)[0].label] };
      }

      //** Update users data */
      await updateFetch.callEndpoint(updateUsersAPI(userId, { body }), (error: any) =>
        handleCreateToast(`${t("ALERT-ERROR-UPDATE")} : ${capitalize(error?.response?.data?.message)} `, "error"),
      );

      //** Update status */
      if (!fromRequest) {
        if (!isMyProfile) {
          await statusFetch.callEndpoint(suspendUsersAPI(userId, active), (error: any) =>
            handleCreateToast(`${t("ALERT-ERROR-UPDATE-STATUS")} : ${capitalize(error?.response?.data?.message)} `, "error"),
          );
        }
        handleCreateToast(t("ALERT-SUCCESS-UPDATE"), "success");
        setUpdatedToggle((prev) => !prev);
      }

      if (isRequest) return;
      if (radioRole === RolesRadioButtons.superAdmin) {
        navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.LIST}`, { replace: false });
      } else {
        // ? If the user come from org landing or loc list then go back to the respective page
        // eslint-disable-next-line no-lonely-if
        if (document.referrer.indexOf(`${config.HOST_URL}`) !== -1 || urlHistory.length > 0) {
          navigate(-1);
        } else {
          navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.LIST}`, { replace: false });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateUserPrimaryContact = async (userId: string, resourseId: string, primaryType: "Organization" | "Location") => {
    try {
      const body: any = { primary_contact: { related_id: resourseId, type: primaryType } };

      //** Update users data */
      await updateFetch.callEndpoint(updateUsersAPI(userId, { body }), (error: any) =>
        handleCreateToast(`${t("ALERT-ERROR-UPDATE")} : ${capitalize(error?.response?.data?.message)} `, "error"),
      );
    } catch (error) {
      console.log(error);
    }
  };
  return { updateUser, loading: updateFetch.loading, updateUserPrimaryContact };
}

import { postFromUrl, putFromUrl } from "utilities";

export const verifyEmailAPI = async (verificationCode: string | null) => {
  const body = { verification_code: verificationCode };
  return postFromUrl("users/code-verify-email", body);
};

export const resendVerificationEmailAPI = async (email: string) => {
  const body = { email };
  return postFromUrl("users/send-verify-email", body);
};

export const updatePassword = async (password: string, email: string | null) => {
  const body = { password, email_user: email };
  return putFromUrl("users/set-password-akamai", body);
};

// ** React Imports
import { MouseEvent, useState } from "react";

import { Box, Divider, ListItemButton, ListItemText, Typography } from "@mui/material";
// ** MUI Imports
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";

// ** Icon Imports
import MoreVertIcon from "@mui/icons-material/MoreVert";

//** Components imports */
import Translations from "layouts/components/Translations";

// ** Hook Import
import { useSettings } from "hooks/useSettings";
import { Check } from "@mui/icons-material";

// ** Type Imports
import { OptionType, OptionsMenuType } from "./types";

export default function OptionsMenuApplication(props: OptionsMenuType) {
  // ** Props
  const { icon, options, menuProps, iconProps, leftAlignMenu, iconButtonProps } = props;

  // ** State
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // ** Hook & Var
  const { settings } = useSettings();
  const { direction } = settings;

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-haspopup="true"
        onClick={handleClick}
        {...iconButtonProps}
        sx={{ "&:focus": { outline: "0px auto -webkit-focus-ring-color" } }}
      >
        {icon || <MoreVertIcon {...iconProps} />}
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        {...(!leftAlignMenu && {
          anchorOrigin: { vertical: "bottom", horizontal: direction === "ltr" ? "right" : "left" },
          transformOrigin: { vertical: "top", horizontal: direction === "ltr" ? "right" : "left" },
        })}
        {...menuProps}
      >
        <MenuItem>
          <Typography component="h5" fontWeight={700}>
            <Translations text="GLOBAL-TITLE-APPLICATIONS" />
          </Typography>
        </MenuItem>
        <Divider />
        {options.map((option: OptionType) => {
          return (
            <ListItemButton
              selected={option.menuItemProps?.selected}
              sx={{ py: 6, minWidth: "320px", display: "flex", justifyContent: "space-between" }}
              key={option.id}
              onClick={(e: any) => {
                handleClose();
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-unused-expressions
                option.menuItemProps && option.menuItemProps.onClick ? option.menuItemProps.onClick(e) : null;
              }}
            >
              <Box display="flex" alignItems="center">
                <ListItemText primary={option.text} sx={{ ml: 8 }} />
              </Box>
              {option.menuItemProps?.selected && (
                <Box>
                  <Check />
                </Box>
              )}
            </ListItemButton>
          );
        })}
      </Menu>
    </>
  );
}

import { postFromUrl } from "utilities";

export const resendResetPasswordEmailAPI = async (email: string) => {
  const body = { email };
  return postFromUrl("users/send-reset-password", body);
};

export const verifyResetPasswordEmailAPI = async (password: string, authorizationCode: string | null) => {
  const body = { password, autorization_code: authorizationCode };
  return postFromUrl("users/code-confirm-password", body);
};

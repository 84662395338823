import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function EmptyListText() {
  const { t } = useTranslation();
  return (
    <Box textAlign="center" my={12}>
      <Typography variant="h3" fontWeight={700} mb={4}>
        {t("EMPTY-FILTER-TITLE")}
      </Typography>
      <Typography variant="h2">{t("EMPTY-FILTER-DESCRIPTION")}</Typography>
    </Box>
  );
}

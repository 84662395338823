//** External import */
import { Box, Card, Skeleton, Theme, Typography, useMediaQuery } from "@mui/material";
//** Icons */
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
//** Hooks */
import { useTranslation } from "react-i18next";
//** Context */
import { useLocationContext } from "pages/locations/context";

export default function LocationOpenHoursMap({ loading }: { loading: boolean }) {
  //**Context */
  const { openingHours } = useLocationContext();
  //** Hooks */ */
  const { t } = useTranslation();
  //** Media querys*/ */
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const underXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));

  const defaultText = underXs ? "----" : "-----";
  const responsiveDisposition = (position: number) => {
    if (underMd) {
      if (loading) {
        return (
          <Box width="100%" mt={2} textAlign="center">
            <Skeleton variant="rounded" width="100%" height={20} />
            to
            <Skeleton variant="rounded" width="100%" height={20} />
          </Box>
        );
      }
      return (
        <Box width="100%" mt={2}>
          {!openingHours[position].opening && !openingHours[position].closing ? (
            "-"
          ) : (
            <Typography variant="h3" textAlign="center">
              {openingHours[position].opening || defaultText} <br /> to <br />
              {openingHours[position].closing || defaultText}{" "}
            </Typography>
          )}
        </Box>
      );
    }
    if (loading) {
      return (
        <Box mt={2}>
          <Skeleton variant="rounded" width="100%" height={20} />
        </Box>
      );
    }
    return (
      <>
        {!openingHours[position].opening && !openingHours[position].closing ? (
          "-"
        ) : (
          <Typography variant="h3" mt={2}>
            {openingHours[position].opening || ""} to {openingHours[position].closing || ""}
          </Typography>
        )}
      </>
    );
  };

  return (
    <Card sx={{ px: 8, py: 4 }}>
      <Box display="flex" alignItems="center" gap={4}>
        <QueryBuilderOutlinedIcon sx={{ fontSize: 24 }} />
        <Typography variant="h2" color="textSecondary">
          {t("STEP-TITLE-OPEN-OPEN-HOURS")}
        </Typography>
      </Box>
      <Box mt={4}>
        <Box display="flex" mt={4} overflow="scroll">
          <Box width="100%" textAlign="center">
            <Typography variant="h3" sx={{ fontWeight: 700 }}>
              {t("SCHEDULE-DAY-1")}
            </Typography>
            {responsiveDisposition(0)}
          </Box>
          <Box mx={2} width="100%" textAlign="center">
            <Typography variant="h3" sx={{ fontWeight: 700 }}>
              {t("SCHEDULE-DAY-2")}
            </Typography>
            {responsiveDisposition(1)}
          </Box>
          <Box mx={2} width="100%" textAlign="center">
            <Typography variant="h3" sx={{ fontWeight: 700 }}>
              {t("SCHEDULE-DAY-3")}
            </Typography>
            {responsiveDisposition(2)}
          </Box>
          <Box mx={2} width="100%" textAlign="center">
            <Typography variant="h3" sx={{ fontWeight: 700 }}>
              {t("SCHEDULE-DAY-4")}
            </Typography>
            {responsiveDisposition(3)}
          </Box>
          <Box mx={2} width="100%" textAlign="center">
            <Typography variant="h3" sx={{ fontWeight: 700 }}>
              {t("SCHEDULE-DAY-5")}
            </Typography>
            {responsiveDisposition(4)}
          </Box>
          <Box mx={2} width="100%" textAlign="center">
            <Typography variant="h3" sx={{ fontWeight: 700 }}>
              {t("SCHEDULE-DAY-6")}
            </Typography>
            {responsiveDisposition(5)}
          </Box>
          <Box mx={2} width="100%" textAlign="center">
            <Typography variant="h3" sx={{ fontWeight: 700 }}>
              {t("SCHEDULE-DAY-7")}
            </Typography>
            {responsiveDisposition(6)}
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

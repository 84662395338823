// ** React Imports
import { Dispatch, Fragment, SetStateAction } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// ** Icon Imports
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
// ** Third Party Components

import { useDropzone } from "react-dropzone";
//** Hooks */
import { useToastMui } from "hooks";
import { List, ListItem, Skeleton, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

//** Images */
import image from "assets/NotImage.png";

//** Style */
import "./style.css";
import { useTranslation } from "react-i18next";

interface FileProp {
  name: string;
  type: string;
  size: number;
}

// Styled component for the upload image inside the dropzone area
const Img = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    marginRight: theme.spacing(10),
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    width: 100,
  },
}));

export default function FileUploaderBrand({
  files,
  setFiles,
  label,
  disabled,
  fileText,
  setFileText,
}: {
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  label: string;
  disabled?: boolean;
  fileText: string[];
  setFileText: Dispatch<SetStateAction<string[]>>;
}) {
  // ** Hooks
  const { handleCreateToast } = useToastMui();
  const theme: any = useTheme();
  const matches = useMediaQuery("(max-width:900px)");
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 10,
    maxSize: 3000000,
    disabled,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    onDrop: (acceptedfiles: any) => {
      setFiles((prev: File[]) => [...prev, ...acceptedfiles.map((file: File) => Object.assign(file))]);
    },
    onDropRejected: () => {
      handleCreateToast("You can only upload 10 files & maximum size of 3 MB.", "error");
    },
  });

  const renderFilePreview = (file: FileProp) => {
    if (file.type.startsWith("image")) {
      return <img className="dropzone-img " width={40} height={40} alt={file.name} src={URL.createObjectURL(file as any)} />;
    }
    return <CloseIcon />;
  };

  const handleRemoveFile = (file: FileProp) => {
    const uploadedFiles = files;
    const filtered = uploadedFiles.filter((i: FileProp) => i.name !== file.name);
    setFiles([...filtered]);
    setFileText([]);
  };

  const fileList = files?.map((file: FileProp, index: number) => (
    <>
      {files.length < fileText.length && fileText[index] ? (
        <Skeleton width="100%" height={80} animation="wave" />
      ) : (
        <ListItem key={file.name} className="file-list" sx={{ mb: 4 }}>
          <div className="file-details">
            <div>{renderFilePreview(file)}</div>
            <div>
              <Typography className="file-name">{file.name}</Typography>
              <Typography className="file-size" variant="body2">
                {Math.round(file.size / 100) / 10 > 1000
                  ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
                  : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
              </Typography>
            </div>
          </div>
          <IconButton onClick={() => handleRemoveFile(file)} disabled={disabled}>
            <CloseIcon />
          </IconButton>
        </ListItem>
      )}
    </>
  ));

  return (
    <>
      <div {...getRootProps({ className: `dropzone ${theme?.palette?.mode}` })}>
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", flexDirection: ["column", "column", "row"], alignItems: "center" }}>
          <Img width={40} alt="Upload img" src={image} />
          <Box sx={{ display: "flex", flexDirection: "column", textAlign: ["center", "center", "inherit"] }}>
            <Typography variant="h5" fontWeight={700}>
              {label}
            </Typography>

            <Typography color="textSecondary">{t("FILE-ALLOWED")}</Typography>
          </Box>
          {!matches && (
            <Button
              variant="outlined"
              name="button-file-upload-brand-remove"
              startIcon={<FileUploadIcon />}
              color="secondary"
              size="large"
              sx={{ margin: "0 20px 0 auto" }}
            >
              {t("BUTTON-UPLOAD")}
            </Button>
          )}
        </Box>
      </div>
      {files.length ? <List>{fileList}</List> : null}
    </>
  );
}

//** Hooks */
import useQuery from "utilities/getLocation";
import { useFetchAndLoad, useToastMui } from "hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
//*Utils* */
import { LocalStorageKeys, getInLocalStorage, setInLocalStorage } from "utilities";
import { PublicRoutes } from "models";
import { updatePassword } from "pages/validateAccount/services";
//** Services */
import { resendResetPasswordEmailAPI, verifyResetPasswordEmailAPI } from "../services";

/**
 ** Reset user password funtions
 * @return {Function} validateResetPassword - funtion to validate user account on akamai
 * @return {Function} resendResetPasswordEmail - funtion to send a new email to reset the user password
 */
export default function useResetPassword() {
  //** Hooks */
  const query = useQuery();
  const navigate = useNavigate();
  const { callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();

  /**
   ** Reset user password on akamai
   * @param {string} password - New password to reset
   *
   * @return { Promise<void> }
   */
  const validateResetPassword = async (password: string) => {
    try {
      if (query.get("code")) {
        //When the reset password request comes from an akamai email with a code
        await callEndpoint(await verifyResetPasswordEmailAPI(password, query.get("code")));

        //alerts and routing
        handleCreateToast(`${t("SUCCESS-RESET-PASSWORD")}`, "success");
        navigate(`/${PublicRoutes.LOGIN}`, { replace: true });
      } else {
        //When the reset password request comes from first time login user on a new account
        //Obtaining the email of the account validation request
        const email = `${getInLocalStorage(LocalStorageKeys.EMAIL)}`;
        //Updating a new password on akamay
        await updatePassword(password, email);

        //alerts and routing
        handleCreateToast(`${t("SUCCESS-RESET-PASSWORD")}`, "success");
        navigate(`/${PublicRoutes.LOGIN}`, { replace: true });
      }
    } catch (error: any) {
      console.error(error);
      handleCreateToast(`${error.response.data.message}`, "error");

      //If token expiret, it will be redirect to a new view for resent the email
      if (`${error.response.data.message}` === "The token is expired, verify try send again") {
        navigate(`/${PublicRoutes.ERROR_VALIDATION_LINK}`, { replace: true });
      }
    } finally {
      setInLocalStorage(LocalStorageKeys.EMAIL, "");
    }
  };

  /**
   ** Send email of reset password to user
   * @param {string} email - User email to send the reset password request
   *
   * @return { Promise<void> }
   */
  const resendResetPasswordEmail = async (email: string) => {
    try {
      await callEndpoint(await resendResetPasswordEmailAPI(email));
      setInLocalStorage(LocalStorageKeys.EMAIL, email);
      handleCreateToast(`${t("SUCCESS-RESET-EMAIL-SENT")}`, "success");
    } catch (error: any) {
      console.log("error sending email");
      console.log(error);
      handleCreateToast(`${error.response.data.message}`, "error");
    }
  };
  return { validateResetPassword, resendResetPasswordEmail };
}

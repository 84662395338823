import Compressor from "compressorjs";

export default function useGetPictures() {
  //FILE ATTACHEMENT
  const getBase64 = (file: File) => {
    return new Promise((resolve) => {
      let baseURL: string | ArrayBuffer | null;
      // Make new FileReader
      const reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleGetCompressedBase64 = async (files: File[], index: number): Promise<string> => {
    if (files.length === 0) return "";

    const file = files[index];
    const compress: any = new Compressor(file, {
      quality: 0.6,
      maxWidth: 1920,
      maxHeight: 1080,
      async success(result: any) {
        return result;
      },
    });
    const base64File: any = await getBase64(compress?.file);
    return base64File;
  };
  return { getBase64, handleGetCompressedBase64 };
}

//** React imports */
import { useState } from "react";
//** COMPONTENS*/
import { CardHeaderLogin, CenterCard } from "components";
//** HOOKS*/
import { useTranslation } from "react-i18next";
import { BackToLoginButton, EmailToSendPasswordInputs, ResendVerifyInputs } from "./components";

export default function EmailToResetPassword() {
  //** HOOKS */
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState<boolean>(false);

  if (!error) {
    return (
      <CenterCard>
        <CardHeaderLogin title={`${t("FORGOT-PASSWORD-TITLE")} 🔒`} subTitle={`${t("FORGOT-PASSWORD-SUBTITLE")}`} />
        <EmailToSendPasswordInputs />
        <BackToLoginButton />
      </CenterCard>
    );
  }
  return (
    <CenterCard>
      <CardHeaderLogin
        title={`${t("VERIFY-ACCOUNT-TITLE")} ✉️`}
        subTitle={`${t("VERIFY-ACCOUNT-SUBTITLE1")}`}
        subTitle2={`${t("VERIFY-ACCOUNT-SUBTITLE2")}`}
      />
      <ResendVerifyInputs />
      <BackToLoginButton />
    </CenterCard>
  );
}

import { HelpService, loadAbort } from "utilities";
import config from "config/settings.json";
import { axios as http } from "frameworks_and_drivers/external_interfaces/Axios";
import { AxiosCall } from "models";
import { AxiosRequestConfig } from "axios";

/**
 * Function to made a get all with Axios controllers
 * @type {T} T - Generic Type of the response
 * @param {string} path - Path to the api
 * @param {any} props - Object with keys and values of the needed querys
 * @return {AxiosCall<unknown>} Axios call - return a promise with the response
 */
export const getAllFromUrl = <T>(path: string, props: any): AxiosCall<unknown> => {
  const controller = loadAbort();
  const { query } = props;
  const URL = `${config.BASE_URL}/${path}?${HelpService.getQueryString(query)}`;
  return {
    call: http.get<T>(URL, { signal: controller.signal }),
    controller,
  };
};

/**
 * Function to made a get all with Axios controllers
 * @type {T} T - Generic Type of the response
 * @param {string} url - URL to the api
 * @param {any} props - Object with keys and values of the needed querys
 * @return {AxiosCall<unknown>} Axios call - return a promise with the response
 */
export const getAllFromCustomUrl = <T>(url: string): AxiosCall<unknown> => {
  const controller = loadAbort();
  const URL = `${url}`;
  return {
    call: http.get<T>(URL, { signal: controller.signal }),
    controller,
  };
};

/**
 * Function to made a get one with Axios controllers
 * @type {T} T - Generic Type of the response
 * @param {string} path - Path to the api
 * @return {AxiosCall<unknown>} Axios call - return a promise with the response
 */
export const getOneFromUrl = <T>(path: string): AxiosCall<unknown> => {
  const controller = loadAbort();
  const URL = `${config.BASE_URL}/${path}`;
  return {
    call: http.get<T>(URL, { signal: controller.signal }),
    controller,
  };
};

/**
 * Function to made a post (create) with Axios controllers
 * @type {T} T - Generic Type of the response
 * @type {bodyT} bodyT - Generic Type of body call
 * @param {string} path - Path to the api
 * @param {bodyT} body - Body of the call
 * @param {AxiosRequestConfig} configCall - Configurations of the call (headers, etc)
 * @return {AxiosCall<unknown>} Axios call - return a promise with the response
 */
export const postFromUrl = <T, bodyT>(path: string, body?: bodyT, configCall?: AxiosRequestConfig): AxiosCall<unknown> => {
  const controller = loadAbort();
  const URL = `${config.BASE_URL}/${path}`;
  return {
    call: http.post<T>(URL, body, { signal: controller.signal, ...configCall }),
    controller,
  };
};

/**
 * Function to made a put (update, delete) with Axios controllers
 * @type {T} T - Generic Type of the response
 * @type {bodyT} bodyT - Generic Type of body call
 * @param {string} path - Path to the api
 * @param {bodyT} body - Body of the call
 * @return {AxiosCall<unknown>} Axios call - return a promise with the response
 */
export const putFromUrl = <T, bodyT>(path: string, body?: bodyT): AxiosCall<unknown> => {
  const controller = loadAbort();
  const URL = `${config.BASE_URL}/${path}`;
  return {
    call: http.put<T>(URL, body, { signal: controller.signal }),
    controller,
  };
};

/**
 * Function to made a delete action with Axios controllers
 * @type {T} T - Generic Type of the response
 * @param {string} path - Path to the api
 * @return {AxiosCall<unknown>} Axios call - return a promise with the response
 */
export const deleteFromUrl = <T>(path: string): AxiosCall<unknown> => {
  const controller = loadAbort();
  const URL = `${config.BASE_URL}/${path}`;
  return {
    call: http.delete<T>(URL, { signal: controller.signal }),
    controller,
  };
};

/**
 * Function to made blob file to export file
 * @type {T} T - Generic Type of the response
 * @param {string} path - URL to the api
 * @param {any} props - Object with keys and values of the needed querys
 * @return {AxiosCall<unknown>} Axios call - return a promise with the response
 */
export const exportFromGetUrl = (path: string, props: any): AxiosCall<unknown> => {
  const controller = loadAbort();
  const { query } = props;
  const URL = `${config.BASE_URL}/${path}?${HelpService.getQueryString(query)}`;
  const opts: AxiosRequestConfig = {
    responseType: "blob",
    signal: controller.signal,
  };
  return {
    call: http.get(URL, opts),
    controller,
  };
};

import { getAllFromUrl, putFromUrl } from "utilities";
import { IBulkOrganization, IOrganizationListFromAPI, IOrganizationListWithOutTokenFromAPI } from "pages/organizations/models";

export const getAllOrganizationsAPI = (props?: unknown) => {
  return getAllFromUrl<IOrganizationListFromAPI>("organizations", props);
};
export const getAllOrganizationsWithOutTokenAPI = (props?: unknown) => {
  return getAllFromUrl<IOrganizationListWithOutTokenFromAPI>("organizations/list-name", props);
};

export const bulkOrganizationAPI = ({ body }: { body: IBulkOrganization }) => {
  return putFromUrl<unknown, IBulkOrganization>("organizations/bulk-update", body);
};

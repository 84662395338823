/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { RoleGuard } from "guards";
import { Roles } from "models";
import { useUserContext } from "pages/users/context";
import { RolesRadioButtons, UserViewsName } from "pages/users/models";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import useQuery from "utilities/getLocation";

export default function UserRadioRoles({
  validateUserEmailByRole,
  disableForViewOnlyRole,
}: {
  validateUserEmailByRole: (email: string, isSuperAdmin: boolean) => void;
  disableForViewOnlyRole: boolean;
}) {
  const [lastView, setLastView] = useState<"Organization" | "Location" | null>(null);
  const queryUrl = useQuery();

  const { email, radioRole, view } = useUserContext();
  const { setRole, setOrganization, setRadioRole } = useUserContext();
  //**Redux  */
  const userState = useSelector((store: AppStore) => store.user);
  const { roles: loggedUserRole } = userState;

  useEffect(() => {
    //**Data comes from url */
    const autocompleteOrgWithQuery =
      queryUrl.get("orgId") && view === "create" ? { id: queryUrl.get("orgId"), label: queryUrl.get("orgName") } : null;
    const autocompleteLocWithQuery =
      queryUrl.get("locId") && view === "create" ? { id: queryUrl.get("locId"), label: queryUrl.get("locName") } : null;

    setLastView(autocompleteLocWithQuery?.id ? "Location" : autocompleteOrgWithQuery?.id ? "Organization" : null);
  }, [queryUrl, view]);

  useEffect(() => {
    if (view === UserViewsName.INFORMATION) return;
    if (lastView === "Location" || lastView === "Organization") {
      //** last view exist if the user comes from Org or Loc Dashboard */
      if (lastView === "Organization") setRadioRole(RolesRadioButtons.orgRelated);
      else setRadioRole(RolesRadioButtons.locRelated);
    } else if (loggedUserRole !== Roles.superAdmin || view === UserViewsName.REQUEST) {
      //** If the user comes from user list itself the radio button should come from user role */
      if (loggedUserRole === Roles.multiOrgManager) setRadioRole(RolesRadioButtons.multiOrgRelated);
      else if (loggedUserRole === Roles.organizationManager) setRadioRole(RolesRadioButtons.orgRelated);
      else if (loggedUserRole === Roles.locationManager) setRadioRole(RolesRadioButtons.locRelated);
    } else {
      setRadioRole(RolesRadioButtons.superAdmin);
    }
  }, [lastView, view]);

  if (lastView === "Location") {
    return (
      <RoleGuard
        authorizedRoles={[
          Roles.superAdmin,
          Roles.multiOrgManager,
          Roles.multiOrgViewer,
          Roles.organizationManager,
          Roles.organizationViewer,
        ]}
        view={view !== UserViewsName.REQUEST}
      >
        <RadioGroup
          row
          value={radioRole}
          aria-labelledby="user-role-group-label"
          name="row-radio-roles-group"
          sx={{ mb: 8 }}
          onChange={(e) => {
            setRadioRole(e.target.value);
            // When the radioRole changes the role should be reset
            // loc related cant have a org related role
            setRole({ label: "", id: "" });
          }}
        >
          <FormControlLabel
            disabled={disableForViewOnlyRole}
            value={RolesRadioButtons.locRelated}
            control={<Radio />}
            label={RolesRadioButtons.locRelated}
            name="user-radio-location"
          />
        </RadioGroup>
      </RoleGuard>
    );
  }

  if (lastView === "Organization") {
    return (
      <RoleGuard
        authorizedRoles={[
          Roles.superAdmin,
          Roles.multiOrgManager,
          Roles.multiOrgViewer,
          Roles.organizationManager,
          Roles.organizationViewer,
        ]}
        view={view !== UserViewsName.REQUEST}
      >
        <RadioGroup
          row
          value={radioRole}
          aria-labelledby="user-role-group-label"
          name="row-radio-roles-group"
          sx={{ mb: 8 }}
          onChange={(e) => {
            setRadioRole(e.target.value);
            // When the radioRole changes the role should be reset
            // loc related cant have a org related role
            setRole({ label: "", id: "" });
          }}
        >
          <FormControlLabel
            disabled={disableForViewOnlyRole}
            value={RolesRadioButtons.orgRelated}
            control={<Radio />}
            label={RolesRadioButtons.orgRelated}
            name="user-radio-organization"
          />
          <FormControlLabel
            disabled={disableForViewOnlyRole}
            value={RolesRadioButtons.locRelated}
            control={<Radio />}
            label={RolesRadioButtons.locRelated}
            name="user-radio-location"
          />
        </RadioGroup>
      </RoleGuard>
    );
  }

  return (
    <RoleGuard
      authorizedRoles={[Roles.superAdmin, Roles.multiOrgManager, Roles.multiOrgViewer, Roles.organizationManager, Roles.organizationViewer]}
      view={view !== UserViewsName.REQUEST}
    >
      <RadioGroup
        row
        value={radioRole}
        aria-labelledby="user-role-group-label"
        name="row-radio-roles-group"
        sx={{ mb: 8 }}
        onChange={(e) => {
          setRadioRole(e.target.value);
          // When the radioRole changes the role should be reset
          // loc related cant have a org related role
          setRole({ label: "", id: "" });
          //** When switch between MultiOrg users and org/role related, the org should change to object */
          if (e.target.value === RolesRadioButtons.locRelated || e.target.value === RolesRadioButtons.orgRelated) {
            //** For locations and org users is not nececesarry clear the orgs becouse those didn't change */
            if (loggedUserRole !== Roles.organizationManager && loggedUserRole !== Roles.locationManager) {
              setOrganization({ label: "", id: "" } as any);
            }
          } else if (e.target.value === RolesRadioButtons.multiOrgRelated) {
            setOrganization([]);
          }
          if (e.target.value === RolesRadioButtons.superAdmin) {
            validateUserEmailByRole(email || "", true);
          }
        }}
      >
        <FormControlLabel
          disabled={loggedUserRole !== Roles.superAdmin}
          value={RolesRadioButtons.superAdmin}
          control={<Radio />}
          label={RolesRadioButtons.superAdmin}
          name="user-radio-super-admin"
        />
        <FormControlLabel
          disabled={disableForViewOnlyRole || loggedUserRole === Roles.organizationManager}
          value={RolesRadioButtons.multiOrgRelated}
          control={<Radio />}
          label={RolesRadioButtons.multiOrgRelated}
          name="user-radio-mom"
        />
        <FormControlLabel
          disabled={disableForViewOnlyRole || (loggedUserRole === Roles.organizationManager && view !== "create")}
          value={RolesRadioButtons.orgRelated}
          control={<Radio />}
          label={RolesRadioButtons.orgRelated}
          name="user-radio-organization"
        />
        <FormControlLabel
          disabled={disableForViewOnlyRole}
          value={RolesRadioButtons.locRelated}
          control={<Radio />}
          label={RolesRadioButtons.locRelated}
          name="user-radio-location"
        />
      </RadioGroup>
    </RoleGuard>
  );
}

/* eslint-disable consistent-return */
import { Box, Divider } from "@mui/material";

import { ContactInputs, OpenHours } from "pages/locations/components";

export default function ContactStep() {
  return (
    <Box>
      <ContactInputs />
      <OpenHours />
      <Divider />
    </Box>
  );
}

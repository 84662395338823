// ** React Imports
import { useState, useEffect, ChangeEvent } from "react";

// ** MUI Components
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

import InputAdornment from "@mui/material/InputAdornment";
import MuiFormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";

//** Icons */
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// ** Layout Import
import { Link } from "react-router-dom";
import { Input } from "components";

//** Hooks */
import { useInputValueContext } from "hooks/changeInputValue";
import { useLoginContext } from "pages/login/context";
import { useTranslation } from "react-i18next";
import { LocalStorageKeys, getInLocalStorage, setInLocalStorage } from "utilities";
import { useGlobalContext } from "context/globalContext";
import { EMAIL_REGEX, PublicRoutes, SBD_EMAIL_REGEX } from "models";
import { Alert } from "@mui/material";

// ** Styled Components

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "1.5rem",
    color: theme.palette.text.secondary,
  },
}));

export default function LoginInputs() {
  //** Hooks */
  const { setEmail, setPassword, email, isValid, setIsValid } = useLoginContext();
  const { setRememberMe, rememberMe } = useGlobalContext();
  const { t } = useTranslation();
  // ** State
  const [showPassword, setShowPassword] = useState(false);

  const passwordInput = useInputValueContext("", setPassword);
  //** On init */
  useEffect(() => {
    //When remember me it's active, i can get the user and password on local storage
    const userRememberinfo = getInLocalStorage(LocalStorageKeys.REMEMBER_TOKEN);
    if (userRememberinfo) {
      //Decode user password and email
      const userRememberinfoDecode = atob(`${userRememberinfo}`);
      const passwordRemember = userRememberinfoDecode.split("/-/")[0];
      const emailRemember = userRememberinfoDecode.split("/-/")[1];

      //Put the password and email on login inputs
      setEmail(emailRemember);
      passwordInput.onChangeState(passwordRemember);
    }
  }, []);

  //** Inputs Functions */

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsValid(EMAIL_REGEX.test(e.target.value));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  //** Checkbox Functions */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(event.target.checked);
    setInLocalStorage(LocalStorageKeys.REMEMBER, `${event.target.checked}`);
  };

  return (
    <Box>
      <Input
        value={email}
        onChange={onChange}
        label={`${t("LOGIN-EMAIL")}`}
        fullWidth
        name="input-login-email"
        id="outlined-basic"
        error={email?.length === 0 || !isValid}
        helperText={!isValid && `${email || ""}`.length > 0 ? `${t("INVALID-EMAIL")}` : `${t("ALL-REQUIRED.INPUT")}`}
        strink={email === null ? true : email.length > 0}
      />
      <Input
        {...passwordInput}
        sx={{ mt: 10, mb: 2 }}
        label={`${t("LOGIN-PASSWORD")}`}
        fullWidth
        name="input-login-password"
        id="input-login-password"
        type={showPassword ? "text" : "password"}
        strink={email === null ? true : passwordInput.value.length > 0}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff fontSize="large" /> : <Visibility fontSize="large" />}
            </IconButton>
          </InputAdornment>
        }
      />
      <Box sx={{ mb: 8, mx: 6, display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "space-between" }}>
        <FormControlLabel
          label={`${t("LOGIN-REMEMBER-ME")}`}
          control={<Checkbox checked={rememberMe} onChange={handleChange} sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }} />}
          sx={{ "& .MuiFormControlLabel-label": { color: "text.primary", fontSize: "1.5rem" } }}
        />
        <Link to={`/${PublicRoutes.CHANGE_PASSWORD_GET_EMAIL}`}>
          <Typography variant="h5" sx={{ color: "primary.main", textDecoration: "none" }}>
            {t("LOGIN-FORGOT-PASSWORD")}
          </Typography>
        </Link>
      </Box>
      {SBD_EMAIL_REGEX.test(email || "") && (
        <Box mb={8}>
          <Alert severity="warning">If you have an sbd account please login with SSO</Alert>
        </Box>
      )}
    </Box>
  );
}

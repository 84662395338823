import { exportFromGetUrl, postFromUrl, putFromUrl } from "utilities";
import { IBulkUser, IDeleteUser } from "../models";

export const bulkUserAPI = ({ body }: { body: IBulkUser }) => {
  return putFromUrl<unknown, IBulkUser>("users/bulk-update", body);
};

export const deleteUserAPI = (usersIds: string[]) => {
  return putFromUrl<unknown, IDeleteUser>("users/delete-many", { items: usersIds });
};
export const getUserImportTemplateAPI = (props?: any) => {
  return exportFromGetUrl("users/download-template", props);
};

export const getUserExportAPI = (props?: any) => {
  return exportFromGetUrl("users/export-csv", props);
};

export const importUsersAPI = ({ file, organization }: { file: File; organization: string | null }) => {
  const body = new FormData();
  body.append("file", file);
  const url = organization ? `users/import?organization_name=${organization}` : "users/import";
  return postFromUrl<unknown, FormData>(url, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

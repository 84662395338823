//** Components imports */
import UserLayout from "layouts/UserLayout";
import { PrivateRoutes, PublicRoutes } from "models/routes";
//** External imports */
import { Navigate, Outlet, useLocation } from "react-router-dom";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { useEffect } from "react";
import { useGlobalContext } from "context/globalContext";
import { MenuNavigationResponsive } from "components/responsiveComponents";
import { Theme, useMediaQuery } from "@mui/material";

export const AuthGuard = () => {
  const userState = useSelector((store: AppStore) => store.user);
  const location = useLocation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { setUrlHistory } = useGlobalContext();
  useEffect(() => {
    setUrlHistory((prev) => [...prev, location.pathname]);
  }, []);

  return userState?.token ? (
    <>
      {location.pathname === `/${PrivateRoutes.SELECT_ORGANIZARION}` ? (
        <Outlet />
      ) : (
        <>
          <UserLayout>
            <Outlet />
          </UserLayout>
          {underMd && <MenuNavigationResponsive />}
        </>
      )}
    </>
  ) : (
    <Navigate to={PublicRoutes.LOGIN} replace={false} state={{ location }} />
  );
};

export default AuthGuard;

/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { useState } from "react";
import { ILocationsListAPI, getAllLocationWithOutTokenAPI, getAllLocationsAPI } from "pages";
import useFetchAndLoad from "./useFetchAndLoad";

export default function useLocationInfiniteScroll(hqFilter?: boolean) {
  const [locPage, setLocPage] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [locData, setLocData] = useState<any[]>([]);
  const { callEndpoint, loading } = useFetchAndLoad();
  const getlocationsInfiniteScroll = async (name: string, organizationId: string) => {
    if (name === null) name = "";
    //** Provide next on api call */
    setHasNextPage(true);
    try {
      //** Setup */
      const limit = 20;
      let query: any = {
        limit,
        offset: !name ? locPage * limit : 0,
        sort: hqFilter ? "desc(is_head_quarter)" : "asc(name)",
        organization_id: organizationId,
      };
      if (name) {
        query = {
          name: typeof name === "string" ? name : "",

          ...query,
        };
      }
      //** Create querys with variable offset */
      const response = await callEndpoint(getAllLocationsAPI({ query }));
      const { data } = response;

      //** Generate the data with label and id for autocomplete */
      const autocompleteData = data.result.items.map((item: ILocationsListAPI) => ({
        label: item.name,
        id: item.id,
        address1: item.address.address1,
        address2: item.address.address2,
        region: item.address.region,
        country: item.market_or_country,
        city: item.address.city,
        postalCode: item.address.postal_code,
        coordinates: item.address.coordinates,
        mapId: item.address.maps_id,
        state: item.address.state,

        url: item.contact.website,
        phone: item.contact.phone,
        email: item.contact.email,
      }));

      //** If the name has no value, all results are displayed from an infinite scroll */
      if (!name) {
        //** Create an array with the previuous data an the new data */
        if (locPage !== 0) {
          setLocData((prev: any) => [...prev, ...autocompleteData]);
        } else {
          setLocData(autocompleteData);
        }

        //** Update the page to call the next data on api */
        setLocPage((prev: number) => prev + 1);

        //** If dosen`t appear more data sent that there are not more information*/
        if (!data?.result?.next) setHasNextPage(false);
        if (autocompleteData.length === 0) setLocPage(0);
      } else {
        setLocPage(0);
        //** Create an array with the previuous data an the new data */
        setLocData([...autocompleteData]);
      }
      return data.result.items;
    } catch (error) {
      console.error("Error gewtting organizations data", error);
    }
  };

  const getLocationsInfiniteScrollWithOutToken = async (name: string, organizationId: string) => {
    if (name === null) name = "";
    //** Provide next on api call */

    setHasNextPage(true);
    try {
      //** Setup */
      const limit = 20;
      let query: any = { limit, offset: !name ? locPage * limit : 0, sort: "asc(name)", organization_id: organizationId };
      if (name.length !== 0) {
        query = {
          name: typeof name === "string" ? name : "",
          ...query,
        };
      }
      //** Create querys with variable offset */
      const response = await callEndpoint(getAllLocationWithOutTokenAPI({ query }));
      const { data } = response;

      //** Generate the data with label and id for autocomplete */
      const autocompleteData = data.result.items.map((item: any) => ({ label: item.name, id: item.id }));

      //** If the name has no value, all results are displayed from an infinite scroll */
      if (!name) {
        //** Create an array with the previuous data an the new data */
        if (locPage !== 0) {
          setLocData((prev: any) => [...prev, ...autocompleteData]);
        } else {
          setLocData(autocompleteData);
        }

        //** Update the page to call the next data on api */
        setLocPage((prev: number) => prev + 1);

        //** If dosen`t appear more data sent that there are not more information*/
        if (!data?.result?.next) setHasNextPage(false);
      } else {
        setLocPage(0);
        //** Create an array with the previuous data an the new data */
        setLocData([...autocompleteData]);
      }
    } catch (error) {
      console.error("Error gewtting organizations data", error);
    }
  };

  return {
    getlocationsInfiniteScroll,
    getLocationsInfiniteScrollWithOutToken,
    locHasNextPage: hasNextPage,
    locData,
    loading,
    locPage,
    setLocPage,
    setLocData,
  };
}

//** Context */
import { RequestAccessView } from "./components/organism";
import { RequestContextProvider } from "./context";

export default function RequestAccess() {
  return (
    <RequestContextProvider>
      <RequestAccessView />
    </RequestContextProvider>
  );
}

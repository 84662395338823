// ** MUI Imports
import MuiBox, { BoxProps } from "@mui/material/Box";
import { StepIconProps } from "@mui/material/StepIcon";
import { alpha, styled, useTheme } from "@mui/material/styles";

// ** Custom Icon Import
import { CheckCircle } from "@mui/icons-material";

// Styled Box component
const Box = styled(MuiBox)<BoxProps>(() => ({
  width: 20,
  height: 20,
  borderWidth: 3,
  borderRadius: "50%",
  borderStyle: "solid",
}));

export default function StepperCustomDot(props: StepIconProps) {
  // ** Props
  const { active, completed } = props;

  // ** Hooks
  const theme = useTheme();

  if (completed) {
    return <CheckCircle sx={{ fontSize: 20, color: theme.palette.primary.main }} />;
  }
  return (
    <Box
      sx={{
        borderColor: active ? "primary.main" : alpha(theme.palette.primary.main, 0.3),
      }}
    />
  );
}

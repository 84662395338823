/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
//**Hooks */
import { useListAndSearchContext } from "context/ListsAndSearchContext";
//**Components */
import { Stack } from "@mui/material";
import { Chip } from "components/molecules";
import { ChipStatus, Methods } from "constants/globalConstants";
import { useTheme } from "@emotion/react";
import { useLocationListContext } from "pages/locations";

export default function ClaimChips() {
  const { setLocationStatus, locationStatus } = useListAndSearchContext();
  const { setToggleClaim } = useLocationListContext();
  const theme: any = useTheme();

  const handleClick = (value: ChipStatus) => {
    //** Set visibility to search */
    let methodStatus: Methods | null = null;
    if (value === ChipStatus.CLAIMED) methodStatus = Methods.CLAIMED;
    if (value === ChipStatus.UNCLAIMED) methodStatus = Methods.UNCLAIMED;
    if (value === ChipStatus.CREATED) methodStatus = Methods.MANUAL;
    setLocationStatus(methodStatus);
    setToggleClaim((prev: boolean | null) => (prev === null ? true : !prev));
    //** Set selected chip */
    setLocationStatus(methodStatus);
  };

  return (
    <Stack direction="row" spacing={4} alignItems="center" ml={4} my={6}>
      <Chip
        key="chip-unclaim"
        skin={locationStatus?.includes(Methods.UNCLAIMED) ? undefined : "light"}
        label={ChipStatus.UNCLAIMED}
        onClick={() => handleClick(ChipStatus.UNCLAIMED)}
        color="primary"
        disableTheme={theme.palette?.mode === "light"}
      />
      <Chip
        key="chip-claim"
        disableTheme={theme.palette?.mode === "light"}
        skin={locationStatus?.includes(Methods.CLAIMED) ? undefined : "light"}
        label={ChipStatus.CLAIMED}
        onClick={() => handleClick(ChipStatus.CLAIMED)}
        color="primary"
      />
      <Chip
        key="chip-created"
        skin={locationStatus?.includes(Methods.MANUAL) ? undefined : "light"}
        disableTheme={theme.palette?.mode === "light"}
        label={ChipStatus.CREATED}
        onClick={() => handleClick(ChipStatus.CREATED)}
        color="primary"
      />
    </Stack>
  );
}

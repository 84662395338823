//** MUI Imports */
import { Box, Card, Divider, Fade, Theme, useMediaQuery } from "@mui/material";
//** Components */
import { GoBackBreadcrumb, SectionsTitles, TableSkeleton } from "components";
import { BasicInformationUsers, SuspendSection, UpdateUserButtons } from "pages/users/components";
//** Hooks */
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
//** Utils */
import useUsers from "pages/users/hooks/useUsers";
import { PrivateRoutes } from "models";

export default function UserInformationView() {
  //** Context */
  //**Hooks */
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading } = useUsers(`${id}`);
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  if (loading) {
    return (
      <Box my={28}>
        <TableSkeleton />
      </Box>
    );
  }

  return (
    <Box px={underMd ? 4 : 0} mb={underMd ? 12 : 0}>
      <GoBackBreadcrumb
        label={t("GO-BACK-USER-LIST")}
        onClick={() => {
          navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.LIST}`);
        }}
      />
      <Fade in unmountOnExit timeout={500}>
        <div>
          <Card sx={{ py: 8, px: 6, mx: 6 }}>
            <SectionsTitles title={`${t("USERS-INFO-TITLE")}`} subTitle={`${t("USERS-INFO-SUBTITLE")}`} />
            <BasicInformationUsers loadingOrganization={loading} loadingLoc={loading} />
            <SuspendSection />
            <Divider />
            <UpdateUserButtons />
          </Card>
        </div>
      </Fade>
    </Box>
  );
}

/* eslint-disable no-nested-ternary */
// ** MUI Theme Provider
import { deepmerge } from "@mui/utils";
import { ThemeOptions, useMediaQuery } from "@mui/material";

// ** User Theme Options
import UserThemeOptions from "layouts/UserThemeOptions";

// ** Type Import
import { Settings } from "context/settingsContext";

// ** Theme Override Imports
import palette from "./palette";
import spacing from "./spacing";
import shadows from "./shadows";
import breakpoints from "./breakpoints";

const themeOptions = (settings: Settings): ThemeOptions => {
  // ** Vars
  const { skin, mode, direction, themeColor } = settings;
  const underMd = useMediaQuery("(max-width:768px)");
  // const underSm = useMediaQuery("(max-width:600px)");

  // ** Create New object before removing user component overrides and typography objects from userThemeOptions
  // eslint-disable-next-line prefer-object-spread
  const userThemeConfig: any = Object.assign({}, UserThemeOptions());

  const userFontFamily = userThemeConfig.typography?.fontFamily;

  // ** Remove component overrides and typography objects from userThemeOptions
  delete userThemeConfig.components;
  delete userThemeConfig.typography;

  const mergedThemeConfig = deepmerge(
    {
      direction,
      palette: palette(mode === "semi-dark" ? "light" : mode, skin),
      typography: {
        fontFamily:
          userFontFamily ||
          [
            "Inter",
            "sans-serif",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(","),

        //Delete the 62% on constants.css and add all this fotns with !important and rem
        //? But for that we need to change the font size on all the final components
        h1: {
          fontSize: underMd ? "20px" : "24px", //! Change with !important from constants.css
        },
        h2: {
          fontSize: underMd ? "14px" : "16px",
        },
        h3: {
          fontSize: underMd ? "12px" : "14px",
        },
        h4: {
          fontSize: underMd ? "10px" : "12px",
        },
        h5: {
          fontSize: "1.5rem",
        },
        h6: {
          fontSize: "1.4rem",
        },
        button: {
          fontSize: "1.3rem",
        },
        allVariants: {
          fontSize: "1.5rem",
        },
        subtitle1: {
          fontSize: "1.4rem",
        },
      },
      shadows: shadows(mode),
      ...spacing,
      breakpoints: breakpoints(),
      shape: {
        borderRadius: 10,
      },
      mixins: {
        toolbar: {
          minHeight: 64,
        },
      },
    },
    userThemeConfig,
  );

  return deepmerge(mergedThemeConfig, {
    palette: {
      primary: {
        ...mergedThemeConfig.palette[themeColor],
      },
    },
  });
};

export default themeOptions;

import { useEffect, useState } from "react";
//** Components Imports */
import { AutoComplete } from "components";
//** External libraries */
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IInputApiCalls {
  apiCallFunction: any;
  label: string;
  loading: boolean;
  setPropValue: any;
  changeWithInputText?: boolean; //when need a query to call the api with autocomplete input value
  secondaryFunction?: Function;
  externalValue?: any;
  name: string;
  hasNextPage: boolean;
  page: number;
  options: any[];
  setPage: any;
  setOptions: any;
  initialValue: any;
  disabled?: boolean;
  requiredFields?: boolean;
  alertLabel?: string;
  size?: "small" | "medium";
  readOnly?: boolean;
  alwaysCallApi?: boolean;
  createWithInitialValue?: boolean;
  search?: boolean;
  changeControllerFn?: Function;
}

export default function InputApiCallInfiniteScroll({
  apiCallFunction,
  label,
  loading,
  changeWithInputText,
  setPropValue,
  secondaryFunction,
  externalValue,
  name,
  hasNextPage,
  page,
  options,
  setPage,
  setOptions,
  initialValue,
  disabled,
  requiredFields,
  alertLabel,
  size = "medium",
  readOnly,
  alwaysCallApi,
  createWithInitialValue,
  search,
  changeControllerFn,
}: IInputApiCalls) {
  const [value, setValue] = useState<any>(initialValue);
  const [inputValue, setInputValue] = useState<any>(initialValue);

  useEffect(() => {
    if (initialValue?.id && value?.id === "") {
      setValue({ label: initialValue?.label, id: initialValue?.id });
    }
    if (window.location.pathname.includes("create") && initialValue?.label?.length === 0 && !createWithInitialValue) {
      setValue("");
    }
    if (search && initialValue?.label?.length === 0) {
      setValue("");
    }
  }, [initialValue]);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: page === 0 ? () => {} : apiCallFunction,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    if (!options.length) return;
    if (value?.length < 3) return;
    setPage(0);
    const delayDebounceFn = setTimeout(async () => {
      if (!changeWithInputText) return;
      setOptions([]);
      await apiCallFunction(value);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  useEffect(() => {
    if (page !== 0) return;
    if (value?.length !== 0) return;
    if (options.length === 0) return;
    setPage(0);
    const delayDebounceFn = setTimeout(async () => {
      if (!changeWithInputText) return;
      setOptions([]);
      await apiCallFunction(value);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  return (
    <Box width="100%">
      <AutoComplete
        inputSize={size}
        readOnly={readOnly}
        sx={{ marginBottom: "1px" }}
        disabled={disabled}
        name={name}
        fullWidth
        requiredFields={requiredFields}
        loading={loading}
        inputLabel={label}
        value={externalValue || value}
        options={options}
        setValue={(fieldValue: any) => {
          setValue(fieldValue);
          setPropValue(fieldValue);
          changeControllerFn && changeControllerFn();
          if (secondaryFunction) secondaryFunction(fieldValue);
        }}
        renderOption={(props: any, option: any) => (
          <li {...props} ref={sentryRef}>
            {option.label}
          </li>
        )}
        onChangeInput={(fieldValue: any) => {
          setValue(fieldValue.target.value);
          setInputValue(fieldValue.target.value);
        }}
        onClick={async () => {
          if (alwaysCallApi) {
            setPage(0);
            setOptions([]);
          }
          if (options.length && !alwaysCallApi) return;
          await apiCallFunction("");
        }}
        clearIcon={
          <IconButton
            sx={{ margin: 0, padding: 0 }}
            onClick={async () => {
              if (options.length && !changeWithInputText) return;
              setValue("");
              await apiCallFunction("");
            }}
          >
            <CloseIcon />
          </IconButton>
        }
      />
      {requiredFields && alertLabel && (
        <Typography component="span" color="error" ml={6} mt={2}>
          {alertLabel}
        </Typography>
      )}
    </Box>
  );
}

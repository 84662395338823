/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */

import { IOrgMapsListAPI, IOrgMapsListDOM } from "pages/organizations/models";
import { hasCloseNumbers } from "utilities/mapsHelpers";

export const locationOrgMapAdapter = (items: IOrgMapsListAPI[]): IOrgMapsListDOM[] => {
  const array: IOrgMapsListDOM[] = [];

  //** The Maps API sometimes gets diferent locations with a very similar coordinates */
  //** In this case we must separate the coordinates to see all on the map */
  const latitudeCoordinates = items?.map((item: IOrgMapsListAPI) => +item.coordinates[0]);
  const longitudeCoordinates = items?.map((item: IOrgMapsListAPI) => +item.coordinates[1]);
  const longitudeCoordinatesWithSpace = hasCloseNumbers(longitudeCoordinates, 0.00001);

  items?.map((item: IOrgMapsListAPI, index: number) => {
    return array.push({
      id: item.id,
      name: item.name,
      address: item.address,
      coordinates: [`${latitudeCoordinates[index]}`, `${longitudeCoordinatesWithSpace[index]}`],
      mapId: item.map_id,
    });
  });
  return array;
};

/* eslint-disable no-param-reassign */
import { UserEmptyState } from "models";
import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: UserEmptyState,
  reducers: {
    createUser: (state, action) => {
      return action.payload;
    },
    modifyUser: (state, action) => {
      const formattedData = { ...state, ...action.payload };
      return formattedData;
    },
    resetUser: () => {
      return UserEmptyState;
    },
    addNotification: (state, action) => {
      state.notifications.unshift({ ...action.payload } as never);
      if (state.notifications.length > 5) state.notifications.pop();
      state.counter += 1;
      state.emailAssosiated = action.payload.emailAssosiated || "";
    },
    removeNotifications: (state, action) => {
      if (state.emailAssosiated !== action?.payload?.emailAssosiated) {
        state.notifications = [];
        state.counter = 0;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { createUser, modifyUser, resetUser, addNotification, removeNotifications } = userSlice.actions;

export default userSlice.reducer;

import { PrivateRoutes } from "models";
import { lazy } from "react";
import { Navigate, Route } from "react-router-dom";
import { RoutesWithNotFound } from "utilities";

const ChangeLogList = lazy(() => import("./pages/changeLogList/ChangeLogList"));
const ChangeLogInformation = lazy(() => import("./pages/changeLogInformation/ChangeLogInformation"));

export default function ChangeLog() {
  return (
    <RoutesWithNotFound>
      <Route path="/" element={<Navigate to={PrivateRoutes.LIST} />} />
      <Route path={PrivateRoutes.LIST} element={<ChangeLogList />} />
      <Route path={PrivateRoutes.INFORMATION} element={<ChangeLogInformation />} />
    </RoutesWithNotFound>
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        borderRadius: 8,
      },
    },
  },
};

import { postFromUrl } from "utilities";

interface IAddress {
  address1: string;
  address2: string;
  city: string;
  postal_code: string;
  coordinates: [string, string];
  country: string;
  maps_id: string;
  region: string;
  state: string;
}
export interface ICreateBodyOrganization {
  name: string;
  description: string;
  website_url: string;
  logo: string;
  address: IAddress;
  phone: string;
  email: string | null;
  purpose_codes: string[];
  premium: boolean;
  factory: boolean;
  categories_products: string[];
  business: string;
  brands: string[];
  is_online_only: boolean;
  display_order: string;
  location_name: string;
}
export interface ICreateBodyOrganizationAPI {
  body: ICreateBodyOrganization;
}

export const createOrganizationsAPI = ({ body }: ICreateBodyOrganizationAPI) => {
  return postFromUrl<unknown, ICreateBodyOrganization>("organizations", body);
};

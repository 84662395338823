//** React imports */
import { useEffect, useState } from "react";

//**Hooks */
import { useAsync, useFetchAndLoad, useGetReduxUser, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import useBreadcrumbs from "hooks/useBreadcrumbs";
//**Model */
import {
  IOrganizationListDOM,
  IOrganizationListFromAPI,
  IOrganizationsLoadHook,
  IOrganizationsSearchHook,
} from "pages/organizations/models";
//**Utils */
import { capitalize, createQuery } from "utilities";
//**Services */
import { PrivateRoutes, Roles, array20 } from "models";
import { getAllOrganizationsAPI } from "../services";
import { organizationGetallAdapter } from "../adapters";

//** Context */
import { useOrganizationListContext } from "../context";
import { OrgsSearchFields } from "./useOrganizationsSearch";

export default function useGetOrganizations(infiniteScroll?: boolean) {
  //** Context */
  const { organizationsPage, setOrganizationsPage, rowsPerPageOrg, setNext, setOrganizationHasFilter, organizationGetToggle } =
    useListAndSearchContext();

  const { searchParamsOrgs } = useListAndSearchContext();
  const nameSearch = searchParamsOrgs.get(OrgsSearchFields.name);
  const urlSearch = searchParamsOrgs.get(OrgsSearchFields.url);
  const statusSearch = searchParamsOrgs.get(OrgsSearchFields.status);

  const { organizationsArray, setOrganizationsListLength, setLoad } = useOrganizationListContext();
  const { setOrganizationsArray } = useOrganizationListContext();

  //** State */
  const [lastPage, setLastPage] = useState(0);
  const [searchToggle, setSearchToggle] = useState(false);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const limit = infiniteScroll ? 50 : rowsPerPageOrg;
  const initialPagination = 20;

  //to sort list by api
  const [countCode, setCountCode] = useState(1);
  const [typeSort, setTypeSort] = useState("");

  useEffect(() => {
    setOrganizationsPage(0);
  }, []);

  //** Hooks */
  const { loading, callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const { startHistory } = useBreadcrumbs();
  const { userState } = useGetReduxUser();

  const multiOrgRoles = [Roles.multiOrgManager, Roles.multiOrgViewer];
  const isMultiOrgUser = multiOrgRoles.includes(`${userState?.roles}` as Roles);

  const loadOrganizations: any = async ({ name, is_suspended, website_url, nowPage }: IOrganizationsLoadHook) => {
    //** Load array for the images loading one by one */
    setLoad([...array20]);
    //Create an object with key that have some search value
    //If the table doesn't have a filter it will be sorted by creation date
    let query = createQuery<IOrganizationsSearchHook>({
      name,
      is_suspended: is_suspended ? `${is_suspended === `${t("OPTION-SELECT-1")}`}` : "",
      website_url,
      sort: typeSort || "desc(name)",
      user_id: isMultiOrgUser ? userState.id : "",
    });
    if (infiniteScroll) {
      query = {
        offset: 0,
        limit: initialPagination,
        ...query,
      };
    } else {
      query = {
        offset: nowPage * limit,
        limit,
        ...query,
      };
    }
    setOrganizationHasFilter(`${name || ""}${is_suspended || ""}${website_url || ""}`);
    //Calling get all API
    const response = await callEndpoint(getAllOrganizationsAPI({ query }), () => handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"));

    return response;
  };

  const adaptInfo = (data: IOrganizationListFromAPI) => {
    //Save the adapted data and the last page and length for the pagination
    const array: IOrganizationListDOM[] = organizationGetallAdapter(data?.result?.items);
    setOrganizationsArray(array);
    setLastPage(data?.result?.total);
    setOrganizationsListLength(data?.result?.total);
    setNext(data.result.next);

    startHistory({
      url: `/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`,
      label: capitalize(`${PrivateRoutes.ORGANIZATIONS}`),
      index: 0,
    });
    if (infiniteScroll) setOrganizationsPage((prev: number) => prev + 1);
  };

  // eslint-disable-next-line consistent-return
  const loadMoreOrganizations: any = async ({ name, is_suspended, website_url, nowPage }: IOrganizationsLoadHook) => {
    try {
      setLoad((prev) => [...prev, ...array20]);
      //Create an object with key that have some search value
      //If the table doesn't have a filter it will be sorted by creation date
      let query = createQuery<IOrganizationsSearchHook>({
        name,
        is_suspended: is_suspended ? `${is_suspended === `${t("OPTION-SELECT-1")}`}` : "",
        website_url,
        sort: typeSort || "desc(name)",
        user_id: isMultiOrgUser ? userState.id : "",
      });

      //?first 20 are loaded and then loaded 50 at a time
      if (nowPage === 1) {
        query = { ...query, offset: initialPagination };
      } else {
        query = { ...query, offset: (nowPage - 1) * limit + initialPagination };
      }

      query = { ...query, limit };

      //Calling get all API
      const response = await callEndpoint(getAllOrganizationsAPI({ query }), () => handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"));
      const data = response?.data;
      //Save the adapted data and the last page and length for the pagination
      const array: IOrganizationListDOM[] = organizationGetallAdapter(data?.result?.items);
      setOrganizationsArray((prev) => [...prev, ...array]);
      //** Update the page to call the next data on api */
      setOrganizationsPage((prev: number) => prev + 1);

      //** If dosen`t appear more data sent that there are not more information*/
      if (!data?.result?.next) setHasNextPage(false);

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  let apiCallDependenses = [searchToggle, typeSort, rowsPerPageOrg, organizationGetToggle];
  if (!infiniteScroll) apiCallDependenses = [...apiCallDependenses, organizationsPage];

  useAsync(
    () => loadOrganizations({ name: nameSearch, is_suspended: statusSearch, website_url: urlSearch, nowPage: organizationsPage }),
    adaptInfo,
    () => {},
    [...apiCallDependenses],
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setOrganizationsPage(value);
  };
  const onSearch = () => {
    setSearchToggle((value) => !value);
    setOrganizationsPage(0);
    setHasNextPage(true);
  };

  // * ------------------------SORT----------------------------------//

  // sort

  const incrementCount = (key: string) => {
    setCountCode((prev) => prev + 1);

    if (countCode === 1) {
      setTypeSort(`asc(${key})`);
    }
    if (countCode === 2) {
      setTypeSort(`desc(${key})`);
      setCountCode(1);
    }
  };

  return {
    organizationsArray,
    loading,
    lastPage,
    limit,
    handleChange,
    onSearch,
    incrementCount,
    page: organizationsPage,
    hasNextPage,
    loadMoreOrganizations,
  };
}

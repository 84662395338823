/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

export enum HTTPCodesEnum {
  SUCCESSFUL = 200,
  CREATED = 201,
  NOT_CONTENT = 204,
  INTERNAL_SERVER_ERROR = 500,
  BAD_REQUEST = 400,
  FORBIDDEN = 403,
  RESOURCE_NOT_FOUND = 404,
  UNAUTHORIZED = 401,
}

// ** MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";

// ** Layout Import
import { useNavigate } from "react-router-dom";

//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";

// ** Styled Components
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
    overflow: "hidden",
  },
}));

export const ErrorView = () => {
  const userState = useSelector((store: AppStore) => store.user);
  const navigate = useNavigate();
  return (
    <Box className="content-center" sx={userState.token ? { height: "100%" } : { height: "100vh" }}>
      <Box
        sx={{
          p: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <BoxWrapper sx={{ mb: 6 }}>
          <Typography variant="h1" sx={{ mb: 2.5 }}>
            Error
          </Typography>
          <Typography variant="h5" sx={{ mb: 2.5, letterSpacing: "0.18px", fontSize: "1.5rem !important" }}>
            Something went wrong ⚠️
          </Typography>
          <Typography variant="body2">We couldn&prime;t find the page you are looking for.</Typography>
        </BoxWrapper>
        {/* <Img alt="error-illustration" src="/images/pages/404.png" /> */}
        <Button
          variant="contained"
          sx={{ px: 5.5 }}
          name="button-not-found"
          onClick={() => {
            navigate(0);
          }}
        >
          Refresh
        </Button>
        {/* <FooterIllustrations image={footerImg} /> */}
      </Box>
    </Box>
  );
};

export default ErrorView;

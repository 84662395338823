import { useSelector } from "react-redux";
import { AppStore } from "redux/store";

export default function useAutorizeUserByRole() {
  const userState = useSelector((store: AppStore) => store.user);

  const isUserAuthorized = (authorizedRoles: string[]) => {
    return !!authorizedRoles.find((item) => item === userState?.roles);
  };

  return { isUserAuthorized };
}

import { useEffect, useState } from "react";
//** MUI Imports */
import { Box, Stack, useMediaQuery } from "@mui/material";

//** Hooks */
import { useTranslation } from "react-i18next";
import { useFetchAndLoad, useInputValueContext } from "hooks";
//** Constants */
//** Components */
import { AutoComplete, ClaimMoreOrgSearch, Input } from "components";
import { getAllRegionsAPI } from "pages/locations";
import { IRegion } from "models";
import { useOrganizationContext } from "pages/organizations/context";

export default function ClaimOrgSearch({ onSearch }: { onSearch: () => void }) {
  const {
    nameLocSearchOrg,
    claimStateSearchOrg,
    claimCitySearchOrg,
    claimCountrySearchOrg,
    setNameLocSearchOrg,
    setClaimStateSearchOrg,
    setClaimCitySearchOrg,
    setClaimCountrySearchOrg,
  } = useOrganizationContext();
  const name = useInputValueContext("", setNameLocSearchOrg);
  const [marketOptionsDropdown, setMarketOptionsDropdown] = useState<any>([]);
  const [regionOptions, setRegionOptions] = useState<IRegion[]>([]);

  //** Hooks */
  const fetchRegion = useFetchAndLoad();
  const matches = useMediaQuery("(max-width:600px)");
  const cityInput = useInputValueContext("", setClaimCitySearchOrg);
  const stateInput = useInputValueContext("", setClaimStateSearchOrg);

  const { t } = useTranslation();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    onSearch();
  };

  //clear state when component unmount
  useEffect(() => {
    return () => {
      setClaimCitySearchOrg("");
      setClaimStateSearchOrg("");
      setClaimCountrySearchOrg({ label: "", id: "" });
    };
  }, []);

  const getCountryData = async () => {
    //Get all the regions/markets data on all the countrys

    const autocompleteData = regionOptions.map((item: IRegion) => ({ markets: item.markets }));

    //create a regions/markets array data on all the countrys information
    let array: { label: string; id: string }[] = [];
    autocompleteData.forEach((item) => {
      item.markets?.forEach((market: any) => {
        array = [...array, { label: market.code, id: market.code }];
      });
    });
    setMarketOptionsDropdown([...array]);
  };
  // eslint-disable-next-line consistent-return
  const getRegionData = async () => {
    //Get country data sort by name
    try {
      const query: any = { sort: "asc(name)" };
      const response = await fetchRegion.callEndpoint(getAllRegionsAPI({ query }));
      const { data } = response;

      //Get all the regions/markets data on all the countrys
      const autocompleteData: IRegion[] = data.result.items.map((item: IRegion) => ({ label: item.code, id: item.code }));
      if (regionOptions.length === 0) setRegionOptions(data?.result?.items);
      return autocompleteData;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRegionData();
  }, []);

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Box mt={4} p={4}>
        <form noValidate onSubmit={handleSubmit} autoComplete="chrome-off">
          <Box display="flex" width="100%">
            <ClaimMoreOrgSearch
              noOptionsComponent={
                <>
                  <Stack direction={!matches ? "row" : "column"} spacing={4} mb={8}>
                    <Input
                      name="loc-claim-search"
                      label={`${t("INPUT-SEARCH-MAP")}`}
                      value={nameLocSearchOrg}
                      onChange={name.onChange}
                      fullWidth
                    />
                    <AutoComplete
                      fullWidth
                      value={claimCountrySearchOrg}
                      options={marketOptionsDropdown}
                      setValue={(valueState: any) => {
                        setClaimCountrySearchOrg(valueState);
                      }}
                      name="loc-market-country"
                      blurOnSelect
                      inputLabel={`${t("INPUT-MARKET-COUNTRY")}`}
                      onClick={getCountryData}
                    />
                  </Stack>
                  <Stack direction={!matches ? "row" : "column"} spacing={4} mb={8}>
                    <Input
                      name="loc-state"
                      value={claimStateSearchOrg}
                      onChange={stateInput.onChange}
                      label={`${t("INPUT-STATE")}`}
                      fullWidth
                    />

                    <Input
                      name="loc-city"
                      value={claimCitySearchOrg}
                      onChange={cityInput.onChange}
                      label={`${t("INPUT-CITY")}`}
                      fullWidth
                    />
                  </Stack>
                </>
              }
              onReset={() => {
                setNameLocSearchOrg("");
                setClaimCitySearchOrg("");
                setClaimCountrySearchOrg({ label: "", id: "" });
                setClaimStateSearchOrg("");
                onSearch();
              }}
              onSubmit={handleSubmit}
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
}

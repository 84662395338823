export const LocalStorageKeys = {
  REMEMBER: "remember",
  TOKEN: "TOKEN",
  REMEMBER_TOKEN: "remember_token",
  EMAIL: "email",
  LIST_VIEW: "list_view",
  NOTIFICATIONS_COUNTER: "notifications_counter",
  NOTIFICATIONS_IDS: "notifications_ids",
  LIST_TYPE: "list_type",
};

/**
 *
 * @param {string} key
 * @param {string} value
 */
export const setInLocalStorage = (key: string, value: string) => {
  if (value === undefined || value === null) {
    localStorage.setItem(key, "");
  } else {
    localStorage.setItem(key, value);
  }
};

export const setInLocalStorageArray = (key: string, value: string[]) => {
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 *
 * @param {string} key
 * @returns
 */
export const getInLocalStorage = (key: string) => {
  const result = localStorage.getItem(key);
  return result;
};

export const getInLocalStorageArray = (key: string) => {
  const array = localStorage.getItem(key);
  const result = JSON.parse(`${array}`);
  return result;
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

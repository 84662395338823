import { Box, Fade, Theme, useMediaQuery } from "@mui/material";
import { UsersSpeedDial } from "components/responsiveComponents";
import { RoleGuard } from "guards";
import { Roles } from "models";
import UserTableResponsive from "pages/users/components/responsive/UserTableResponsive/UserTableResponsive";
import UsersTable from "./components/molecules/UsersTable/UsersTable";
import { UsersListContextProvider } from "./context";

export default function UsersList({
  organizationId,
  embebed,
  locationId,
  staticTop,
  height,
  invertBg,
  locationLanding,
}: {
  organizationId?: string;
  embebed?: boolean;
  locationId?: string;
  staticTop?: boolean;
  height?: string;
  invertBg?: boolean;
  locationLanding?: boolean;
}) {
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <UsersListContextProvider>
      <Box m={!underMd ? 4 : 2} mt={14}>
        <Fade in timeout={500}>
          <Box>
            {!underMd && !embebed ? (
              <UsersTable organizationId={organizationId || ""} embebed={embebed} locationId={locationId || ""} />
            ) : (
              <UserTableResponsive
                organizationId={organizationId || ""}
                locationId={locationId || ""}
                embebed={embebed}
                staticTop={staticTop}
                height={height}
                invertBg={!!invertBg}
                locationLanding={locationLanding}
              />
            )}
          </Box>
        </Fade>

        <RoleGuard authorizedRoles={[Roles.superAdmin, Roles.organizationManager, Roles.locationManager, Roles.multiOrgManager]}>
          <Fade in={underMd}>
            <Box position="relative">
              <UsersSpeedDial organizationId={organizationId || ""} embebed={embebed} locationId={locationId || ""} />
            </Box>
          </Fade>
        </RoleGuard>
      </Box>
    </UsersListContextProvider>
  );
}

// ** React Imports
import { ElementType } from "react";

// ** MUI Imports
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled, useTheme } from "@mui/material/styles";
import ListItemButton, { ListItemButtonProps } from "@mui/material/ListItemButton";

// ** Configs Import
import themeConfig from "config/themeConfig";

// ** Types
import { NavLink, NavGroup } from "models/layoutsTypes";
import { Settings } from "context/settingsContext";

// ** Custom Components Imports

import Translations from "layouts/components/Translations";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";

//**Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { useGlobalContext } from "context/globalContext";

interface Props {
  parent?: boolean;
  item: NavLink;
  navHover?: boolean;
  settings: Settings;
  navVisible?: boolean;
  collapsedNavWidth: number;
  navigationBorderWidth: number;
  toggleNavVisibility: () => void;
  isSubToSub?: NavGroup | undefined;
}

// ** Styled Components
const MenuNavLink = styled(ListItemButton)<ListItemButtonProps & { component?: ElementType; href: string; target?: "_blank" | undefined }>(
  ({ theme }) => ({
    width: "100%",
    borderRadius: 8,
    transition: "padding-left .25s ease-in-out",
    "&.active": {
      "&, &:hover": {
        backgroundColor: theme.palette.primary.light,
        "&.Mui-focusVisible": {
          backgroundColor: theme.palette.primary.main,
        },
      },
      "& .MuiTypography-root": {
        fontWeight: 500,
        color: `${theme.palette.common.white} !important`,
      },
      "& .MuiListItemIcon-root": {
        color: `${theme.palette.common.white} !important`,
      },
    },
  }),
);

const MenuItemTextMetaWrapper = styled(Box)<BoxProps>({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  transition: "opacity .25s ease-in-out",
  ...(themeConfig.menuTextTruncate && { overflow: "hidden" }),
});

const VerticalNavLink = ({
  item,
  parent,
  navHover,
  settings,
  navVisible,
  isSubToSub,
  collapsedNavWidth,
  toggleNavVisibility,
  navigationBorderWidth,
}: Props) => {
  // ** Hooks
  const theme = useTheme();
  const location = useLocation();
  const navigation = useNavigate();
  // ** Vars
  const { mode, navCollapsed } = settings;
  const { breadcrumbsHistory } = useGlobalContext();

  //**Redux user role*/
  const userState = useSelector((store: AppStore) => store.user);
  const role = userState.roles;

  const conditionalIconColor = () => {
    if (mode === "semi-dark") {
      return {
        color: `rgba(${theme.palette.customColors.dark}, ${parent ? 0.6 : 0.87})`,
      };
    }
    return {
      color: parent ? "text.secondary" : "text.primary",
    };
  };

  const conditionalBgColor = () => {
    if (mode === "semi-dark") {
      return {
        "&:hover": {
          backgroundColor: `rgba(${theme.palette.customColors.dark}, 0.05)`,
        },
      };
    }
    return {};
  };

  const isNavLinkActive = () => {
    //** If the history is greater than 2, it means that I am in a flow within the same view, */
    if (breadcrumbsHistory.length > 2) {
      const navItemPath = item?.path?.toLocaleLowerCase();
      const firstBreadcrumbLabel = `${breadcrumbsHistory[0].label}`.toLocaleLowerCase();
      const breadcrumbsInNavItem = navItemPath?.includes(firstBreadcrumbLabel);
      return breadcrumbsInNavItem;
    }
    //** When selecting a tab the history starts again, therefore it would be compared with the path.*/
    const urlPathInNavItem = location.pathname.includes(`${item.path}`);
    return urlPathInNavItem;
  };

  // eslint-disable-next-line no-shadow
  const isValidRole = !!item?.roles.find((item) => {
    if (item === "all") {
      return true;
    }
    return item === role;
  });

  if (isValidRole) {
    return (
      <ListItem
        disablePadding
        className="nav-link"
        disabled={item.disabled || false}
        sx={{
          mt: 1.5,
          transition: "padding .25s ease-in-out",
          px: parent ? "0 !important" : `${theme.spacing(navCollapsed && !navHover ? 2 : 3)} !important`,
        }}
      >
        <MenuNavLink
          href=""
          {...(item.disabled && { tabIndex: -1 })}
          className={isNavLinkActive() ? "active" : ""}
          {...(item.openInNewTab ? { target: "_blank" } : null)}
          onClick={(e) => {
            navigation(item.path === undefined ? "/a" : `${item.path}`);
            if (item.path === undefined) {
              e.preventDefault();
              e.stopPropagation();
            }
            if (navVisible) {
              toggleNavVisibility();
            }
          }}
          sx={{
            py: 2.25,
            ...conditionalBgColor(),
            ...(item.disabled ? { pointerEvents: "none" } : { cursor: "pointer" }),
            pr: navCollapsed && !navHover ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8 : 3,
            pl: navCollapsed && !navHover ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8 : 4,
          }}
        >
          {isSubToSub ? null : (
            <ListItemIcon
              sx={{
                ...conditionalIconColor(),
                transition: "margin .25s ease-in-out",
                ...(navCollapsed && !navHover ? { mr: 0 } : { mr: 2 }),
                ...(parent ? { ml: 2, mr: 4 } : {}), // This line should be after (navCollapsed && !navHover) condition for proper styling
                "& svg": {
                  ...(!parent ? { fontSize: "1.5rem" } : { fontSize: "0.5rem" }),
                  ...(parent && item.icon ? { fontSize: "0.875rem" } : {}),
                },
              }}
            >
              <Icon sx={!parent ? { fontSize: "2rem" } : { fontSize: "0.8rem", mt: 3 }}>{item.icon}</Icon>
            </ListItemIcon>
          )}

          <MenuItemTextMetaWrapper
            sx={{
              ...(isSubToSub ? { ml: 8 } : {}),
              ...(navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 }),
            }}
          >
            <Typography
              {...((themeConfig.menuTextTruncate || (!themeConfig.menuTextTruncate && navCollapsed && !navHover)) && {
                noWrap: true,
              })}
            >
              <Translations text={item.title} />
            </Typography>
            {item.badgeContent ? (
              <Chip
                size="small"
                label={item.badgeContent}
                color={item.badgeColor || "primary"}
                sx={{ ml: 1.5, "& .MuiChip-label": { px: 2.5, lineHeight: 1.385, textTransform: "capitalize" } }}
              />
            ) : null}
          </MenuItemTextMetaWrapper>
        </MenuNavLink>
      </ListItem>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default VerticalNavLink;

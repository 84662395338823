import { Box, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";

interface IProps {
  show: boolean;
}
export default function TopLoader({ show }: IProps) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <>
      {show && (
        <Box sx={{ width: "100%", position: "fixed", top: "0", left: "0", zIndex: "1001" }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
    </>
  );
}

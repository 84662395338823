/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { IsPhoneNumber } from "class-validator";

interface ILocationsRowsDOM {
  id: string;
  name: string;
  region: string;
  market: string;
  purpose: string;
  visibility: boolean;
  organization: string;
  alert?: boolean;
  address1: string;
}

export type DataLocation = ILocationsRowsDOM;

export type Order = "asc" | "desc";

export interface HeadCellLocation {
  disablePadding: boolean;
  id: keyof DataLocation;
  label: string;
  numeric: boolean;
  actions?: any;
  hidden?: boolean;
}

export interface IBulkLocation {
  is_visible?: boolean;
  organization_id?: string;
  item_id: string[];
}

export type ILocationCSVRow = {
  phone: string;
};

export interface IDeleteLocations {
  items?: string[];
}

type ILocationValidations = {
  phone: string;
};
export class LocationValidations implements ILocationValidations {
  @IsPhoneNumber(undefined, { message: "Phone number must be valid" })
  phone!: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Alert, Box, useTheme } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Papa from "papaparse";
import Translations from "layouts/components/Translations";
import { useLocationContext } from "pages/locations/context";

import useValidateImportFields from "pages/locations/hooks/useValidateImportFields";
import "./style.css";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { Roles } from "models";
import useValidateImportFieldsNoAdmin from "pages/locations/hooks/useValidateImportFieldsNoAdmin";
import { locationImportHeaders, locationImportHeadersNonAdmins } from "./tableData";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.black,
    fontSize: 12,
    "&:first-child": {
      borderRadius: "10px 0 0 0",
    },
    "&:last-child": {
      borderRadius: "0 10px 0 0",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    minWidth: 200,
    "&:first-child": {
      minWidth: 20,
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
interface ExcelDataItem {
  label: string;
  value: string;
  haveAnError?: boolean;
}

export default function ImportTableLocation({ file }: { file: File }) {
  const [excelData, setExcelData] = useState<ExcelDataItem[][]>([]);
  const userData = useSelector((store: AppStore) => store.user);
  const { roles } = userData;
  const isAdmin = roles === Roles.superAdmin;

  const [excelKeys, setExcelKeys] = useState<any>([]);
  const [excelErrorLength, setExcelErrorLength] = useState<number>(0);

  const theme = useTheme();
  const { validateImportFields } = useValidateImportFields();
  const { validateImportFieldsNonAdmin } = useValidateImportFieldsNoAdmin();
  const { setExcelDataLenght, setExcelErrors } = useLocationContext();

  const tableHeadKeys = isAdmin ? locationImportHeaders : locationImportHeadersNonAdmins;

  const handleFilePapa = () => {
    if (!file) return;
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete(results) {
        const rowsArray: any[] = [];
        // Iterating data to get column name and their values

        let array: any[] = [];
        results.data.forEach((row: any) => {
          if (array.length + 1 > 100) return;
          const rowData = isAdmin ? validateImportFields(row, rowsArray) : validateImportFieldsNonAdmin(row, rowsArray);
          if (rowData.length !== 0) {
            array = [...array, isAdmin ? validateImportFields(row, rowsArray) : validateImportFieldsNonAdmin(row, rowsArray)];
          }
        });

        setExcelData(array || []);
        setExcelKeys(rowsArray[0]);
        setExcelErrorLength(rowsArray[0].length);
        setExcelErrors(array.length > 0);
        setExcelDataLenght(array.length + 1);
      },
    });
  };

  useEffect(() => {
    handleFilePapa();
  }, [file]);

  if (excelData.length === 0) return null;
  if (excelData.length > 100) {
    return (
      <Alert severity="error" sx={{ "& .MuiPaper-root": { padding: "0" }, mb: 8 }} className="alert-no-padding">
        <Translations text="ALERT-ERROR-ON-IMPORTS" />
      </Alert>
    );
  }

  return (
    <>
      <Alert severity="error" sx={{ "& .MuiPaper-root": { padding: "0 !important" }, mb: 8 }}>
        <Translations text="ALERT-ERROR-ON-IMPORTS" />
      </Alert>
      <Box sx={{ maxHeight: "500px", overflow: "scroll" }} className="tableFixHead">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell> </StyledTableCell>
              {tableHeadKeys.map((headerkey, index: number) => (
                <StyledTableCell
                  sx={
                    headerkey !== excelKeys[index]
                      ? {
                          [`&.${tableCellClasses.head}`]: {
                            color: `${theme.palette.mode === "dark" ? theme.palette.error.dark : theme.palette.error.light}`,
                          },
                        }
                      : {}
                  }
                >
                  {headerkey}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {excelData.map((item: any, index: number) => (
              <>
                {item.length > excelErrorLength && (
                  <StyledTableRow key={item}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    {item.map((data: any, dataIndex: number) => {
                      return (
                        <>
                          {dataIndex < excelErrorLength && (
                            <StyledTableCell
                              style={
                                data.haveAnError && {
                                  color: `${theme.palette.mode === "dark" ? theme.palette.error.dark : theme.palette.error.light}`,
                                }
                              }
                            >
                              {data.value}
                            </StyledTableCell>
                          )}
                        </>
                      );
                    })}
                  </StyledTableRow>
                )}
              </>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}

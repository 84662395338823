import { useState } from "react";
import { useLocationListContext } from "../pages";

export default function useLocationTableItems({ handleClickTableCheckboxMobile }: { handleClickTableCheckboxMobile: Function }) {
  const { selectedClaim } = useLocationListContext();

  const [hoverItem, setHoverItem] = useState<string>("");
  const [scrolling, setScrolling] = useState(false);
  const [longPressTimer, setLongPressTimer] = useState<number | NodeJS.Timeout>(0);

  const handleTouchStart = (itemId: string, hq: boolean, status: boolean) => {
    if (scrolling) return;
    setHoverItem(itemId);

    const timer = setTimeout(
      () => {
        // Handle long press here (e.g., item selection)
        handleClickTableCheckboxMobile(itemId, hq, status);
      },
      selectedClaim.length > 0 ? 300 : 500,
    ); // Adjust the delay as needed for your long press threshold
    setLongPressTimer(timer);
  };

  const handleTouchMove = () => {
    if (!scrolling) {
      //When moving the user its scrolling
      setScrolling(true);
      //clear the timer (USER CAN'T SELECT WHILE SCROLLING)
      clearTimeout(longPressTimer);
    }
  };

  const handleTouchEnd = () => {
    setHoverItem("");
    // Clear the timer when the touch ends
    // Start a timer when the user touches an item
    setScrolling(false);
    clearTimeout(longPressTimer);
  };

  return { handleTouchStart, handleTouchMove, handleTouchEnd, hoverItem, setHoverItem };
}

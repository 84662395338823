//** Components imports */
import { PrivateRoutes } from "models/routes";
//** External imports */
import { Navigate, Outlet, useLocation } from "react-router-dom";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";

export default function LoginGuard() {
  const userState = useSelector((store: AppStore) => store.user);
  const location = useLocation();

  return userState?.token ? <Navigate to={PrivateRoutes.ORGANIZATIONS} replace={false} state={{ location }} /> : <Outlet />;
}

//** Components imports */
import { Box } from "@mui/material";
import { SectionsTitles } from "components";
//**Hooks */
import { useTranslation } from "react-i18next";
//** Context */
import { useLocationContext } from "pages/locations/context";
import OpenHoursInputs from "./OpenHoursInputs";

export default function OpenHours() {
  const { openingHours, setOpeningHours, googleMapsInputs } = useLocationContext();
  //** Hooks */
  const { t } = useTranslation();
  return (
    <Box mt={12}>
      <SectionsTitles title={`${t("GLOBAL-TITLE-BUSINESS-INFO")}`} subTitle={`${t("STEP-TITLE-OPEN-OPEN-HOURS")}`} mb={4} h2 />

      {openingHours?.map((data: any, index: number) => (
        <OpenHoursInputs
          data={data}
          index={index}
          openingHours={openingHours}
          setOpeningHours={setOpeningHours}
          googleMapsInputs={googleMapsInputs}
        />
      ))}
    </Box>
  );
}

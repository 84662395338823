import { getOneFromUrl, putFromUrl } from "utilities";
import { IUsersFromAPI } from "pages/users/models";

export interface IUpdateBodyUser {
  username: string | null;
  first_name: string;
  last_name: string;
  phone: string;
  description: string;
  sbd_contact: string;
  roles: string[];
  organization_id: string[];
  location_id: string;
  designation: string;
}

interface ISuspendedBody {
  is_suspended: boolean;
}
export interface IUpdateBodyUserAPI {
  body: Partial<IUpdateBodyUser>;
}

export const updateUsersAPI = (id: string, { body }: IUpdateBodyUserAPI) => {
  return putFromUrl<any, Partial<IUpdateBodyUser>>(`users/${id}/update-one`, body);
};
export const suspendUsersAPI = (id: string, isSuspended: boolean) => {
  const body = {
    is_suspended: isSuspended,
  };
  return putFromUrl<any, ISuspendedBody>(`users/${id}/suspend-user`, body);
};

export const getOneUserAPI = (id: string) => {
  return getOneFromUrl<IUsersFromAPI>(`users/${id}/get-one`);
};

/* eslint-disable no-nested-ternary */
// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";

// ** Type Imports
import { CustomRadioIconsProps } from "./types";

function CustomRadioIcons(props: CustomRadioIconsProps) {
  // ** Props
  const { data, icon, name, selected, gridProps, handleChange, color = "primary" } = props;

  const { title, value, content } = data;

  const renderComponent = () => {
    return (
      <Grid item {...gridProps}>
        <Box
          onClick={() => handleChange(value)}
          sx={{
            px: 4,
            py: 12,
            height: "100%",
            display: "flex",
            borderRadius: 1,
            cursor: "pointer",
            position: "relative",
            alignItems: "center",
            flexDirection: "column",
            border: (theme) => `1px solid ${theme.palette.divider}`,
            ...(selected === value
              ? { borderColor: `${color}.main` }
              : { "&:hover": { borderColor: (theme) => `rgba(${theme.palette.customColors.main}, 0.25)` } }),
          }}
        >
          {data?.icon ? <>{data.icon}</> : null}
          {title ? (
            typeof title === "string" ? (
              <Typography variant="h2" sx={{ fontWeight: 700, ...(content ? { mb: 4, mt: 4 } : { my: "auto" }) }}>
                {title}
              </Typography>
            ) : (
              title
            )
          ) : null}
          {content ? (
            typeof content === "string" ? (
              <Typography variant="body2" sx={{ my: "auto", textAlign: "center" }}>
                {content}
              </Typography>
            ) : (
              content
            )
          ) : null}
          <Radio
            name={name}
            color={color}
            value={value}
            onChange={handleChange}
            checked={selected === value}
            sx={{
              mb: -2,
              "& .MuiSvgIcon-root": {
                fontSize: 22,
              },
              ...(!icon && !title && !content && { mt: -2 }),
            }}
          />
        </Box>
      </Grid>
    );
  };

  return data ? renderComponent() : null;
}

export default CustomRadioIcons;

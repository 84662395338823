import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetUser } from "redux/slices/user.slice";

export default function useObserverStore() {
  const dispatch = useDispatch();
  function isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  useEffect(() => {
    window.addEventListener("storage", (event) => {
      const oldEventValue = event?.oldValue && isJsonString(event.oldValue) ? JSON.parse(event.oldValue) : null;
      const newEventValue = event?.newValue && isJsonString(event.newValue) ? JSON.parse(event.newValue) : null;
      const oldToken = oldEventValue?.token || null;
      const newToken = newEventValue?.token || null;
      if (oldToken !== newToken && !newToken) {
        dispatch(resetUser());
      }
      if (oldToken !== newToken && newToken) {
        window.location.reload();
      }
    });
    return () => window.removeEventListener("storage", () => {});
  }, []);
}

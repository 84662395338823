// ** Type import
import { VerticalNavItemsType } from "core/layouts/types";
import { PrivateRoutes, Roles } from "models";
import { t } from "i18next";

const navigation = (): VerticalNavItemsType => {
  const routeArray: VerticalNavItemsType = [
    {
      sectionTitle: `${t("NAV-BAR-TITLE-2")}`,
      roles: [Roles.superAdmin],
    },
    {
      title: `${t("NAV-BAR-ITEM-7")}`,
      icon: "key",
      path: `/${PrivateRoutes.API_TOKEN}`,
      roles: [Roles.superAdmin],
    },
    {
      title: `${t("NAV-BAR-ITEM-5")}`,
      icon: "history_outlined",
      path: `/${PrivateRoutes.CHANGELOG}`,
      roles: [Roles.superAdmin],
    },
  ];

  return [
    {
      sectionTitle: `${t("NAV-BAR-TITLE-1")}`,
      roles: ["all"],
    },

    {
      title: `${t("NAV-BAR-ITEM-1")}`,
      icon: "corporate_fare",
      path: `/${PrivateRoutes.ORGANIZATIONS}`,
      roles: [Roles.superAdmin, Roles.multiOrgManager, Roles.multiOrgViewer],
    },
    {
      title: `${t("NAV-BAR-ITEM-6")}`,
      icon: "corporate_fare",
      path: `/${PrivateRoutes.ORGANIZATIONS}`,
      roles: [Roles.locationManager, Roles.locationViewer, Roles.organizationManager, Roles.organizationViewer],
    },
    {
      title: `${t("NAV-BAR-ITEM-2")}`,
      icon: "place_outlined",
      path: `/${PrivateRoutes.LOCATIONS}`,
      roles: ["all"],
    },
    {
      title: `${t("NAV-BAR-ITEM-3")}`,
      icon: "group",
      path: `/${PrivateRoutes.USERS}`,
      roles: [
        Roles.locationManager,
        Roles.locationViewer,
        Roles.organizationManager,
        Roles.organizationViewer,
        Roles.multiOrgManager,
        Roles.multiOrgViewer,
      ],
    },
    {
      title: `${t("NAV-BAR-ITEM-3")}`,
      icon: "group",
      roles: [Roles.superAdmin],
      children: [
        {
          title: `${t("NAV-BAR-ITEM-3")}`,
          path: `/${PrivateRoutes.USERS}`,
          icon: "circle",
          roles: [Roles.superAdmin],
        },
        {
          title: `${t("NAV-BAR-ITEM-4")}`,
          path: `/${PrivateRoutes.USERS_REQUEST}`,
          icon: "circle",
          roles: [Roles.superAdmin],
        },
      ],
    },
    ...routeArray,
  ];
};

export default navigation;

/* eslint-disable no-nested-ternary */
//** React imports */
import { useEffect, useState } from "react";
//** Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
//**Services */
import { getAllLocationsAPI, getAllNextLocationsAPI } from "pages/locations/services";
//**Hooks */
import { useAsync, useFetchAndLoad, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import { useGlobalContext } from "context/globalContext";
//**Model */
import { ILocationsListDOM, ILocationsListFromAPI, ILocationsLoadHook, ILocationsSearchHook } from "pages/locations/models";
//**Utils */
import { createQuery } from "utilities";
//** Context */
import { PrivateRoutes } from "models";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { locationGetallAdapter, useLocationListContext } from "pages/locations";
import { Methods } from "constants/globalConstants";
import { LocsSearchFields } from "../../locationsList/hooks/useLocationSearch";

export default function useLocationClaims(organizationId: string | undefined) {
  //** Context */
  const { rowsPerPageLoc, locationStatus, claimCitySearch, claimCountrySearch, claimStateSearch, claimSearchToggle } =
    useListAndSearchContext();
  const {
    locationsMapListArray,
    setLocationsMapListArray,
    setLocationsMapMarksArray,
    setSelectedClaim,
    toggleClaim,
    latitudeClaimMap,
    setLatitudeClaimMap,
    longitudeClaimMap,
    setLongitudeClaimMap,
    toggleChangeCoordinates,
  } = useLocationListContext();

  //**Search */
  const { searchParamsLocs } = useListAndSearchContext();
  const nameLocSearch = searchParamsLocs.get(LocsSearchFields.name);
  const visibilitySearch = searchParamsLocs.get(LocsSearchFields.visibility);
  const marketSearch = searchParamsLocs.get(LocsSearchFields.marketId);
  const purposeSearch = searchParamsLocs.get(LocsSearchFields.purposeCodes);
  const organizationsearch = searchParamsLocs.get(LocsSearchFields.organization);

  const { breadcrumbsHistory } = useGlobalContext();
  //** State */

  const [searchToggle, setSearchToggle] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<string | null>(null);

  const limit = rowsPerPageLoc;

  //** Hooks */
  const { loading, callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const { createCustomHistory, addToTheEndOfHistory } = useBreadcrumbs();

  //**Redux */
  const userState = useSelector((store: AppStore) => store.user);
  useEffect(() => {
    if (userState.organization?.id) {
      createCustomHistory([
        {
          label: userState.organization.name,
          url: `/${PrivateRoutes.ORGANIZATIONS_DASHBOARD}`,
          index: 0,
        },
        {
          label: "Locations Claim",
          url: `/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.CLAIM_LOCATION}`,
          index: breadcrumbsHistory.length,
        },
      ]);
    } else {
      addToTheEndOfHistory({
        label: "Locations Claim",
        url: `/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.CLAIM_LOCATION}`,
        index: breadcrumbsHistory.length,
      });
    }
  }, []);

  const loadLocations: any = async ({
    name,
    market_or_country,
    purpose,
    is_visible,
    organization_id,
    location_status,
    city,
    state,
    latitude,
    longitude,
  }: ILocationsLoadHook) => {
    //Create an object with key that have some search value
    //If the table doesn't have a filter it will be sorted by creation
    let query = createQuery<ILocationsSearchHook>({
      name,
      market_or_country,
      purpose,
      is_visible: is_visible?.length > 0 ? `${is_visible === "Visible"}` : "",
      organization_id: userState?.organization?.id ? `${userState?.organization.id}` : organization_id,
      location_status: location_status || Methods.UNCLAIMED,
      city,
      state,
      coordinates: latitude && longitude ? `${latitude},${longitude}` : undefined,
    });
    query = { offset: 0, limit, ...query };

    //Calling get all API
    const response = await callEndpoint(getAllLocationsAPI({ query }), () => handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"));
    return response;
  };

  const adaptInfo = (data: ILocationsListFromAPI) => {
    //Save the adapted data and the last page and length for the pagination
    const array: ILocationsListDOM[] = locationGetallAdapter(data?.result?.items);
    setLocationsMapListArray([...array]);
    setLocationsMapMarksArray(
      array.map((item) => ({
        lat: item.address.coordinates[0],
        lng: item.address.coordinates[1],
        id: item.id,
        name: item.name,
        status: item.isVisible,
        address: item.address.address1,
      })),
    );

    //** Update the page to call the next data on api */
    setPage((prev: number) => prev + 1);
    //** If dosen`t appear more data sent that there are not more information*/
    const nextPageString = data?.result?.next
      ? data?.result?.next.includes("https")
        ? data?.result?.next
        : data?.result?.next.replace("http", "https")
      : null;
    setHasNextPage(nextPageString);
  };

  const loadMoreLocations = async () => {
    //Google API doesn't allow two request at the same time
    //then every request will be delayed 1.2 seconds for the next call
    const delayTimer = setTimeout(async () => {
      //Calling get all API
      const response = await callEndpoint(getAllNextLocationsAPI(hasNextPage || ""), () =>
        handleCreateToast(t("ALERT-GLOBAL-ERROR-GET"), "error"),
      );
      const { data } = response;
      //Save the adapted data and the last page and length for the pagination
      const array: ILocationsListDOM[] = locationGetallAdapter(data?.result?.items);
      setLocationsMapListArray((prev) => [...prev, ...array]);
      setLocationsMapMarksArray((prev) => [
        ...prev,
        ...array.map((item) => ({
          lat: item.address.coordinates[0],
          lng: item.address.coordinates[1],
          id: item.id,
          name: item.name,
          status: item.isVisible,
          address: item.address.address1,
        })),
      ]);
      //** Update the page to call the next data on api */
      setPage((prev: number) => prev + 1);

      //** If dosen`t appear more data sent that there are not more information*/
      const nextPageString = data?.result?.next
        ? data?.result?.next.includes("https")
          ? data?.result?.next
          : data?.result?.next.replace("http", "https")
        : null;
      setHasNextPage(nextPageString);
      // eslint-disable-next-line consistent-return
      return response;
    }, 1200);

    return () => clearTimeout(delayTimer);
  };

  useAsync(
    () =>
      loadLocations({
        name: nameLocSearch,
        market_or_country: (marketSearch?.label || claimCountrySearch?.label) ?? "",
        purpose: purposeSearch ?? "",
        is_visible: visibilitySearch,
        organization_id: organizationId || organizationsearch.id,
        location_status: locationStatus,
        city: claimCitySearch,
        state: claimStateSearch,
        latitude: latitudeClaimMap,
        longitude: longitudeClaimMap,
      }),
    adaptInfo,
    () => {},
    [searchToggle, userState?.organization?.id, claimSearchToggle],
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const onSearch = () => {
    setSearchToggle((value) => !value);
    setLocationsMapListArray([]);
    setPage(0);
    setSelectedClaim([]);

    //** When search by filters the filters must be the main search */
    //** Then when i move the map and ge coordinates this lat,long must be the main search */
    setLatitudeClaimMap(undefined);
    setLongitudeClaimMap(undefined);
  };

  const onSearchWithCoordinates = () => {
    setSearchToggle((value) => !value);
    setLocationsMapListArray([]);
    setPage(0);
    setSelectedClaim([]);
    setHasNextPage(null);
  };

  useEffect(() => {
    if (toggleClaim === null) return;
    onSearch();
  }, [toggleClaim]);

  //**Search by coordinates */
  useEffect(() => {
    if (!latitudeClaimMap) return;
    if (!longitudeClaimMap) return;
    onSearchWithCoordinates();
  }, [latitudeClaimMap, longitudeClaimMap, toggleChangeCoordinates]);

  // * ------------------------SORT----------------------------------//

  return {
    locationsMapListArray,
    loading,
    limit,
    handleChange,
    onSearch,
    loadMoreLocations,
    hasNextPage,
    page,
  };
}

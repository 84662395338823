import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomRadioIconsData } from "components";

import { PrivateRoutes } from "models";
import { useNavigate, useParams } from "react-router-dom";
//* Icons
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import { useListAndSearchContext } from "context/ListsAndSearchContext";

export default function useEmptyCardsLocationData() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setOpenImportModal, setOpenImportModalUser } = useListAndSearchContext();

  //** Var */
  const { id } = useParams();

  const fromAdminOrgView = window.location.pathname.includes(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.INFORMATION_NAV}`);
  const adminNavigation = `/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.CLAIM_LOCATION_NAV}/${id}`;
  const organizationRelatedNavigation = `/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.CLAIM_LOCATION_NAV}`;
  const dataCards: CustomRadioIconsData[] = [
    {
      isSelected: true,
      value: "1",
      title: `${t("EMPTY-CARD-1-TITLE")}`,
      icon: <AddLocationAltOutlinedIcon sx={{ fontSize: 26 }} />,
      content: (
        <Typography variant="body2" sx={{ my: "auto", textAlign: "center" }}>
          {`${t("EMPTY-CARD-1-DESCRIPTION")}`}
        </Typography>
      ),
      action: () => navigate(`/${PrivateRoutes.LOCATIONS}/${PrivateRoutes.CREATE}`),
    },
    {
      value: "2",
      title: `${t("EMPTY-CARD-2-TITLE")}`,
      icon: <FlagOutlinedIcon sx={{ fontSize: 26 }} />,
      content: (
        <Typography variant="body2" sx={{ my: "auto", textAlign: "center" }}>
          {`${t("EMPTY-CARD-2-DESCRIPTION")}`}
        </Typography>
      ),
      action: () => navigate(fromAdminOrgView ? adminNavigation : organizationRelatedNavigation, { replace: false }),
      hide: !fromAdminOrgView,
    },
    {
      value: "3",
      title: `${t("EMPTY-CARD-3-TITLE")}`,
      icon: <FileUploadOutlinedIcon sx={{ fontSize: 26 }} />,
      content: (
        <Typography variant="body2" sx={{ my: "auto", textAlign: "center" }}>
          {`${t("EMPTY-CARD-3-DESCRIPTION")}`}
        </Typography>
      ),
      action: () => {
        setOpenImportModal(true);
      },
    },
  ];

  const dataUsersCards: CustomRadioIconsData[] = [
    {
      isSelected: true,
      value: "1",
      title: `${t("EMPTY-CARD-4-TITLE")}`,
      icon: <PersonAddAltOutlinedIcon sx={{ fontSize: 26 }} />,
      content: (
        <Typography variant="body2" sx={{ my: "auto", textAlign: "center" }}>
          {`${t("EMPTY-CARD-4-DESCRIPTION")}`}
        </Typography>
      ),
      action: () => navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.CREATE}`),
    },
    {
      value: "2",
      title: `${t("EMPTY-CARD-3-TITLE")}`,
      icon: <FileUploadOutlinedIcon sx={{ fontSize: 26 }} />,
      content: (
        <Typography variant="body2" sx={{ my: "auto", textAlign: "center" }}>
          {`${t("EMPTY-CARD-3-1-DESCRIPTION")}`}
        </Typography>
      ),
      action: () => {
        setOpenImportModalUser(true);
      },
    },
  ];

  const dataOrganizationsCards: CustomRadioIconsData[] = [
    {
      isSelected: true,
      value: "1",
      title: `${t("EMPTY-CARD-1-TITLE")}`,
      icon: <AddBusinessOutlinedIcon sx={{ fontSize: 26 }} />,
      content: (
        <Typography variant="body2" sx={{ my: "auto", textAlign: "center" }}>
          {`${t("EMPTY-CARD-5-DESCRIPTION")}`}
        </Typography>
      ),
      action: () => navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.CREATE}`),
    },
  ];
  return { dataCards, dataUsersCards, dataOrganizationsCards };
}

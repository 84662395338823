//** MUI Imports */
import { Box, Typography } from "@mui/material";
//** HOOKS */
import { useFetchAndLoad, useToastMui } from "hooks";
import { useTranslation } from "react-i18next";
//**Redux */
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";
import { Roles } from "models";
import { createQuery } from "utilities";

//** Services */
import { exportModalActions } from "constants/globalConstants";
import { useParams } from "react-router-dom";
import { downloadFileFromResponse } from "utilities/HelpService";
import { useListAndSearchContext } from "context/ListsAndSearchContext";
import { useGlobalContext } from "context/globalContext";
import { getLocationExportAPI } from "../services";
import { useLocationListContext } from "../pages";
import { ILocationsSearchHook } from "../models";
import { LocsSearchFields } from "../pages/locationsList/hooks/useLocationSearch";

export default function useExportLocation() {
  const { callEndpoint, loading } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();

  //** Redux */
  const userData = useSelector((store: AppStore) => store.user);
  const { roles } = userData;
  const isAdmin = roles === Roles.superAdmin;
  const { id } = useParams();

  const { exportLocationFilter } = useGlobalContext();
  const { selectedClaim, locationsArray } = useLocationListContext();

  //**Search */
  const { searchParamsLocs } = useListAndSearchContext();
  const nameLocSearch = searchParamsLocs.get(LocsSearchFields.name);
  const visibilitySearch = searchParamsLocs.get(LocsSearchFields.visibility);
  const marketSearch = searchParamsLocs.get(LocsSearchFields.marketId);
  const purposeSearch = searchParamsLocs.get(LocsSearchFields.purposeCodes);
  const organizationsearch = searchParamsLocs.get(LocsSearchFields.organization);

  const exportLocations = async () => {
    handleCreateToast(
      <Box>
        <Typography variant="h5" mb={4} fontWeight={700}>
          {t("LOC-IMPORT-NOTIFICATION-EXPORT-TITLE")}
        </Typography>
        <Typography fontSize={6}>{t("LOC-IMPORT-NOTIFICATION-DESC-2")}</Typography>
      </Box>,
      "notification",
      true,
      { horizontal: "right", vertical: "top" },
    );
    try {
      let query = createQuery<Partial<ILocationsSearchHook>>({
        name: nameLocSearch,
        market_or_country: marketSearch?.label ?? "",
        purpose: purposeSearch ?? "",
        is_visible: visibilitySearch?.length > 0 ? `${visibilitySearch === "Visible"}` : "",
      });
      const orgQuery = createQuery<Partial<ILocationsSearchHook>>({
        organization_id: userData?.organization?.id ? `${userData?.organization.id}` : id,
      });
      const orgQueryFilter = createQuery<Partial<ILocationsSearchHook>>({
        organization_id: userData?.organization?.id ? `${userData?.organization.id}` : id || organizationsearch.id,
      });

      let exportItems = "";

      if (exportLocationFilter === exportModalActions.EXPORT_CURRENT_PAGE) exportItems = locationsArray.map((item) => item.id).toString();
      if (exportLocationFilter === exportModalActions.EXPORT_ALL) query = { ...orgQuery, org_users: !isAdmin };
      if (exportLocationFilter === exportModalActions.EXPORT_SELECTED_ITEMS) exportItems = selectedClaim.map((item) => item.id).toString();
      if (exportLocationFilter === exportModalActions.EXPORT_CURRENT_SEARCH) query = { ...query, ...orgQueryFilter, org_users: !isAdmin };

      if (
        exportLocationFilter === exportModalActions.EXPORT_CURRENT_PAGE ||
        exportLocationFilter === exportModalActions.EXPORT_SELECTED_ITEMS
      ) {
        query = { search: exportItems || "empty" };
      }

      const response = await callEndpoint(getLocationExportAPI({ query }), async (error: any) => {
        const responseObj = await error?.response?.data?.text();
        handleCreateToast(JSON.parse(responseObj)?.message || t("ALERT-ERROR-ON-EXPORT"), "error");
      });
      downloadFileFromResponse(response, ".csv", "locations");
    } catch (error) {
      console.log(error);
    }
  };

  return { loading, exportLocations };
}

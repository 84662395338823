/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

import { SxProps } from "@mui/material";

//**ROLES */
export enum Roles {
  superAdmin = "Super Admin",
  multiOrgManager = "Multi Organization Manager",
  multiOrgViewer = "Multi Organization Viewer",
  organizationManager = "Organization Manager",
  organizationViewer = "Organization Viewer",
  locationManager = "Location Manager",
  locationViewer = "Location Viewer",
  sbdWebsite = "SBD Website",
}
//**ROLES */
export enum RolesIds {
  superAdmin = "6494c30774434e042097dc96",
  multiOrgManager = "665f1206520b5a1e21684973",
  multiOrgViewer = "64a5a647fe0e394d298939ad",
  organizationManager = "64a5a616fe0e394d298939ac",
  organizationViewer = "64b0349037532310d02d0e7a",
  locationManager = "64b0321a37532310d02d0e74s",
  locationViewer = "64b032af37532310d02d0e77",
  sbdWebsite = "SBD Website",
}

export enum RolesColors {
  superAdmin = "#EE7D04",
  multiOrganization = "#814BC9",
  organization = "#CC06CC",
  location = "#00736D",
}

export enum RolesBgColors {
  superAdmin = "#FDF6ED",
  multiOrganization = "#F7EFFE",
  organization = "#FFEDFF",
  location = "#E3F8FF",
}

export interface IGlobalProps {
  name: string;
  id?: string;
  className?: string;
  style?: any;
  sx?: SxProps;
}

// ** Inputs **

export enum InputVariants {
  filled = "filled",
  outlined = "outlined",
  standard = "standard",
}

export enum InputSizes {
  small = "small",
  medium = "medium",
}

export enum InputMargins {
  normal = "normal",
  dense = "dense",
  none = "none",
}
export enum InputColors {
  primary = "primary",
  secondary = "secondary",
  success = "success",
  danger = "danger",
  warning = "warning",
  info = "info",
  light = "light",
  dark = "dark",
}

//** Buttons */

export enum ButtonVariants {
  text = "text",
  contained = "contained",
  outlined = "outlined",
}

export enum ButtonColors {
  primary = "primary",
  secondary = "secondary",
  success = "success",
  error = "error",
  warning = "warning",
  info = "info",
  light = "light",
  dark = "dark",
}
export enum ButtonSizes {
  small = "small",
  medium = "medium",
  large = "large",
}

//** ALERTS */

export enum AlertSeverity {
  error = "error",
  warning = "warning",
  info = "info",
  success = "success",
}

export enum AlertVariants {
  outlined = "outlined",
  filled = "filled",
}

//** PROGRESS */

export enum ProgressColor {
  primary = "primary",
  secondary = "secondary",
  success = "success",
  danger = "danger",
  warning = "warning",
  info = "info",
  inherit = "inherit",
}

export enum ProgressVariant {
  determinate = "determinate",
  indeterminate = "indeterminate",
  buffer = "buffer",
}

//**SWITCH */
export enum SwitchSizes {
  small = "small",
  medium = "medium",
}

export enum SwitchColors {
  primary = "primary",
  secondary = "secondary",
  success = "success",
  danger = "danger",
  warning = "warning",
  info = "info",
  inherit = "inherit",
}

/** Select*/

export interface SelectVariants {
  filled: "filled";
  standard: "standard";
}

/** TABLE */
export interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: any;
  orderBy: any;
  rowCount: number;
  headCells: any[];
  checkbox?: boolean;
}

import { useFetchAndLoad } from "hooks";
import { IRegion } from "models";
import { getAllPurposesAPI, getAllRegionsAPI } from "pages/locations/services";
import { getAllOrganizationsAPI } from "pages/organizations";
import { useState } from "react";

export default function useGetSearchData() {
  const fetchApps = useFetchAndLoad();
  const fetchCountry = useFetchAndLoad();
  const fetchOrganizations = useFetchAndLoad();
  const [purposeOptions, setPurposeOptions] = useState<any[]>([]);

  //**Organization infinite scroll states */
  const [orgPage, setOrgPage] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [orgData, setOrgData] = useState<any[]>([]);

  const getPurposes = async () => {
    //Call applications
    const response = await fetchApps.callEndpoint(getAllPurposesAPI({}));
    const { data } = response;

    const purposeData = data?.result.items.map((purpose: any) => ({ label: purpose.name, id: purpose.code, code: purpose.code }));

    setPurposeOptions([...purposeData]);
    return [...purposeData];
  };

  const getCountryData = async () => {
    //Get country data sort by name
    const query: any = { sort: "asc(name)" };
    const response = await fetchCountry.callEndpoint(getAllRegionsAPI({ query }));
    const { data } = response;

    //Get all the regions/markets data on all the countrys
    const autocompleteData: IRegion[] = data.result.items.map((item: IRegion) => ({ markets: item.markets }));

    //create a regions/markets array data on all the countrys information
    let array: any[] = [];
    autocompleteData.forEach((item) => {
      item.markets?.forEach((market: any) => {
        array = [...array, { label: market.code, id: market.code }];
      });
    });
    return array;
  };

  const getOrganizations = async (name: string) => {
    // eslint-disable-next-line no-param-reassign
    if (name === null) name = "";
    //** Provide next on api call */
    setHasNextPage(true);
    try {
      //** Setup */
      const limit = 20;
      let query: any = { limit, offset: !name ? orgPage * limit : 0, sort: "asc(name)" };
      if (name) {
        query = {
          name: typeof name === "string" ? name : "",
          ...query,
        };
      }
      //** Create querys with variable offset */
      const response = await fetchOrganizations.callEndpoint(getAllOrganizationsAPI({ query }));
      const { data } = response;

      //** Generate the data with label and id for autocomplete */
      const autocompleteData = data.result.items.map((item: any) => ({ label: item.name, id: item.id }));

      //** If the name has no value, all results are displayed from an infinite scroll */
      if (!name) {
        //** Create an array with the previuous data an the new data */
        if (orgPage !== 0) {
          setOrgData((prev: any) => [...prev, ...autocompleteData]);
        } else {
          setOrgData(autocompleteData);
        }

        //** Update the page to call the next data on api */
        setOrgPage((prev: number) => prev + 1);

        //** If dosen`t appear more data sent that there are not more information*/
        if (!data?.result?.next) setHasNextPage(false);
      } else {
        setOrgPage(0);
        //** Create an array with the previuous data an the new data */
        setOrgData([...autocompleteData]);
      }
    } catch (error) {
      console.error("Error gewtting organizations data", error);
    }
  };
  return {
    getPurposes,
    getCountryData,
    getOrganizations,
    purposeOptions,
    orgPage,
    hasNextPage,
    orgData,
    setOrgPage,
    setHasNextPage,
    setOrgData,
    loadingOrg: fetchOrganizations.loading,
    loadingCountry: fetchCountry.loading,
    loadingPurpose: fetchApps.loading,
  };
}

/* eslint-disable nonblock-statement-body-position */
/* eslint-disable curly */
/* eslint-disable dot-notation */
import axios, { AxiosStatic as HttpHandler, AxiosResponse, AxiosRequestConfig } from "axios";
import { PublicRoutes, SBD_EMAIL_REGEX, UserEmptyState } from "models";
import { HTTPCodesEnum } from "models/httpcodes.models";
import { resetUser } from "redux/slices/user.slice";
import { store } from "redux/store";
import { logOutFromSSO } from "services";

const unlessPaths = [
  `/${PublicRoutes.LOGIN}`,
  "/",
  `/${PublicRoutes.HEALTHY}`,
  `/${PublicRoutes.CHANGE_PASSWORD}`,
  `/${PublicRoutes.EMAIL_VERIFICATION}`,
  `/${PublicRoutes.CHANGE_PASSWORD_GET_EMAIL}`,
  `/${PublicRoutes.PINGID_AUTORIZED}`,
];
const apiUrlsNotSendToken = ["maps.googleapis.com"];
const setInterceptors = () => {
  let data = UserEmptyState;

  const listener = () => {
    data = store.getState().user;
  };

  store.subscribe(listener);

  const unless = (url: string, paths: string[]) => {
    return !paths.findIndex((path) => url.includes(path));
  };

  const onRequest = (request: AxiosRequestConfig<any>) => {
    const URL = request.url;

    if (URL && !unless(URL, unlessPaths))
      if (apiUrlsNotSendToken?.findIndex((item) => `${request.url}`.includes(item)) === -1)
        if (request.headers && data.token) {
          request.headers["Authorization"] = `Bearer ${data.token}`;
        }
    return request;
  };

  const onError = (err: any) => {
    return Promise.reject(err);
  };

  axios.interceptors.request.use(onRequest, onError);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const URL = window.location.pathname;
      if (URL && !unless(URL, unlessPaths)) {
        if (error?.response?.status === HTTPCodesEnum.UNAUTHORIZED || error?.response?.status === HTTPCodesEnum.FORBIDDEN) {
          store.dispatch(resetUser());
          if (SBD_EMAIL_REGEX.test(data.email)) logOutFromSSO();
          // window.location.replace(`/${PublicRoutes.LOGIN}`);
        }
        console.log({ error });
      }
      return Promise.reject(error);
    },
  );
};

const service = {
  axios,
};
export default service;
export { axios, setInterceptors };
export type { HttpHandler, AxiosResponse };

import { TextField } from "@mui/material";
import { IInputProps } from "./interface";

function Input(props: IInputProps) {
  const {
    onChange,
    type = "text",
    name,
    placeholder,
    style = {},
    value,
    className = "",
    id,
    strink,
    helperText = "",
    required,
    label,
    defaultValue,
    autoComplete,
    readonly,
    size,
    margin,
    fullWidth,
    focused,
    multiline,
    maxRows,
    rows,
    color,
    onClick,
    endAdornment,
    startAdornment,
    error,
    sx,
    disabled,
    inputProps,
    maxLength,
    changeControllerFn,
  } = props;

  return (
    <TextField
      sx={sx}
      id={id}
      className={className}
      type={type}
      name={`input-${name}`}
      placeholder={placeholder}
      style={style}
      onChange={(e) => {
        onChange(e);
        changeControllerFn && changeControllerFn();
      }}
      onClick={onClick}
      value={value ?? ""}
      helperText={error ? helperText : null}
      required={required}
      InputLabelProps={{ shrink: strink }}
      label={label}
      defaultValue={defaultValue}
      autoComplete={autoComplete}
      InputProps={{
        readOnly: readonly,
        endAdornment,
        startAdornment,
        ...inputProps,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{ maxLength: maxLength || 120, step: 0.5 }}
      size={size}
      margin={margin}
      fullWidth={fullWidth}
      focused={focused}
      multiline={multiline}
      maxRows={maxRows}
      rows={rows}
      error={error}
      onWheel={type === "number" ? (e: any) => e.target.blur() : () => {}}
      color={color}
      disabled={disabled}
    />
  );
}
export default Input;

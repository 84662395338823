import { MouseEventHandler } from "react";
import { IconButton, Tooltip } from "@mui/material";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppStore } from "redux/store";

export default function ReturnDataIcon({
  onClick,
  authorizedRoles,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  authorizedRoles?: string[];
}) {
  const { t } = useTranslation();
  const userState = useSelector((store: AppStore) => store.user);
  return (
    <Tooltip title={t("TOOLTIP-RESET-DATA")} placement="top">
      <IconButton onClick={onClick} disabled={authorizedRoles ? !authorizedRoles?.find((item) => item === userState?.roles) : false}>
        <KeyboardReturnIcon />
      </IconButton>
    </Tooltip>
  );
}

import { getOneFromUrl, putFromUrl } from "utilities";
import { IOrganizationFromAPI } from "pages/organizations/models";

export interface IUpdateBodyOrganization {
  name: string;
  description: string;
  // eslint-disable-next-line no-undef
  logo?: File | string; //Change to File
  is_suspended: boolean;
  notification_time: number;
}
export interface IUpdateBodyOrganizationAPI {
  body: IUpdateBodyOrganization;
}

export const getOneOrganizationAPI = (id: string) => {
  return getOneFromUrl<IOrganizationFromAPI>(`organizations/${id}`);
};

export const updateOrganizationsAPI = (id: string, { body }: IUpdateBodyOrganizationAPI) => {
  return putFromUrl<unknown, IUpdateBodyOrganization>(`organizations/${id}`, body);
};

// ** React Imports
import React, { Dispatch, Fragment, SetStateAction } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// ** Icon Imports
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
// ** Third Party Components

import { useDropzone } from "react-dropzone";
//** Hooks */
import { useToastMui } from "hooks";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

//** Style */
import "./style.css";
import { useTranslation } from "react-i18next";
import { FilePresent } from "@mui/icons-material";

interface FileProp {
  name: string;
  type: string;
  size: number;
}

export default function CSVUploader({
  files,
  setFiles,
  disabled,
  removeFunction,
}: {
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  disabled?: boolean;
  removeFunction?: () => void;
}) {
  // ** Hooks
  const { handleCreateToast } = useToastMui();
  const theme: any = useTheme();
  const matches = useMediaQuery("(max-width:900px)");
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 3000000,
    disabled: files.length > 0 || disabled,
    accept: {
      "file/*": [".csv"],
    },
    onDrop: (acceptedfiles: any) => {
      setFiles(acceptedfiles.map((file: File) => Object.assign(file)));
    },
    onDropRejected: () => {
      handleCreateToast("You can only upload 1 .csv file & maximum size of 3 MB.", "error");
    },
  });

  const handleRemoveFile = (file: FileProp) => {
    const uploadedFiles = files;
    const filtered = uploadedFiles.filter((i: FileProp) => i.name !== file.name);
    setFiles([...filtered]);
    if (removeFunction) removeFunction();
  };

  const fileList = files?.map((file: FileProp) => (
    <React.Fragment key={`file-${file.name}`}>
      <>
        <Box
          sx={{ backgroundColor: "#F5F5F5", width: 50, height: 50, borderRadius: "10px" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          mr={8}
        >
          <FilePresent color="secondary" sx={{ fontSize: 24 }} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", textAlign: ["center", "center", "inherit"] }}>
          <Typography variant="h5" fontWeight={700}>
            {file.name}
          </Typography>

          <Typography className="file-size" variant="body2">
            {Math.round(file.size / 100) / 10 > 1000
              ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
              : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
          </Typography>
        </Box>
      </>
      {!matches && (
        <IconButton onClick={() => handleRemoveFile(file)} sx={{ margin: "0 20px 0 auto" }} disabled={disabled}>
          <CloseIcon />
        </IconButton>
      )}
    </React.Fragment>
  ));

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  return (
    <>
      <div {...getRootProps({ className: `dropzone ${theme?.palette?.mode}` })}>
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", flexDirection: ["column", "column", "row"], alignItems: "center" }}>
          {files.length ? (
            <>{fileList}</>
          ) : (
            <>
              <Box sx={{ display: "flex", flexDirection: "column", textAlign: ["center", "center", "inherit"] }} ml={8}>
                <Typography variant="h5" fontWeight={700}>
                  {t("FILE-TITLE")}
                </Typography>
                <Typography color="textSecondary">{t("FILE-ALLOWED-CSV")}</Typography>
              </Box>
              {!matches && (
                <Button
                  name="button-file-upload"
                  variant="outlined"
                  startIcon={<FileUploadIcon />}
                  color="secondary"
                  size="large"
                  sx={{ margin: "0 20px 0 auto" }}
                >
                  {t("BUTTON-UPLOAD")}
                </Button>
              )}
            </>
          )}
        </Box>
      </div>
      {files.length ? (
        <>
          {matches && (
            <Box sx={{ float: "right" }} my={4}>
              <Button name="button-file-upload-remove" color="error" variant="outlined" onClick={handleRemoveAllFiles} disabled={disabled}>
                {t("BUTTON-REMOVE")}
              </Button>
            </Box>
          )}
        </>
      ) : null}
    </>
  );
}

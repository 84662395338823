import { ModalConfirm } from "components";
import { useLocationContext } from "pages/locations/context";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function ModalGoBackLocation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { goBackModalLoc, setGoBackModalLoc } = useLocationContext();

  const handleCloseDelete = () => {
    setGoBackModalLoc((prev) => {
      if (prev) return { ...prev, open: false };
      return null;
    });
  };

  const handleConfirm = () => {
    if (`${goBackModalLoc?.urlToGo}` === "-1") {
      navigate(-1);
    } else {
      navigate(`${goBackModalLoc?.urlToGo}`, { replace: false });
    }
    setGoBackModalLoc(null);
  };

  return (
    <ModalConfirm
      open={goBackModalLoc?.open || false}
      title={t("GLOBAL-GO-BACK-MODAL-TITLE")}
      description={t("GLOBAL-GO-BACK-MODAL-DESCRIPTION")}
      textButtonConfirm={t("BUTTON-GO-BACK")}
      handleClose={handleCloseDelete}
      handleConfirm={handleConfirm}
      sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
      sxTitle={{ fontSize: "2rem !important" }}
    />
  );
}
